<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{ $t('我的消息.我的消息') }}</p>
            <div>
              <span class="editBtn hand" @click="allMsg" style="margin-right:10px;background:#F3F5F8;color:#333">{{ $t('我的消息.全部标记为已读')
                }}</span>
              <!-- <span class="editBtn hand">{{ $t('我的消息.删除已读消息') }}</span> -->
            </div>
          </div>
          <div class="detailSele">
            <div class="seleLeft">
              <p @click="tabClick(1)" :class="state == 1 ? 'active' : 'hand hover'">
                {{ $t('我的消息.全部') }}{{ $t('我的消息.(') }}{{tableData.length}}{{ $t('我的消息.)') }}</p>
              <!-- <span>|</span> -->
              <!-- <p @click="tabClick(2)" :class="state == 2 ? 'active' : 'hand hover'">{{ $t('我的消息.未读') }}</p> -->
              <!-- <span>|</span> -->
              <!-- <p @click="tabClick(3)" :class="state == 3 ? 'active' : 'hand hover'">{{ $t('我的消息.已读') }}</p> -->
            </div>
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{ $t('我的消息.信息标题') }}</th>
                <th>{{ $t('我的消息.状态') }}</th>
                <th>{{ $t('我的消息.发送时间') }}</th>
                <th>{{ $t('我的消息.操作') }}</th>
              </tr>
              <tr class="trCon hand" v-for="(item, index) in tableData" @click="detailClick(item)">
                <td>
                  <div class="descCss" style="width: 300px;">
                    <p class="allBtn" style="margin-right:10px">
                      <img @click="flag = !flag"
                        :src="flag ? require('@/assets/member/xz.png') : require('@/assets/member/wxz.png')" />
                    </p>
                    <div>
                      <p>
                        {{ $i18n.locale=='zh'?item.messageName:item.messageNameEn }}
                        <img v-if="item.messageStatus==1" src="@/assets/member/msg.png" />
                      </p>
                    </div>
                  </div>
                </td>
                <td class="dj">
                  <p v-if="item.messageStatus==1" style="width:80px">{{ $t('我的消息.未读') }}</p>
                  <p v-if="item.messageStatus==2" style="width:80px">{{ $t('我的消息.已读') }}</p>
                </td>
                <td class="xj">
                  <p style="width:130px">{{item.createTime}}</p>
                </td>
                <td>
                  <div class="editTab hand">
                    <p @click="pageClick(item)">{{ $t('我的消息.查看详情') }}</p>
                    <p class="activeP" @click.stop="delMessage(item)">{{ $t('我的消息.移除') }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import { messageList } from "@/api/api";
import {editRead,msgdelete} from '@/util/http'
export default {
  components: { carTopNav, bottomNav, myInfoLeft },
  data() {
    return {
      loading: false,
      flag: false,
      state: 1,//1商品收藏，2文章收藏
      options: [],
      value: '',
      value1: '',
      tableData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    pageClick(item){
      this.$router.push({
        path: '/msgDetail',
        query: { id: item.id }
      })
    },
    delMessage(item){
      msgdelete(item.id).then(res=>{
        this.$message(res.msg)
        this.getData()
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    allMsg(){
      editRead().then(res=>{
        this.$message(res.msg)
        this.getData()
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    async getData() {
      const res = await messageList({
        // messageStatus:this.state-1
      })
      this.tableData=res.rows
    },
    detailClick(item) {
      this.$router.push({
        path: '/msgDetail',
        query: { id: item.id }
      })
    },
    tabClick(num) {
      this.state = num;
      this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .detailSele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: #D9D9D9 solid 1px;

      .seleLeft {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;

        p {
          padding-bottom: 11px;
        }

        span {
          color: #D9D9D9;
          margin: 0 14px;
          padding-bottom: 11px;
        }

        .active {
          font-weight: bold;
          font-size: 16px;
          color: #012F9C;
          line-height: 19px;
          border-bottom: 2px #FF6600 solid;
        }
      }
    }

    .selectDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .seleLeft {
        display: flex;
        flex-wrap: wrap;
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        grid-gap: 20px;

        //border: solid 1px;
        //margin-right: 30px;
        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            width: 200px;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            height: 36px;
          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }

        .ac {
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }

        .exportbtn {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        display: flex;

        .goodsPhoto {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
        word-break: break-all;
      }

      .editTab {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }

        .activeP {
          color: #FF0000;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px;

        /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {}

        .trTit {
          height: 48px;
          line-height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #FFFFFF;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          display: flex;
          align-items: center;
          text-align: center;

          img {
            width: 22px;
            height: 22px;
            margin-left: 5px;
          }
        }

        .xj p {
          text-align: center;
          display: block
        }
      }

    }
  }

  .dialogThreeCss {
    padding: 0 60px;
    margin: auto;

    .checkCss {
      text-align: center;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;

      span {
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }

      input {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #999999;
        width: 278px;
        height: 26px;
      }

      .infoSub {
        display: flex;
        align-content: center;
        margin: 20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right: 15px;
        width: 100px;
      }
    }
  }

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;

    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
}
</style>