<template>
  <div>
    <div class="box_1 w_1200">
      <p>{{ $t('首页.代理品牌商品精选推荐') }}</p>
      <div class="sp">
        <div class="l_btn hand">
          <img alt="" src="@/assets/home/icon/rI2.png"/>
        </div>
        <div class="xd">
          <div ref="swiperContainer" class="swiper-container_1 hand">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in list" :key="index" class="swiper-slide"
                   @click="goodsClick(item)">
                <img v-if="item.productPhoto" :src="$baseUrl + item.productPhoto"/>
                <p>{{ $i18n.locale == 'zh' ? item.productName : item.productNameEn }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="r_btn hand">
          <img alt="" src="@/assets/home/icon/rI.png"/>
        </div>
      </div>
      <div class="loadMore hand hover">{{ $t('首页.查看更多') }} <img
          alt="" src="@/assets/home/icon/more.png"/></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {getHandpickList} from '@/util/http';

export default {
  data() {
    return {
      list: [],
      swiper: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      getHandpickList().then(res => {
        this.list = res.rows;

        if (this.list.length !== 0) {
          // 如果数据不足5条，填充数据以支持无限滚动
          while (this.list.length < 5) {
            this.list.push(...res.rows); // 重复数据
          }
          this.$nextTick(() => {
            this.initializeSwiper();
          });
        }
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg);
        }
      });

    },
    initializeSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.swiper = new Swiper(this.$refs.swiperContainer, {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,
        freeMode: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".r_btn",
          prevEl: ".l_btn",
        },
      });
    },
    goodsClick(item) {
      this.$router.push({
        path: '/goods',
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>


<style lang='scss' scoped>
.box_1 {
  margin-top: 40px;
  margin-bottom: 19px;
  color: #000000;

  p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 19px;
  }

  .loadMore {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: fit-content;

    img {
      margin-left: 3px;
      width: 17px;
      height: 17px;
    }
  }

  .sp {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 230px;

    .l_btn,
    .r_btn {
      width: 54px;
      height: 70px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .xd {
      width: 85%;
    }
  }

  .swiper-container_1 {
    overflow: hidden;
  }

  .swiper-wrapper {
    height: 230px;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
    border-radius: 4px;

    p {
      font-size: 13px;
    }

    img {
      margin-bottom: 13px;
      width: 140px;
      height: 140px;
      border-radius: 0;
    }
  }
}
</style>
