<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="twoModule w_1200">
      <div class="twoTop">
        <p class="topMsg">{{ $t('商品目录.当前') }}<span
            style="color:#000">{{
            $i18n.locale == 'zh' ? categoryName : categoryNameEn
          }}</span>{{ $t('商品目录.共') }}<span
            style="color:#FF6600">{{ num }}</span>{{ $t('商品目录.件相关商品') }}</p>
        <div class="filtrateDiv">
          <!--    品牌单独计算      -->
          <div class="fileSub">
            <p>{{ $t('品牌资源.品牌') }}</p>
            <div class="searDiv">
              <div></div>
              <input v-model="nameValue" :placeholder="$t('商品目录.输入搜索')" type="text"
                     @input="productClassification"/>
            </div>
            <div class="fileModule">
              <div v-for="item in ProductBrandList"
                   :class=" item.id == productBrandId ? 'div1':'div'" @click="load2(item)">
                {{ item.nickName }}
              </div>
            </div>
          </div>
          <div v-for="(item,index) in ProductClassificationList" class="fileSub">
            <p>{{ item.nickName }}</p>
            <div class="searDiv">
              <div></div>
              <input v-model="item.nameValue" :placeholder="$t('商品目录.输入搜索')" type="text"
                     @input="productClassification"/>
            </div>
            <div class="fileModule">
              <div v-for="item in item.productSpecificationList"
                   :class=" item.id == SpecificationIdList[index] ? 'div1':'div'"
                   @click="load(item,index)">
                {{ item.productCategorySpecificationValue }}
              </div>
            </div>
          </div>


        </div>
        <p class="fileBot">{{ $t('商品目录.仅显示授权代理品牌') }}</p>
        <div class="checkCss">
          <el-checkbox v-model="hasStock" :false-label="0" :true-label="1" :label="$t('商品目录.有库存')"
                       @change="getList"/>
          <el-checkbox v-model="hasPrice" :false-label="0" :true-label="1" :label="$t('商品目录.有价格')"
                       @change="getList"/>
          <el-checkbox v-model="isRoHSCompliant" :false-label="0" :true-label="1" :label="$t('商品目录.符合RoHS')"
                       @change="getList"/>
          <el-checkbox v-model="hasDataSheet" :false-label="0" :true-label="1" :label="$t('商品目录.有数据手册')"
                       @change="getList"/>
          <el-checkbox v-model="isHidden" :false-label="0" :true-label="1" :label="$t('商品目录.隐藏不可选')"
                       @change="getList"/>
        </div>
      </div>
      <div class="twoBottom">
        <div class="tabTop">
          <div class="fileBtn active">{{ $t('商品目录.综合排序') }}</div>
          <div :class=" stockOrder ===1 ? 'fileBtn1 hand':'fileBtn hand' " @click="bottle(1)">
            <span>{{ $t('商品目录.库存') }}</span><img
              alt="" src="@/assets/sub/sx.png"></div>
          <div :class=" priceOrder ===1 ? 'fileBtn1 hand':'fileBtn hand' " @click="bottle(2)">
            <span>{{ $t('商品目录.价格') }}</span><img
              alt="" src="@/assets/sub/sx.png"></div>
          <div class="fileSea">
            <input v-model="searchCriteria" :placeholder="$t('商品目录.从结果中搜索')" type="text"
                   @input="getList"/>
            <img alt="" src="@/assets/sub/sea.png">
          </div>
          <p class="allT">{{ $t('商品目录.共2') }}{{ total }}{{ $t('商品目录.条') }}</p>
          <!-- <p class="allT" style="margin:0 17px">{{pageNum}}<span>/{{total}}</span></p> -->
          <div class="pageBtn hand">
            <div @click="pageChange('left')"><img src="@/assets/sub/le.png"/></div>
            <div class="pageRig" @click="pageChange('rig')"><img src="@/assets/sub/le.png"/></div>
          </div>
        </div>
        <div class="tabDiv">
          <table>
            <tr>
              <th>{{ $t('商品目录.型号') }}</th>
              <th>{{ $t('商品目录.商品信息') }}</th>
              <th>{{ $t('商品目录.库存') }}</th>
              <th>
                <p @click="bottle(2)">{{ $t('商品目录.单价(含税)') }}<img
                    :class=" priceOrder ===1 ? 'img1 hand':'img hand' "
                    src="@/assets/sub/hsx.png"/></p>
                <input v-model="priceInput" :placeholder="$t('商品目录.按需求数量显示价格')"
                       type="text" @input="priceClick"/>
              </th>
              <th>{{ $t('商品目录.交期(工作日)') }}</th>
              <th>{{ $t('商品目录.起订/递增') }}</th>
              <th>{{ $t('商品目录.数量') }}</th>
              <th>{{ $t('商品目录.操作') }}</th>
            </tr>


            <tr v-for="item in list" class="subTr">
              <td class="tdOne hand" @click="detailClick(item)">
                <img :src="$baseUrl+item.productPhoto" class="god"/>
                <p style="display: flex;align-items: center;">
                  <img :src="require(item.collectBoolean>0?'@/assets/member/sc.png':'@/assets/member/favo.png')"
                       class="favo"/>
                  <span>{{ item.manufacturerNumber }}</span>
                </p>
              </td>
              <td class="tdTwo">
                <!-- <p>{{$t('商品目录.供应商')}}YaoGo（国剧）</p> -->
                <p>{{ $t('商品目录.品牌') }}{{
                    $i18n.locale == "zh" ? item.productBrandName : item.productBrandNameEn
                  }}</p>
                <p v-for="item2 in item.children">
                  {{
                    $i18n.locale == "zh" ? item2.productCategorySpecificationName
                        : item2.productCategorySpecificationNameEn
                  }}
                  :
                  {{ item2.productCategorySpecificationValue }}
                </p>
                <p>{{ $t('商品目录.分类') }}{{
                    $i18n.locale == "zh" ? item.productCategoryName : item.productCategoryNameEn
                  }}</p>
                <!-- <p>{{$t('商品目录.描述')}}djfhsk JHSD 678K 12% dsjf jash</p> -->
              </td>
              <td class="tdThree">{{ $t('商品目录.库存：') }}<span>{{ item.inventory }}</span></td>
              <td class="tdFour">
                <p v-for="item3 in item.priceChildren">
                  <!-- v-if="unitPrice ?  unitPrice>= item3.quantity : true"> -->
                  {{ item3.quantity }} : <span>¥{{ item3.unitPrice }}  </span>
                </p>
              </td>
              <td class="tdFive">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox :label="$t('商品目录.大陆7-9')"></el-checkbox>
                  <el-checkbox :label="$t('商品目录.海外3-6')"></el-checkbox>
                </el-checkbox-group>
              </td>
              <td class="tdSix">
                <p>{{ $t('商品目录.起订量') }}{{ item.minNumber }}</p>
                <p>{{ $t('商品目录.递增量') }}1</p>
              </td>
              <td class="tdSeven">
                <el-input-number v-model="item.buyNumber" :max="item.inventory*1"
                                 :min="item.minNumber*1"
                                 size="mini" @change="(e)=>handleChange(e,item)"></el-input-number>
                <p>{{ $t('商品目录.合计') }}<span>¥{{ item.subTotal }}</span></p>
              </td>
              <td class="tdEight hand">
                <p @click="payClick(item)">{{ $t('商品目录.立即购买') }}</p>
                <p class="carBtn" @click="addCarClick(item)">{{ $t('商品目录.加入购物车') }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {goodsGetList, userShoppingCar} from '@/util/http'
import Cookies from "js-cookie";
import {OneUnifiedWayToPlaceYourOrderNow} from "@/api/myInfo/addCarClick";
import {
  getProductBrandList,
  getProductClassificationList
} from "@/api/myInfo/productClassification";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      priceInput: '',
      num: '1',
      nameValue: "",
      categoryName: '',
      categoryNameEn: '',
      unitPrice: "",
      checkList: ['选中且禁用', '隐藏不可选'],
      classfyId: '',
      list: [],
      pageSize: 6,//每页显示条数
      pageNum: 1,
      total: 0,
      ProductBrandList: [],
      ProductClassificationList: [],
      //品牌id
      productBrandId: "",
      //规格id的搜索条件(多个,分割)
      SpecificationIdList: [],
      //搜索条件
      searchCriteria: "",
      // 是否有库存
      hasStock: 0,
      // 是否有价格
      hasPrice: 0,
      // 是否符合RoHS
      isRoHSCompliant: 0,
      // 是否有数据手册
      hasDataSheet: 0,
      // 是否隐藏不可选
      isHidden: 0,
      // 库存倒序
      stockOrder: 0,
      // 价格倒序
      priceOrder: 0,
    }
  },
  created() {
    this.classfyId = this.$route.query.classfyId
    this.categoryName = this.$route.query.categoryName
    this.categoryNameEn = this.$route.query.categoryNameEn
    this.num = this.$route.query.num
    this.getList()
    this.productClassification()
  },
  methods: {
    load2(item) {
      if (this.productBrandId !== item.id && this.productBrandId !== '') {
        this.productBrandId = item.id
      } else if (this.productBrandId !== '') {
        this.productBrandId = ""
      } else {
        this.productBrandId = item.id
      }
      this.getList()
    },
    load(item, val) {
      if (this.SpecificationIdList[val] !== item.id && this.SpecificationIdList[val] !== "") {
        this.SpecificationIdList[val] = item.id
      } else if (this.SpecificationIdList[val] !== "") {
        this.SpecificationIdList[val] = ""
      } else {
        this.SpecificationIdList[val] = item.id
      }
      let f = true;
      this.SpecificationIdList.forEach(e => {
        if (e !== "") {
          f = false;
        }
      })
      if (f) {
        this.SpecificationIdList = []
      }

      this.getList()
    },
    productClassification(val) {
      getProductBrandList({productCategoryId: this.classfyId, nickName: this.nameValue}).then(
          res => {
            this.ProductBrandList = res.data
          })

      let nameValue = ""
      if (val) {
        this.ProductClassificationList.forEach(e => {
          if (e.nameValue != "") {
            nameValue = nameValue + e.nameValue + ","
          } else {
            nameValue = nameValue + "..." + ","
          }
        })
        nameValue = nameValue.substring(0, nameValue.lastIndexOf(','));
      }
      getProductClassificationList({productCategoryId: this.classfyId, nickName: nameValue}).then(
          res => {
            console.log(res.data)
            this.ProductClassificationList = res.data
          })
    },
    bottle(val) {
      if (val === 1) {
        if (this.stockOrder === 0) {
          this.stockOrder = 1
        } else {
          this.stockOrder = 0
        }
      } else {
        if (this.priceOrder === 0) {
          this.priceOrder = 1
        } else {
          this.priceOrder = 0
        }
      }
      this.getList()

    },
    getList() {
      let SpecificationIdListStr = [...this.SpecificationIdList]
      goodsGetList({
        productCategoryId: this.classfyId,
        pageSize: this.pageSize,
        //品牌id
        productBrandId: this.productBrandId,
        //规格id的搜索条件(多个,分割)
        specificationIds: SpecificationIdListStr.length > 0 ? SpecificationIdListStr.join(',')
            : '',
        // 是否有库存（0：否，1：是）
        hasStock: this.hasStock,
        // 是否有价格（0：否，1：是）
        hasPrice: this.hasPrice,
        // 是否符合RoHS（0：否，1：是）
        isRoHSCompliant: this.isRoHSCompliant,
        // 是否有数据手册（0：否，1：是）
        hasDataSheet: this.hasDataSheet,
        // 是否隐藏不可选（0：否，1：是）
        isHidden: this.isHidden,
        // 库存倒序（0：倒序，1：升序）
        stockOrder: this.stockOrder,
        // 价格倒序（0：倒序，1：升序）
        priceOrder: this.priceOrder,
        // 搜索条件
        searchCriteria: this.searchCriteria,

      }).then(res => {
        // this.list=res.rows
        this.total = res.total
        this.list = res.rows.map(row => ({
          ...row,
          buyNumber: 1,
          unitPrice: this.getUnitPrice(row.priceChildren, row.minNumber * 1),
          subTotal: this.calculateSubTotal(row.priceChildren, row.minNumber * 1)
        }));
        console.log(this.list[0])
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getUnitPrice(children, buyNumber) {//根据用户输入的数量找到合适的单价
      let prevQuantity = 0;
      let prevUnitPrice = null;

      for (const child of children) {
        if (buyNumber <= child.quantity) {
          return prevUnitPrice || child.unitPrice;
        }
        prevQuantity = child.quantity;
        prevUnitPrice = child.unitPrice;
      }

      return children[children.length - 1].unitPrice;
    },
    calculateSubTotal(children, buyNumber, item) {                  //根据单价和用户输入的数量计算小计金额
      const unitPrice = this.getUnitPrice(children, buyNumber);
      if (item && item.id) {
        this.list.map(i => {
          if (i.id == item.id) {
            return i.subTotal = unitPrice * buyNumber
          }
        })
      }
      return unitPrice * buyNumber;
    },
    priceClick() {
      this.list.map(i => {
        i.buyNumber = this.priceInput * 1
        this.handleChange(i, this.priceInput * 1)
      })
    },
    detailClick(item) {//查看详情
      this.$router.push({
        path: '/goods',
        query: {
          id: item.id
        }
      })
    },
    async payClick(item) {//立即购买
      let res = await OneUnifiedWayToPlaceYourOrderNow(JSON.parse(Cookies.get('userInfo')).userId,item.id,item.buyNumber)
      Cookies.set('carId', res.data)
      await this.$router.push('/order')
    },
    addCarClick(item) {//加入购物车
      userShoppingCar({
        userId: JSON.parse(Cookies.get('userInfo')).userId,
        productId: item.id,
        buyNumber: item.buyNumber
      }).then(res => {
        this.$message(res.msg)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    handleChange(item, e) {//订单数量
      console.log(e, item)
      this.list.map(i => {
        if (i.id == item.id) {
          i.buyNumber =
              this.calculateSubTotal(item.priceChildren, e, item)
        }
      })
    },
    pageChange(text) {//当前页
      if (text == 'left') {
        if (this.pageNum == 1) {
          return
        }
        this.pageNum--
      } else {
        if (this.total >= this.pageSize) {
          this.pageNum++
        } else {
          this.$message(this.$t('公用.没有更多了'))
        }
      }
      console.log(this.pageNum)
      this.getList()
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .twoModule {
    margin: 40px auto;

    .twoTop {
      height: 396px;
      background: #FFFFFF;
      padding: 13px 20px;
      box-sizing: border-box;

      .topMsg {
        font-size: 12px;
        color: #777777;
      }

      .filtrateDiv {
        margin: 15px 0;
        height: 230px;
        //border: solid 1px;
        display: flex;
        overflow-x: auto;
      }

      .fileSub {
        margin-right: 10px;
        width: 202px;
        height: 213px;
        background: #FFFFFF;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #E2E2E2;
        font-size: 13px;
        color: #313131;

        .searDiv {
          display: flex;
          background: #FFFFFF;
          align-items: center;
          margin: 10px 0 8px 0;
          border: 1px solid #E2E2E2;

          div {
            width: 30px;
            height: 25px;
            background-image: url("@/assets/sub/fd.png");
            background-size: 60%;
            background-repeat: no-repeat;
            background-position: center;
            border-right: 1px solid #E2E2E2;
          }

          input {
            height: 25px;
            flex: 1;
            border: none;
          }
        }

        .fileModule {
          height: 140px;
          overflow-y: auto;
          //padding-top:6px;
          box-sizing: border-box;

          .div1 {
            width: 95%;
            background-color: rgb(1, 47, 156);
            padding: 10px 4px;
            margin: 2px 0;
            font-size: 12px;
            color: white;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }

          .div {
            width: 95%;
            padding: 10px 4px;
            margin: 2px 0;
            font-size: 12px;
            color: #000000;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }

      .fileBot {
        font-size: 20px;
        color: #000000;
        margin: 15px 0;
      }

      .checkCss {
        :deep .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #000;
        }

        :deep .el-checkbox__label {
          font-size: 19px;
          color: #333;
          font-weight: 400;
        }

        :deep .el-checkbox__inner {
          background: linear-gradient(180deg, #F6F6F6 0%, #E7E7E7 100%);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #A7A7A7;
        }

        :deep .el-checkbox__inner {
          width: 21px;
          height: 21px;
          line-height: 21px;
        }

        :deep .el-checkbox__inner::after {
          border: 2px solid #012F9C;
          height: 10px;
          width: 4px;
          left: 6px;
          position: absolute;
          top: 2px;
          border-left: 0;
          border-top: 0;
        }
      }
    }

    .twoBottom {
      .tabTop {
        margin-top: 20px;
        width: 1200px;
        height: 83px;
        background: #012F9C;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fileBtn1 {
          margin-left: 18px;
          width: 92px;
          height: 38px;
          border: 2px #FFFFFF solid;
          background: #4F77D4;
          font-size: 17px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            transform: rotateX(180deg);
            width: 23px;
            height: 23px;
            margin-left: 8px;
          }
        }


        .fileBtn {
          margin-left: 18px;
          // width: 92px;
          padding:0 5px;
          height: 38px;
          background: #4F77D4;
          border: 2px #4F77D4 solid;
          font-size: 17px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 23px;
            height: 23px;
            margin-left: 8px;
          }
        }

        .active {
          background: #fff;
          font-size: 18px;
          color: #012F9C;
        }

        .fileSea {
          padding: 8px;
          box-sizing: border-box;
          width: 412px;
          height: 38px;
          background: #F5F5F5;
          display: flex;
          align-items: center;
          margin: 0 6px 0 38px;
          justify-content: space-between;

          input {
            line-height: 23px;
            background: #F5F5F5;
            border: none;
            flex: 1;
          }

          img {
            width: 23px;
            height: 23px;
          }
        }

        .allT {
          font-size: 21px;
          color: #FFFFFF;

          span {
            color: #CCC5C8;
          }
        }

        .pageBtn {
          display: flex;
          align-items: center;

          div {
            height: 80px;
            padding: 0 26px;
            display: flex;
            align-items: center;
            border-left: #4F77D4 1px solid;
          }

          img {
            width: 29px;
            height: 29px;
          }

          .pageRig img {
            transform: rotate(180deg);
          }
        }
      }

      .tabDiv {
        table {
          width: 1200px;
        }

        tr {
          width: 1200px;
          // height: 62px;
          background: #E1E1E1;
        }

        tr th {
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          color: #000000;
          padding: 8px 0;
          box-sizing: border-box;

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
          }


          .img {
            width: 19px;
            height: 19px;
            margin-left: 8px;
          }

          .img1 {
            transform: rotateX(180deg);
            width: 19px;
            height: 19px;
            margin-left: 8px;
          }

          input {
            text-indent: 1.5rem;
            width: 166px;
            height: 31px;
            background: #FFFFFF;
            border: none;
          }
        }

        .subTr {
          margin-bottom: 10px;
          border: 1px solid #E1E1E1;
          background: #ffffff;
          //padding-bottom: 10px;
        }

        td {
          vertical-align: middle;
          //background: #fff;
          font-size: 13px;
          border: 1px solid #fff;
          color: #000;
          border-bottom: 1px solid #E1E1E1;
          padding: 0 8px 8px 8px;
          text-align: center;
          box-sizing: border-box;
          height: 140px;
          // border: solid 1px red;
        }

        .tdOne {
          text-align: left;

          p {
            display: flex;
            align-items: center;
            color: #012F9C;
            margin: 6px 0;

            img {
              margin-right: 6px;
            }
          }

          .god {
            width: 131px;
            height: 88px;
          }

          .favo {
            width: 16px;
            height: 16px;
          }
        }

        .tdTwo {
          text-align: left;
          line-height: 16px;

          p {
            margin: 3px 0;
          }
        }

        .tdThree, .tdFour {
          white-space: nowrap;

          span {
            font-weight: bold;
            color: #FF7900;
          }
        }

        .tdFive {
          :deep .el-checkbox {
            margin-right: 0;
          }
        }

        .tdSix {
          white-space: nowrap;

          p {
            margin-top: 8px;
          }
        }

        .tdSeven {
          p {
            margin-top: 8px;
          }

          span {
            color: #FF0000;
          }
        }

        .tdEight {
          p {
            color: #FFFFFF;
            text-align: center;
            width: 98px;
            margin-top: 10px;
            line-height: 26px;
            background: #012F9C;
          }

          .carBtn {
            color: #012F9C;
            background: #fff;
            border: 1px solid #012F9C;
          }
        }
      }
    }
  }
}
</style>
