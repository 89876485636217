import request from '@/util/request'



export function getProductBrandList(data) {
  return request({
    url: `/frontEnd/productClassification/getProductBrandList`,
    method: 'GET',
    params: data
  })
}

export function getProductClassificationList(data) {
  return request({
    url: `/frontEnd/productClassification/getProductClassificationList`,
    method: 'GET',
    params: data
  })
}
