<template>
  <div v-loading="loading" class="indexBg">
    <topNav :active="5"></topNav>
    <div class="authModule">
      <img alt="" class="authTop" src="../../assets/sub/ban.png">
      <div class="authDiv w_1200">
        <div class="classSera">
          <input v-model="searVal" :placeholder="$t('授权代理.请输入类别关键字')" type="text"/>
          <div class="hand" @click="searClick">{{ $t('授权代理.搜索') }}</div>
        </div>
        <div class="authZs">
          <div v-for="(item,index) in introduce" :key="index"
               class="authSub hand hoverTo" @click="authClick(item)">
            <img :src="$baseUrl+item.brandShowPic" class="imgCss"/>
            <div class="authSub1">
              <img :src="$baseUrl+item.brandLogo" class="imgZs"/>
            </div>
            <p class="imgName">{{ $i18n.locale == 'zh' ? item.nickName : item.nickNameEn }}</p>
            <!-- <img class="imgName" src="@/assets/sub/asN.png" /> -->
          </div>
        </div>
        <!--          <div class="authHz">-->
        <!--            <p class="hzTit">{{$t('授权代理.合作伙伴')}}</p>-->
        <!--            <div class="hzModule">-->
        <!--              <div class="hzSub" v-for="(item,index) in partner">-->
        <!--                <div class="hzImg">-->
        <!--                  <img :src="$baseUrl+item.companyLogo" />-->
        <!--                  <img :src="$baseUrl+item.companyPic" />-->
        <!--                </div>-->
        <!--                <div class="hzText">-->
        <!--                  <p><span>{{$t('授权代理.应用领域')}}</span>{{$i18n.locale=='zh'?item.companyLabel:item.companyLabelEn}}</p>-->
        <!--                  <div>{{$i18n.locale=='zh'?item.companyIntroduce:item.companyIntroduceEn}}</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {cooperativePartner, productBrand} from '@/util/http'
import Cookies from "js-cookie";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      introduce: [],//品牌
      partner: [],//合作伙伴
      searVal: '',
    }
  },
  mounted() {
    this.getDetail()
    this.getDetailTwo()
  },
  methods: {
    searClick() {
      this.getDetail()
    },
    getDetail() {//品牌
      productBrand({
        nickName: this.searVal,
        nickNameEn: this.searVal
      }).then(res => {
        this.introduce = res.rows
      }).catch(err => {
        this.$message(err.msg);
      })
    },
    getDetailTwo() {//合作伙伴
      cooperativePartner().then(res => {
        this.partner = res.rows
      }).catch(err => {
        this.$message(err.msg);
      })
    },
    authClick(item) {
      Cookies.set('brandId', item.id)
      this.$router.push({
        path: '/authInfo',
        query: {
          brandId: item.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .authModule {
    .authTop {
      width: 100%;
      height: 181px;
    }

    .authDiv {
      margin: 40px auto;

      .classSera {
        margin: 50px auto 100px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          height: 53px;
          width: 398px;
          background: #FFFFFF;
          text-indent: 1rem;
          border: 1px solid #CCCCCC;
        }

        div {
          width: 93px;
          height: 55px;
          line-height: 55px;
          text-align: center;
          background: #012F9C;
          font-size: 18px;
          border: 1px solid #012F9C;
          color: #FFFFFF;
        }
      }
      .authZs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 60px;
        .authSub {
          width: 210px;
          height: 340px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .imgCss {
            border: 1px solid #dddddd;
            //position: absolute;
            //right: 0;
            width: 153px;
            height: 218px;
            z-index: 1;

          }
          .authSub1 {
            margin-top: 40px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .imgZs {
              width: 98px;
              height: 30px;
            }
          }
          .imgName {
            padding: 12px;
            color: black;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 18px;
          }
        }
      }

      .authHz {
        .hzTit {
          font-size: 23px;
          color: #000000;
          text-align: center;
          margin: 60px 0;
        }

        .hzModule {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 30px;

          .hzSub {
            width: 555px;
            background: #FFFFFF;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #E5E5E5;

            .hzImg {
              display: flex;
              justify-content: space-between;

              img {
                width: 237px;
                height: 171px;
              }
            }

            .hzText {
              color: #6B6B6B;

              p {
                margin: 20px 0;
                font-size: 14px;

                span {
                  font-size: 18px;
                }
              }

              div {
                font-size: 17px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
