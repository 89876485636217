<template>
  <div class="loginTop">
    <div class="topCon">
      <img src="@/assets/login/logo.png" />
      <span>统一登录中心</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTit",
  props:{
    title:''
  },
  data(){
    return{

    }
  },
  mounted() {},
  methods:{}
}
</script>

<style scoped lang="scss">
.loginTop{
  background:#fff;
  width: 100%;
  height: 80px;
  padding:10px 0;
  box-sizing: border-box;
  .topCon{
    width:1200px;
    margin: auto;
    // border: solid 1px;
    display: flex;
    align-items: center;
    img{
      width: 169px;
      height: 60px;
      margin-right: 30px;
    }
    span{
      font-size: 16px;
      color: #333333;
    }
  }
}
</style>