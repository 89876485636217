<template>
  <div class="tearBg w_1200">
    <p class="toolTit">{{ $t("首页.特色产品行业独特解决方案一览") }}</p>
    <div class="tearDiv">
      <div
        class="hBg1 wBig hand hoverTo"
        :style="`background-image:url(${baseUrl}${list[0].classifyPic});`"
        @click="schemeClick(list[0])"
      >
        {{
          $i18n.locale == "zh" ? list[0].classifyName : list[0].classifyNameEn
        }}
      </div>
      <div
        class="hBg2 wMin hand hoverTo"
        @click="schemeClick(list[1])"
        :style="`background-image:url(${baseUrl}${list[1].classifyPic});`"
      >
        {{
          $i18n.locale == "zh" ? list[1].classifyName : list[1].classifyNameEn
        }}
      </div>
      <div
        class="hBg3 wMin hand hoverTo"
        @click="schemeClick(list[2])"
        :style="`background-image:url(${baseUrl}${list[2].classifyPic});`"
      >
        {{
          $i18n.locale == "zh" ? list[2].classifyName : list[2].classifyNameEn
        }}
      </div>
    </div>
    <div class="tearDiv">
      <div
        class="hBg4 wMin hand hoverTo"
        @click="schemeClick(list[3])"
        :style="`background-image:url(${baseUrl}${list[3].classifyPic});`"
      >
        {{
          $i18n.locale == "zh" ? list[3].classifyName : list[3].classifyNameEn
        }}
      </div>
      <div
        class="hBg5 wBig hand hoverTo"
        @click="schemeClick(list[4])"
        :style="`background-image:url(${baseUrl}${list[4].classifyPic});`"
      >
        {{
          $i18n.locale == "zh" ? list[4].classifyName : list[4].classifyNameEn
        }}
      </div>
      <div
        class="hBg6 wMin hand hoverTo"
        @click="schemeClick(list[5])"
        :style="`background-image:url(${baseUrl}${list[5].classifyPic});`"
      >
        {{
          $i18n.locale == "zh" ? list[5].classifyName : list[5].classifyNameEn
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { productCateList } from "@/api/api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    };
  },
  mounted() {
    this.getProductListHandle();
  },
  methods: {
    async getProductListHandle() {
      const res = await productCateList({});
      this.list = res.rows;
    },
    schemeClick(item) {
      this.$router.push({ path: "/scheme", query: { num: item.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.tearBg {
  margin-bottom: 80px;
  .toolTit {
    text-align: center;
    font-size: 24px;
    color: #333333;
    margin: 40px 0;
  }
  .tearDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: end;
      height: 300px;
      border-radius: 4px;
      margin-bottom: 22px;
      font-size: 30px;
      color: #ffffff;
      padding: 30px;
      box-sizing: border-box;
    }
    .wBig {
      width: 588px;
    }
    .wMin {
      width: 282px;
    }
    // .hBg1 {
    //   background-image: url("@/assets/home/h1.png");
    // }
    // .hBg2 {
    //   background-image: url("@/assets/home/h2.png");
    // }
    // .hBg3 {
    //   background-image: url("@/assets/home/h3.png");
    // }
    // .hBg4 {
    //   background-image: url("@/assets/home/h4.png");
    // }
    // .hBg5 {
    //   background-image: url("@/assets/home/h5.png");
    // }
    // .hBg6 {
    //   background-image: url("@/assets/home/h6.png");
    // }
  }
}
</style>