<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="brandDiv">
        <div class="braBanner">
          <p>{{$t('品牌资源.精选品牌授权代理')}}</p>
          <img src="@/assets/images/hono2.png" />
        </div>
        <div class="brandModule w_1200">
          <div class="beanLeft">
            <p class="bLTit">{{$t('品牌资源.品牌')}}</p>
            <div class="blArr">
              <p :class="!brandVal?'active':'hand'" @click="allClick">{{$t('品牌资源.全部')}}</p>
              <p :class="brandVal==item.id?'active':'hand'" @click="brandClick(item)" v-for="(item,index) in brandList" :key="item">{{$i18n.locale == "zh"?item.nickName:item.nickNameEn}}</p>
            </div>
          </div>
          <div class="beanRig">
            <div class="branHot">
              <p class="branTit">{{$t('品牌资源.热门文档')}}</p>
              <div class="hotmodule">
                <div class="hotSub" v-if="item.popular==1" v-for="(item,index) in list" :key="item">
                  <div class="hotText">
                    <p>{{item.title}}</p>
                    <div>{{$t('品牌资源.品牌')}}：<span>{{ $i18n.locale=='zh'?item.nickName:item.nickNameEn }}</span></div>
                  </div>
                  <a :href="$baseUrl+item.document" target="_blank">
                    <img class="hotImg hand hoverTo" src="@/assets/images/down.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="branAll">
              <p class="branTit">{{$t('品牌资源.所有文档')}}</p>
              <div class="allSub" v-for="(item,index) in list">
                <div class="allText">
                  <p class="allTit">{{ item.title }}</p>
                  <p class="allDesc">
                    <span>{{$t('品牌资源.品牌')}}：{{ $i18n.locale=='zh'?item.nickName:item.nickNameEn }}</span>
                    <span>{{$t('品牌资源.时间')}}：{{item.createTime}}</span>
                  </p>
                </div>
                <a :href="$baseUrl+item.document" target="_blank">
                  <div class="allBtn hand hoverTo">
                    <img src="@/assets/images/down.png" />
                    <p>{{$t('品牌资源.立即下载')}}</p>
                  </div>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {getAll,productBrandInfo} from '@/util/http'
import Cookies from "js-cookie";

export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            brandList:[],//品牌列表
            brandVal:'',
            list:[],//列表
        }
    },
    created(){
      this.getBrand()
      this.getList()
    },
    methods:{
      getList(){
        productBrandInfo({
          productBrandId:this.brandVal
        }).then(res=>{
          this.list=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);

        })
      },
      getBrand(){//品牌列表
        getAll().then(res=>{
          this.brandList=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);

        })
      },
      allClick(){
        this.brandVal=''
        this.getList()
      },
      brandClick(item){//选中的品牌
        this.brandVal=item.id
        this.getList()
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .brandDiv{
    .braBanner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 48px;
      color: #FFFFFF;
      width:100%;
      height: 199px;
      background-image: url("@/assets/images/honor.png");
      background-size: 110% 100%;
      padding: 0 100px;
      box-sizing: border-box;
      img{
        width: 199px;
        height: 199px;
      }
    }
  }
  .brandModule{
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    .beanLeft{
      width: 354px;
      background: #FFFFFF;
      border: 1px solid #F3F3F3;
      .bLTit{
        line-height: 61px;
        background: #F3F3F3;
        font-size: 26px;
        color: #000000;
        border: 1px solid #F3F3F3;
        text-align: center;
      }
      .blArr{
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //justify-content: space-between;
        p{
          font-size: 20px;
          color: #000000;
          margin-right: 20px;
          margin-bottom: 20px;
        }
        .active{
          color: #012F9C;
        }
      }
    }
    .beanRig{
      width: 835px;
      .branTit{
        font-size: 23px;
        color: #000000;
        padding-left: 10px;
        border-left: solid #012F9C 2px;
        margin-bottom: 10px;
      }
      .branHot{
        background: #FFFFFF;
        border: 1px solid #F3F3F3;
        margin-bottom: 20px;
        padding: 20px;
        box-sizing: border-box;
        .hotmodule{
          display:grid;
          grid-template-columns: repeat(2,1fr);
          .hotSub:nth-child(2n){
            //border-left: 1px dotted #C2C2C2;
          }
          .hotSub{
            width: 375px;
            padding: 15px 5px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dotted #C2C2C2;
            .hotText{
              width: 270px;
              font-size: 17px;
              color: #000000;
              p{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              div{
                font-size: 15px;
                color: #999999;
                margin-top: 17px;
              }
              span{
                color: #000000;
              }
            }
            img{
              width: 23px;
              height: 23px;
            }
          }
        }
      }
      .branAll{
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #F3F3F3;
        .allSub{
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .allText{
            .allTit{
              font-size: 20px;
              color: #000000;
              margin-bottom: 20px;
            }
            .allDesc{
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 17px;
              color: #666666;
            }
          }
          .allBtn{
            width: 143px;
            line-height: 48px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 5px 5px 5px 5px;
            border: 1px solid #012F9C;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: #012F9C;
            img{
              width: 21px;
              height: 23px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>