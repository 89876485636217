<template>
  <div class="loginTop">
    <div class="topCon hand" @click="navTo('https://beian.miit.gov.cn/#/Integrated/index')">
        Copyright@2024 XXXX贞光科技有限公司
        <img src="@/assets/login/icon9.png" />
        XX公网安备35021102001214号 XXICP备17005748号-1 XXICP备17005748号-1
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTit",
  props: {
    title: ''
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {
    navTo(url){
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.loginTop {
  background: #fff;
  width: 100%;
  // height: 180px;
  padding: 33px 0;
  box-sizing: border-box;
  .topCon {
    width: 1200px;
    margin: auto;
    // border: solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #333333;

    img {
      width: 20px;
      height: 20px;
      margin:0 2px;
    }
  }
}</style>