<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="twoModule w_1200">
      <div class="twoTop">
        <brandLeft ref="childClick"></brandLeft>
        <div class="twoRight">
          <div class="rigPhoto">
            <div class="photoCon">
              <h2>{{ $t('品牌专区.贞光已成为') }}{{
                  $i18n.locale == "zh" ? detail.nickName : detail.nickNameEn
                }}{{ $t('品牌专区.官方授权代理商') }}</h2>
              <p>{{ $t('品牌专区.该品牌共有') }}{{ detail.productNumber }}{{
                  $t('品牌专区.种商品')
                }}</p>
              <p>
                <span v-if="detail.subscriptionNumberBoolean" class="hand" @click="dingClick">{{
                    $t('品牌专区.已订阅')
                  }}</span>
                <span v-else class="hand" @click="dingClick">+{{ $t('品牌专区.订阅') }}</span>
                {{ detail.subscriptionNumber }}{{ $t('品牌专区.人订阅') }}
              </p>
              <p style="line-height:30px;">{{
                  $t('品牌专区.订阅可获得最新品牌资讯品牌资源和促销活动等')
                }}</p>
              <div class="hand" @click="pathClick"><img src="@/assets/sub/sh.png"/>{{
                  $t('品牌专区.授权证明')
                }}
              </div>
            </div>
          </div>
          <div class="rigIntro">
            <p class="infroTit">{{ $t('品牌专区.品牌介绍') }}</p>
            <div class="infoDetail">{{
                $i18n.locale == "zh" ? detail.introduce : detail.introduceEn
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="twoBot">
        <div class="tabTop">
          <div class="checkCss">
            <!-- <el-checkbox-group v-model="checkList"> -->
            <el-checkbox v-model="hasStock" :false-label="0" :true-label="1" :label="$t('商品目录.有库存')"
                         @change="getList"/>
            <el-checkbox v-model="hasPrice" :false-label="0" :true-label="1" :label="$t('商品目录.有价格')"
                         @change="getList"/>
            <el-checkbox v-model="isRoHSCompliant" :false-label="0" :true-label="1" :label="$t('商品目录.符合RoHS')"
                         @change="getList"/>
            <el-checkbox v-model="hasDataSheet" :false-label="0" :true-label="1" :label="$t('商品目录.有数据手册')"
                         @change="getList"/>
            <!-- </el-checkbox-group> -->
          </div>
          <div class="fileSea">
            <input v-model="searchCriteria" :placeholder="$t('商品目录.从结果中搜索')" type="text"
                   @input="getList"/>
            <img alt="" src="@/assets/sub/sea.png">
          </div>
          <p class="allT">{{ $t('商品目录.共2') }}{{ total }}{{ $t('商品目录.条') }}</p>
          <!-- <p class="allT" style="margin:0 17px">{{pageNum}}<span>/{{total}}</span></p> -->
          <div class="pageBtn hand">
            <div @click="pageChange('left')"><img src="@/assets/sub/le.png"/></div>
            <div class="pageRig" @click="pageChange('rig')"><img src="@/assets/sub/le.png"/></div>
          </div>
        </div>
        <table>
          <tr>
            <th>{{ $t('商品目录.型号') }}</th>
            <th>{{ $t('商品目录.商品信息') }}</th>
            <th>{{ $t('商品目录.库存') }}</th>
            <th>
              <p>{{ $t('商品目录.单价(含税)') }}
                <!--                <img class="hand" src="@/assets/sub/hsx.png"/>-->
              </p>
              <input v-model="priceInput" :placeholder="$t('商品目录.按需求数量显示价格')"
                     type="text" @input="priceClick"/>
            </th>
            <th>{{ $t('商品目录.交期(工作日)') }}</th>
            <th>{{ $t('商品目录.起订/递增') }}</th>
            <th>{{ $t('商品目录.数量') }}</th>
            <th>{{ $t('商品目录.操作') }}</th>
          </tr>
          <tr v-for="item in list" class="subTr">
            <td class="tdOne hand" @click="detailClick(item)">
              <img :src="$baseUrl+item.productPhoto" class="god"/>
              <p style="display: flex;align-items: center;">
                <img
                    :src="require(item.collectBoolean>0?'@/assets/member/sc.png':'@/assets/member/favo.png')"
                    class="favo"/>
                <span>{{ item.manufacturerNumber }}</span>
              </p>
            </td>
            <td class="tdTwo">
              <!-- <p>{{$t('商品目录.供应商')}}YaoGo（国剧）</p> -->
              <p>{{ $t('商品目录.品牌') }}{{
                  $i18n.locale == "zh" ? item.productBrandName : item.productBrandNameEn
                }}</p>
              <p v-for="item2 in item.children">
                {{
                  $i18n.locale == "zh" ? item2.productCategorySpecificationName
                      : item2.productCategorySpecificationNameEn
                }}
                :
                {{ item2.productCategorySpecificationValue }}
              </p>
              <p>{{ $t('商品目录.分类') }}{{
                  $i18n.locale == "zh" ? item.productCategoryName : item.productCategoryNameEn
                }}</p>
              <!-- <p>{{$t('商品目录.描述')}}djfhsk JHSD 678K 12% dsjf jash</p> -->
            </td>
            <td class="tdThree">{{ $t('商品目录.库存：') }}<span>{{ item.inventory }}</span></td>
            <td class="tdFour">
              <p v-for="item3 in item.priceChildren">
                <!-- v-if="unitPrice ?  unitPrice>= item3.quantity : true"> -->
                {{ item3.quantity }} : <span>¥{{ item3.unitPrice }}  </span>
              </p>
            </td>
            <td class="tdFive">
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="$t('商品目录.大陆7-9')"></el-checkbox>
                <el-checkbox :label="$t('商品目录.海外3-6')"></el-checkbox>
              </el-checkbox-group>
            </td>
            <td class="tdSix">
              <p>{{ $t('商品目录.起订量') }}{{ item.minNumber }}</p>
              <p>{{ $t('商品目录.递增量') }}1</p>
            </td>
            <td class="tdSeven">
              <el-input-number v-model="item.buyNumber" :max="item.inventory*1"
                               :min="item.minNumber*1"
                               size="mini" @change="(e)=>handleChange2(item,e)"></el-input-number>
              <p>{{ $t('商品目录.合计') }}<span>¥{{ item.subTotal }}</span></p>
            </td>
            <td class="tdEight hand">
              <p @click="payClick(item)">{{ $t('商品目录.立即购买') }}</p>
              <p class="carBtn" @click="addCarClick(item)">{{ $t('商品目录.加入购物车') }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import brandLeft from '@/component/home/brandLeft.vue'
import {brandDetail, goodsGetList, subscription, userShoppingCar} from '@/util/http'
import Cookies from "js-cookie";
import {OneUnifiedWayToPlaceYourOrderNow} from "@/api/myInfo/addCarClick";
import {
  getProductBrandList,
  getProductClassificationList
} from "@/api/myInfo/productClassification";

export default {
  components: {topNav, bottomNav, brandLeft},
  data() {
    return {
      loading: false,
      num: 1,
      brandId: '',
      checkList: ['选中且禁用', '隐藏不可选'],
      list: [],
      pageSize: 6,//每页显示条数
      pageNum: 1,
      total: 0,
      detail: {},//品牌详情
      ProductBrandList: [],
      ProductClassificationList: [],
      //品牌id
      productBrandId: "",
      //规格id的搜索条件(多个,分割)
      SpecificationIdList: [],
      //搜索条件
      searchCriteria: "",
      priceInput: "",
      // 是否有库存
      hasStock: 0,
      // 是否有价格
      hasPrice: 0,
      // 是否符合RoHS
      isRoHSCompliant: 0,
      // 是否有数据手册
      hasDataSheet: 0,
      // 是否隐藏不可选
      isHidden: 0,
      // 库存倒序
      stockOrder: 0,
      // 价格倒序
      priceOrder: 0,
    }
  },
  created() {

    this.productClassification()
  },
  mounted() {
    if (this.$route.query.brandId) {
      this.brandId = this.$route.query.brandId
    } else {
      this.brandId = Cookies.get('brandId')
    }

    // 页面加载时立即调用子组件方法
    this.$refs.childClick.tabClick(1)
    this.getDetail()
  },
  methods: {
    priceClick() {
      this.list.map(i => {
        i.buyNumber = this.priceInput * 1
        this.handleChange(i, this.priceInput * 1)
      })
    },
    handleChange2(item, e) {
      this.list.map(i => {
        if (i.id == item.id) {
          i.buyNumber = this.calculateSubTotal(item.priceChildren, e, item)
          console.log(i.buyNumber)
          console.log(i.subTotal)
        }
      })
    },
    productClassification(val) {
      getProductBrandList({productCategoryId: this.classfyId, nickName: this.nameValue}).then(
          res => {
            this.ProductBrandList = res.data
          })

      let nameValue = ""
      if (val) {
        this.ProductClassificationList.forEach(e => {
          if (e.nameValue != "") {
            nameValue = nameValue + e.nameValue + ","
          } else {
            nameValue = nameValue + "..." + ","
          }
        })
        nameValue = nameValue.substring(0, nameValue.lastIndexOf(','));
      }
      getProductClassificationList({productCategoryId: this.classfyId, nickName: nameValue}).then(
          res => {
            console.log(res.data)
            this.ProductClassificationList = res.data
          })
    },
    bottle(val) {
      if (val === 1) {
        if (this.stockOrder === 0) {
          this.stockOrder = 1
        } else {
          this.stockOrder = 0
        }
      } else {
        if (this.priceOrder === 0) {
          this.priceOrder = 1
        } else {
          this.priceOrder = 0
        }
      }
      this.getList()

    },
    pathClick() {
      this.$router.push({
        path: '/authInfo',
        query: {
          brandId: this.detail.id
        }
      })
    },
    dingClick() {
      subscription({productBrandId: this.detail.id}).then(res => {
        this.$message(res.msg)
        this.getDetail()
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getDetail() {
      brandDetail(this.brandId).then(res => {
        this.detail = res.data
        this.getList()
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getList() {//商品列表
      goodsGetList({
        productBrandId: this.detail.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        // 是否有库存（0：否，1：是）
        hasStock: this.hasStock,
        // 是否有价格（0：否，1：是）
        hasPrice: this.hasPrice,
        // 是否符合RoHS（0：否，1：是）
        isRoHSCompliant: this.isRoHSCompliant,
        // 是否有数据手册（0：否，1：是）
        hasDataSheet: this.hasDataSheet,
        // 是否隐藏不可选（0：否，1：是）
        isHidden: this.isHidden,
        // 库存倒序（0：倒序，1：升序）
        stockOrder: this.stockOrder,
        // 价格倒序（0：倒序，1：升序）
        priceOrder: this.priceOrder,
        // 搜索条件
        searchCriteria: this.searchCriteria,
      }).then(res => {
        // this.list=res.rows
        // console.log(JSON.stringify(res))
        this.total = res.total
        this.list = res.rows.map(row => {
          const unitPrice = this.getUnitPrice(row.priceChildren, row.minNumber * 1);
          const subTotal = this.calculateSubTotal(row.priceChildren, row.minNumber * 1);
          return {
            ...row,
            buyNumber: 1,
            unitPrice: unitPrice,
            subTotal: subTotal
          };
        });
        console.log(this.list)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getUnitPrice(children, buyNumber) {//根据用户输入的数量找到合适的单价
      let prevQuantity = 0;
      let prevUnitPrice = null;
      for (const child of children) {
        if (buyNumber <= child.quantity) {
          return prevUnitPrice || child.unitPrice;
        }
        prevQuantity = child.quantity;
        prevUnitPrice = child.unitPrice;
      }

      return children[children.length - 1].unitPrice;
    },
    calculateSubTotal(children, buyNumber, item) {                  //根据单价和用户输入的数量计算小计金额
      const unitPrice = this.getUnitPrice(children, buyNumber);
      if (item && item.id) {
        this.list.map(i => {
          if (i.id == item.id) {
            return i.subTotal = unitPrice * buyNumber
          }
        })
      }
      return unitPrice * buyNumber;
    },
    detailClick(item) {//查看详情
      this.$router.push({
        path: '/goods',
        query: {
          id: item.id
        }
      })
    },
    async payClick(item) {//立即购买
      let res = await OneUnifiedWayToPlaceYourOrderNow(JSON.parse(Cookies.get('userInfo')).userId,
          item.id, item.buyNumber)
      Cookies.set('carId', res.data)
      await this.$router.push('/order')
    },
    addCarClick(item) {//加入购物车
      userShoppingCar({
        userId: JSON.parse(Cookies.get('userInfo')).userId,
        productId: item.id,
        buyNumber: item.buyNumber
      }).then(res => {
        this.$message(res.msg)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    handleChange(e, item) {//订单数量
      console.log(item, e)
      this.list.map(i => {
        if (i.id == item.id) {
          i.buyNumber = e
        }
      })
    },
    pageChange(text) {//当前页
      if (text == 'left') {
        if (this.pageNum == 1) {
          return
        }
        this.pageNum--
      } else {
        if (this.total >= this.pageSize) {
          this.pageNum++
        } else {
          this.$message(this.$t('公用.没有更多了'))
        }
      }
      console.log(this.pageNum)
      this.getList()
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .twoModule {
    margin: 40px auto;

    .twoTop {
      display: flex;
      justify-content: space-between;
      height: 400px;

      .twoRight {
        width: 900px;
        display: flex;
        justify-content: space-between;

        .rigPhoto::-webkit-scrollbar {
          display: none; /* 隐藏滚动条 */
        }
        .rigPhoto {
          width: 334px;
          height: 403px;
          background: #FFFFFF;
          padding: 24px 16px;
          box-sizing: border-box;
          background-image: url("@/assets/sub/shBg.png");
          background-size: 90% 95%;
          background-repeat: no-repeat;
          background-position: center;
          overflow-y:auto;

          .photoCon {
            width: 230px;
            margin: auto;
            text-align: center;

            h2 {
              font-weight: bold;
              font-size: 24px;
              color: #FFFFFF;
              line-height: 46px;
            }

            p {
              font-size: 17px;
              color: #FFFFFF;
              margin-bottom: 15px;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                width: 85px;
                height: 26px;
                background: #FFFFFF;
                border-radius: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: #0332A1;
                margin-right: 13px;
              }
            }

            div {
              width: 188px;
              height: 38px;
              background: #FFC7A2;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              font-size: 17px;
              color: #FF6500;
              margin: auto;

              img {
                width: 34px;
                height: 29px;
                margin-right: 8px;
              }
            }
          }
        }

        .rigIntro {
          width: 541px;
          height: 403px;
          background: #FFFFFF;
          padding: 24px 16px;
          box-sizing: border-box;
          overflow-y: auto;

          .infroTit {
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            padding-left: 15px;
            border-left: solid 4px #0332A1;
            margin-bottom: 25px;
          }

          .infoDetail {
            font-size: 13px;
            color: #000000;
            line-height: 25px;
          }
        }
      }
    }

    .twoBot {
      .tabTop {
        margin-top: 20px;
        width: 1200px;
        height: 83px;
        background: #012F9C;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fileBtn {
          margin-left: 18px;
          width: 92px;
          height: 38px;
          background: #4F77D4;
          font-size: 17px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 23px;
            height: 23px;
            margin-left: 8px;
          }
        }

        .active {
          background: #fff;
          font-size: 18px;
          color: #012F9C;
        }

        .fileSea {
          padding: 8px;
          box-sizing: border-box;
          width: 298px;
          height: 38px;
          background: #F5F5F5;
          display: flex;
          align-items: center;
          margin: 0 6px 0 38px;
          justify-content: space-between;

          input {
            line-height: 23px;
            background: #F5F5F5;
            border: none;
            flex: 1;
          }

          img {
            width: 23px;
            height: 23px;
          }
        }

        .allT {
          font-size: 21px;
          color: #FFFFFF;

          span {
            color: #CCC5C8;
          }
        }

        .pageBtn {
          display: flex;
          align-items: center;

          div {
            height: 80px;
            padding: 0 26px;
            display: flex;
            align-items: center;
            border-left: #4F77D4 1px solid;
          }

          img {
            width: 29px;
            height: 29px;
          }

          .pageRig img {
            transform: rotate(180deg);
          }
        }
      }

      .checkCss {
        margin-left:20px;

        :deep .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #fff;
        }
        :deep .el-checkbox{
          margin-right: 11px;
        }
        :deep .el-checkbox__label {
          padding-left: 3px;
          font-size: 16px;
          color: #fff;
        }
      }

      table {
        //border-collapse: collapse;
        //border-collapse:separate;
        //border-spacing: 0px 10px;
        width: 1200px;
      }

      tr {
        width: 1200px;
        height: 72px;
        background: #E1E1E1;
      }

      tr th {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        padding: 8px 0;
        box-sizing: border-box;

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
        }

        img {
          width: 19px;
          height: 19px;
          margin-left: 8px;
        }

        input {
          text-indent: 1.5rem;
          width: 166px;
          height: 31px;
          background: #FFFFFF;
          border: none;
        }
      }

      .subTr {
        margin-bottom: 10px;
        border: 1px solid #E1E1E1;
        background: #ffffff;
        //padding-bottom: 10px;
      }

      td {
        //background: #fff;
        vertical-align: middle;
        font-size: 13px;
        border: 1px solid #fff;
        color: #000;
        border-bottom: 1px solid #E1E1E1;
        padding: 8px;
        // padding:0 8px 8px 8px;
        text-align: center;
        box-sizing: border-box;
        height: 140px;
      }

      .tdOne {
        text-align: left;

        p {
          color: #012F9C;
          margin: 6px 0;

          img {
            margin-right: 6px;
          }
        }

        .god {
          width: 131px;
          height: 88px;
        }

        .favo {
          width: 16px;
          height: 16px;
        }
      }

      .tdTwo {
        text-align: left;
        line-height: 16px;

        p {
          margin: 3px 0;
        }
      }

      .tdThree, .tdFour {
        white-space: nowrap;

        span {
          font-weight: bold;
          color: #FF7900;
        }
      }

      .tdFive {
        :deep .el-checkbox {
          margin-right: 0;
        }
      }

      .tdSix {
        white-space: nowrap;

        p {
          margin-top: 8px;
        }
      }

      .tdSeven {
        p {
          margin-top: 8px;
        }

        span {
          color: #FF0000;
        }
      }

      .tdEight {
        p {
          color: #FFFFFF;
          text-align: center;
          width: 98px;
          margin-top: 10px;
          line-height: 26px;
          background: #012F9C;
        }

        .carBtn {
          color: #012F9C;
          background: #fff;
          border: 1px solid #012F9C;
        }
      }
    }
  }
}
</style>
