<template>
    <div class="indexBg">
      <topNav></topNav>
      <div class="agencyModule">
        <div class="agencyLeft">
          <div @click="titClick('1')" :class="num==1?'active':'hand'">{{$t('授权代理.代理品牌')}}</div>
          <div @click="titClick('2')" :class="num==2?'active':'hand'">{{$t('授权代理.合作伙伴')}}</div>
        </div>
        <div class="agencyRig w_1200">
          <div class="agnecyModule" v-if="num==1">
            <h1>{{$t('授权代理.代理品牌')}}</h1>
            <div class="agnecySub" v-for="item in introduce">
              <img :src="$baseUrl+item.brandLogo" />
              <div class="subRig">
                <p>{{$i18n.locale=='zh'?item.nickName:item.nickNameEn}}</p>
                <div>{{$i18n.locale=='zh'?item.introduce:item.introduceEn}}</div>
              </div>
            </div>
          </div>
          <div class="agnecyModule" v-if="num==2">
            <h1>{{$t('授权代理.合作伙伴')}}</h1>
            <div class="agnTwo">
              <div class="ahnRigSub" v-for="item in partner">
                <div class="loImg">
                  <img :src="$baseUrl+item.companyLogo" />
                  <img :src="$baseUrl+item.companyPic" />
                </div>
                <div>{{$i18n.locale=='zh'?item.companyIntroduce:item.companyIntroduceEn}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {productBrand,cooperativePartner} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            num:1,
            introduce:[],//品牌
            partner:[],//合作伙伴
        }
    },
    mounted(){
      this.getDetail()
    },
    methods:{
      getDetail(){//品牌
        productBrand().then(res=>{
          this.introduce=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      getDetailTwo(){//合作伙伴
        cooperativePartner().then(res=>{
          this.partner=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      titClick(num){
        if(num==this.num) return;
        this.num=num;
        if(num==1){
          this.getDetail()
        }else{
          this.getDetailTwo()
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .agencyModule{
    //border: solid 1px;
    margin:60px auto;
    width: 1475px;
    display: flex;
    justify-content: space-between;
    .agencyLeft{
      div{
        width: 262px;
        line-height: 53px;
        text-align: center;
        background: #FFFFFF;
        font-size: 21px;
        color: #000000;
        margin-top: 3px;
      }
      .active{
        color: #0D39A1;
        border-right:2px solid #0D39A1;
      }
    }
    .agencyRig{
      background: #fff;
      margin-left:10px;
      .agnecyModule{
        padding:30px 70px;
        box-sizing: border-box;
        h1{
          text-align: center;
          font-weight: bold;
          font-size: 37px;
          color: #000000;
        }
        .agnecySub{
          display: flex;
          align-items: center;

          margin-bottom: 70px;
          img{
            width: 260px;
            height: 173px;
            margin-right:20px;
          }
          .subRig{
            p{
              font-weight: bold;
              font-size: 32px;
              color: #000000;
              margin-bottom: 20px;
            }
            div{
              font-size: 13px;
              color: #000000;
              line-height: 26px;
            }
          }
        }
        .agnTwo{
          display:grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
        }
        .ahnRigSub{
          width: 514px;
          .loImg{
            display: flex;
            align-items: center;
           justify-content: space-between;
           margin-bottom: 15px;
          }
          img{
            width:250px;
            height:200px;
          }
          div{
            font-size: 13px;
            color: #000000;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>