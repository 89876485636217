import {insertSave, userShoppingCar2} from '@/util/http'

//立即下单的统一方法
export async function OneUnifiedWayToPlaceYourOrderNow(userId, productId,
    buyNumber) {
  let data;
  //先加入购物车
  await userShoppingCar2({
    userId: userId,
    productId: productId,
    buyNumber: buyNumber
  }).then(async res => {
    let shoppingCarId = res.data;
    data = await insertSave({shoppingCarIds: shoppingCarId});
  })
  return data;
}
