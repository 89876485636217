<template>
    <div class="newBg" v-loading="loading">
      <topNav></topNav>
      <div class="w_1200">
        <div class="newCon">
          <div class="newTit">
            <span @click="tabClick(item)" class="hand hover" :class="item.flag?'newActive':''" v-for="(item,index) in titArr">{{item.tit}}</span>
          </div>
        </div>
        <div class="newsModule" v-if="titArrVal==1">
          <p class="moduleTit">{{$t('最新活动.正在进行中的热门活动')}}</p>
          <div class="newsBot">
            <div class="newsSub hand hoverTo" v-if="index<=1" v-for="(item,index) in hotList">
              <img :src="$baseUrl+item.picture">
              <div class="subText">
                <p class="subTit">{{ item.title }}</p>
                <p class="subTit" v-if="item.perennial==1">{{$t('最新活动.活动时间')}}：长期活动</p>
                <p class="subTit" v-else>{{$t('最新活动.活动时间')}}：{{ item.startTime }}-{{ item.endTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="newsModule">
          <p class="moduleTit" v-if="titArrVal==1">{{$t('最新活动.正在进行中所有活动')}}</p>
          <p class="moduleTit" v-if="titArrVal==2">{{$t('最新活动.往期活动')}}</p>
          <div class="newsBot newsAll">
            <div class="newsSub hand hoverTo" v-for="item in list">
              <img :src="$baseUrl+item.picture">
              <div class="subText">
                <p class="subTit">{{ item.title }}</p>
                <p class="subTit" v-if="item.perennial==1">{{$t('最新活动.活动时间')}}：长期活动</p>
                <p class="subTit" v-else>{{$t('最新活动.活动时间')}}：{{ item.startTime }}-{{ item.endTime }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {activityInfo,activityList,activList} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            list:[],//进行中的所有活动
            hotList:[],//热门活动
            titArrVal:1,
            titArr:[
              {id:1,tit:this.$t('最新活动.正在进行'),url:'',flag:true},
              {id:2,tit:this.$t('最新活动.往期活动'),url:'',flag: false},
            ]
        }
    },
    created(){
      this.getAll()
      this.getActive()
    },
    methods:{
      getAll(){//进行中的所有活动
        activityInfo().then(res=>{
          this.list = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getAlTo(){//进行中的所有活动
        activityList().then(res=>{
          this.list = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getActive(){//热门活动
        activList().then(res=>{
          this.hotList = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      tabClick(item){
        this.titArr.map((i)=>{
          i.flag=false;
          if(item.id==i.id){
            i.flag=true;
            this.titArrVal=i.id
          }
        })
        if(this.titArrVal==1){
          this.getAll()
        }else{
          this.getAlTo()
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.newBg{
    width:100%;
  .newCon{
    height: 105px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    margin:40px auto;
    padding:0 30px;
    .newTit{
      font-size: 20px;
      color: #333333;
      span{
        margin-left: 30px;
      }
      .newActive{
        font-weight: bold;
        font-size: 26px;
        color: #012F9C;
        padding-bottom: 8px;
        border-bottom: 4px solid #FF6600;
      }
    }

  }
  .newsModule{
    .moduleTit{
      font-size: 24px;
      color: #333333;
      text-align: center;
      margin:20px 0;
    }
    .newsBot{
      margin-bottom: 40px;
      display: grid;
      justify-content:space-evenly;
      grid-template-rows: 399px;
      grid-template-columns: 566px 566px;
      .newsSub{
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 8px;
        margin-bottom:20px;
        img{
          width: 526px;
          height: 292px;
          border-radius: 4px;
        }
        .subText{
          font-size: 16px;
          color: #333333;
          p{
            margin-top: 16px;
          }
        }
      }
    }
    .newsAll{
      grid-template-rows: 288px;
      grid-template-columns: 384px 384px 384px;
      .newsSub img{
        width: 344px;
        height: 179px;
        border-radius: 8px
      }
    }
  }
}
</style>