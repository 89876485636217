<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{$t('售后服务.售后服务')}}</p>
          </div>
          <div class="termModule">
            <div class="searDiv">
              <input />
              <div class="sea hand">{{$t('售后服务.订单搜索')}}</div>
            </div>
            <div class="termSub hand">{{$t('售后服务.更多筛选条件')}}<i class="el-icon-arrow-down" /></div>
            <div class="editBtn hand">{{$t('售后服务.批量导出订单')}}</div>
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{$t('售后服务.订单号')}}</th>
                <th>{{$t('售后服务.退货单号')}}</th>
                <th>{{$t('售后服务.申请时间')}}</th>
                <th>{{$t('售后服务.退款金额')}}</th>
                <th>{{$t('售后服务.退款状态')}}</th>
                <th>{{$t('售后服务.操作')}}</th>
              </tr>
              <tr v-if="tableData.length<=0">
                <td colspan="6">
                  <div class="wuCss">
                    <div class="wuTips">
                      <img src="@/assets/member/wu.png" />
                      <span>{{$t('售后服务.抱歉暂时无相关售后服务记录')}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-else class="trCon" v-for="(item,index) in tableData">
                <td style="width:200px">
                  <div class="descCss">{{ item.orderNumber }}</div>
                </td>
                <td class="xj" style="width:180px">
                  <div>{{ item.id }}</div>
                </td>
                <td class="xj" style="width:150px"><div>{{ item.createTime }}</div></td>
                <td class="xj" style="width:100px">
                  <div>{{$t('公用.¥')}}{{ item.productPrice }}</div>
                </td>
                <td class="dj" style="width:100px"><div>{{$t('售后服务.已完成')}}</div></td>
                <td>
                  <div class="editTab hand">
                    <p @click="afterClick(item)">{{$t('售后服务.详情')}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {orderList2} from "@/api/myInfo/after";
import Cookies from "js-cookie";
export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
    return{
      loading: false,
      tableData: []
    }
  },
  mounted(){
    this.load();
  },
  methods:{
    load(){
      let info = {
        userId: JSON.parse(Cookies.get('userInfo')).userId,
      }
      orderList2(info).then(res=>{
        this.tableData = res.rows
      })
    },
    afterClick(item){
      this.$router.push({path:'/afterDetail',query:{id:item.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg{
  width:100%;
  .infoModule{
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .selectDiv{
      display: flex;
      align-items: center;
      //justify-content: space-between;
      .seleLeft{
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        //grid-gap: 20px;
        margin-right: 30px;
        .sub{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          .subInp{
            flex: 1;
          }
          input{
            width: 265px;
            height: 26px;
            border-radius:4px;
            border: 1px solid #999999;

          }
        }
      }
      .seleRig{
        display: flex;
        align-items: center;
        p{
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }
        .ac{
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
        .exportbtn{
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
          color: #333333;
          background: #fff;
        }
      }
    }
    .tab{
      font-size: 13px;
      color: #333333;
      line-height: 22px;
      p{
        margin-bottom:8px;
        display: flex;
        align-items: center;
        input{
          width:80px;
        }
      }
      .descCss{}
      .numEd{
        display: flex;
        align-items: center;
      }
      .editTab{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 30px;
          height: 30px;
          margin:0 5px;
        }
        p{
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }
        .activeP{
          //color: #FF0000;
        }
      }
      table{
        width: 100%;
        border-collapse: separate; /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px; /* 行之间的距离 */
        td{
          padding:20px 0;
          box-sizing: border-box;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
          text-align: center;
          word-break: break-all;
        }
        td:first-child{
          padding-left:20px;
        }
        td:last-child{
        }
        .trTit{
          line-height: 48px;
          height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #fff;
          th:nth-child(1){
            border-radius: 8px 0px 0px 0px;
          }
          th:last-child{
            border-radius: 0px 8px 0px 0px;
          }
        }
        .trCon{
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }
        .dj p{
          //color:#FF6600;
          //text-align: center;
          display: block
        }
        .xj p{
          //text-align: center;
          display: block
        }
      }
      .wuCss{
        //border: solid;
        //margin: auto;
        div{

          display: flex;
          align-items: center;
          justify-content: center;
        }
        .wuTips{
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 40px;
          img{
            width: 40px;
            height: 40px;
            margin-right: 6px;
          }
        }
        .allOrBtn{
          font-size: 13px;
          color: #999999;
          width: 113px;
          height: 32px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #999999;
        }
        .xuanOrBtn{
          font-size: 13px;
          color: #FFFFFF;
          width: 113px;
          height: 32px;
          background: #012F9C;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }
  .dialogThreeCss{
    padding: 0 60px;
    margin: auto;
    .checkCss{
      text-align: center;
    }
    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;
      span{
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }
      input{
        border-radius: 2px 2px 2px 2px;
        border:1px solid #DCDFE6;
        width: 278px;
        height: 26px;
        flex: 1;
      }
      .infoSub {
        display: flex;
        align-content: center;
        margin:20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
        .subInp{
          flex: 1;
        }
      }
      p{
        //border: solid 1px;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right:15px;
        width:100px;
      }
    }
  }
  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 30px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;
    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }
  .termModule{
    display: flex;
    justify-content: end;
    .searDiv{
      width: 316px;
      height: 26px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #999999;
      display: flex;
      align-items: center;
      input{
        flex: 1;
        //height: 24px;
        border: none;
      }
      .sea{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #666666;
        line-height: 15px;
        width: 82px;
        height: 26px;
        background: #D9D9D9;
        border-radius: 0px 2px 2px 0px;
      }
    }
    .termSub{
      margin:0 10px;
      font-weight: 400;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      display: flex;
      align-items: center;
    }
    .editBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      width: 89px;
      height: 26px;
      background: #F3F5F8;
      border-radius: 2px 2px 2px 2px;
    }
  }
  .botDiv{
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #000000;
    justify-content: space-between;
    .editBtn{
      background: #012F9C;
      border-radius: 2px 2px 2px 2px;
      font-size: 11px;
      color: #FFFFFF;
      padding: 5px 15px;
    }
  }
}
</style>
