<template>
  <div>
    <div class="r_nav" v-show="show" @mouseleave="hidePopups">
      <div class="close hand" @click="close">
        <img src="@/assets/home/icon/close.png" alt="" />
      </div>
      <div class="list">
        <div class="item hand hover" @click="rigClick(item)" @mouseover="rigMouse(item)" v-for="(item, index) in list" :key="index">
          <img :src="require('@/assets/home/icon/' + item.url + '.png')" />
          {{ item.text }}
        </div>
      </div>
      <div class="footPrint favoCss" v-if="qqPop" @mouseleave="qqPop=false">
        <div><img src="@/assets/home/icon/r_n_1.png" alt="" />37294927423</div>
      </div>
      <div class="footPrint" v-if="favoritePop" @mouseleave="favoritePop=false">
        <div class="footTit">
          <p>{{$t('首页.我的收藏')}}<img src="@/assets/home/rl.png" alt="" /></p>
          <span class="hand" @click="rigToClick('/myFavorite')">{{$t('首页.进入我的收藏')}}</span>
        </div>
        <div class="footCon">
          <div class="footSub hand" v-for="item in collList" @click="goodsClick(item)">
            <img :src="$baseUrl+item.productPhoto" alt="" />
            <div class="subRig">
              <div class="s_tit hover">{{ $i18n.locale=='zh'?item.productName:item.productNameEn }}</div>
              <p class="s_desc">{{ $i18n.locale=='zh'?item.productBrandName:item.productBrandNameEn }}</p>
              <p class="s_kc">库存：<span>{{item.inventory}}</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="footPrint" v-if="footPop" @mouseleave="footPop=false">
        <div class="footTit">
          <p>{{$t('首页.我的足迹')}}<img src="@/assets/home/rl.png" alt="" /></p>
          <span class="hand" @click="delClick">{{$t('首页.清空浏览历史')}}</span>
        </div>
        <div class="footCon">
          <div class="footSub hand" v-for="item in historyList" @click="goodsClick(item)">
            <img :src="$baseUrl+item.productPhoto" alt="" />
            <div class="subRig">
              <div class="s_tit hover">{{ $i18n.locale=='zh'?item.productName:item.productNameEn }}</div>
              <p class="s_desc">{{ $i18n.locale=='zh'?item.productBrandName:item.productBrandNameEn }}</p>
              <p class="s_kc">库存：<span>{{item.inventory}}</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="footPrint favoCss" style="top:calc(50% + 200px);" v-if="wxPop" @mouseleave="wxPop=false">
        <div><img src="@/assets/home/icon/r_n_7.png" alt="" />37294927423</div>
      </div>
    </div>
    <div class="shuCss hand" v-show="!show" @click="show=true">
      <i class="el-icon-circle-plus-outline iCss"></i>
        <div>{{ $t('首页.点击展开') }}</div>
    </div>
  </div>
</template>

<script>
import {collectList,browsingHistory,delHistory} from '@/util/http'
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      footPop:false,
      favoritePop:false,
      qqPop:false,
      wxPop:false,
      show: true,
      collList:[],
      historyList:[],
      list: [
        {
          id:1,
          text:this.$t('首页.在线销售'),
          url: "r_n_1",
          path:''
        },
        {
          id:2,
          text:this.$t('首页.购物车'),
          url: "r_n_2",
          path:'/carIndex'
        },
        {
          id:3,
          text:this.$t('首页.会员中心'),
          url: "r_n_3",
          path:'/vip'
        },
        {
          id:4,
          text:this.$t('首页.我的收藏'),
          url: "r_n_4",
          path:'/myFavorite'
        },
        // {
        //   id:5,
        //   text:this.$t('首页.浏览历史'),
        //   url: "r_n_5",
        //   path:''
        // },

        {
          id:6,
          text:this.$t('首页.意见反馈'),
          url: "r_n_6",
          path:'/feedBack'
        },
        {
          id:7,
          text:this.$t('首页.活动咨询'),
          url: "r_n_7",
          path:''
        },
      ],
    };
  },
  created() {
    this.getCollect()
    this.getHistory()
  },
  methods: {
    delClick(){
      delHistory().then(res=>{
        this.$message(res.msg)
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    getCollect(){
      collectList().then(res=>{
        this.collList=res.rows
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    getHistory(){
      browsingHistory().then(res=>{
        this.historyList=res.rows
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    goodsClick(item){
        this.$router.push({
            path: '/goods',
            query: {
                id: item.id
            }
        })
    },
    rigClick(item){
      this.$router.push(item.path)
    },
    rigToClick(path){
      this.$router.push(path)
    },
    rigMouse(item){
      if(item.id==5){
        this.footPop=true;
        this.favoritePop=false;
        this.qqPop=false;
        this.wxPop=false;
        return
      }
      if(item.id==4){
        this.footPop=false;
        this.qqPop=false;
        this.wxPop=false;
        this.favoritePop=true;
        return
      }
      if(item.id==1){
        this.footPop=false;
        this.wxPop=false;
        this.favoritePop=false;
        this.qqPop=true;
        return
      }
      if(item.id==7){
        this.footPop=false;
        this.wxPop=true;
        this.favoritePop=false;
        this.qqPop=false;
        return
      }
    },
    hidePopups() {
      this.footPop = false;
      this.qqPop=false;
      this.wxPop=false;
      this.favoritePop = false;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.shuCss{
  position: fixed;
  right: 0%;
  top: 25%;
  z-index: 1000;
  width:40px;
  // height:150px;
  padding:10px;
  box-sizing: border-box;
  display: flex;
  flex-direction:column;
  align-items: center;
  background: #ff8000;
  justify-content: center;
  .iCss{
    color: ff8000;
    font-size: 22px;
    color:#fff;
  }
  div{
    color:#fff;
    line-height: 22px;
    font-size: 18px;
    padding-top:10px;
  }
}
.r_nav {
  position: fixed;
  right: 3%;
  top: 25%;
  z-index: 1000;
  width: 112px;
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px 6px 6px 6px;
  .list {
    box-sizing: border-box;
    padding: 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 13px;
      white-space: nowrap;
      img {
        width: 26px;
        height: 26px;
        margin-bottom: 13px;
      }
    }
  }
  .close {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .footPrint{
    width: 300px;
    background: #fff;
    border: solid #d2d2d2 1px;
    position: fixed;
    right:calc(3% + 112px);
    top:calc(25% + 200px);
    z-index: 1000;
    padding:20px 10px;
    box-sizing: border-box;
    .footTit{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      p{
        color: #012f9c;
        font-size:18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        img{
          width:20px;
          height: 20px;
        }
      }
      span{
        font-size:14px;
      }
    }
    .footCon{
       max-height: 400px;
      overflow-y: auto;
      .footSub{
        margin-bottom: 16px;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
        padding:5px;
        box-sizing: border-box;
        width:280px;
        overflow: hidden;
        height:110px;
        //border: solid 1px;
        display: flex;
        //align-items: center;
        img{
          width:135px;
          height:100px;
          margin-right: 5px;
        }
        .subRig{
          //border: solid red 1px;
          width:125px;
          flex-wrap: wrap;
          font-size:14px;
          p{
          }
          .s_tit{
            height:40px;
            //border: solid 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient: vertical;
            line-height: 20px;
          }
          .s_desc{
            color:#999;
            margin:15px 0;
          }
          .s_kc{
            span{
              color: #ff6200;
            }
          }
        }
      }
    }
  }
  .favoCss{
    top:calc(6% + 200px);
    width: 170px;
    div{
      display: flex;
      align-items: center;
      img{
        width: 20px;
        margin-right:5px;
      }
    }
  }
}
</style>
