<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="twoModule w_1200">
        <div class="twoTop">
          <brandLeft ref="childClick"></brandLeft>
          <div class="rigIntro">
            <p class="infroTit">{{$t('品牌专区.授权信息')}}</p>
            <div class="imgCon">
              <img :src="$baseUrl+detail.authorizeIntroduce" />
            </div>
            <p class="imgTips">{{$t('品牌专区.授权代理证明')}}</p>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import brandLeft from '@/component/home/brandLeft.vue'
import {brandDetail} from '@/util/http'
import Cookies from "js-cookie";

export default{
  components: {topNav, bottomNav, brandLeft },
  data(){
        return{
            loading: false,
            detail:{},//品牌详情
        }
    },
    onload(){},
    mounted() {
      if(this.$route.query.brandId){
        this.brandId=this.$route.query.brandId
      }else{
        this.brandId=Cookies.get('brandId')
      }
      // 页面加载时立即调用子组件方法
      this.$refs.childClick.tabClick(2)
      this.getDetail()
    },
    methods:{
      getDetail(){
        brandDetail(this.brandId).then(res=>{
          this.detail=res.data
          this.getList()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .twoModule{
    margin: 40px auto;
    .twoTop {
      display: flex;
      justify-content: space-between;
      height: 400px;
      .rigIntro {
        width: 900px;
        background: #FFFFFF;
        padding: 24px 16px;
        box-sizing: border-box;
        .infroTit{
          font-weight: bold;
          font-size: 20px;
          color: #000000;
          padding-left: 15px;
          border-left: solid 4px #0332A1;
          //margin-bottom: 25px;
        }
        .imgCon{
          width: 300px;
          height: 300px;
          background-image: url("@/assets/sub/imgbg.png");
          background-size:  100%;
          background-repeat: no-repeat;
          background-position: center;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 193px;
            height: 274px;
            margin: auto;
          }
        }
        .imgTips{
          text-align: center;
          font-size: 18px;
          color: #000000;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>