<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="shopModule w_1200">
        <div class="shopLeft">
          <div class="totalModule pad">
            <div class="shopTit">{{$t('积分商城.我的积分')}}</div>
            <div class="totalSum">{{ totalIntegral }}<span></span></div>
            <div class="sLBtn">
              <div class="sLBtn1 hand" @click="pathClick('/myOrder','兑换记录')">
                <img src="@/assets/sub/jf1.png" />
                <span>{{$t('积分商城.兑换记录')}}</span>
              </div>
              <div class="sLBtn2 hand" @click="pathClick('/signScore')">
                <img src="@/assets/sub/jf2.png" />
                <span>{{$t('积分商城.赚取积分')}}</span>
              </div>
            </div>
          </div>
          <div class="hotModule pad">
            <div class="shopTit">{{$t('积分商城.热门产品')}}</div>
            <div class="hotGoods">
              <div class="hotSub hand" v-for="item in activeArr">
              <img class="hotImg" :src="$baseUrl+item.productPic" />
                <div class="hotRig">
                  <p class="hover">{{$i18n.locale == "zh" ?item.productName:item.productNameEn}}</p>
                  <p>{{item.requiredIntegral}}{{$t('积分商城.积分')}}</p>
                  <div class="subBtn" @click="integraClick(item)">{{$t('积分商城.立即兑换')}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="tagModule pad">
            <div class="shopTit">{{$t('积分商城.商品标签')}}</div>
            <div class="goodTag">
              <div class="hand" v-for="(item,index) in 20" :key="item">口罩{{index}}</div>
            </div>
          </div> -->
        </div>
        <div class="shopRig">
          <div class="sRigTop">
            <div class="topTag">
              <div class="tagDiv">
                <p>{{$t('积分商城.类型')}}</p>
                <div class="topTips">
                  <img src="@/assets/sub/yl.png" />
                  <p>{{$t('积分商城.每月最多可兑换3次当前还剩下')}} <span>{{count}}{{$t('积分商城.次')}}</span> </p>
                </div>
              </div>
              <div class="tClassfy hand">
                <span :class="item.dictValue==typeVal?'active':''" @click="typeClick(item)" v-for="item in typeList">{{ item.dictLabel }}</span>
              </div>
            </div>
            <div class="topSea">
              <el-checkbox v-model="checked" @change="checkClick">{{$t('积分商城.只显示积分够兑换的物品')}}</el-checkbox>
              <div class="seaDiv">
                <el-input class="inp" v-model="searchVal" @change="checkClick"></el-input>
                <img src="@/assets/sub/sea.png" />
              </div>
            </div>
          </div>
          <div class="sRigGoods">
            <div class="goodsSub hand hoverTo" v-for="item in list">
              <img :src="$baseUrl+item.productPic" />
              <p>{{$i18n.locale == "zh" ?item.productName:item.productNameEn}}</p>
              <div class="jfNum">
                <img src="@/assets/sub/jfSum.png" />
                <span>{{item.requiredIntegral}}{{$t('积分商城.积分')}}</span>
              </div>
              <div class="subBtn" @click="integraClick(item)">{{$t('积分商城.立即兑换')}}</div>
            </div>
          </div>
          <div style="text-align:right;margin-top:30px;" >
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {userIntegral,selectUser,creditsShop,insertAdd,collegeCount,collegeType,creditsShopTo} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
          loading: false,
          checked:false,
          pageSize:10,//每页显示条数
          pageNum:1,
          total:0,
          count:0,//兑换次数
          totalIntegral:'',//总积分
          typeVal:'',//选中的类型
          list:[],//兑换商品列表
          activeArr:[],//热门产品
          typeList:[],//类型列表
          searchVal:'',//搜索框
        }
    },
    created(){
      this.getMyIntegral()
      this.getList()
      this.getCount()
      this.getType()
      this.getActive()
    },
    methods:{
      typeClick(item){//选中分类
        this.typeList.map(i=>{
          i.flag=false
          if(item.dictValue==i.dictValue){
            this.typeVal = i.dictValue
          }
        })
        this.checkClick();
      },
      checkClick(){
        this.pageNum=1
        this.getList();
      },
      getType(){//分类
        collegeType().then(res=>{
          this.typeList=res.data
          this.typeList.map(i=>{
            i.flag = false
          })
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getCount(){//兑换次数
        collegeCount().then(res=>{
          this.count=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      pathClick(path,text){//兑换记录
        if(text=='兑换记录'){
          this.$router.push({
            path:path,
            query: {
              navVal: '2-1'
            }
          })
          return
        }
        this.$router.push(path)
      },
      integraClick(item){//立即兑换
        insertAdd({
          userId:JSON.parse(Cookies.get('userInfo')).userId,
          productId:item.id
        }).then(res=>{
          this.$message(res.msg);
          this.getMyIntegral()
          this.getList()
          this.getCount()
          this.getActive()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getMyIntegral(){//我的积分
        selectUser({userId:JSON.parse(Cookies.get('userInfo')).userId}).then(res=>{
          this.totalIntegral=res.userDetails.totalIntegral
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getActive(){
        creditsShopTo().then(res=>{
          this.activeArr=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);

        })
      },
      getList(){
        creditsShop({
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          productName:this.searchVal,
          type:this.typeVal,
          enoughItems:this.checked==true?1:2
        }).then(res=>{
          this.list=res.rows
          this.total=res.total
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);

        })
      },
      pageChange(e){//当前页
        this.pageNum=e
        this.getList()
        console.log(e)
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .shopModule{
    display: flex;
    justify-content: space-between;
    margin: 60px auto;
    div{
      border-radius: 4px;
    }
    .shopLeft{
      width: 322px;
      .pad{
        padding:25px 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        margin-bottom: 30px;
      }
      .shopTit{
        font-size: 24px;
        color: #333333;
        border-radius:2px;
        padding-left:3px;
        border-left: 6px solid #FF6600;
        margin-bottom: 20px;
      }
      .totalModule{
        .totalSum{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 64px;
          color: #000000;
          text-align: center;
          margin:25px auto;
          span{
            display: inline-block;
            margin-left: 10px;
            height: 35px;
            width: 32px;
            background-image: url("@/assets/sub/jfRig.png");
            background-size: 100% 100%;
          }

        }
        .sLBtn{
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .sLBtn1,.sLBtn2{
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 32px;
            font-size: 13px;
            width: 110px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 11px;
              height: 14px;
              margin-right: 10px;
            }
          }
          .sLBtn1{
            border: 1px solid #012F9C;
            color: #012F9C;
          }
          .sLBtn2{
            background: #012F9C;
            color: #fff;
          }
        }
      }
      .hotModule{
        .hotSub{
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .hotImg{
            width: 150px;
            height: 96px;
            min-width: 150px;
            min-height: 96px;
            border-radius: 4px;
          }
          .hotRig{
            margin-left: 10px;
            p{
              line-height: 15px;
              font-size: 13px;
              color: #333333;
              margin-bottom:10px;
            }
          }
        }
      }
      .tagModule{
        .goodTag{
          display: flex;
          flex-wrap: wrap;
          div{
            font-size: 16px;
            color: #000000;
            padding:8px 12px;
            text-align: center;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #000000;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .shopRig{
      width: 854px;
      .sRigTop{
        background: #FFFFFF;
        margin-bottom: 30px;
        padding:20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .topTag{
          flex: 1;
          .tagDiv{
            font-size: 24px;
            color: #000000;
            line-height: 28px;
            display: flex;
            align-items: center;
          }
          .topTips{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #333333;
            margin-left: 150px;
            img{
              width: 20px;
              height: 20px;
              margin-right: 6px;
            }
            span{
              color:#FF6600;
            }
          }
          .tClassfy{
            display: flex;
            align-items: center;
            margin-top: 15px;
            flex-wrap: wrap;
            span{
              font-size: 16px;
              color: #999999;
              margin-right:30px;
              margin-top: 10px;
            }
            .active{
              font-weight: bold;
              font-size: 16px;
              color: #012F9C;
            }
          }
        }
        .topSea{
          font-size: 13px;
          color: #999999;
          margin-top: 8px;

          .seaDiv{
            margin-top: 8px;
            padding: 10px 15px;
            box-sizing: border-box;
            width: 208px;
            height: 40px;
            background: #F3F5F8;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #999999;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          :deep .el-input__inner{
            background: #F3F5F8;
            height: 26px;
            border: none !important;
          }
          img{
            width: 26px;
            height: 26px;
          }
        }
      }
      .sRigGoods{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        .goodsSub{
          width: 272px;
          height: 296px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
          border-radius: 8px;
          padding: 20px;
          line-height: 20px;
          box-sizing: border-box;
          font-size: 16px;
          color: #000000;
          img{
            width: 232px;
            height: 136px;
            border-radius: 4px;
            margin-bottom: 10px;
          }
          .jfNum{
            margin:10px 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #FF6600;
            img{
              width: 22px;
              height: 22px;
              margin-right: 6px;
              margin-bottom: 0px;

            }
          }
        }
      }
    }
          .subBtn{
            font-size: 13px;
            color: #FFFFFF;
            text-align: center;
            width: 80px;
            line-height: 30px;
            background: #012F9C;
            border-radius: 4px;
          }
  }
}
</style>