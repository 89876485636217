import axios from 'axios'
import { COMMON_CODE } from './code'
import { getToken } from './cookie'
import router from '../router/index'
import { MessageBox, Message } from 'element-ui'
import Vue from 'vue'
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // timeout: 3000 // 超时时间
})

//权限key字符串
const AUTH = "token"

//无需权限的接口
const NO_AUTH_URL = [
    '/api/user/login',
    '/api/user/mobilelogin',
    '/api/user/register',
    '/api/user/restpwd1',
    '/api/user/restpwd2'
]

//请求拦截器
instance.interceptors.request.use(function (config) {
    if (NO_AUTH_URL.indexOf(config.url) >= 0) {
        return config
    }
    let token = getToken();
    if (token) {
        config.headers['Authorization'] = token
    }
    return config
}, function (error) {
    return Promise.reject(error);
});


//响应拦截器
instance.interceptors.response.use(function (response) {
    let data = response.data
    if (data.code === COMMON_CODE.OK.code) {
        return Promise.resolve(data)
    }
    //
    // if (data.code == COMMON_CODE.UNAUTHORIZED.code) {
    //     window.location.href = COMMON_CODE.UNAUTHORIZED.redirect
    // }
    //
    // if (data.code == COMMON_CODE.ERROR.code) {
    //     return Promise.reject(COMMON_CODE.ERROR.message)
    // }
    else if (data.code === 401) {
        if (!Vue.prototype.$pop) {
            Vue.prototype.$pop = true;
            MessageBox.alert('您好! 请先登录账号', '提示', {
                confirmButtonText: '去登录',
                callback: (action) => {
                    if (action == 'cancel') {
                        Vue.prototype.$pop = false;
                        return
                    }
                    Vue.prototype.$pop = false;
                    router.push('/login')
                }
            });
        }

        return Promise.reject();
    } else {
        // Message({
        //     type: 'warning',
        //     message: data.msg,
        //     offset: 100
        // });

        //原本：
        // return Promise.reject(data)

        //修改后 - 我需要放行
        return Promise.resolve(data)
    }
}, function (error) {
    if (error.response.data.code == 401) {
        if (!Vue.prototype.$pop) {
            Vue.prototype.$pop = true;
            MessageBox.alert('您好! 请先登录账号', '提示', {
                confirmButtonText: '去登录',
                callback: (action) => {
                    if (action == 'cancel') {
                        Vue.prototype.$pop = false;
                        return
                    }
                    Vue.prototype.$pop = false;
                    router.push('/login')
                }
            });
        }

        return Promise.reject();
        // router.push('/login')
    } else {
        return Promise.reject(error.response.data);
    }
});

export default instance
