<template>
  <div>
    <div class="box_1">
      <div class="nav_top w_1200">
        <div v-if="userInfo && userInfo.userName" class="n_l clear">{{ userInfo.userName }},
          <span><router-link class="orange" to="/login">{{ $t('首页.退出登录') }}</router-link></span>
        </div>
        <div v-else class="n_l clear">
          <span><router-link to="/login">{{ $t('首页.登录') }}</router-link></span>
          <span><router-link class="orange" to="/regist">{{ $t('首页.注册') }}</router-link></span>
        </div>
        <div class="n_r clear">
          <span><router-link class="orange" to="/signIn">{{ $t('首页.签到送积分') }}</router-link></span>
          <span><router-link to="/myOrder">{{ $t('首页.我的订单') }}</router-link></span>
          <span><router-link to="/myMsg">{{ $t('首页.消息') }}
            <!-- <img class="i" src="@/assets/images/i.png"alt="" /> -->
          </router-link></span>
          <span><router-link to="/vip">
            {{ $t('首页.会员中心') }}
            <!-- <img class="i" src="@/assets/home/icon/down.png" alt="" /> -->
          </router-link></span>
          <span class="orange"><img class="i" src="@/assets/home/icon/phone.png" alt="" />
            400-870-9968</span>
          <span @click="setLanguage">{{ $t('首页.语言') }}</span>
          <!-- <a href=""></a> -->
          <span><img class="i" src="@/assets/home/icon/collect.png" alt="" />{{ $t('首页.加入收藏') }}</span>
          <span><img class="i" src="@/assets/home/icon/mobile.png" alt="" />{{ $t('首页.手机贞光') }}</span>
        </div>
      </div>
    </div>
    <div class="box_2">
      <div class="nav_main w_1200" @mouseleave="carPop = false">
        <div class="left hand" @click="$router.push('/')">
          <img src="@/assets/home/logo.png" alt="" />
        </div>
        <div class="center">
          <el-input class="int" :placeholder="$t('首页.请输入内容')" suffix-icon="el-icon-search">
          </el-input>
          <div class="list">
            <span class="item hover" :class="active == item.id ? 'active' : ''" @click="activeHand(item, index)"
              v-for="(item, index) in list" :key="index">{{ item.name }}</span>
          </div>
        </div>
        <div class="right">
          <div class="car hand" @mouseover="carClick" @click="carPath"><img class="i" src="@/assets/home/icon/shopCar.png" alt="" />{{
            $t('首页.我的购物车') }}</div>
        </div>
        <div class="carDiv" v-if="carPop" @mouseleave="carPop = false">
          <el-table :data="tableData" height="175" style="width: 100%">
            <el-table-column prop="manufacturerNumber" :label="$t('首页.型号')" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="materialNumber" :label="$t('首页.自定义料号')" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="unitPrice" :label="$t('首页.单价')">
              <template slot-scope="scope">
                <span>¥{{ scope.row.unitPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="buyNumber" :label="$t('首页.数量')" width="90">
            </el-table-column>
            <el-table-column prop="subTotal" :label="$t('首页.合计')" width="120">
              <template slot-scope="scope">
                <span>¥{{ scope.row.subTotal }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { shoppingCarList } from '@/util/http'

export default {
  name: "topNav",
  props: {
    navFlag: {
      type: Boolean,
      default: false
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      carPop: false,
      userInfo: {},
      // active: 0, //激活下标
      list: [
        { id: 1, name: this.$t('首页.首页'), url: '/' },
        { id: 2, name: this.$t('首页.商品目录'), url: '/catalogueOne' },
        { id: 3, name: this.$t('首页.品牌专区'), url: '/brandOne' },
        { id: 4, name: this.$t('首页.BOM配单'), url: '/BOM' },
        { id: 5, name: this.$t('首页.授权代理'), url: '/authorized' },
        { id: 6, name: this.$t('首页.供应商合作'), url: '/supplier' },
      ],
      tableData: []
    };
  },
  mounted() {
    this.userInfo = JSON.parse(Cookies.get('userInfo'))
    // console.log('uuu', this.userInfo)
    window.addEventListener("scroll", this.handleScroll);
    this.getList()

  },
  methods: {
    getList(){//获取购物车列表
        shoppingCarList({
          sort:'',
          collect:'',
          pageSize:10,
          pageNum:1
        }).then(res=>{
          // this.tableData = res.rows;
          this.tableData = res.rows.map(row => ({
          ...row,
            flag: false,
            unitPrice: this.getUnitPrice(row.children, row.buyNumber),
            subTotal: this.calculateSubTotal(row.children, row.buyNumber)
        }));
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getUnitPrice(children, buyNumber) {//根据用户输入的数量找到合适的单价
      let prevQuantity = 0;
      let prevUnitPrice = null;

      for (const child of children) {
        if (buyNumber <= child.quantity) {
          return prevUnitPrice || child.unitPrice;
        }
        prevQuantity = child.quantity;
        prevUnitPrice = child.unitPrice;
      }

      return children[children.length - 1].unitPrice;
    },
    calculateSubTotal(children, buyNumber) {//根据单价和用户输入的数量计算小计金额
      const unitPrice = this.getUnitPrice(children, buyNumber);
      return unitPrice * buyNumber;
    },
    carPath(){
      this.$router.push('/carIndex')
    },
    carClick() {
      this.carPop = true;
    },
    activeHand(item, index) {
      this.active = index;
      if (item.url) {
        this.$router.push(item.url)
      }
    },
    setLanguage() {
      if (this.$i18n.locale == 'zh') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'zh'
      }
      Cookies.set('lang', this.$i18n.locale)
      console.log(Cookies.get('lang'))
    },
    // 滚动吸附
    handleScroll() {
      let scrollY =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //scrollObj 这个是下图右边答题卡的div
      if (scrollY > 40) {
        $(".box_2").addClass("is_fixed");
      } else {
        $(".box_2").removeClass("is_fixed");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.active {
  font-weight: bold;
  color: #012f9c !important;
}

body {
  color: #333;
}

.is_fixed {
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3), 0 0 6px 2px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 107;
  // height: 82px;
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
}

.box_1 {
  position: relative;
  background: #e6e6e6;
  font-size: 13px;
  // 测试用
  // position: fixed;
  // top: 144px;
  // z-index:100;
  // width: 100%;

  .nav_top {
    height: 40px;
    line-height: 40px;

    .n_l {
      float: left;

      span {
        cursor: pointer;
        padding: 0 10px;
      }
    }

    .n_r {
      float: right;

      span {
        cursor: pointer;
        margin-left: 20px;
        float: left;

        // flex-wrap: nowrap;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .i {
          bottom: 1px;
          position: relative;
          margin: 0 2px;
          vertical-align: middle;
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}

.box_2 {
  background-color: #fff;
  width: 100%;

  .nav_main {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 23px;
    height: 144px;

    .left {
      width: 225px;
      height: 80px;
      border-radius: 0px 0px 0px 0px;
      background-color: skyblue;
    }

    .center {
      // width: 572px;
      min-width: 572px;

      .int {
        margin-bottom: 26px;
        width: 100%;
        outline: none;
        height: 40px;
        border: 1px solid #808080;
        border-radius: 6px 6px 6px 6px;
      }

      .list {
        box-sizing: border-box;
        // padding: 0 5px;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;

        .item {
          cursor: pointer;
          line-height: 40px;
          font-size: 16px;
          white-space: nowrap;
          padding:0 6px;
        }
      }
    }

    .right {
      position: relative;
      width: 146px;
      height: 100%;

      // background: #f3f5f8;
      .car {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 40px;
        position: absolute;
        bottom: 15px;
        right: 0;
        background: #f3f5f8;
        border-radius: 4px 4px 4px 4px;
        font-size: 13px;

        .i {
          margin-right: 6px;
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}

.carDiv {
  position: absolute;
  top: 144px;
  right: 0px;
  z-index: 100;
  width: 608px;
  height: 211px;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: solid 1px #bebebe;

  :deep .el-table th.el-table__cell {
    background: #012F9C;
    color: #fff;
    font-weight: 400;
  }

  :deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none !important;
    padding: 4px 0;
    color: #222;
  }
}
</style>