<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <div class="indexModule w_1200">
          <div class="carModule">
            <div class="carTop">
              <p class="titP">{{$t('购物车.购物车')}}</p>
              <div class="allRig hand">
                <span @click="allClick(checkAll)">{{$t('购物车.全选')}}</span>
                <span>{{$t('购物车.按时间排序')}}</span>
                <span class="active">{{$t('购物车.继续购物')}}</span>
              </div>
            </div>
            <div class="classify">
              <div class="ifyLeft">
                <p v-for="(item,index) in classifyArr" @click="classifyClick(item)">
                  <span class="titTop" :class="item.flag?'classAct':'hand hover'">{{item.name}}</span>
                  <span class="line" v-if="index>=0&&index<classifyArr.length-1"></span>
                </p>
              </div>
              <p class="ifyRig">{{$t('购物车.订单已满')}}299{{$t('公用.元')}}{{$t('购物车.已免运费！')}}</p>
            </div>
            <div class="tab">
              <table>
                <tr class="trTit">
                  <th>{{$t('购物车.商品编号')}}</th>
                  <th>{{$t('购物车.商品描述')}}</th>
                  <th>{{$t('购物车.单价（含税）')}}</th>
                  <th>{{$t('购物车.订单数量')}}</th>
                  <th>{{$t('购物车.小计')}}</th>
                  <th>{{$t('购物车.操作')}}</th>
                </tr>
                <tr class="trCon" v-for="(item,index) in tableData">
                  <td style="width:340px">
                    <div class="descCss">
                      <p class="allBtn hand"><img @click.stop="selectClick(item)" :src="item.flag?require('@/assets/member/xz.png'):require('@/assets/member/wxz.png')" /></p>
                      <img class="goodsPhoto" @click="detailClick(item)" :src="$baseUrl+item.productPhoto" />
                      <div>
                        <p class="hand" @click="detailClick(item)">{{$t('购物车.商品编号：')}}{{item.manufacturerNumber}}</p>
                        <p>{{$t('购物车.自定义料号')}}<input type="text" v-model="item.materialNumber" @blur.stop="handleChange(item,'liao')" /></p>
                        <p>{{$t('购物车.品牌')}}{{ $i18n.locale=='zh'?item.productBrandName:item.productBrandNameEn }}</p>
                      </div>
                    </div>
                  </td>
                  <td style="width:220px">
                    <p>{{$t('购物车.商品描述')}}{{$i18n.locale=='zh'?item.productName:item.productNameEn}}</p>
                  </td>
                  <td class="dj" style="width:160px">
                    <p>{{$t('购物车.商品单价')}}{{$t('公用.¥')}}{{item.unitPrice}}</p>
                  </td>
                  <td style="width:200px">
                    <p class="numEd">
                      <span>{{$t('购物车.需求数量')}}</span>
                      <el-input-number v-model="item.buyNumber" size="mini" @change="(e)=>handleChange(item,'num',e)" :min="1"></el-input-number>
                    </p>
                    <p>{{$t('购物车.库存')}}{{item.inventory}}</p>
                  </td>
                  <td style="width:105px" class="xj"><p>{{$t('公用.¥')}}{{item.subTotal}}</p></td>
                  <td>
                    <div class="editTab hand">
                      <img @click.stop="collectClick(item)" :src="require(item.collectBoolean>0?'@/assets/member/sc.png':'@/assets/member/favo.png')" />
                      <img @click.stop="delsClick(item)" src="@/assets/member/del.png" />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div style="text-align:right;margin-top:30px;" >
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
          </div>
        <div class="carAll">
            <div class="allLeft hand">
              <p class="allBtn"><img @click="allClick(checkAll)" :src="checkAll?require('@/assets/member/xz.png'):require('@/assets/member/wxz.png')" /><span>{{$t('购物车.购物车')}}全选</span></p>
              <p @click="delsClick">{{$t('购物车.批量删除')}}</p>
              <p @click="editClick" >{{$t('购物车.批量更改国内外')}}</p>
              <p>{{$t('购物车.清除失效商品')}}</p>
              <!-- <p>{{$t('购物车.导出商品')}}</p> -->
              <p @click="allAddLiaoHao">{{$t('购物车.批量添加料号')}}</p>
            </div>
          <div class="allRig">
            <div class="text">
              <p>{{$t('购物车.已选择')}}{{tableData.length}}{{$t('购物车.个型号')}} {{$t('购物车.总金额(不含运费):')}}</p>
              <p style="font-size:12px;">{{$t('购物车.您现在下单发货后还可获得')}}<span>{{ calculateTotal}}</span>{{$t('购物车.积分!')}}</p>
            </div>
            <p class="catTotal">￥{{ calculateTotal }}</p>
            <div class="carBtn hand" @click="submitClick">{{$t('购物车.去结算')}}</div>
          </div>
        </div>
        <div class="carGoods">
          <div class="goodsModule" v-for="(item,index) in proArr">
            <p class="goodsTit" v-if="index==0">{{$t('购物车.热销商品')}}</p>
            <p class="goodsTit" v-if="index==1">{{$t('购物车.我的收藏夹')}}</p>
            <p class="goodsTit" v-if="index==2">{{$t('购物车.我买过的')}}</p>
            <div @click="pathClick(item2)" v-if="index2<=1" class="goodSub hand" v-for="(item2,index2) in item" :key="item2.id">
              <img v-if="item2.picChildren&&item2.picChildren.length>0" :src="$baseUrl+item2.productPhoto" />
              <div>
                <p>{{$t('购物车.产品名称')}}{{ $i18n.locale=='zh'?item2.productName:item1.productNameEn }}</p>
                <p>{{$t('购物车.品牌：')}}{{ $i18n.locale=='zh'?item2.productBrandName:item1.productBrandNameEn }}</p>
                <p class="pri" v-if="item2.priceChildren&&item2.priceChildren.length>0">{{$t('公用.¥')}}{{ item2.priceChildren[0].unitPrice }}</p>
                <p class="addBtn hand" @click.stop="addCar(item2)">{{$t('购物车.加入购物车')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {shoppingCarList,editShoppingCar,delCar,productList,collectList,buyList,userShoppingCar,insertSave,homeAndAbroad,addItemNumber,myCollect} from '@/util/http'
import Cookies from 'js-cookie'
export default{
  components: {carTopNav, bottomNav },
  data(){
        return{
          loading: false,
          pageSize:10,//每页显示条数
          pageNum:1,
          total:0,
          classifyVal:{},//海内外
          classifyArr:[
              {id:1,name:this.$t('购物车.全部'),num:'3',val:'',flag:true},
              {id:2,name:this.$t('购物车.国内'),num:'3',val:'1',flag:false},
              {id:3,name:this.$t('购物车.海外'),num:'3',val:'2',flag:false},
          ],
          tableData: [],//购物车列表
          proArr:[],//热销商品 我的收藏夹 我买过的
          collArr:[],//我的收藏夹
          buyArr:[],//我买过的
        }
    },
    mounted(){
      this.getList()
      this.getProduct()
    },
      computed: {
      // 计算属性来自动计算总金额
      calculateTotal() {
        let priceAll=0;
				this.tableData.map((item,index)=>{
					if(item.flag){
						// priceAll=((priceAll*100+(item.subTotal)*100)/100).toFixed(2)
						priceAll=((priceAll*100+(item.subTotal)*100)/100)
						// priceAll=((priceAll*100+(item.subTotal.replace(/\./g, "")*item.buyNumber)*100)/100)
					}
				})
				// priceAll=priceAll.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				return priceAll;
      },
      checkAll(){
        if(!this.tableData || this.tableData.length<=0) return false
        let allState=true;
        this.tableData.forEach(item=>{
          if(!item.flag){
            allState=false;
          }else{
            // console.log(item.id)
          }
        })
        return allState;
      }
    },
    methods:{
      submitClick(){//去结算
        let tabArr=[]
        let carIdStr=''
        this.tableData.forEach(item=>{
          if(item.flag){
            tabArr.push(item)
            carIdStr!=''?carIdStr+=','+item.id:carIdStr=item.id
          }
        })
        if(!carIdStr){
          this.$message(this.$t('购物车.请选择要下单的商品'))
          return
        }
        insertSave({shoppingCarIds:carIdStr}).then(res=>{
          Cookies.set('carId',res.data)
          this.$router.push('/order')
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      detailClick(item){//查看商品详情
        this.$router.push({
          path: '/goods',
          query: {
            id: item.productId
          }
        })
      },
      pathClick(item){
        this.$router.push({
          path: '/goods',
          query: {
            id: item.id
          }
        })
      },
      selectClick(item){//选中商品
        item.flag=!item.flag;
      },
      allClick(checkAll){//全选按钮、
        this.tableData.map(item => {
          item.flag = !checkAll;
        });
      },
      getProduct(){//热销 收藏 买过
        this.proArr=[]
        const arr=[productList,collectList,buyList];
        const promises = arr.map(api => api());
        Promise.all(promises).then(([productRes, collectRes, buyRes]) => {
          this.proArr.push(productRes.rows)
          this.proArr.push(collectRes.rows)
          this.proArr.push(buyRes.rows)
          // console.log(this.proArr)

          // this.collArr=collectRes.rows
          // this.buyArr=buyRes.rows
        }).catch((error) => {
          console.log(error)
        });
      },
      getList(){//获取购物车列表
        shoppingCarList({
          sort:this.classifyVal.val,
          collect:this.collect,
          pageSize:this.pageSize,
          pageNum:this.pageNum
        }).then(res=>{
          // this.tableData = res.rows;
          this.total = res.total;
          this.tableData = res.rows.map(row => ({
          ...row,
            flag: false,
            unitPrice: this.getUnitPrice(row.children, row.buyNumber),
            subTotal: this.calculateSubTotal(row.children, row.buyNumber)
        }));
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      collectClick(item){
        myCollect({productId:item.productId}).then(res=>{
          this.getList();
          this.getProduct()
          item.unitPrice = this.getUnitPrice(item.children, item.buyNumber);
          item.subTotal = this.calculateSubTotal(item.children, item.buyNumber);
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      handleChange(item,text,index) {//订单数量
        console.log(item,text,index)
        let obj={
          id: item.id,
          userId:JSON.parse(Cookies.get('userInfo')).userId,
          productId:item.productId
        }
        console.log(index)
        if(text=='num'){
          obj.buyNumber=index
        }
        // if(text=='coll'){
        //   obj.collect=item.collect?0:1
        // }
        if(text=='liao'){
          obj.materialNumber=item.materialNumber
        }
        editShoppingCar(obj).then(res=>{
          this.getList();
          this.getProduct()
          item.unitPrice = this.getUnitPrice(item.children, item.buyNumber);
          item.subTotal = this.calculateSubTotal(item.children, item.buyNumber);
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
    },
    addCar(item){//加入购物车
      userShoppingCar({
          productId:item.id,
          buyNumber:1,
          userId: JSON.parse(Cookies.get('userInfo')).userId
        }).then(res=>{
          this.getList();
          this.getProduct()
          this.$message(res.msg)
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
    },
    allAddLiaoHao(){//批量添加料号
      let ids=''
      let materialNumbers=''
      this.tableData.forEach(item=>{
          if(item.flag){
            ids==''?ids=item.id:ids+=','+item.id
            materialNumbers==''?materialNumbers=item.materialNumber:materialNumbers+=','+item.materialNumber
          }
        })
        if(!ids){
          this.$message(this.$t('购物车.请选择要更改的商品'))
          return
        }
      addItemNumber({ids:ids,materialNumbers:materialNumbers}).then(res=>{
          this.$message(res.msg)
          this.getList()
        }).catch(err=>{
            if(err.msg) this.$message(err.msg)
        })
    },
    editClick(){//批量更改国内外
      let ids=''
      this.tableData.forEach(item=>{
          if(item.flag){
            ids==''?ids=item.id:ids+=','+item.id
          }
        })
        if(!ids){
          this.$message(this.$t('购物车.请选择要更改的商品'))
          return
        }
        homeAndAbroad({ids:ids}).then(res=>{
          this.$message(res.msg)
          this.getList()
        }).catch(err=>{
            if(err.msg) this.$message(err.msg)
        })
    },
    delsClick(item){//删除购物车
      console.log(item)
      let ids=''
      if(item.id){
        ids=item.id
      }else{
        this.tableData.forEach(item=>{
          if(item.flag){
            ids==''?ids=item.id:ids+=','+item.id
          }
        })
      }
      if(!ids){
        this.$message(this.$t('购物车.请选择要删除的商品'))
        return
      }
      delCar(ids).then(res=>{
        this.$message(res.msg)
        this.getList()
      }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
    },
    getUnitPrice(children, buyNumber) {//根据用户输入的数量找到合适的单价
      let prevQuantity = 0;
      let prevUnitPrice = null;

      for (const child of children) {
        if (buyNumber <= child.quantity) {
          return prevUnitPrice || child.unitPrice;
        }
        prevQuantity = child.quantity;
        prevUnitPrice = child.unitPrice;
      }

      return children[children.length - 1].unitPrice;
    },
    calculateSubTotal(children, buyNumber) {//根据单价和用户输入的数量计算小计金额
      const unitPrice = this.getUnitPrice(children, buyNumber);
      return unitPrice * buyNumber;
    },
      pageChange(e){//当前页
        this.pageNum=e
        this.getList()
        console.log(e)
      },
      classifyClick(item){//分类
        this.classifyArr.forEach(i=>{
          i.flag=false;
          if(i.id==item.id){
            i.flag=true;
            this.classifyVal=i;
            this.page=1;
            this.getList()
          }
        })
      },
      selectTable(e){
        console.log(e)
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .indexModule{
    margin:60px auto;
    .titP{
      font-size: 24px;
      color: #333333;
    }
    .carModule{
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding:20px 40px;
      box-sizing: border-box;
      .carTop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .allRig{
          display: flex;
          align-items: center;
          span{
            font-size: 12px;
            color: #333333;
            padding:5px 10px;
            margin-left: 25px;
            background: #F3F5F8;
            border-radius: 2px 2px 2px 2px;
          }
          .active{
            background: #012F9C;
            color: #fff;
          }
        }
      }
      .classify{
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom:2px solid #D9D9D9;
        .ifyLeft{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          .titTop{
            padding-bottom: 7px;

          }
          .line{
            margin:0 15px;
            width: 2px;
            height: 13px;
            border-left: solid 2px #D9D9D9;
            border-radius: 23px;
            display: inline-block;
          }
          .classAct{
            font-size: 16px;
            color: #012F9C;
            border-bottom: 2px solid #ff6600;
          }
        }
        .ifyRig{
          font-size: 16px;
          color: #FF6600;
        }
      }
      .tab{
        font-size: 13px;
        color: #333333;
        line-height: 22px;
        p{
          margin-bottom:8px;
          display: flex;
          align-items: center;
          input{
            width:80px;
          }
        }
        .descCss{
          display: flex;
          .goodsPhoto{
            width: 80px;
            height: 80px;
            margin-right:10px;
            border-radius: 4px;
          }
        }
        .numEd{
          display: flex;
          align-items: center;
        }
        .editTab{
          display: flex;
          align-items: center;
          img{
            width: 30px;
            height: 30px;
            margin:0 5px;
          }
        }
        table{
          width: 100%;
          border-collapse: separate; /* 保持单元格分开 */
          //border-collapse: collapse; /* 保持单元格分开 */
          border-spacing: 0 20px; /* 行之间的距离 */
          td{
            padding:20px 0;
            box-sizing: border-box;
            //vertical-align: top;
            //border: solid 1px #D0D0D0;
            background: #FFFFFF;
          }
          td:first-child{
            padding-left:20px;
          }
          td:last-child{
          }
          .trTit{
            height: 48px;
            line-height: 48px;
            background: #012F9C;
            font-size: 16px;
            color: #FFFFFF;
            th:nth-child(1){
              border-radius: 8px 0px 0px 0px;
            }
            th:last-child{
              border-radius: 0px 8px 0px 0px;
            }
          }
          .trCon{
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #D0D0D0;
            padding: 20px;
            box-sizing: border-box;
          }
          .dj p{
            color:#FF6600;
            text-align: center;
            display: block
          }
          .xj p{
            text-align: center;
            display: block
          }
        }

      }
    }
    .allBtn{
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #333333;
      img{
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
    }
    .carAll{
      height: 70px;
      padding:0 40px;
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px auto;
      .allLeft{
        font-size: 14px;
        color: #012F9C;
        display: flex;
        align-items: center;
        p{
          margin: 0 15px;
        }
      }
      .allRig{
        display: flex;
        align-items: center;
        .text{
          font-size: 14px;
          color: #333333;
          p{
            margin-bottom: 6px;
          }
          span{
            color: #FF6600;
          }
        }
        .catTotal{
          font-weight: bold;
          font-size: 17px;
          color: #FF6600;
          margin:0 15px;
        }
        .carBtn{
          width: 133px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          font-weight: bold;
          font-size: 17px;
          color: #FFFFFF;
          background: #FF6600;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
    .carGoods{
      display: flex;
      justify-content: space-between;
      .goodsModule{
        width: 384px;
        height: 370px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        padding: 20px 40px;
        box-sizing: border-box;
        .goodsTit{
          font-size: 24px;
          color: #333333;
        }
        .goodSub{
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          color: #000000;
          margin-top: 20px;
          img{
            width: 120px;
            height: 120px;
            margin-right: 20px;
            border-radius: 4px 4px 4px 4px;
          }
          div{
            p{
              margin-bottom: 9px;
            }
            .pri{
              color: #FF6600;
            }
            .addBtn{
              width: 153px;
              height: 40px;
              text-align: center;
              color: #fff;
              line-height: 40px;
              background: #012F9C;
              border-radius: 4px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>