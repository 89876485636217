<template>
    <div class="indexBg" v-loading="loading">
      <topNav :active="3"></topNav>
      <div class="brandModule w_1200">
        <p class="brandTit">{{$t('商品目录.精选品牌')}}</p>
        <div class="brandDiv">
          <img class="hand hoverTo" @click="imgClick(item)" v-for="item in list" :src="$baseUrl+item.brandShowPic" />
        </div>
        <div class="classTop">
          <div class="className">
            {{$t('商品目录.元器件共计')}}
            <div class="checkCss">
              <el-checkbox-group v-model="checkList" @change="getBrandList">
                <el-checkbox :label="$t('商品目录.仅显示授权代理品牌')"></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="classSera">
            <input type="text" v-model="search" :placeholder="$t('商品目录.请输入关键字')" />
            <div class="hand" @click="getBrandList">{{$t('商品目录.搜索')}}</div>
          </div>
        </div>
        <div class="num_box">
          <div class="tit">{{$t('首页.字母索引')}}:</div>
          <div class="hand" @click="ziMuClick(index)" :class="numActive==index?'active':'hover'" v-for="(item, index) in numIndex" :key="index">{{ item }}</div>
        </div>
        <div class="beandDiv">
          <div class="brandSub">
            <p class="zTit">{{numIndex[numActive]}}</p>
            <div class="subItem">
              <!-- <p class="hand hover" v-for="(item,index) in brandList" @click="subClick(item)"> -->
                <p class="hand hover" v-for="(item,index) in brandList" @click="imgClick(item)">
                <span>{{ $i18n.locale=='zh'?item.nickName:item.nickNameEn }}</span>
                <img v-if="item.authorize==1" src="@/assets/sub/dp.png" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {getAllJx,getBrandList} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
          loading: false,
          list:[],
          brandList:[],
          checkList:[],
          search:'',
          numActive: 0,
          numIndex: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "#",
          ],
        }
    },
    created(){
      this.getList()
      this.getBrandList()
    },
    methods:{
      getList(){
        getAllJx().then(res=>{
          this.list=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getBrandList(){
        getBrandList({
          letter:this.numIndex[this.numActive]=='#'?'':this.numIndex[this.numActive],
          authorize:this.checkList[0]=='仅显示授权代理品牌'?1:0,//0：否，1：是
          brandName:this.search
        }).then(res=>{
          this.brandList=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      ziMuClick(index){
        this.numActive=index
        this.getBrandList()
      },
      subClick(item){
        this.$router.push('/catalogueTwo')
      },
      imgClick(item){
        Cookies.set('brandId',item.id)
        this.$router.push({path:'/brandTwo',query:{brandId:item.id}})
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .brandModule{
    margin: 40px auto;
    .brandTit{
      font-weight: bold;
      font-size: 23px;
      color: #000000;
      margin-bottom: 10px;
    }
    .brandDiv{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap:15px;
      img{
        width: 157px;
        height: 70px;
        border: 1px solid #E5E5E5;
      }
    }
    .classTop{
      margin:25px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .className{
        font-weight: bold;
        font-size: 23px;
        color: #000000;
        display: flex;
        align-items: center;
        .checkCss{
          :deep .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #000;
          }
          :deep .el-checkbox__label{
            font-size: 19px;
            color: #333;
            font-weight: 400;
          }
          :deep .el-checkbox__inner{
            background: linear-gradient( 180deg, #F6F6F6 0%, #E7E7E7 100%);
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #A7A7A7;
          }
          :deep .el-checkbox__inner{
            width: 21px;
            height: 21px;
            line-height: 21px;
          }
          :deep .el-checkbox__inner::after {
            border: 2px solid #012F9C;
            height: 10px;
            width: 4px;
            left: 6px;
            position: absolute;
            top: 2px;
            border-left: 0;
            border-top: 0;
          }
        }
      }
      .classSera{
        display: flex;
        align-items: center;
        input{
          height: 40px;
          width: 284px;
          background: #FFFFFF;
          text-indent: 1rem;
          border: 1px solid #CCCCCC;
        }
        div{
          width: 93px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #012F9C;
          font-size: 18px;
          border: 1px solid #012F9C;
          color: #FFFFFF;
        }
      }
    }
    .num_box {
       padding: 0 52px;
       padding-right: 65px;
       height: 54px;
       display: flex;
       align-items: center;
       justify-content: space-between;
      font-weight: bold;
      font-size: 21px;
      color: #000000;
      .tit{
        font-weight: 400;
        font-size: 20px;
        color: #333333;
      }
      .active{
        width: 34px;
        text-align: center;
        line-height: 34px;
        background: #666666;
        color: #fff;
      }
     }
    .beandDiv{
      .brandSub{
        width: 1203px;
        .zTit{
          line-height: 48px;
          font-weight: bold;
          font-size: 28px;
          padding-left:20px;
          color: #000000;
          background: #F3F3F3;
          border: 1px solid #F3F3F3;
        }
        .subItem{
          padding: 25px;
          box-sizing: border-box;
          background: #fff;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 15px;
          p{
            font-size: 18px;
            color: #797979;
            display: flex;
            align-items: center;
            img{
              width: 23px;
              height: 23px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
</style>