<template>
    <div class="aboutBg">
      <topNav></topNav>
      <div class="adoutCon" :style="{'background-image':'url('+$baseUrl+introduce.titleBackgroundPic+')'}">
        <div class="adbouTop">
          <img src="@/assets/images/logo2.png" />
          <div class="topRig hand">
            <span>{{$t('关于我们.关于贞光')}}</span>
            <span class="cenLine">|</span>
            <span @click="pageClick('/honor')">{{$t('关于我们.荣誉风采')}}</span>
            <span class="cenLine">|</span>
            <span @click="pageClick('/agency')">{{$t('关于我们.授权代理')}}</span>
            <span class="cenLine">|</span>
            <span @click="pageClick('/contact')">{{$t('关于我们.联系我们')}}</span>
          </div>
        </div>
        <div class="aboutText">
          <h1>{{$i18n.locale=="zh"?introduce.title:introduce.titleEn}}</h1>
          <p>{{$i18n.locale=="zh"?introduce.titleIntroduce:introduce.titleIntroduceEn}}</p>
        </div>
        <img class="topMin" :src="$baseUrl+introduce.titlePic" />
      </div>
      <div class="w_1200">
        <div class="recom">
          <p class="abTit">{{$t('关于我们.企业介绍')}}</p>
          <div>
            <div v-if="$i18n.locale=='zh'" v-html="content"></div>
            <div v-if="$i18n.locale=='en'" v-html="contentEn"></div>
          </div>
        </div>
        <div class="ysDiv">
          <p class="abTit">{{$t('关于我们.优势模块')}}</p>
          <div class="yxModule">
            <div class="yxSub" v-for="item in superiority">
              <img :src="$baseUrl+item.advantagePic" />
              <div class="subRig">
                <h3>{{$i18n.locale=='zh'?item.advantageName:item.advantageNameEn}}</h3>
                <p>{{$i18n.locale=='zh'?item.advantageIntroduce:item.advantageIntroduceEn}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="zgModule">
          <p class="abTit">{{$t('关于我们.贞光大事件')}}</p>
          <div class="zgSub">
            <div class="zgItem" v-for="item in affair">
              <img :src="$baseUrl+item.incidentPic" />
              <p>{{$i18n.locale=='zh'?item.incidentName:item.incidentNameEn}}</p>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {aboutUs,abousUsAdvantage,aboutUsIncident} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            introduce:{},//介绍
            content:'',//介绍富文本
            contentEn:'',//介绍富文本
            superiority:[],//优势
            affair:[]//事件
        }
    },
  mounted() {
    this.getDetail()
    this.getDetailTwo()
    this.getDetailThree()
  },
    methods:{
      getDetail(){//介绍
        aboutUs().then(res=>{
          this.introduce=res.rows[0]
          this.contentEn=res.rows[0].companyIntroduceEn
          this.content=res.rows[0].companyIntroduce
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      getDetailTwo(){//优势
        abousUsAdvantage().then(res=>{
          this.superiority=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      getDetailThree(){//大事件
        aboutUsIncident().then(res=>{
          this.affair=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      pageClick(path){
        console.log('111')
        this.$router.push(path)
      }
    }
}
</script>
<style lang="scss" scoped>
.adoutCon{
  position: relative;
  width: 100%;
  height: 500px;
  background-size: 100% 100%;
  padding: 42px 100px;
  box-sizing: border-box;
  margin-bottom: 100px;
  .adbouTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 223px;
      height: 79px;
    }
    .topRig{
      font-weight: 400;
      font-size: 19px;
      color: #000000;
      .cenLine{
        color: #8B8F96;
        margin: 0 40px;
      }
    }
  }
  .aboutText{
    margin-top: 56px;
    width:860px;
    h1{
      font-weight: bold;
      font-size: 44px;
      color: #000000;
      margin-bottom: 38px;
    }
    p{
      font-weight: 400;
      font-size: 22px;
      color: #000000;
      margin-top: 5px;
      line-height: 30px;
    }
  }
  .topMin{
    width: 534px;
    height: 324px;
    position: absolute;
    right: 100px;
    bottom: -80px;
  }
}
.recom{
  font-size: 17px;
  color: #000000;
  text-align: center;
  width:1100px;
  line-height: 45px;
  div{
    margin-top: 45px;

  }
}
.abTit{
  text-align: center;
  font-weight: bold;
  font-size: 33px;
  color: #000000;
}
.ysDiv{
  margin: 60px 0;
  .yxModule{
    margin: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .yxSub{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 403px;
      height: 134px;
      background: #012F9C;
      border-radius: 89px;
      box-sizing: border-box;
      margin-right:30px;
      img{
        width: 134px;
        height: 134px;
      }
      .subRig{
        box-sizing: border-box;
        padding:0 10px;
        font-size: 16px;
        font-weight: 400;
        line-height:26px;
        color: #FFFFFF;
        overflow-y: auto;
        height: 100%;
        h3{
          margin:0;
          margin-bottom:10px;
          font-weight: bold;
          font-size: 25px;
          color: #FFFFFF;
        }
      }
    }
  }
}
.zgModule{
    .zgSub{
      margin:60px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .zgItem{
        img{
          width: 349px;
          height: 189px;
          border: 1px solid #707070;
        }
        p{
          font-size: 15px;
          color: #000000;
          margin-top: 12px;
          line-height: 18px;
          width: 350px;
        }
      }
    }
}
</style>
