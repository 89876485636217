<template>
  <div>
    <!-- 进度条容器 -->
    <div class="progress-container">
      <div class="progress-line">
        <!-- 进度条填充部分 -->
        <div
            :style="{ width: progressWidth + '%', backgroundColor: '#ff4500' }"
            class="progress-fill"
        ></div>
      </div>
      <div
          v-for="(level, index) in levels"
          :key="index"
          :class="{ active: index <= currentStep, highlighted: index === currentStep }"
          class="progress-point"
      >
        <span class="level-label">{{ level.name }}</span>
      </div>
      <div v-if="currentStep === levels.length - 1" class="progress-label">续费保持!</div>
    </div>

    <!-- 会员权益信息 -->
    <div class="membership-info">
      <img :src="membershipIcon" alt="会员图标">
      {{ currentLevel.name }}会员权益：积分直接 <span class="highlight">{{
        currentLevel.multiplier
      }}</span> 倍订单金额累计
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    currentPoints: {
      type: Number,
      required: true, // 当前积分
    },
    membershipIcon: {
      type: String,
      default: "https://example.com/icon.png", // 会员图标URL
    },
  },
  data() {
    return {
      levels: [
        {name: "普通会员", points: 0, multiplier: 1.00},
        {name: "青铜会员", points: 1, multiplier: 1.05},
        {name: "白银会员", points: 1000, multiplier: 1.10},
        {name: "黄金会员", points: 5000, multiplier: 1.15},
        {name: "铂金会员", points: 10000, multiplier: 1.20},
        {name: "钻石会员", points: 100000, multiplier: 1.25},
      ],
      ff: true
    }
  },
  computed: {
    // 计算当前的会员等级
    currentLevel() {
      return this.levels.find((level) => this.currentPoints >= level.points);
    },

    // 计算当前所在进度条的等级索引
    currentStep() {
      let step = 0;
      for (let i = 0; i < this.levels.length; i++) {
        if (this.currentPoints >= this.levels[i].points) {
          step = i;
        } else {
          break;
        }
      }
      return step;
    },

    // 计算进度条的填充位置，基于当前积分和当前等级间距
    progressWidth() {
      const currentLevel = this.levels[this.currentStep + 1];
      const nextLevel = this.levels[this.currentStep + 1] || currentLevel; // 如果是最后一个等级
      const progressRange = nextLevel.points - currentLevel.points;
      this.currentLevel = this.levels[this.currentStep];
      this.ff = false
      // 计算当前积分在当前等级区间的填充比例
      if (progressRange > 0) {
        let count = this.currentStep * 20
        let cc = 0;
        if (this.currentPoints != this.levels[this.currentStep].points) {
          cc = (this.currentPoints - this.levels[this.currentStep].points)
              / this.levels[this.currentStep + 1].points;
        }
        return count + (cc * 30);
      }
      return 0;
    },
  },
};
</script>

<style scoped>
/* 进度条容器样式 */
.progress-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 20px 0;
}

/* 进度条背景线 */
.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background: #a4a4a4; /* 灰色背景 */
  z-index: 1;
  border-radius: 2px;
  transform: translateY(-50%);
}

/* 进度条填充 */
.progress-fill {
  height: 4px;
  border-radius: 2px;
  z-index: 2;
}

/* 进度点 */
.progress-point {
  background: #a4a4a4;
  height: 16px;
  color: white;
  z-index: 2;
  position: relative;
  padding: 2px 6px;
  width: 48px;
  border-radius: 12px;
  font-weight: bold;
}

/* 当前节点和经过的节点 */
.progress-point.active {
  background: #ff4500;
  border: 2px solid #ff4500;
  color: white;
  padding: 2px 6px;
  width: 48px;
  border-radius: 12px;
  font-weight: bold;
}

/* 高亮当前等级的节点 */
.progress-point.highlighted {
  background: #ff4500;
  border: 2px solid #ff4500;
  color: white;
  padding: 2px 6px;
  width: 48px;
  border-radius: 12px;
  font-weight: bold;
}

/* 进度条上的等级标签 */
.level-label {
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
}

/* 进度条上的标签样式 */
.progress-label {
  font-weight: bold;
  position: absolute;
  top: -25px;
  right: 0;
}

/* 会员权益信息样式 */
.membership-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}

.membership-info img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.membership-info .highlight {
  font-weight: bold;
}
</style>
