<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <div class="indexModule w_1200">
      <div class="orderOne">
        <div class="addressDiv divCss">
          <p class="orderTit">{{ $t('订单信息.订单信息') }}</p>
          <div style="display:flex">
            <div v-if="addressList.id" class="addDivOne">
              <p class="addName"><span>{{ addressList.userName }}</span><span
                  v-if="addressList.type">{{ $t('订单信息.默认地址') }}</span></p>
              <p class="addInfo">{{
                  addressList.provinc + addressList.city + addressList.county + addressList.details
                }}</p>
              <p>{{ addressList.userMobile }}</p>
              <p>
                <span class="hover hand" @click="addressEdit">{{ $t('订单信息.修改') }}</span>
                <span class="hover hand" @click="addressDel">{{ $t('订单信息.删除') }}</span>
              </p>
            </div>
            <div class="addDivToo hand" @click="addPage">
              <img alt="" src="@/assets/member/add.png"/>
              <p>{{ $t('订单信息.添加收货地址') }}</p>
            </div>
          </div>
        </div>
        <div class="distribution divCss">
          <p class="orderTit">{{ $t('订单信息.配送方式') }}</p>
          <div class="seleDiv">
            <p>{{ $t('订单信息.您可以选择') }}:</p>
            <el-select v-model="modeVal" :placeholder="$t('订单信息.请选择')">
              <el-option v-for="item in options" :key="item.id" :label="item.logisticsName"
                         :value="item" @change="modeChange">
              </el-option>
            </el-select>
          </div>
          <p class="tips">
            <img alt="" src="@/assets/login/icon10.png"/>
            <span>{{ $t('订单信息.当前订单已') }}</span>
          </p>
        </div>
      </div>
      <div class="orderTwo">
        <div class="twoLeft divCss">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.下单联系人') }}</p>
            <span class="editBtn hand" @click="saveLinkman">{{ $t('订单信息.保存') }}</span>
          </div>
          <div class="inpModule">
            <div><span>{{ $t('订单信息.联系人') }}</span><input v-model="linkmanObj.name"/></div>
            <div><span>{{ $t('订单信息.QQ') }}</span><input v-model="linkmanObj.qq"/></div>
            <div><span>{{ $t('订单信息.手机') }}</span><input v-model="linkmanObj.mobile"/></div>
            <div><span>{{ $t('订单信息.微信') }}</span><input v-model="linkmanObj.wx"/></div>
          </div>
          <p class="tips">
            <img alt="" src="@/assets/login/icon10.png"/>
            <span>{{ $t('订单信息.若订单执行中有需确认的项目') }}</span>
          </p>

        </div>
        <div class="twoRig divCss">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.发票信息') }}</p>
            <span class="editBtn hand" @click="fapiaoClick">{{ $t('订单信息.修改') }}</span>
          </div>
          <div class="detailSele">
            <div class="seleLeft">
              <p :class="type==1?'active':'hand hover'" @click="typeClick(1)">{{
                  $t('订单信息.数电增值税(专用)发票(可抵扣)')
                }}</p>
              <span>|</span>
              <p :class="type==2?'active':'hand hover'" @click="typeClick(2)">{{
                  $t('订单信息.数电增值税(普通)发票(不可抵扣) ')
                }}</p>
              <span>|</span>
              <p :class="type==0?'active':'hand hover'" @click="typeClick(0)">{{
                  $t('订单信息.不开发票(没有发票)')
                }}</p>
            </div>
          </div>
          <div v-for="(item,index) in invoiceList" v-if="type==item.type&&index==0" class="twoInfo">
            <div><p>{{ $t('订单信息.开票公司名称') }}</p><input v-model="item.companyName"
                                                                disabled/></div>
            <div><p>{{ $t('订单信息.统一社会信用代码') }}</p><input v-model="item.creditCode"
                                                                    disabled/></div>
            <div><p>{{ $t('订单信息.注册地址') }}</p><input v-model="item.registerAddress"
                                                            disabled/></div>
            <div><p>{{ $t('订单信息.公用电话') }}</p><input v-model="item.companyMobile" disabled/>
            </div>
            <div><p>{{ $t('订单信息.邮箱') }}</p><input v-model="item.mailbox" disabled/></div>
            <div><p>{{ $t('订单信息.开户银行') }}</p><input v-model="item.openBank" disabled/></div>
            <div><p>{{ $t('订单信息.银行账号') }}</p><input v-model="item.openAccount" disabled/>
            </div>
          </div>
          <p class="tips">
            <img alt="" src="@/assets/login/icon10.png"/>
            <span>{{ $t('订单信息.敝司已纳入全面数字化的电子发票试点') }}"invoice@oneyac.com"{{
                $t('订单信息.发送至您预留的邮箱')
              }}</span>
          </p>
        </div>
      </div>
      <div class="orderThree divCss">
        <div class="twoT">
          <p class="orderTit">{{ $t('订单信息.确认订单信息') }}</p>
          <span class="editBtn hand">{{ $t('订单信息.保存') }}</span>
        </div>
        <div v-for="item in carGoods" class="orderGoods">
          <img :src="$baseUrl+item.productPhoto"/>
          <div class="threeDiv" style="min-width:200px">
            <p class="threeTit">{{ $t('订单信息.商品编号') }}</p>
            <p class="threeCon" style="text-align:left;">{{
                $t('订单信息.自定义料号')
              }}{{ item.materialNumber }}</p>
            <p class="threeCon" style="text-align:left;">{{ $t('订单信息.品牌') }}{{
                $i18n.locale == 'zh' ? item.productBrandName : item.productBrandNameEn
              }}</p>
          </div>
          <div class="threeDiv">
            <p class="threeTit">{{ $t('订单信息.商品描述') }}</p>
            <p class="threeCon" style="text-align:left;">{{
                $i18n.locale == 'zh' ? item.productName : item.productNameEn
              }}</p>
          </div>
          <div class="threeDiv">
            <p class="threeTit">{{ $t('订单信息.单价') }}</p>
            <p class="threeCon">{{ $t('公用.¥') }}{{ item.unitPrice }}</p>
          </div>
          <div class="threeDiv">
            <p class="threeTit">{{ $t('订单信息.订单数量') }}</p>
            <p class="threeCon">{{ item.buyNumber }}</p>
          </div>
          <div class="threeDiv">
            <p class="threeTit">{{ $t('订单信息.合计') }}</p>
            <p class="threeCon">{{ $t('公用.¥') }}{{ item.subTotal }}</p>
          </div>
        </div>
        <div class="threeRemark">
          <div class="remaDiv">
            <p>{{ $t('订单信息.订单备注') }}</p>
            <input/>
          </div>
          <div class="remaDiv">
            <p>{{ $t('订单信息.合同号') }}</p>
            <input/>
          </div>
        </div>
      </div>
      <div class="orderFour">
        <div class="fourLeft divCss">
          <p class="orderTit">{{ $t('订单信息.支付方式') }}</p>
          <div v-for="item in payType" class="payDiv hand" @click="payClick(item)">
            <img v-if="!item.flag" src="@/assets/member/paywxz.png" style="width:30px;height:30px"/>
            <img v-else src="@/assets/member/payxz.png"/>
            <p>{{ item.name }}</p>
          </div>
        </div>
        <div class="fourRight divCss">
          <p class="orderTit">{{ $t('订单信息.优惠券') }}</p>
          <div class="detailSele">
            <div class="seleLeft">
              <p v-for="(item,index) in titArr" @click="tabClick(item)">
                <span :class="item.flag?'active':'hand hover'" class="titCss">{{
                    item.tit
                  }}</span><span v-if="index<titArr.length">|</span>
              </p>
            </div>
          </div>
          <div v-if="couponArr.length>0" class="couponSele">
            <!-- <p>优惠券：</p>
            <el-select v-model="couponVal" placeholder="请选择" @change="couponChang">
              <el-option v-for="item in couponArr" :key="item.id" :label="$i18n.locale=='zh'?item.couponName:item.couponNameEn" :value="item"></el-option>
            </el-select> -->
            <div class="couMaxOut">
              <div v-for="item in couponArr" :class="item.flag?'couAct':'conBg'"
                   class="maxOutSub hand" @click="couponChang(item)">
                <p v-if="item.couponType==1" class="max">{{
                    $t('公用.¥')
                  }}<span>{{ item.subtractPrice }}</span>{{
                    $t('领券中心.满')
                  }}{{ item.fullPrice }}{{ $t('领券中心.可用') }}</p>
                <p v-if="item.couponType==2" class="max"><span>{{ item.discount }}</span>{{
                    $t('领券中心.元无门槛')
                  }}</p>
                <p v-if="item.couponType==3" class="max"><span>{{ item.discount }}</span>{{
                    $t('领券中心.折扣券')
                  }}</p>
                <p class="out">{{ $i18n.locale == 'zh' ? item.couponName : item.couponNameEn }}</p>
                <p class="desc">{{ item.effectiveDate }} ～ {{ item.expiryDate }}</p>
              </div>
            </div>
          </div>
          <div v-if="couponArr.length==0" class="couponDiv">
            <img src="@/assets/member/tx.png"/>
            <p>{{ $t('订单信息.暂无优惠券！') }}</p>
          </div>
          <div class="priceDe">
            <p>{{ $t('订单信息.全部优惠') }}<span>{{ $t('公用.¥') }}0.00</span></p>
            <p>{{ $t('订单信息.商品总额') }}<span>{{ $t('公用.¥') }}{{ calculateTotal }}</span></p>
            <p>{{ $t('订单信息.运费') }}<span>{{ $t('公用.¥') }}0</span></p>
            <p>{{ $t('订单信息.应付总额') }}<span>{{ $t('公用.¥') }}{{ calculateTotal }}</span></p>
          </div>
          <p class="tips">
            <img alt="" src="@/assets/login/icon10.png"/>
            <span>使用商品返现券0张，返现0元!使用商品优惠券0张，优惠0.00元!使用运 费券0张，抵扣运费0元</span>
          </p>
        </div>
      </div>
      <div class="fourFive divCss">
        <div class="fiveLeft">
          <div :class="dealFlag?'acv':''" class="radioCss hand" @click="dealFlag=!dealFlag"><i
              v-if="dealFlag" class="el-icon-check"></i></div>
          <p>{{ $t('订单信息.我已阅读并同意') }}<span class="hand hover" @click="dealDialog=true">{{
              $t('订单信息.订单提交协议')
            }}</span>{{ $t('订单信息.和') }}<span class="hand hover" @click="dealDialog=true">{{
              $t('订单信息.销售条款')
            }}</span></p>
        </div>
        <div class="fiveRight">
          <p>{{ $t('订单信息.应付总额') }} <span>{{ $t('公用.¥') }}{{ calculateTotal }}</span></p>
          <div class="hand" @click="submitPop">{{ $t('订单信息.提交订单') }}</div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
    <el-dialog :title="$t('订单信息.贞光商城')" :visible.sync="dealDialog" width="830px">
      <div class="dialogCss">
        <p class="deatips">{{ $t('订单信息.尊敬的用户') }}</p>
        <div class="deaCon">
          <p class="tit">{{ $t('订单信息.订单信息') }}</p>
          <div>
            <p v-html="privateVal"></p>
          </div>
        </div>
        <div class="diaBtn">
          <p class="no hand" @click="handleClose">{{ $t('订单信息.不同意') }}</p>
          <p class="yes hand" @click="handSure">{{ $t('订单信息.已阅读并同意继续') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="billDialog" width="620px">
      <div class="dialogTwoCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png"/>
          <p>{{ $t('订单信息.请确认发票信息是否正确') }}</p>
        </div>
        <p class="tips">{{ $t('订单信息.当前发票信息') }}</p>
        <div v-if="invoiceList.length>0" class="twoInfo">
          <div><p>{{ $t('订单信息.开票公司名称') }}</p>{{ invoiceList[0].companyName }}</div>
          <div><p>{{ $t('订单信息.统一社会信用代码') }}</p>{{ invoiceList[0].creditCode }}</div>
          <div><p>{{ $t('订单信息.注册地址') }}</p>{{ invoiceList[0].registerAddress }}</div>
          <div><p>{{ $t('订单信息.公用电话') }}</p>{{ invoiceList[0].companyMobile }}</div>
          <div><p>{{ $t('订单信息.邮箱') }}</p>{{ invoiceList[0].mailbox }}</div>
          <div><p>{{ $t('订单信息.开户银行') }}</p>{{ invoiceList[0].openBank }}</div>
          <div><p>{{ $t('订单信息.银行账号') }}</p>{{ invoiceList[0].openAccount }}</div>
        </div>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="billClick">{{ $t('订单信息.我已确认无误') }}</p>
          <p class="no hand" @click="billDialog=false">{{ $t('订单信息.返回修改') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="$t('订单信息.设置开票单位')" :visible.sync="billingUnit" width="620px">
      <div class="dialogThreeCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png"/>
          <p>{{ $t('订单信息.开票单位指发票上的数量单位') }}</p>
        </div>
        <div class="unit">
          <p>{{ $t('订单信息.选择开票单位') }}</p>
          <div>
            <el-radio v-model="radio" label="1">只</el-radio>
            <el-radio v-model="radio" label="2">千只</el-radio>
          </div>
        </div>
        <p class="sure hand" @click="unitClick">{{ $t('订单信息.确定') }}</p>
        <div class="botTips">
          <p>{{ $t('订单信息.注意1') }}</p>
          <p>{{ $t('订单信息.注意2') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="$t('订单信息.确认提交')" :visible.sync="subDialog" width="620px">
      <div class="dialogTwoCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png"/>
          <p style="color:#333;line-height:22px">{{
              $t('订单信息.您购买的商品中有特惠库存的商品')
            }}</p>
        </div>
        <div class="diaBtn">
          <p class="yes hand" @click="submitClick">{{ $t('订单信息.确定') }}</p>
          <p class="no hand" @click="subDialog=false">{{ $t('订单信息.取消') }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {
  addLinkman,
  carList,
  delAddress,
  getAddList,
  getOne,
  invoiceGetList,
  myCouponList,
  placeOrder,
  regionDelivery,
  saveSysUserUnit,
  typeDdTj,
  userLinkman
} from '@/util/http.js'
import Cookies from 'js-cookie'

export default {
  components: {carTopNav, bottomNav},
  data() {
    return {
      loading: false,
      dealFlag: false,//是否同意协议
      dealDialog: false,//协议内容弹窗
      billDialog: false,//发票内容弹窗
      billingUnit: false,//开票单位
      subDialog: false,//提交订单弹窗
      userInfo: JSON.parse(Cookies.get('userInfo')),
      carId: '',//购物车列表id
      carGoods: [],//购物车商品
      addressList: {},//订单地址
      radio: '',
      options: [],//配送方式
      modeVal: '',//选中的配送方式
      type: 1,//发票类型
      invoiceList: [],//发票信息
      linkmanObj: {//下单联系人
        name: '',
        mobile: '',
        qq: '',
        wx: ''
      },
      privateVal: '',//提交协议 销售条款
      payObj: {},//支付方式
      couponVal: '',
      couponObj: {},//当前选中的对象
      couponArr: [],//优惠券数组
      unitFlag: null,//发票单位是否选择标识
      payType: [
        {id: 1, name: this.$t('订单信息.微信支付'), flag: false},
        {id: 2, name: this.$t('订单信息.支付宝支付'), flag: false},
        {id: 3, name: this.$t('订单信息.余额支付'), flag: false},
        // {id:4,name:this.$t('订单信息.对公转账'),flag:false},
      ],
      tabId: 1,
      titArr: [
        {id: 1, tit: this.$t('领券中心.商品券'), url: '', flag: true},
        {id: 2, tit: this.$t('领券中心.优惠券'), url: '', flag: false},
        {id: 3, tit: this.$t('领券中心.运费券'), url: '', flag: false},
      ]
    }
  },
  computed: {
    calculateTotal() {
      let priceAll = 0;
      this.carGoods.map((item, index) => {
        priceAll = ((priceAll * 100 + (item.subTotal) * 100) / 100).toFixed(2)
        // priceAll=((priceAll*100+(item.subTotal)*100)/100)
        // priceAll=((priceAll*100+(item.subTotal.replace(/\./g, "")*item.buyNumber)*100)/100)
      })
      priceAll = priceAll.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      return priceAll;
    },
  },
  created() {
    this.carId = Cookies.get('carId')
    if (this.carId) {
      this.getCarLIst()
    }
    this.getAddress()
    this.getMode()
    this.getFaPiao()
    this.getLinkman()
    this.getXieYi()
    this.getMyCoupon()
    this.getUnit()
  },
  methods: {
    getUnit() {
      getOne().then(res => {
        this.unitFlag = res.data
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    couponChang(e) {
      this.couponArr.map(i => {
        i.flag = false;
        if (i.id == e.id) {
          i.flag = true
          this.couponObj = i
        }
      })
      this.couponArr = [...this.couponArr]
      // if(this.$i18n.locale=='zh'){
      //   this.couponVal=e.couponName
      // }else{
      //   this.couponVal=e.couponNameEn
      // }
    },
    getMyCoupon() {//优惠券
      myCouponList({
        userId: this.userInfo.userId,
        couponClassify: this.tabId
      }).then(res => {
        this.couponArr = res.rows
        this.couponArr.map(i => {
          i.flag = false
        })
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    tabClick(item) {//切换优惠券
      this.titArr.map((i) => {
        i.flag = false;
        if (item.id == i.id) {
          i.flag = true;
          this.tabId = i.id
        }
      })
      this.getMyCoupon()
    },
    getCarLIst() {//订单列表
      carList({id: this.carId}).then(res => {
        this.carGoods = res.rows.map(row => ({
          ...row,
          flag: false,
          unitPrice: this.getUnitPrice(row.children, row.buyNumber),
          subTotal: this.calculateSubTotal(row.children, row.buyNumber)
        }));
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getUnitPrice(children, buyNumber) {//根据用户输入的数量找到合适的单价
      let prevQuantity = 0;
      let prevUnitPrice = null;

      for (const child of children) {
        if (buyNumber <= child.quantity) {
          return prevUnitPrice || child.unitPrice;
        }
        prevQuantity = child.quantity;
        prevUnitPrice = child.unitPrice;
      }

      return children[children.length - 1].unitPrice;
    },
    calculateSubTotal(children, buyNumber) {//根据单价和用户输入的数量计算小计金额
      const unitPrice = this.getUnitPrice(children, buyNumber);
      return unitPrice * buyNumber;
    },
    getMode() {//配送方式
      regionDelivery().then(res => {
        this.options = res.rows
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getAddress() {//地址列表
      getAddList().then(res => {
        res.rows.map(item => {
          if (item.type) {
            this.addressList = item
            // console.log(item)
            return
          }
          if (!item.type) {
            this.addressList = res.rows[0]
            // console.log(item)
            return
          }
          console.log(this.addressList)
        })
        // this.addressList=res.rows
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    addressEdit() {//编辑地址
      this.$router.push({path: '/address', query: {navVal: '1-2'}})
    },
    addressDel() {//删除地址
      delAddress(this.addressList.id).then(res => {
        this.$message(res.msg)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    typeClick(num) {//切换发票类型
      if (num == this.type) {
        return
      }
      this.type = num
      this.getFaPiao()
    },
    getFaPiao() {//获取发票信息
      if (!this.type) {
        return
      }
      invoiceGetList().then(res => {
        if (this.type == 1) {
          this.invoiceList = res.rows.filter(item => item.type == 1)
        }
        if (this.type == 2) {
          this.invoiceList = res.rows.filter(item => item.type == 2)
        }
        // this.invoiceList=res.rows
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    fapiaoClick() {//修改发票
      this.$router.push({path: '/makeOut', query: {navVal: '1-3'}})
    },
    addPage() {//跳转发票页面
      this.$router.push({path: '/address', query: {navVal: '1-2'}})
    },
    getLinkman() {//获取下单联系人
      userLinkman().then(res => {
        if (res.data.length > 0) {
          this.linkmanObj = res.data[0]
        }
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    saveLinkman() {//新增联系人
      addLinkman({
        userId: this.userInfo.userId,
        name: this.linkmanObj.name,
        mobile: this.linkmanObj.mobile,
        qq: this.linkmanObj.qq,
        wx: this.linkmanObj.wx,
        defaultStatus: 0
      }).then(res => {
        this.getLinkman()
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getXieYi() {
      typeDdTj().then(res => {
        this.privateVal = res.data.content
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    submitPop() {
      if (!this.addressList.id) {
        this.$message('订单信息.请添加收获地址')
        return
      }
      if (!this.modeVal) {
        this.$message('订单信息.请选择配送方式')
        return
      }
      if (!this.linkmanObj.id) {
        this.$message('订单信息.请填写下单联系人')
        return
      }
      if (!this.payObj.id) {
        this.$message('订单信息.请选择支付方式')
        return
      }
      if (!this.dealFlag) {
        this.$message('订单信息.请阅读并同意订单提交协议')
        return
      }
      this.billDialog = true
    },
    payClick(item) {
      this.payType.forEach(i => {
        i.flag = false;
        if (i.id == item.id) {
          i.flag = true;
          this.payObj = i;
        }
      })
    },
    handleClose() {
      console.log(1)
      this.dealDialog = false
    },
    handSure() {//关闭协议弹窗
      this.dealDialog = false
      this.dealFlag = true
    },
    billClick() {//关闭发票弹窗 打开开票单位弹窗
      this.billDialog = false
      if (this.unitFlag == null) {
        this.billingUnit = true;
      } else {
        this.subDialog = true
      }
    },
    unitClick() {//关闭开票单位弹窗 打开提交订单弹窗
      saveSysUserUnit({unitInfo: this.radio}).then(res => {
        this.billingUnit = false
        this.subDialog = true
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg);
        }
      })
    },
    modeChange(e) {//选择配送方式
      // this.modeVal=e
      console.log(this.modeVal)
    },
    submitClick() {//提交订单 跳转支付信息页面

      if (this.payObj.id === 1) {
        this.$message('当前微信支付暂未开通,请选择其他的支付方式')
        return
      }

      let productIdStr = ''
      let buyNumberStr = ''
      this.carGoods.map(item => {
        productIdStr ? productIdStr += ',' + item.productId : productIdStr = item.productId
      })
      this.carGoods.map(item => {
        buyNumberStr ? buyNumberStr += ',' + item.buyNumber : buyNumberStr = item.buyNumber
      })
      placeOrder({
        userId: this.userInfo.userId,
        type: 1,
        paymentType: this.payObj.id,
        addressId: this.addressList.id,
        linkmanId: this.linkmanObj.id,
        distributionId: this.modeVal.id,
        productId: productIdStr,
        buyNumber: buyNumberStr,
        freightCollectPayment: '',//运费支付方式
        productDiscountCouponId: this.couponObj.id ? this.couponObj.id + ',0' + ',0' : '',//优惠券ID
        freightDiscountCouponId: '',//运费优惠券id
      }).then(res => {
        this.$router.push({path: '/myOrder?navVal=2-1', query: {subIndex: 1}})
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .indexModule {
    margin: 60px auto;

    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        width: 54px;
        height: 26px;
        line-height: 26px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .tips {
      //width: 297px;
      //height: 44px;
      padding: 15px 20px;
      background: #FFFDED;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF6600;
      margin-top: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

    }

    .detailSele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-bottom: 20px;
      border-bottom: #D9D9D9 solid 1px;

      .seleLeft {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;

        .titCss {
          color: #333333;
          font-size: 14px;
        }

        p {
          padding-bottom: 11px;
        }

        span {
          color: #D9D9D9;
          margin: 0 14px;
          padding-bottom: 11px;
        }

        .active {
          font-weight: bold;
          font-size: 16px;
          color: #012F9C;
          line-height: 19px;
          border-bottom: 2px #FF6600 solid;
        }
      }
    }

    .orderOne {
      display: flex;
      overflow: hidden;
      justify-content: space-between;

      .addressDiv {
        width: 749px;
        box-sizing: border-box;

        .addDivOne {
          background-image: url("@/assets/member/bxz.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 284px;
          height: 140px;
          padding: 12px;
          box-sizing: border-box;
          font-size: 14px;
          color: #333333;
          line-height: 15px;

          p {
            margin: 5px 0;

            span {
              margin-right: 10px;
            }
          }

          .addName {
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px #999999 dotted;
            justify-content: space-between;

            span:nth-child(2) {
              font-size: 13px;
              color: #FF6600;
            }
          }

        }

        .addDivToo {
          width: 284px;
          height: 140px;
          padding: 12px;
          box-sizing: border-box;
          background-image: url("@/assets/member/bwxz.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          font-size: 14px;
          color: #999999;
          line-height: 15px;
          text-align: center;
          margin: 0 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            width: 46px;
            height: 46px;
            margin: 0px auto 10px auto;
          }
        }
      }

      .distribution {
        width: 427px;

        .seleDiv {
          margin-bottom: 60px;
          font-size: 13px;
          color: #333333;
          display: flex;
          align-items: center;

          p {
            margin-right: 10px;
          }
        }
      }
    }

    .orderTwo {
      display: flex;
      justify-content: space-between;

      .twoLeft {
        width: 377px;
        box-sizing: border-box;

        .inpModule {
          margin: 20px 0;

          div {
            display: flex;
            align-items: center;
            justify-content: end;
            margin: 10px 0;
            font-size: 13px;
            color: #333333;
            line-height: 15px;

            input {
              width: 242px;
              height: 26px;
              border-radius: 2px 2px 2px 2px;
              border: 1px solid #999999;
            }

            span {
              display: inline-block;
              width: 60px;
              text-align: right;
            }
          }
        }
      }

      .twoRig {
        width: 799px;
        box-sizing: border-box;

        .twoInfo {
          margin: 20px 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          font-size: 13px;
          color: #333333;

          div {
            display: flex;
            align-content: center;
          }

          input {
            border: none;
            border-bottom: solid 1px #999999;
            background: #fff;
          }

          p {
            width: 120px;
            //flex: 1;
            //text-align: right;
          }
        }
      }
    }

    .orderThree {
      .orderGoods {
        border-bottom: 2px solid #D9D9D9;
        padding-bottom: 18px;
        margin-bottom: 18px;
        display: flex;
        //height: 94px;
        //align-content: center;
        justify-content: space-between;

        img {
          width: 94px;
          height: 94px;
        }

        .threeDiv {
          //border: solid 1px;
          //margin:0 20px;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 16px;
          min-width: 150px;
          color: #333333;
          line-height: 22px;
          text-align: center;

          .threeTit {
            margin-bottom: 20px;
          }

          .threeCon {
            font-size: 13px;
            color: #333333;
            line-height: 22px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

          }
        }
      }

      .threeRemark {
        display: flex;
        align-content: center;
        justify-content: space-between;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        //height: 26px;
        .remaDiv {
          display: flex;
          align-content: center;
          justify-content: space-between;
        }

        input {
          flex: 1;
          line-height: 22px;
          width: 429px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
        }
      }
    }

    .orderFour {
      display: flex;
      justify-content: space-between;

      .fourLeft {
        width: 427px;

        .payDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: #333333;
          line-height: 28px;
          width: 347px;
          height: 68px;
          border-radius: 4px 4px 4px 4px;
          border: 2px solid #999999;
          margin-top: 28px;

          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }

      .fourRight {
        width: 749px;

        .couponSele {
          overflow-x: auto;
          // width: 300px;
          display: flex;
          align-items: center;
          // justify-content: center;
          font-size: 13px;
          color: #333333;
          line-height: 22px;
          margin: 40px 0;

          p {
            font-size: 16px;
          }
        }

        .couMaxOut {
          display: flex;
          // display: grid;
          // grid-template-columns: repeat(4,1fr);
          // grid-gap: 25px;
          //margin-top: 40px;
          .conBg {
            background: #45a1ff;
          }

          .couAct {
            background-image: url("@/assets/sub/cou3.png") !important;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .maxOutSub {
            width: 200px;
            height: 135px;
            margin-right: 20px;
            border-radius: 4px;
            position: relative;
            padding: 0 15px;
            box-sizing: border-box;

            p {
              color: #fff;
            }

            .max {
              margin-top: 25px;
              font-size: 16px;

              span {
                font-size: 40px;
              }
            }

            .out {
              font-weight: 500;
              font-size: 16px;
              margin: 15px 0;
            }

            .desc {
              font-size: 11px;
              margin-bottom: 6px;
            }

            .btn {
              font-size: 16px;
              position: absolute;
              bottom: 17px;
              left: 40%;
            }
          }
        }

        .couponDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #333333;
          line-height: 22px;
          margin: 40px 0;

          img {
            width: 40px;
            height: 40px;
            margin-right: 6px;
          }
        }

        .priceDe {
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          line-height: 15px;
          margin-bottom: 40px;

          p {
            margin-bottom: 10px;
          }

          span {
            vertical-align: middle;
            color: #FF6600;
          }
        }
      }
    }

    .fourFive {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fiveLeft {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 19px;

        .radioCss {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #D9D9D9;
          margin-right: 10px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #fff;
          font-size: 22px;
        }

        .acv {
          background: #012F9C;
        }
      }

      .fiveRight {
        display: flex;
        align-items: center;

        p {
          font-weight: 400;
          font-size: 12px;
          color: #333333;

          span {
            vertical-align: text-top;
            font-weight: bold;
            font-size: 14px;
            color: #FF6600;
            line-height: 15px;
            margin: 0 13px;
            display: inline-block;
          }
        }

        div {
          font-weight: bold;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 19px;
          width: 133px;
          height: 50px;
          background: #012F9C;
          border-radius: 4px 4px 4px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .dialogCss {
    .deatips {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 19px;
      width: 756px;
      margin: 15px 0;
    }

    .deaCon {
      padding: 18px 35px;
      box-sizing: border-box;
      width: 756px;
      height: 334px;
      background: #F2F2F2;
      overflow-y: auto;

      .tit {
        font-weight: 400;
        font-size: 20px;
        color: #333333;
        line-height: 23px;
        text-align: center;
        margin-bottom: 20px;
      }

      div {
        font-size: 13px;
        color: #000000;
        line-height: 17px;
      }
    }
  }

  .dialogTwoCss {
    padding: 0 70px;
    box-sizing: border-box;

    .couponDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #FF6600;
      line-height: 22px;

      img {
        width: 40px;
        height: 40px;
        margin-right: 6px;
      }
    }

    .tips {
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin: 40px 0;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      line-height: 15px;

      div {
        display: flex;
        align-content: center;
        margin: 10px 0;
        font-size: 13px;
        color: #333333;
      }

      p {
        color: #999999;
        margin-right: 15px;
      }
    }
  }

  .dialogThreeCss {
    padding: 0 70px;
    box-sizing: border-box;

    .couponDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #FF6600;
      line-height: 22px;

      img {
        width: 40px;
        height: 40px;
        margin-right: 6px;
      }
    }

    .unit {
      margin: 40px 0;
      font-size: 13px;
      color: #333333;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sure {
      width: 172px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: auto;
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
    }

    .botTips {
      margin-top: 50px;
      font-size: 16px;
      color: #FF6600;
      line-height: 19px;

      p {
        text-indent: 3em;
        margin-bottom: 15px;
      }

      p:first-child {
        text-indent: 0;
      }
    }
  }

  :deep .el-dialog__title {
    font-size: 24px;
    color: #333;
  }
}
</style>
