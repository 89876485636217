import request from '@/util/request'

// 官网登录
export function login(data) {
    return request({
        url: `/login/10`,
        method: 'POST',
        data
    })
}
// 官网注册
export function regist(data) {
    return request({
        url: `/register/10`,
        method: 'POST',
        data
    })
}
// 修改密码
export function editPassword(data) {
    return request({
        url: `/zgGetBack`,
        method: 'POST',
        data
    })
}
// 发送验证码
export function send(data) {
    return request({
        url: `/sendSmsCode`,
        method: 'GET',
        params: data
    })
}
// 获取用户信息
export function getUserInfo(data) {
    return request({
        url: `/selectUserDetailsByUserId`,
        method: 'GET',
        params: data
    })
}
// 修改用户信息
export function editUser(data) {
    return request({
        url: `/updateUserDetails`,
        method: 'POST',
        data
    })
}
// 查询商品分类列表
export function categoryList(data) {
    return request({
        url: `/zgkj/productCategory/list`,
        method: 'GET',
        params: data
    })
}
// 轮播图列表
export function swiperList(data) {
    return request({
        url: `/zgkj/banner/list`,
        method: 'GET',
        params: data
    })
}
// 产品分类
export function productCateList(data) {
    return request({
        url: `/zgkj/productProgramClassify/list`,
        method: 'GET',
        params: data
    })
}
// 新闻列表
export function newsList(data) {
    return request({
        url: `/zgkj/tidings/list`,
        method: 'GET',
        params: data
    })
}
// 合作供应商列表
export function supplyList(data) {
    return request({
        url: `/zgkj/supplier/list`,
        method: 'GET',
        params: data
    })
}
// 合作伙伴列表
export function partnerList(data) {
    return request({
        url: `/zgkj/productBrand/list`,
        method: 'GET',
        params: data
    })
}
// 我的地址列表
export function addressList(data) {
    return request({
        url: `/zgkj/userAddress/getList`,
        method: 'GET',
        params: data
    })
}
// 新增地址
export function addAddress(data) {
    return request({
        url: `/zgkj/userAddress`,
        method: 'POST',
        data
    })
}
// 修改地址
export function editAddress(data) {
    return request({
        url: `/zgkj/userAddress`,
        method: 'PUT',
        data
    })
}
// 删除地址
export function delAddress(data) {
    return request({
        url: `/zgkj/userAddress/${data}`,
        method: 'DELETE'
    })
}
// 发票资料列表
export function invoiceList(data) {
    return request({
        url: `/zgkj/userInvoice/getList`,
        method: 'GET',
        params: data
    })
}
// 新增发票
export function addInvoice(data) {
    return request({
        url: `/zgkj/userInvoice`,
        method: 'POST',
        data
    })
}
// 修改发票
export function editInvoice(data) {
    return request({
        url: `/zgkj/userInvoice`,
        method: 'PUT',
        data
    })
}
// 删除发票
export function delInvoice(data) {
    return request({
        url: `/zgkj/userInvoice/${data}`,
        method: 'DELETE'
    })
}
// 优惠券列表
export function ticketList(data) {
    return request({
        url: `/zgkj/discountCoupon/frontEndList`,
        method: 'GET',
        params: data
    })
}

// 领取优惠券
export function receiveTicket(data) {
    return request({
        url: `/zgkj/discountCoupon/receive`,
        method: 'GET',
        params: data
    })
}

// 接收我的消息列表
export function getMessageList(data) {
    return request({
        url: `/zgkj/message/getList`,
        method: 'GET',
        params: data
    })
}

// 我的消息列表
export function messageList(data) {
    return request({
        url: `/zgkj/message/list`,
        method: 'GET',
        params: data
    })
}
// 导出消息列表

export function exportMessageList(data) {
    return request({
        url: `/zgkj/userInvoice`,
        method: 'POST',
        data
    })
}
// 获取消息详情
export function messageDetail(data) {
    return request({
        url: `/zgkj/message/detailed`,
        method: 'GET',
        params: data
    })
}
// 新增消息


export function addMessage(data) {
    return request({
        url: `/zgkj/message/insertSave`,
        method: 'POST',
        data
    })
}
// 修改消息

export function editMessage(data) {
    return request({
        url: `/zgkj/message/update`,
        method: 'PUT',
        data
    })
}
// 删除消息

export function delMessage(ids) {
    return request({
        url: `/zgkj/message/delete/${ids}`,
        method: 'DELETE',
    })
}

//系统配置
export function SystemConfigurationInfoDetailed() {
    return request({
        url: `/zgkj/systemConfigurationInfo/detailed?sort=4`,
        method: 'get',
    })
}
