<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="purseBot divCss">
            <p class="orderTit">{{ $t('发票查询.发票查询') }}</p>
            <div class="detailSele">
              <div class="seleLeft">
                <p :class="state==1?'active':'hand hover'" @click="tabClick(1)">{{
                    $t('发票查询.全部')
                  }}</p>
                <span>|</span>
                <p :class="state==2?'active':'hand hover'" @click="tabClick(2)">{{
                    $t('发票查询.待开票')
                  }}</p>
                <span>|</span>
                <p :class="state==3?'active':'hand hover'" @click="tabClick(3)">{{
                    $t('发票查询.已开票')
                  }}</p>
                <span>|</span>
                <p :class="state==4?'active':'hand hover'" @click="tabClick(4)">{{
                    $t('发票查询.不开票')
                  }}</p>
              </div>
            </div>
            <div class="selectDiv">
              <div class="seleLeft">
                <div class="sub">
                  <span>{{ $t('发票查询.订单号') }}{{ $t('公用.：') }}</span>
                  <input/>
                </div>
                <div class="sub">
                  <span style="width:50px">{{ $t('发票查询.时间') }}{{ $t('公用.：') }}</span>
                  <el-date-picker v-model="value1" :end-placeholder="$t('公用.结束日期')"
                                  :range-separator="$t('公用.至')"
                                  :start-placeholder="$t('公用.开始日期')"
                                  type="datetimerange">
                  </el-date-picker>
                </div>
              </div>
              <div class="seleRig">
                <p class="hand">{{ $t('发票查询.查询') }}</p>
                <p class="hand exportbtn">{{ $t('发票查询.重置') }}</p>
              </div>
            </div>
            <el-table :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column :label="$t('发票查询.订单号')" prop="orderNumber"></el-table-column>
              <el-table-column :label="$t('发票查询.订单金额')" prop="totalPrice"></el-table-column>
              <el-table-column :label="$t('发票查询.订单状态')" prop="orderType">
                <template slot-scope="scope">
                  <p v-if="scope.row.orderType == 0" style="width:110px;">{{ $t('我的订单.待审核') }}</p>
                  <p v-if="scope.row.orderType == 1" style="width:110px;">{{ $t('我的订单.待支付') }}</p>
                  <p v-if="scope.row.orderType == 2" style="width:110px;">{{ $t('我的订单.待发货') }}</p>
                  <p v-if="scope.row.orderType == 3" style="width:110px;">{{ $t('我的订单.待收货') }}</p>
                  <p v-if="scope.row.orderType == 4" style="width:110px;">{{ $t('我的订单.已收货') }}</p>
                  <p v-if="scope.row.orderType == 5" style="width:110px;">{{
                      $t('我的订单.退货待审核')
                    }}</p>
                  <p v-if="scope.row.orderType == 6" style="width:110px;">{{ $t('我的订单.退货中') }}</p>
                  <p v-if="scope.row.orderType == 7" style="width:110px;">{{ $t('我的订单.已退货') }}</p>
                  <p v-if="scope.row.orderType == 8" style="width:110px;">{{ $t('我的订单.已完成') }}</p>
                  <p v-if="scope.row.orderType == 99" style="width:110px;">{{
                      $t('我的订单.已取消')
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('发票查询.发票类型')" prop="num3"></el-table-column>
              <el-table-column :label="$t('发票查询.下单时间')" prop="createTime"></el-table-column>
              <el-table-column :label="$t('发票查询.开票状态')" prop="date"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import Cookies from "js-cookie";
import {orderList} from "@/api/myInfo/myOrder";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      state: 1,
      value: '',
      value1: '',
      options: '',
      tableData: []
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load(val) {
      let info = {
        userId: JSON.parse(Cookies.get('userInfo')).userId,
        orderType: val
      }
      orderList(info).then(res => {
        console.log(res.rows)
        this.tableData = res.rows
      })
    },
    tabClick(num) {
      this.state = num;
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }

  .selectDiv {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .seleLeft {
      display: flex;
      flex-wrap: wrap;
      //display: grid;
      //grid-template-columns:repeat(2,1fr);
      grid-gap: 20px;
      //border: solid 1px;
      //margin-right: 30px;
      .sub {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        color: #666666;

        .subInp {
          flex: 1;
        }

        input {
          width: 200px;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          height: 36px;
        }
      }
    }

    .seleRig {
      display: flex;
      align-items: center;

      p {
        width: 54px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 13px;
        margin-right: 10px;
      }

      .ac {
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
        margin-left: 15px;
      }

      .exportbtn {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
      }
    }
  }

  .infoModule {
    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .purseBot {
      :deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 300px;
      }

      :deep .el-table th.el-table__cell {
        background: #012F9C;
        color: #fff;
      }

      :deep th:last-child {
        border-radius: 0 8px 0 0;
      }

      :deep th:first-child {
        border-radius: 8px 0 0 0;
      }
    }
  }

  :deep .el-dialog__title {
    font-size: 24px;
    color: #333333;
    line-height: 28px;
  }
}
</style>
