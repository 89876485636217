<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="twoModule w_1200">
        <div class="twoTop">
          <brandLeft ref="childClick"></brandLeft>
          <div class="rigIntro">
            <div style="display: flex;align-items: center;justify-content: space-between">
              <p class="infroTit">{{$t('品牌专区.市场新闻')}}</p>
              <div class="classSera">
                <input type="text" v-model="searchVal" :placeholder="$t('商品目录.请输入关键字')" />
                <div class="hand" @click="searClick">{{$t('商品目录.搜索')}}</div>
              </div>
            </div>
            <div class="bazzarNews">
              <div class="classDiv">
                <span class="hover hand" :class="sortObj.id==item.id?'active':''" @click="sortClick(item)" v-for="item in sortList">{{item.name}}</span>
              </div>
              <div class="newModule hand hoverTo" v-for="item in list" @click="newsClick(item)">
                <img class="newImg" :src="$baseUrl+item.exhibitionPicture" />
                <div class="newText">
                  <p class="newTit">{{ $i18n.locale='zh'?item.title:item.titleEn }}</p>
                  <div class="newIcon">
                    <p><img src="@/assets/sub/n1.png" /><span>{{item.createTime}}</span></p>
                    <p><img src="@/assets/sub/n2.png" /><span>{{item.accessNumber}}</span></p>
                    <p><img src="@/assets/sub/n3.png" /><span>{{item.accessNumber}}</span></p>
                    <p><img src="@/assets/sub/n4.png" /><span>{{item.collectNumber}}</span></p>
                    <!-- <p><img src="@/assets/sub/n5.png" /><span>618活动大促</span></p> -->
                  </div>
                </div>
              </div>
              <div style="text-align:right;margin-top:30px;" >
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import brandLeft from '@/component/home/brandLeft.vue'
import {tidingsList} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav, brandLeft },
  data(){
        return{
          loading: false,
          list:[],
          pageSize:10,//每页显示条数
          pageNum:1,
          total:0,
          searchVal:'',
          sortObj:{id:10,name:'全部',val:null},
          sortList:[//分类(1方案，2技术，3问答，4动态，5新闻，6看点，7产品，8活动，9发现)
              {id:10,name:this.$t('资讯.全部'),val:null},
              {id:1,name:this.$t('资讯.方案'),val:1},
              {id:2,name:this.$t('资讯.技术'),val:2},
              {id:3,name:this.$t('资讯.问答'),val:3},
              {id:4,name:this.$t('资讯.动态'),val:4},
              {id:5,name:this.$t('资讯.新闻'),val:5},
              {id:6,name:this.$t('资讯.新闻'),val:6},
              {id:7,name:this.$t('资讯.新闻'),val:7},
              {id:8,name:this.$t('资讯.活动'),val:8},
              {id:9,name:this.$t('资讯.发现'),val:9}
            ],
        }
    },
    onload(){},
    mounted() {
      // 页面加载时立即调用子组件方法
      this.$refs.childClick.tabClick(4)
      this.getList()
    },
    methods:{
      getList(){//获取列表
        tidingsList({brandId:Cookies.get('brandId'),sort:this.sortObj.val,title:this.searchVal}).then(res=>{
          this.list=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      searClick(){
        this.pageNum=1
        this.getList()
      },
      sortClick(item){//选择分类
        this.sortObj = item;
        this.pageNum=1
        this.getList()
      },
      newsClick(item){//新闻详情
        this.$router.push({path:'/bazzaarNewsDetail',query:{id:item.id,brandId:item.brandId}});
      },
      pageChange(e){//当前页
        this.pageNum=e
        this.getList()
        console.log(e)
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .twoModule{
    margin: 40px auto;
    .twoTop {
      display: flex;
      justify-content: space-between;
      .rigIntro {
        width: 900px;
        background: #FFFFFF;
        padding: 24px 16px;
        box-sizing: border-box;
        .infroTit{
          font-weight: bold;
          font-size: 20px;
          color: #000000;
          padding-left: 15px;
          border-left: solid 4px #0332A1;
          //margin-bottom: 25px;
        }
        .classSera{
          display: flex;
          align-items: center;
          input{
            height: 40px;
            width: 284px;
            background: #FFFFFF;
            text-indent: 1rem;
            border: 1px solid #CCCCCC;
          }
          div{
            width: 93px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #012F9C;
            font-size: 18px;
            border: 1px solid #012F9C;
            color: #FFFFFF;
          }
        }
        .bazzarNews{
          margin-top: 15px;
          .classDiv{
            display: flex;
            align-items: center;
            border-bottom: solid 1px #E2E2E2;
            //padding: 15px 0;
            //height: 66px;
            //border: solid 1px;
            box-sizing: border-box;
            span{
              font-size: 16px;
              color: #818181;
              margin-right: 35px;
              padding: 15px 0 15px 0;
            }
            .active{
              border-bottom: solid 4px #012F9C;
              color: #012F9C;
            }
          }
          .newModule{
            margin-top: 15px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            //justify-content: space-between;
            .newImg{
              width: 138px;
              height: 94px;
              margin-right: 10px;
            }
            .newText{
              .newTit{
                font-size: 16px;
                color: #000000;
                height:61px;
                line-height: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

              }
              .newIcon{
                margin-top: 15px;
                display: flex;
                align-items: center;
                p{
                  display: flex;
                  align-items: center;
                  margin-right: 20px;
                  font-size: 13px;
                  color: #AFAFAF;
                  img{
                    width: 15px;
                    height: 15px;
                    margin-right: 3px;
                    font-size: 13px;
                    color: #AFAFAF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>