<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="converModule">
      <div class="conBan">{{ $t('换算工具.贞光商城在线工具') }}</div>
      <div class="converBot w_1200">
        <div class="conLeft">
          <div class="conCss">
            <h2>{{ $t('换算工具.推荐工具') }}</h2>
            <div class="titModule">
              <p v-for="item in tabArr" :class="item.flag?'active':'hand hover'"
                 @click="tabClick(item)">
                <span>{{ item.name }}</span>
                <i :class="item.flag?'iA':''" class="el-icon-arrow-right iCss"></i>
              </p>
            </div>
          </div>
          <!-- <div class="conCss">
            <h2>{{ $t('换算工具.推荐商品') }}</h2>
            <div class="titModule">
              <p v-for="(item,index) in 10" class="hover hand" style="margin-bottom:10px">
                贴片电阻-{{ index }}</p>
            </div>
          </div> -->
        </div>
        <div class="conRight">
          <div class="conCss rigHs">
            <h2 class="tabCss">{{ tabName }}</h2>
            <div class="tabModule">
              <div class="scaleUnit">
                <div class="unitSub">
                  <p>{{ $t('换算工具.数值') }}</p>
                  <el-input v-model="value" class="inpCss" @input="count"/>
                </div>
                <div class="unitSub">
                  <p>{{ $t('换算工具.单位') }}</p>
                  <el-select v-model="optionVal" class="inpCss" placeholder="法拉（F）"
                             @change="count">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="result">
                <div class="resTit">
                  <p>{{ $t('换算工具.数值') }}</p>
                  <el-checkbox>{{ $t('换算工具.科学计数法显示') }}</el-checkbox>
                </div>
                <div v-for="item in options" class="resultDiv">
                  <p><span>{{ item.label }}</span>{{ item.labelValue }}</p>
                </div>
                <div class="resuTips">{{ $t('换算工具.提示') }}</div>
              </div>
            </div>
          </div>
          <div class="conCss">
            <h2>{{ $t('换算工具.关于') }}</h2>
            <div class="line"></div>
            <div class="aboutText">使用在线电容换算器可进行皮法（pF）、纳法和法拉（F）单位换算</div>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import Decimal from "decimal.js";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      tabVal: '1',
      tabName: '',
      value: "",
      tabArr: [
        {
          id: 1,
          name: this.$t('首页.电容换算器'),
          flag: true,
          options: [
            {
              value: '0',
              label: '法拉(F)',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '1',
              label: '毫法(mF)',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '2',
              label: '微法(μF)',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '3',
              label: '纳法(nF)',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '4',
              label: '皮法(pF)',
              numericalValue: "1000",
              labelValue: "0"
            }
          ],
        },
        {
          id: 2,
          name: this.$t('首页.电感换算器'),
          flag: false,
          options: [
            {
              value: '0',
              label: '千亨（kH）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '1',
              label: '亨利（H）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '2',
              label: '毫亨（mH）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '3',
              label: '微亨（μH）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '4',
              label: '纳亨（nH）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '5',
              label: '皮亨（pH）',
              numericalValue: "1000",
              labelValue: "0"
            }
          ],
        },
        {
          id: 3,
          name: this.$t('首页.长度换算器'),
          flag: false,
          options: [
            {
              value: '0',
              label: '公里（km）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '1',
              label: '米（m）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '2',
              label: '厘米（cm）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '3',
              label: '毫米（mm）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '4',
              label: '微米（μm）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '5',
              label: '纳米（nm）',
              numericalValue: "1000",
              labelValue: "0"
            }
          ],
        },
        {
          id: 4,
          name: this.$t('首页.压力换算器'),
          flag: false,
          options: [
            {
              value: '0',
              label: '千帕（kPa）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '1',
              label: '帕（Pa N/m2）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '2',
              label: '毫巴（mb）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '3',
              label: '巴（bar）',
              numericalValue: "1000",
              labelValue: "0"
            },
            {
              value: '4',
              label: '千巴（kbar）',
              numericalValue: "1000",
              labelValue: "0"
            }
          ],
        },
        // {
        //   id: 5,
        //   name: this.$t('首页.温度换算器'),
        //   flag: false,
        //   options: [
        //     {
        //       value: '0',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '1',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '2',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '3',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '4',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     }
        //   ],
        // },
        // {
        //   id: 6,
        //   name: this.$t('首页.体积换算器'),
        //   flag: false,
        //   options: [
        //     {
        //       value: '0',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '1',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '2',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '3',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '4',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     }
        //   ],
        // },
        // {
        //   id: 7,
        //   name: this.$t('首页.重量换算器'),
        //   flag: false,
        //   options: [
        //     {
        //       value: '0',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '1',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '2',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '3',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '4',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     }
        //   ],
        // },
        // {
        //   id: 8,
        //   name: 'BTU/焦耳换算器',
        //   flag: false,
        //   options: [
        //     {
        //       value: '0',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '1',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '2',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '3',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     },
        //     {
        //       value: '4',
        //       label: '',
        //       numericalValue: "1000",
        //       labelValue: "0"
        //     }
        //   ],
        // }
      ],
      options: [],
      optionVal: '0'
    }
  },
  mounted() {
    if (this.$route.query.num) {
      this.tabVal = this.$route.query.num
      this.tabArr.map((item) => {
        if (item.id == this.tabVal) {
          this.tabClick(item)
        }
      })
    }
  },
  onload() {
  },
  methods: {
    count() {
      // 确保输入的值是有效的数字
      let inputValue = parseFloat(this.value);
      if (isNaN(inputValue) || inputValue <= 0) {
        inputValue = 0;
      }
      // 再次确保清除之前的计算记录
      this.options.forEach(option => option.labelValue = "0");
      // 获取当前选择的数据
      let item = this.options[this.optionVal];
      let isValueSet = false;
      // 计算一下从上到下
      for (let i = 0; i < this.options.length; i++) {
        let opt = this.options[i];
        if (item.value === opt.value) {
          this.options[i].labelValue = inputValue;
          isValueSet = true;
          continue;
        }
        if (isValueSet) {
          this.options[i].labelValue = this.options[i - 1].labelValue
              * this.options[i].numericalValue;
        } else {
          if (i !== 0) {
            this.options[i].labelValue = this.options[i - 1].labelValue
                / this.options[i].numericalValue;
          }
        }
      }
      // 计算方法 F 为 true 之前的数据从下到上
      let optionsList = [...this.options].reverse();
      for (let i = 0; i < optionsList.length; i++) {
        if (optionsList[i].labelValue == 0) {
          // 计算 labelValue，如果当前值为 0，则从倒序数组中获取对应的 labelValue
          const originalIndex = this.options.length - 1 - i;  // 计算原数组中的对应索引
          optionsList[i].labelValue = optionsList[i - 1].labelValue / optionsList[i].numericalValue;
          this.options[originalIndex].labelValue = optionsList[i].labelValue;
        }
      }
    },
    formatResult(value) {
      // 这里可以根据需要格式化输出，比如添加科学计数法
      let count = new Decimal(value)
      return count.toString();
    },
    tabClick(item) {
      this.tabArr.map((i) => {
        i.flag = false;
        if (item.id == i.id) {
          i.flag = true;
          this.tabVal = i.id;
          this.tabName = i.name;
          this.options = i.options;
          this.value = ""
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .converModule {
    .conBan {
      width: 100%;
      line-height: 200px;
      background-image: url("@/assets/sub/ver.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      color: #FFFFFF;
    }

    .converBot {
      margin: 40px auto;
      display: flex;
      justify-content: space-between;

      .conCss {
        background: #FFFFFF;
        box-sizing: border-box;
        margin-bottom: 15px;
        padding: 10px 0;
      }

      h2 {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        padding-left: 30px;
      }

      .conLeft {
        width: 277px;

        .titModule {
          margin-top: 30px;

          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 5px 30px;
            box-sizing: border-box;
            font-size: 21px;
            border-left: solid 3px #fff;
          }

          .active {
            color: #0332A1;
            border-left: solid 3px #0332A1;
          }

          .iCss {
            font-size: 21px;
          }

          .iA {
            color: #0D39A1;
          }
        }
      }

      .conRight {
        width: 899px;

        .rigHs {
          padding: 10px 20px;
        }

        .tabCss {
          padding-left: 10px;
          border-left: solid 3px #0332A1;
        }

        .tabModule {
          .scaleUnit {
            display: flex;
            align-items: center;

            p {
              font-size: 15px;
              color: #999999;
              margin: 10px 0;
            }

            .inpCss {
              width: 275px;
              margin-right: 30px;
            }

            :deep .el-input__inner {
              border: solid 1px #999;
            }
          }

          .result {
            .resTit {
              font-size: 15px;
              color: #999999;
              margin: 15px 0;
              padding: 10px 0;
              box-sizing: border-box;
              width: 580px;
              display: flex;
              border-bottom: solid 1px #B3C7E3;
              align-items: center;
              justify-content: space-between;
            }

            .resultDiv {
              padding: 10px 0;
              display: grid;
              grid-template-columns:repeat(2, max-content);
              grid-gap: 15px;

              p {
                //border: solid 1px red;
              }

              span {
                font-size: 15px;
                color: #000000;
                margin-right: 60px;
              }
            }

            .resuTips {
              margin: 10px 0;
              font-size: 15px;
              color: #FF6600;
            }
          }
        }

        .line {
          border-bottom: solid 1px #E2E2E2;
          margin: 10px 0;
        }

        .aboutText {
          font-size: 15px;
          color: #000000;
          line-height: 23px;
          padding: 0 25px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
