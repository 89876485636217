<template>
  <div class="twoLeft">
    <div class="brandLogo">
      <div class="LoImg"><img :src="$baseUrl+detail.brandLogo"/></div>
      <p>{{$i18n.locale=='zh'?detail.nickName:detail.nickNameEn}}</p>
    </div>
    <div class="titModule">
      <p @click="tabClick(item.id)" :class="item.flag?'active':'hand hover'" v-for="item in titArr">
        <span>{{item.tit}}</span>
        <i class="el-icon-arrow-right iCss" :class="item.flag?'iA':''"></i>
      </p>
    </div>
  </div>

</template>

<script>
import {brandDetail} from '@/util/http'
import Cookies from "js-cookie";
export default {
  name: "brandLeft",
  props:{},
  data(){
    return{
      titVal:'1',
      detail:{},
      brandId:'',
      titArr:[
        {id:1,tit:this.$t('品牌专区.关于品牌'),url:'/brandTwo',flag:true},
        {id:2,tit:this.$t('品牌专区.授权代理'),url:'/authInfo',flag:false},
        {id:3,tit:this.$t('品牌专区.产品线'),url:'/product',flag:false},
        {id:4,tit:this.$t('品牌专区.市场新闻'),url:'/bazaarNews',flag:false},
      ]
    }
  },
  mounted(){
    if(this.$route.query.brandId){
        this.brandId=this.$route.query.brandId
      }else{
        this.brandId=Cookies.get('brandId')
      }
      // 页面加载时立即调用子组件方法
      this.getDetail()
  },
  methods:{
    getDetail(){
        brandDetail(this.brandId).then(res=>{
          this.detail=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
    tabClick(id){
      if(this.titVal==id) return
      this.titArr.map((i)=>{
        i.flag=false;
        if(id==i.id){
          console.log(i.id)
          i.flag=true;
          this.titVal=i.id;
          // this.$emit('tabClick',i)
          if(i.url && this.$route.path !== i.url){
            this.$router.push(i.url)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.twoLeft{
  width: 277px;
  height: 400px;
  color: #000000;
  background: #fff;
  .brandLogo{
    font-size: 16px;
    margin: auto;
    text-align: center;
    .LoImg{
      margin:10px auto;
      width: 222px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        height: 100%;
      }

    }
  }
  .titModule{
    margin-top:30px;
    p{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom:20px;
      padding:5px 30px;
      box-sizing: border-box;
      font-size: 21px;
      border-left: solid 3px #fff;
    }
    .active{
      color: #0332A1;
      border-left: solid 3px #0332A1;
    }
    .iCss{
      font-size: 21px;
    }
    .iA{
      color: #0D39A1;
    }
  }
}

</style>