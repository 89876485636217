<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="newsModule w_1200">
        <div class="newLeft">
          <div class="divCss">
            <p class="leftTit">{{$t('资讯.热门标签')}}</p>
            <div class="tagDiv">
              <p class="hand hover" @click="tabClick(item)" v-for="(item,index) in tabList">{{item.labelName}}</p>
            </div>
          </div>
          <div class="divCss" style="margin-top:10px" v-if="activeArr.length>0">
            <p class="leftTit">{{$t('资讯.热门文章')}}</p>
            <ul class="newDiv">
              <li v-for="item in activeArr" @click="newsClick(item)">
                <p class="hand hover">{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
              </li>
            </ul>
          </div>
          <div class="divCss" style="margin-top:10px">
            <p class="leftTit">{{$t('资讯.热门物料')}}</p>
            <p class="wuP"><span>{{$t('资讯.型号')}}</span><span>{{$t('资讯.价格')}}</span></p>
            <div class="wuSub hand" v-for="item in 6">
              <div class="wuLeft">
                <p>TPS54560DDAR/DC-D</p>
                <p>C电源芯片</p>
              </div>
              <p class="wuRig">4.52</p>
            </div>
          </div>
        </div>
        <div class="newRig">
          <div class="classTop">
            <div class="className"><span>{{$t('资讯.全部资讯')}}</span>({{list.length}})</div>
            <div class="classSera">
              <input type="text" v-model="search" :placeholder="$t('资讯.请输入关键字')" />
              <div class="hand" @click="searchClick">{{$t('资讯.搜索')}}</div>
            </div>
          </div>
          <div class="classDiv">
            <span class="hover hand" :class="sortObj.id==item.id?'active':''" @click="sortClick(item)" v-for="item in sortList">{{item.name}}</span>
          </div>
          <div class="newModule hand hoverTo" v-for="item in list" @click="newsClick(item)">
            <img class="newImg" :src="$baseUrl+item.exhibitionPicture" />
            <div class="newText">
              <p class="newTit">{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
              <div class="newIcon">
                <p><img src="@/assets/sub/n1.png" /><span>{{item.createTime}}</span></p>
                <p><img src="@/assets/sub/n2.png" /><span>{{item.accessNumber}}</span></p>
                <p><img src="@/assets/sub/n3.png" /><span>{{item.likeNumber}}</span></p>
                <p><img src="@/assets/sub/n4.png" /><span>{{item.collectNumber}}</span></p>
                <!-- <p><img src="@/assets/sub/n5.png" /><span>618活动大促</span></p> -->
              </div>
            </div>
          </div>
          <div style="text-align:right;margin-top:30px;" >
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {labelList,tidingsList,newsActive} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            pageSize:10,//每页显示条数
            pageNum:1,
            total:0,
            tabObj:{},//选中的热门标签
            tabList:[],//热门标签
            search:'',
            list:[],//新闻
            activeArr:[],//热门文章
            sortObj:{id:10,name:'全部',val:null},
            sortList:[//分类(1方案，2技术，3问答，4动态，5新闻，6看点，7产品，8活动，9发现)
              {id:10,name:this.$t('资讯.全部'),val:null},
              {id:1,name:this.$t('资讯.方案'),val:1},
              {id:2,name:this.$t('资讯.技术'),val:2},
              {id:3,name:this.$t('资讯.问答'),val:3},
              {id:4,name:this.$t('资讯.动态'),val:4},
              {id:5,name:this.$t('资讯.新闻'),val:5},
              {id:6,name:this.$t('资讯.新闻'),val:6},
              {id:7,name:this.$t('资讯.新闻'),val:7},
              {id:8,name:this.$t('资讯.活动'),val:8},
              {id:9,name:this.$t('资讯.发现'),val:9}
            ],
        }
    },
    created(){
      this.getList()
      this.getTabClick()
      this.getActve()
    },
    methods:{
      getActve(){//热门文章
        newsActive({popular:1,pageSize:10,pageNum:1}).then(res=>{
          this.activeArr = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      searchClick(){
        this.pageNum=1
        this.getList()
      },
      sortClick(item){//选择分类
        this.sortObj = item;
        this.pageNum=1
        this.tabObj ={}
        this.getList()
      },
      pageChange(e){//当前页
        this.pageNum=e
        this.getList()
        console.log(e)
      },
      getList(){//获取列表
        tidingsList({
          sort:this.sortObj.val,
          tags:this.tabObj.labelName,
          pageSize:this.pageSize,
          pageNum:this.pageNum
        }).then(res=>{
          this.total=res.total
          this.list = res.rows;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getTabClick(){
        labelList({labelHot:1}).then(res=>{
          this.tabList = res.rows;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      tabClick(item){
        this.pageNum=1
        this.tabObj = item;
        this.getList()
      },
      newsClick(item){//新闻详情
        this.$router.push({path:'/newsDetail',query:{id:item.id}});
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .newsModule{
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    .newLeft{
      width: 277px;
      .divCss{
        background: #FFFFFF;
        padding: 15px 10px;
        box-sizing: border-box;
        font-size: 13px;
        color: #000000;
        //margin-bottom: 10px;
      }
      .leftTit{
        font-size: 16px;
        color: #000000;
        padding-left: 10px;
        border-left: #0332A1 3px solid;
        margin-bottom: 15px;
      }
      .tagDiv{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p{
          margin-bottom: 15px;
          margin-right: 15px;
        }
      }
      .newDiv{
        padding-left: 18px;
          li{
            list-style: disc;
            margin-bottom: 15px;
            color: #0234A5;
            p{
              color: #000;
            }
          }
      }
      .wuP{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        height: 27px;
        background: #C4D5FF;
        padding: 0 10px;
        box-sizing: border-box;
        span{
          font-size: 16px;
          color: #000000;
        }
      }
      .wuSub{
        padding:6px 10px;
        box-sizing: border-box;
        border-bottom: solid 1px #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wuLeft p{
          margin:6px 0;
        }
        .wuRig{
          color: #FF6600;
        }
      }
    }
    .newRig{
      width: 899px;
      background: #FFFFFF;
      padding: 20px 20px 10px 20px;
      box-sizing: border-box;
      .classTop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .className{
          font-weight: 400;
          font-size: 20px;
          color: #000000;
          span{
            font-weight: bold;
            font-size: 25px;
          }
        }
        .classSera{
          display: flex;
          align-items: center;
          input{
            height: 40px;
            width: 338px;
            background: #FFFFFF;
            text-indent: 1rem;
            border: 1px solid #CCCCCC;
          }
          div{
            width: 93px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #012F9C;
            font-size: 18px;
            border: 1px solid #012F9C;
            color: #FFFFFF;
          }
        }
      }
      .classDiv{
        display: flex;
        align-items: center;
        border-bottom: solid 1px #E2E2E2;
        //padding: 15px 0;
        //height: 66px;
        //border: solid 1px;
        box-sizing: border-box;
        span{
          font-size: 16px;
          color: #818181;
          margin-right: 35px;
          padding: 15px 0 15px 0;
        }
        .active{
          border-bottom: solid 4px #012F9C;
          color: #012F9C;
        }
      }
      .newModule{
        margin-top: 15px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        //justify-content: space-between;
        .newImg{
          width: 138px;
          height: 94px;
          margin-right: 10px;
        }
        .newText{
          .newTit{
            font-size: 16px;
            color: #000000;
            height:61px;
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

          }
          .newIcon{
            margin-top: 15px;
            display: flex;
            align-items: center;
            p{
              display: flex;
              align-items: center;
              margin-right: 20px;
              font-size: 13px;
              color: #AFAFAF;
              img{
                width: 15px;
                height: 15px;
                margin-right: 2px;
                font-size: 13px;
                color: #AFAFAF;
              }
            }
          }
        }
      }
    }
  }
}
</style>