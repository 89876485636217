<template>
  <div class="toolBg">
    <p class="toolTit">{{ $t("首页.丰富多元新闻咨询等你来") }}</p>
    <div class="newsDiv">
      <div class="newTit">
        <div @click="titClick(item)" v-for="item in titArr" :class="item.flag ? 'active' : 'hand'">{{ item.name }}</div>
      </div>
      <div class="newsView">
        <div class="viewLeft hand hoverTo" @click="newsClick(list[0])" v-if="list[0]">
          <img :src="$baseUrl+list[0].exhibitionPicture" />
          <p class="viewTit">{{$i18n.locale=='zh'?list[0].describeCh:list[0].describeEn}}</p>
          <p class="viewTime">{{ list[0].createTime }}</p>
          <div class="viewDesc" v-if="$i18n.locale=='zh'" v-html="list[0].content"></div>
          <div class="viewDesc" v-else v-html="list[0].contentEn"></div>
        </div>
        <div class="viewMin">
          <div class="viewRig hand hoverTo"
            @click="newsClick(item)" v-for="(item,index) in list" v-if="item&&index>0&&index<=3">
            <img :src="$baseUrl+item.exhibitionPicture" />
            <div class="rigCon">
              <p class="viewTit">{{$i18n.locale=='zh'?item.describeCh:item.describeEn}}</p>
              <p class="viewTime">{{ item.createTime }}</p>
              <div class="viewDesc" v-if="$i18n.locale=='zh'" v-html="item.content"></div>
              <div class="viewDesc" v-else v-html="item.contentEn"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="loadMore hand hoverTo" @click="newsClick()">
        {{ $t("首页.查看更多") }} <img src="@/assets/home/ri.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { newsList,tidings } from "@/api/api";
export default {
  components: {},
  data() {
    return {
      list:[],
      tabVal:5,
      titArr: [
        { id: 1, name: this.$t("首页.新闻动态"), flag: true,val:5 },
        { id: 2, name: this.$t("首页.原厂动态"), flag: false,val:4 },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await newsList({
        sort:this.tabVal
      });
      this.list = res.rows;
    },
    titClick(item) {
      this.titArr.map((i) => {
        i.flag = false;
        if (item.id == i.id) {
          i.flag = true;
          this.tabVal=item.val
          this.getData();
        }
      });
    },
    newsClick(item) {
      if (item) {
        console.log(item);
        this.$router.push({ path: "/newsDetail",query: { id: item.id} });
      } else {
        console.log(item);
        this.$router.push({ path: "/news" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.toolBg {
  width: 100%;
  height: 780px;
  background: #012f9c;
  padding: 40px 0;
  box-sizing: border-box;
  .toolTit {
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .newsDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .newTit {
      display: flex;
      justify-content: center;
      div {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.5);
        // width: 88px;
        padding:0 10px;
        height: 32px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
      }
      .active {
        color: #ffffff;
        border: 1px solid #ffffff;
      }
    }
    .newsView {
      display: flex;
      align-items: self-start;
      justify-content: space-between;
      margin-top: 40px;
      div {
        //border: solid 1px;
      }
      .viewTit {
        font-size: 16px;
        color: #333333;
        margin-bottom: 6px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 320px;
      }
      .viewTime {
        font-size: 13px;
        color: #666666;
        margin-bottom: 13px;
      }
      .viewDesc {
        font-size: 13px;
        color: rgba(153, 153, 153, 0.6);
        line-height: 15px;
        //border: solid 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
      .viewLeft {
        width: 384px;
        height: 538px;
        padding: 20px;
        margin-right: 24px;
        background: #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        img {
          width: 344px;
          height: 262px;
          border-radius: 4px;
          margin-bottom: 13px;
        }
        .viewDesc {
          width: 344px;
          height: 150px;
          -webkit-line-clamp: 10;
        }
      }
      .viewMin {
        //display: flex;
        //flex-direction: column;
      }
      .viewRig {
        padding: 10px;
        width: 588px;
        height: 166px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        img {
          width: 226px;
          height: 146px;
          border-radius: 4px;
          //margin-right: 10px;
        }
        .viewDesc {
          width: 332px;
          height: 90px;
        }
        .rigCon {
        }
      }
    }
    .loadMore {
      // text-align: center;
      // display: block;
      display: flex;
      align-items: center;
      margin: 10px auto;
      width: fit-content;
      font-size: 13px;
      color: #ffffff;
      img {
        margin-left: 3px;
        width: 17px;
        height: 17px;
      }
    }
  }
}
</style>