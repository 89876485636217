<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="scoreModule">
      <div class="moduleTop">{{ $t('签到.完成任务可获得丰厚积分') }}</div>
      <div class="moduleBot w_1200">
        <div class="scoreLeft">
          <div v-for="item in userSignNotifyList" class="scoreItem">
            <img src="@/assets/images/sigin1.png"/>
            <p>{{ $t('签到.恭喜') }}<span>{{ maskPhoneNumber(item.createBy) }}</span>{{ $t('签到.签到领') }}50{{$t('签到.积分！')}}</p>
          </div>
        </div>
        <div class="scoreRig">
          <div v-for="item in funList" :key="item.id" class="scoreFun" @click="pathClick(item)">
            <img :src="require('@/assets/images/'+item.img+'.png')"/>
            <div class="funCen">
              <p>{{ item.tit }}</p>
              <div class="funCBot">
                <div>
                  <img src="@/assets/images/jinbi.png"/>
                  <span v-if="item.id!=6">+{{ item.jifen }}</span>
                  <span v-else>无上限</span>
                </div>
                <p>{{ $t('签到.奖励') }}<span>{{ item.jifen }}</span>{{ $t('签到.积分') }}</p>
              </div>
            </div>
            <p v-if="item.id==3" v-clipboard:copy="$baseUrl+item.url+userInfo.userId" :class="item.flag?'funActive':''" class="funBtn hand">{{item.flag? item.endText : item.notText}}</p>
            <p v-else :class="item.flag?'funActive':''" class="funBtn hand">{{item.flag? item.endText : item.notText}}</p>
          </div>

        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {configManage} from '@/util/http'
import {userSignNotify} from "@/api/home/signIn";
import Cookies from "js-cookie";
export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      userSignNotifyList: [],
      userInfo: {},
      funList: [
        {
          id: 1,
          tit: this.$t('签到.每日签到'),
          img: 'i1',
          jifen: '50',
          notText: '未签到',
          endText: this.$t('签到.已签到'),
          flag: false
        },
        // {id:2,tit:this.$t('签到.关注公众号'),img:'i2',jifen:'500',notText:this.$t('签到.未关注'),endText:'去关注',flag:false},
        {
          id: 3,
          tit: this.$t('签到.邀请注册'),
          img: 'i3',
          jifen: '500',
          notText: this.$t('签到.未邀请'),
          endText: '去邀请',
          flag: false,
          url:'/#/login?superiorUserId='
        },
        // {id:4,tit:this.$t('签到.上传数据手册'),img:'i4',jifen:'50',notText:this.$t('签到.未上传'),endText:'去上传',flag:false},
        {
          id: 5,
          tit: this.$t('签到.完善个人信息'),
          img: 'i5',
          jifen: '400',
          notText: this.$t('签到.未完善'),
          endText: '去完善',
          flag: false
        },
        {
          id: 6,
          tit: this.$t('签到.支付订单'),
          img: 'i6',
          jifen: '',
          notText: this.$t('签到.无上限'),
          endText: '去下单',
          flag: false
        },
      ]
    }
  },
  created() {
    this.getList()
  },
  mounted(){
    this.userInfo = JSON.parse(Cookies.get('userInfo'))
  },
  methods: {
    maskPhoneNumber(number) {
      // 将中间四位替换为星号
      return number.slice(0, 3) + '****' + number.slice(7);
    },
    getList() {
      configManage().then(res => {
        let list = res.rows
        this.funList[0].jifen = list[0].configValue
        this.funList[0].flag = list[0].configTypeBoolean
        this.funList[1].jifen = list[4].configValue
        this.funList[1].flag = list[4].configTypeBoolean
        this.funList[2].jifen = list[5].configValue
        this.funList[2].flag = list[5].configTypeBoolean
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
      userSignNotify().then(res => {
        this.userSignNotifyList = res.data
      })
    },
    pathClick(item) {
      if(item.id == 1){
        this.$router.push('/signIn')
      }
      if(item.id == 3){
        this.$message(this.$t('签到.复制成功'))
      }
      if (item.id == 5) {
        this.$router.push({
          path: '/myInfo',
          query: {
            navVal: '1-1'
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .scoreModule {
    .moduleTop {
      background-image: url("@/assets/images/score.png");
      background-size: 100%;
      width: 100%;
      line-height: 200px;
      height: 200px;
      text-align: center;
      font-size: 43px;
      color: #FFFFFF;
    }

    .moduleBot {
      display: flex;
      justify-content: space-between;
      margin: 50px auto;

      .scoreLeft {
        width: 277px;
        height: 368px;
        background: #FFFFFF;
        padding: 25px 20px;
        box-sizing: border-box;
        overflow-y: auto;

        .scoreItem {
          margin-bottom: 25px;
          font-size: 13px;
          color: #000000;
          display: flex;
          align-items: center;

          span {
            color: #FECC3F;
          }

          img {
            width: 17px;
            height: 17px;
            margin-right: 20px;
          }
        }
      }

      .scoreRig {
        padding: 40px;
        box-sizing: border-box;
        width: 899px;
        // height: 515px;
        background: #FFFFFF;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;

        .scoreFun {
          width: 388px;
          height: 117px;
          background: #FFFFFF;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #E3E3E3;
          padding: 22px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 75px;
            height: 75px;
            //margin-right: 15px;
          }

          .funCen {
            p {
              font-size: 17px;
              color: #000000;
            }

            .funCBot {
              margin-top: 20px;
              display: flex;
              align-items: center;
              margin-right: 5px;

              div {
                display: flex;
                align-items: center;
                width: 64px;
                height: 19px;
                background: #FCF4D7;
                border-radius: 20px;
                font-size: 13px;
                color: #F9B721;

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 3px;
                }
              }

              p {
                margin-left: 3px;
                font-size: 13px;

                span {
                  color: #F9B721;
                }
              }
            }
          }

          .funBtn {
            width: 87px;
            line-height: 30px;
            text-align: center;
            background: #0233A3;
            border-radius: 23px;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
          }

          .funActive {
            color: #0233A3;
            background: #FFFFFF;
            border: 1px solid #0233A3;
          }
        }
      }
    }
  }
}
</style>
