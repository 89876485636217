<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{ $t('我的订单.我的订单') }}</p>
            <div><span class="editBtn hand" style="margin-right:10px;background:#F3F5F8;color:#333"><i
                class="el-icon-delete"/>{{ $t('我的订单.订单回收站') }}</span></div>
          </div>
          <div class="detailSele">
            <div class="seleLeft">
              <p :class="state==1?'active':'hand hover'" @click="tabClick(1)">{{
                  $t('我的订单.所有订单')
                }}</p>
              <span>|</span>
              <p :class="state==2?'active':'hand hover'" @click="tabClick(2)">{{
                  $t('我的订单.待审核')
                }}</p>
              <span>|</span>
              <p :class="state==3?'active':'hand hover'" @click="tabClick(3)">{{
                  $t('我的订单.待付款')
                }}</p>
              <span>|</span>
              <p :class="state==4?'active':'hand hover'" @click="tabClick(4)">{{
                  $t('我的订单.待发货')
                }}</p>
              <span>|</span>
              <p :class="state==5?'active':'hand hover'" @click="tabClick(5)">{{
                  $t('我的订单.待收货')
                }}</p>
            </div>
          </div>
          <div class="termModule">
            <div class="searDiv">
              <input/>
              <div class="sea hand">{{ $t('我的订单.订单搜索') }}</div>
            </div>
            <!-- <div class="termSub hand">{{ $t('我的订单.更多筛选条件') }}<i
                class="el-icon-arrow-down"/></div>
            <div class="editBtn hand">{{ $t('我的订单.批量导出订单') }}</div> -->
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{ $t('我的订单.全部订单') }}</th>
                <th>{{ $t('我的订单.订单详情') }}</th>
                <th>{{ $t('我的订单.收货人') }}</th>
                <th>{{ $t('我的订单.金额') }}</th>
                <th>{{ $t('我的订单.订单状态') }}</th>
                <th>{{ $t('我的订单.操作') }}</th>
              </tr>
              <tr v-if="tableData.length<=0">
                <td colspan="6">
                  <div class="wuCss">
                    <div class="wuTips">
                      <img src="@/assets/member/wu.png"/>
                      <span>{{ $t('我的订单.当前时段暂时没有订单哦') }}</span>
                    </div>
                    <div style="display: flex;justify-content: space-evenly">
                      <!-- <div class="allOrBtn hand">{{ $t('我的订单.查看全部订单') }}</div> -->
                      <div class="xuanOrBtn hand" @click="pathClick('/catalogueOne')">{{ $t('我的订单.去选购') }}</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="tableData.length>0" v-for="(item,index) in tableData" class="trCon">
                <td>
                  <div class="descCss" style="width:260px">
                    <p style="margin-left:70px;font-size:12px">{{ $t('我的订单.订单号') }}{{
                        $t('公用.：')
                      }} <span style="color:#012F9C;">{{ item.orderNumber }}</span></p>
                    <div v-for="item1 in item.children" class="descOrd">
                      <img :src="$baseUrl + item1.productPhoto"/>
                      <div>
                        <p>{{ $t('我的订单.订单型号') }}{{ $t('公用.：') }} {{ item1.zgNumber }}</p>
                        <p>{{ $t('我的订单.品牌') }}{{ $t('公用.：') }} {{
                            $i18n.locale=='zh'?item1.productBrandName:item1.productBrandNameEn
                          }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="xj" style="width:130px">
                  <div>
                    <p>{{ $t('我的订单.单价') }}{{ $t('公用.：') }}{{
                        $t('公用.¥')
                      }}{{ item.productPriceStr }}</p>
                    <p>{{ $t('我的订单.订单数量') }}{{ $t('公用.：') }}{{ item.buyNumber }}</p>
                  </div>
                </td>
                <td class="xj" style="width:100px">
                  <div>{{ item.name }}</div>
                </td>
                <td class="xj" style="width:80px">
                  <div>{{ $t('公用.¥') }}{{ item.totalPrice }}</div>
                </td>
                <td class="dj" style="width:110px">
                  <p v-if="item.orderType == 0" style="width:110px;">{{ $t('我的订单.待审核') }}</p>
                  <p v-if="item.orderType == 1" style="width:110px;">{{ $t('我的订单.待支付') }}</p>
                  <p v-if="item.orderType == 2" style="width:110px;">{{ $t('我的订单.待发货') }}</p>
                  <p v-if="item.orderType == 3" style="width:110px;">{{ $t('我的订单.待收货') }}</p>
                  <p v-if="item.orderType == 4" style="width:110px;">{{ $t('我的订单.已收货') }}</p>
                  <p v-if="item.orderType == 5" style="width:110px;">{{
                      $t('我的订单.退货待审核')
                    }}</p>
                  <p v-if="item.orderType == 6" style="width:110px;">{{ $t('我的订单.退货中') }}</p>
                  <p v-if="item.orderType == 7" style="width:110px;">{{ $t('我的订单.已退货') }}</p>
                  <p v-if="item.orderType == 8" style="width:110px;">{{ $t('我的订单.已完成') }}</p>
                  <p v-if="item.orderType == 99" style="width:110px;">{{
                      $t('我的订单.已取消')
                    }}</p>
                  <p class="hand" style="color:#012F9C" @click="detailClick(item)">{{ $t('我的订单.订单详情') }}</p>
                </td>
                <td>
                  <div v-if="item.orderType == 1" class="editTab hand">
                    <p @click="payment(item)">{{ $t('我的订单.去支付') }}</p>
                  </div>
                  <div class="editTab hand">
                    <p @click="payClick(item)">{{ $t('我的订单.再次购买') }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </myInfoLeft>`
    <bottomNav></bottomNav>
    <div ref="alipayWap" v-html="alipay"/>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {orderList, pay,orderDetail} from "@/api/myInfo/myOrder";
import Cookies from "js-cookie";
import {OneUnifiedWayToPlaceYourOrderNow} from "@/api/myInfo/addCarClick";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      radio: '1',
      state: 1,//1数电增值税(专用)开票资料 2数电增值税(普通)开票资料
      checked: false,
      dialogFlag: false,//编辑发票信息弹窗
      billDialogialog: false,//修改发票抬头弹窗
      addDialog: false,//添加资料弹窗
      value: '',
      options: '',
      flag: false,
      tableData: [{}, {}],
      alipay: "",
    }
  },
  mounted() {
    this.load("")
  },
  methods: {
    detailClick(item){
      this.$router.push({
        path: '/orderState',
        query: {id: item.id}
      })
    },
    async payClick(item) {//立即购买
      let res = await OneUnifiedWayToPlaceYourOrderNow(JSON.parse(Cookies.get('userInfo')).userId,item.productId,
          item.buyNumber)
      Cookies.set('carId', res.data)
      await this.$router.push('/order')
    },
    payment(item) {
      console.log(item)
      if (item.paymentType == 2) {
        pay({id: item.id}).then(res => {
          this.alipay = res.data;
          // 防抖避免重复支付
          this.$nextTick(() => {
            // 提交支付表单
            this.$refs.alipayWap.children[0].submit();
            setTimeout(() => {
              // this.toPayFlag = false;
            }, 500);
          });
        })
      }
    },
    tabClick(val) {
      console.log(val)
      this.state = val
      if (val === 1) {
        this.load("")
      }
      if (val === 2) {
        this.load("0")
      }
      if (val === 3) {
        this.load("1")
      }
      if (val === 4) {
        this.load("2")
      }
      if (val === 5) {
        this.load("3")
      }
    },
    pathClick(path){
      this.$router.push(path)
    },
    load(val) {
      let info = {
        userId: JSON.parse(Cookies.get('userInfo')).userId,
        orderType: val
      }
      orderList(info).then(res => {
        this.tableData = res.rows
      })
    },
    billClick() {
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .selectDiv {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      .seleLeft {
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        //grid-gap: 20px;
        margin-right: 30px;

        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            width: 265px;
            height: 26px;
            border-radius: 4px;
            border: 1px solid #999999;

          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }

        .ac {
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }

        .exportbtn {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
          color: #333333;
          background: #fff;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        //display: flex;
        .descOrd {
          display: flex;
          align-items: center;

          img {
            width: 55px;
            height: 55px;
            margin-right: 15px;
          }
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
        text-align: center;
        word-break: break-all;
      }

      .editTab {
        display: flex;
        align-items: center;
        justify-content: end;

        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }

        .activeP {
          //color: #FF0000;
        }
      }

      table {
        width: 100%;
        border-collapse: separate; /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px; /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {
        }

        .trTit {
          line-height: 48px;
          height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #fff;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          //text-align: center;
          display: block
        }

        .xj p {
          //text-align: center;
          display: block
        }
      }

      .wuCss {
        //border: solid;
        //margin: auto;
        div {

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .wuTips {
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 40px;

          img {
            width: 40px;
            height: 40px;
            margin-right: 6px;
          }
        }

        .allOrBtn {
          font-size: 13px;
          color: #999999;
          width: 113px;
          height: 32px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #999999;
        }

        .xuanOrBtn {
          font-size: 13px;
          color: #FFFFFF;
          width: 113px;
          height: 32px;
          background: #012F9C;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }

  .dialogThreeCss {
    padding: 0 60px;
    margin: auto;

    .checkCss {
      text-align: center;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;

      span {
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }

      input {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #DCDFE6;
        width: 278px;
        height: 26px;
        flex: 1;
      }

      .infoSub {
        display: flex;
        align-content: center;
        margin: 20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;

        .subInp {
          flex: 1;
        }
      }

      p {
        //border: solid 1px;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right: 15px;
        width: 100px;
      }
    }
  }

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 30px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;

    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }

  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }

  .termModule {
    display: flex;
    justify-content: end;

    .searDiv {
      width: 316px;
      height: 26px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #999999;
      display: flex;
      align-items: center;

      input {
        flex: 1;
        //height: 24px;
        border: none;
      }

      .sea {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #666666;
        line-height: 15px;
        width: 82px;
        height: 26px;
        background: #D9D9D9;
        border-radius: 0px 2px 2px 0px;
      }
    }

    .termSub {
      margin: 0 10px;
      font-weight: 400;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      display: flex;
      align-items: center;
    }

    .editBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      width: 89px;
      height: 26px;
      background: #F3F5F8;
      border-radius: 2px 2px 2px 2px;
    }
  }

  .botDiv {
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #000000;
    justify-content: space-between;

    .editBtn {
      background: #012F9C;
      border-radius: 2px 2px 2px 2px;
      font-size: 11px;
      color: #FFFFFF;
      padding: 5px 15px;
    }
  }

  :deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #012F9C;
    background: #012F9C;
  }

  :deep .el-radio__input.is-checked + .el-radio__label {
    color: #333;
  }
}
</style>
