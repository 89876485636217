<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="newsModule w_1200">
        <div class="newLeft">
          <div class="divCss pageDiv" v-if="index==0" v-for="(item,index) in pageArr">
            <div>{{$t('资讯.上一篇')}}</div>
            <p class="hand hover" @click="newsClick(item)">{{ item.title }}</p>
          </div>
          <div class="divCss pageDiv" v-if="index==2" v-for="(item,index) in pageArr">
            <div>{{$t('资讯.下一篇')}}</div>
            <p class="hand hover" @click="newsClick(item)">{{ item.title }}</p>
          </div>
          <div class="divCss" v-if="activeArr.length>0">
            <p class="leftTit">{{$t('资讯.热门文章')}}</p>
            <ul class="newDiv">
              <li v-for="item in activeArr" @click="newsClick(item)">
                <p class="hand hover">{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
              </li>
            </ul>
          </div>
          <div class="divCss" v-if="likeArr.length>0">
            <p class="leftTit">{{$t('资讯.猜你喜欢')}}</p>
            <div class="wuSub hand" v-for="item in likeArr" @click="newsClick(item)">
              <img :src="$baseUrl+item.exhibitionPicture" />
              <p>{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
            </div>
          </div>
        </div>
        <div class="newRig">
          <div class="rigDiv">
            <p class="rigTit">{{ $i18n.locale=='zh'?detailObj.title:detailObj.titleEn }}</p>
            <div class="bazzDY">
              <div class="dyLeft">
                <!-- <img src="@/assets/home/b_6.png" /> -->
                <span>{{ $i18n.locale=='zh'?detailObj.nickName:detailObj.nickNameEn }}</span>
              </div>
              <div class="dyCon">{{$t('品牌专区.订阅可获得最新品牌资讯')}}{{detailObj.popular}}{{$t('品牌专区.人订阅')}}</div>
              <div class="dyRig hand">{{$t('品牌专区.+订阅')}}</div>
            </div>
            <div class="newIcon">
              <p><img src="@/assets/sub/n1.png" /><span>{{detailObj.createTime}}</span></p>
              <p><img src="@/assets/sub/n2.png" /><span>{{detailObj.accessNumber}}</span></p>
              <p><img src="@/assets/sub/n7.png" /><span>{{detailObj.subscriptionNumber}}</span></p>
            </div>
            <div class="newsCon">
              <div class="conDiv" v-html="$i18n.locale=='zh'?detailObj.content:detailObj.contentEn"></div>
            </div>
          </div>
          <!-- <div class="rigDiv goodsDiv">
            <p class="listTit">FASTON快接端子和接头型号推荐</p>
            <table>
              <tr>
                <th>{{$t('品牌专区.图片')}}</th>
                <th>{{$t('品牌专区.制造商零件编号')}}</th>
                <th>{{$t('品牌专区.描述')}}</th>
                <th>{{$t('品牌专区.可供货数量')}}</th>
                <th>{{$t('品牌专区.操作')}}</th>
              </tr>
              <tr class="trCss hand hoverTo" v-for="item in 6">
                <td><img src="@/assets/sub/good.png" /></td>
                <td style="width:200px"><p>3489-ShHGG</p></td>
                <td class="tdDesc"><p>PCB Terminals, Receptacle,Mating Tab Widn [6.35 mm / 5.21 mm], PCB Hole Diameter 1.4 mm [.055 in],FASTON 205</p></td>
                <td><p>56</p></td>
                <td><p class="tdBtn">{{$t('品牌专区.查看详情')}}</p></td>
              </tr>
            </table>
          </div> -->
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import{tidingsDetail,newsActive,likeBrandTidings,upAndDownList} from "@/util/http.js";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            id:'',
            brandId:'',//品牌id
            detailObj:{},
            likeArr:[],//猜你喜欢
            activeArr:[],//热门文章
            pageArr:[],//上下篇
        }
    },
    created(){
      this.id=this.$route.query.id
      this.brandId=this.$route.query.brandId
      this.detail()
      this.getLike()
      this.getActve()
      this.getPage()
    },
    beforeRouteUpdate(to, from, next) {//当路径参数变化时，更新 id 并重新获取数据
      console.log(to, from, next)
      this.id = to.query.id;
      this.detail();
      this.getLike()
      this.getActve()
      this.getPage()
      next();
    },
    methods:{
      getPage(){
        upAndDownList({id:this.id}).then(res=>{
          this.pageArr=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      detail(){
        tidingsDetail(this.id).then(res=>{
          this.detailObj=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getLike(){//猜你喜欢
        likeBrandTidings({brandId:this.brandId}).then(res=>{
          this.likeArr=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getActve(){//热门文章
        newsActive({popular:1,pageSize:10,pageNum:1,brandId:this.brandId}).then(res=>{
          this.activeArr = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      newsClick(item){//详情
        if(item.id==this.$route.query.id){
          this.$message('已经是第一条了')
          return
        }
        this.$router.push({path:'/bazzaarNewsDetail',query:{id:item.id}});
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .newsModule{
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    .newLeft{
      width: 277px;
      .divCss{
        background: #FFFFFF;
        padding: 15px 10px;
        box-sizing: border-box;
        font-size: 13px;
        color: #000000;
        margin-bottom: 10px;
      }
      .leftTit{
        font-size: 16px;
        color: #000000;
        padding-left: 10px;
        border-left: #0332A1 3px solid;
        margin-bottom: 15px;
      }
      .pageDiv{
        padding:0!important;
        div{
          padding:12px;
          border-bottom: #E8E8E8 solid 1px;
        }
        p{
          padding:12px;
          line-height: 16px;
        }
      }
      .newDiv{
        padding-left: 18px;
          li{
            list-style: disc;
            margin-bottom: 15px;
            color: #0234A5;
            p{
              color: #000;
            }
          }
      }
      .wuP{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        height: 27px;
        background: #C4D5FF;
        padding: 0 10px;
        box-sizing: border-box;
        span{
          font-size: 16px;
          color: #000000;
        }
      }
      .wuSub{
        box-sizing: border-box;
        display: flex;
        margin-bottom: 15px;
        //align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height:68px;
        p{
          font-size: 13px;
          line-height: 13.5px;
          color: #000000;
        }
        img{
          width: 95px;
          height: 65px;
          margin-right: 10px;
        }
      }
    }
    .newRig{
      width: 899px;
      .rigDiv{
        background: #FFFFFF;
        padding: 50px;
        box-sizing: border-box;
        margin-bottom: 20px;
      }
      .rigTit{
        font-weight: bold;
        font-size: 25px;
        color: #000000;
        line-height: 35px;
        text-align: center;
      }
      .bazzDY{
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 807px;
        height: 41px;
        background: #E6EDFF;
        border-radius: 21px 21px 21px 21px;
        padding: 0 35px;
        box-sizing: border-box;
        .dyLeft{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #012F9C;
          img{
            width: 77px;
            height: 35px;
            margin-right:8px;
          }
        }
        .dyCon{
          font-size: 14px;
          color: #000000;
        }
        .dyRig{
          // width: 80px;
          padding:0 8px;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          font-size: 14px;
          color: #FFFFFF;;
          background: #012F9C;
          border-radius: 14px;
        }
      }
      .newIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        //margin: 35px 0;
        p{
          display: flex;
          align-items: center;
          margin-right:40px;
          font-size: 14px;
          color: #AFAFAF;
          img{
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }
      }
      .newsCon{
        .conDiv{
          font-size: 13px;
          color: #000000;
          line-height:25px;
          text-align: left;
        }
        .openDiv{
          display: flex;
          align-items: center;
          justify-content: left;
          margin:25px 0;
          p{
            display: flex;
            align-items: center;
            margin-right:30px;
            font-size: 21px;
            color: #AFAFAF;
            img{
              width:20px;
              height:20px;
              margin-right:8px;
            }
          }
        }
      }

      .goodsDiv{
        padding: 20px;
        box-sizing: border-box;
        .listTit{
          font-weight: bold;
          font-size: 16px;
          color: #000000;
        }
        table{
          border-collapse: collapse;
          border-collapse:separate;
          border-spacing: 0px 10px;
          font-size: 13px;
          color: #000000;
          width: 100%;
          tr{
            text-align: center;
            margin:10px auto;
          }
          tr th{
            height: 36px;
            background: #F3F3F3;
          }
          .trCss{
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
            box-sizing: border-box;
            img{
              width: 148px;
              height: 98px;
            }
            td{
              padding: 10px;
              box-sizing: border-box;
              //border: solid 1px;
            }
            .tdDesc{
              width: 210px;
            }
            .tdBtn{
              display: inline-block;
              width: 87px;
              color: #012F9C;
              text-align: center;
              line-height: 37px;
              background: #FFFFFF;
              border-radius: 9px 9px 9px 9px;
              border: 1px solid #012F9C;
            }
          }
        }
      }
    }
  }
}
</style>