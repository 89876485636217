<template>
    <div class="footBg">
      <topNav></topNav>
      <div class="footTop">
        <div class="footTips">
          <img src="@/assets/home/msg.png" />
          <p>{{$t('首页.请问需要什么帮助')}}</p>
        </div>
      </div>
      <!-- <div class="searchDiv">
        <input type="text" :placeholder="$t('首页.请输入问题关键词')" />
        <div class="rigSear">
          <img src="@/assets/home/sear.jpg" />
        </div>
      </div> -->
      <div class="footCon w_1200" style="margin-top:46px;">
        <div class="footLeft">
          <p class="conTit">{{$t('首页.帮助中心')}}</p>
          <el-menu :default-active="subIndex" active-text-color="#0332A1" class="el-menu-vertical-demo" @select="handleSelect" @open="handleOpen" @close="handleClose">
            <el-submenu index="index" v-for="(item,index) in five">
              <template slot="title"><span>{{item.title}}</span></template>
              <el-menu-item-group v-for="(item2,index2) in item.list">
                <el-menu-item :index="index+'-'+index2">{{ item2.text }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="footRig">
          <p class="footP">{{selectedItem.text}}</p>
          <div class="conSoot" v-html="detail">
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {typeDl,typeBOM,typeQb,typeZmm,typeSc,typeGw,typeSp,typeYh,typeHt,typeDd,typeZh,typeZfSafe,typeYf,typeZf,typeSH,typeJf,typeFp,typeYs,typeMz,typeZc,typeXs,typeDdTj} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
          detail:'',
          subIndex:'0-0',
          selectedItem:{text:this.$t('首页.注册登录'),val:'0-0'},//选中的对象
          five: [
            {
              title:this.$t('首页.新手入门'),
              list: [
                {
                  text:this.$t('首页.注册登录'),
                  val:'0-0'
                },
                {
                  text:this.$t('首页.找回密码'),
                  val:'0-1'
                },
                {
                  text:this.$t('首页.收藏功能'),
                  val:'0-2'
                },
                {
                  text:this.$t('首页.BOM配单'),
                  val:'0-3'
                },
                {
                  text:this.$t('首页.我的钱包'),
                  val:'0-4'
                },
              ],
            },
            {
              title:this.$t('首页.购物指南'),
              list: [
                {
                  text:this.$t('首页.购物流程'),
                  val:'1-0'
                },
                {
                  text:this.$t('首页.搜索商品'),
                  val:'1-1'
                },
                {
                  text:this.$t('首页.优惠券规则'),
                  val:'1-2'
                },
                {
                  text:this.$t('首页.订单管理'),
                  val:'1-3'
                },
                {
                  text:this.$t('首页.合同下载'),
                  val:'1-4'
                },
              ],
            },
            {
              title:this.$t('首页.配送支付'),
              list: [
                {
                  text:this.$t('首页.支付方式'),
                  val:'2-0'
                },
                {
                  text:this.$t('首页.账户信息'),
                  val:'2-1'
                },
                {
                  text:this.$t('首页.支付安全'),
                  val:'2-2'
                },
                {
                  text:this.$t('首页.运费说明'),
                  val:'2-3'
                },
              ],
            },
            {
              title:this.$t('首页.售后服务'),
              list: [
                {
                  text:this.$t('首页.售后服务'),
                  val:'3-0'
                },
                {
                  text:this.$t('首页.发票须知'),
                  val:'3-1'
                },
                {
                  text:this.$t('首页.销售条款'),
                  val:'3-2'
                },
                {
                  text:this.$t('首页.重新交付'),
                  val:'3-3'
                },
              ],
            },
            {
              title:this.$t('首页.协议条款'),
              list: [
                {
                  text:this.$t('首页.隐私协议'),
                  val:'4-0'
                },
                {
                  text:this.$t('首页.免责声明'),
                  val:'4-1'
                },
                {
                  text:this.$t('首页.注册协议'),
                  val:'4-2'
                },
                {
                  text:this.$t('首页.订单提交协议'),
                  val:'4-3'
                },
              ],
            },
          ],
          requestMap:{
            '0-0':typeDl,
            '0-1':typeZmm,
            '0-2':typeSc,
            '0-3':typeBOM,
            '0-4':typeQb,
            '1-0':typeGw,
            '1-1':typeSp,
            '1-2':typeYh,
            '1-3':typeDd,
            '1-4':typeHt,
            '2-0':typeZf,
            '2-1':typeZh,
            '2-2':typeZfSafe,
            '2-3':typeYf,
            '3-0':typeSH,
            '3-1':typeFp,
            '3-2':typeXs,
            '3-3':typeJf,
            '4-0':typeYs,
            '4-1':typeMz,
            '4-2':typeZc,
            '4-3':typeDdTj,
          }
        }
    },
    mounted(){
      if(this.$route.query.subIndex){
        this.subIndex=this.$route.query.subIndex
        this.openSelect()
      }
      this.getMeth()

    },
    methods:{
     async getMeth(){//接口请求
        const requestFun=this.requestMap[this.subIndex]
        if(requestFun){
          this.detail=''
          try{
            const res=await requestFun()
            this.detail=res.data.content
          }catch(e){
            // this.$message(e.msg);
            console.log(e)
          }
        }else{
          console.log('没有找到对应的请求函数');
        }
      },
      handleOpen(key, keyPath){
        console.log(key, keyPath)
      },
      handleClose(key, keyPath){
        console.log(key, keyPath)
      },
      handleSelect(key, keyPath) {
        // console.log(key, keyPath);
        // 解析二级菜单的索引
        const subIndex = keyPath[1]; // keyPath[1] 是形如 "0-0" 的字符串
        // 将 subIndex 拆分成一级菜单和二级菜单的索引
        const [mainIndex, subMenuIndex] = subIndex.split('-').map(Number);
        // 根据索引获取选中的对象
        this.selectedItem = this.five[mainIndex].list[subMenuIndex];
        this.subIndex=this.selectedItem.val
        this.getMeth()
      },
      openSelect(){
        // 将 subIndex 拆分成一级菜单和二级菜单的索引
        const [mainIndex, subMenuIndex] = this.subIndex.split('-').map(Number);
        // 根据索引获取选中的对象
        this.selectedItem = this.five[mainIndex].list[subMenuIndex];
        // 打印当前选中的对象
        // console.log('Selected Item:', this.selectedItem);
      }
    }
}
</script>
<style lang="scss" scoped>
.footBg{
    width:100%;
  .footTop{
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0332A1;
    .footTips{
      font-size: 66px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 148px;
        height: 148px;
        margin-right: 21px;
      }
    }
  }
  .searchDiv{
    width: 913px;
    height: 69px;
    margin: -30px auto 70px auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:15px 0 15px 30px;
    box-sizing: border-box;
    input{
      flex: 1;
      height: 40px;
      border: none;
    }
    .rigSear{
      margin-left: 20px;
      width:80px;
      border-left:#D9D9D9 1px solid;
      display:flex;
      align-items: center;
      justify-content: center;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
  .footCon{
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    margin-bottom: 40px;
    .footLeft{
      width: 277px;
      height: 1293px;
      background: #FFFFFF;
      :deep .el-menu{
        text-align: center;
      }
      :deep .el-submenu__title{
        font-size: 20px;
        font-weight: 600;
      }
      :deep .el-menu-item{
        font-weight: 400;
        font-size: 16px;
        color: #A8A8A8;
      }
      .conTit{
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
        width: 277px;
        line-height: 63px;
        text-align: center;
        background: #0332A1;
        margin-bottom: 20px;
      }
    }
    .footRig{
      width: 899px;
      //height: 1293px;
      background: #FFFFFF;
      overflow-y: auto;
      padding:20px 30px;
      box-sizing: border-box;
      .footP{
        font-weight: bold;
        font-size: 25px;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: solid 1px #CCCCCC;
        margin-bottom:20px;
      }
      .conSoot{
        min-height:700px;
      }
    }
  }
  :deep .el-submenu .el-menu-item{
    height: 35px;
    //border: solid 1px;
    line-height: 35px;
}
  :deep .el-menu-item-group__title{
    padding:3px 0px 3px 20px;
  }
}
</style>
