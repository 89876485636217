<template>
    <div class="indexBg" v-loading="loading">
      <topNav :active="6"></topNav>
      <div class="supModule">
        <img class="suTop" src="../../assets/sub/sup.png"/>
        <div class="w_1200">
          <div class="suInp">
            <p class="supTit">{{$t('供应商合作.合作申请')}}</p>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.公司名称')}}</p>
              <input type="text" v-model="supplierName"/>
            </div>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.商品品牌')}}</p>
              <input type="text" v-model="supplierBrand"/>
            </div>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.类别')}}</p>
              <div>
                <el-radio v-model="supplierType" label="1">{{$t('供应商合作.原厂')}}</el-radio>
                <el-radio v-model="supplierType" label="2">{{$t('供应商合作.代理商')}}</el-radio>
              </div>
            </div>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.联系人')}}</p>
              <input type="text" v-model="supplierUsername"/>
            </div>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.手机号')}}</p>
              <input type="text" v-model="supplierPhone"/>
            </div>
            <div class="inpSub">
              <p><span>*</span>{{$t('供应商合作.邮箱')}}</p>
              <input type="text" v-model="supplierEmail"/>
            </div>
            <div class="subBtn hand" @click="submitClick"><p>{{$t('供应商合作.提交')}}</p></div>
          </div>
          <div class="photoSub">
            <p class="supTit">{{$t('供应商合作.入住优势')}}</p>
            <div class="teamModul">
              <div class="teamOne">
                <img style="width:752px" src="@/assets/sub/su1.png" alt="" />
                <img src="@/assets/sub/su2.png" alt="" />
              </div>
              <div class="teamTwo">
                <img src="@/assets/sub/su3.png" alt="" />
              </div>
              <div class="teamThree">
                <img src="@/assets/sub/su4.png" alt="" />
                <img src="@/assets/sub/su5.png" alt="" />
                <img src="@/assets/sub/su6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {supplier} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            supplierName:'',
            supplierBrand:'',
            supplierType:'1',
            supplierUsername:'',
            supplierPhone:'',
            supplierEmail:'',
        }
    },
    onload(){},
    methods:{
      submitClick(){
        supplier({
          supplierName:this.supplierName,
          supplierBrand:this.supplierBrand,
          supplierType:this.supplierType,
          supplierUsername:this.supplierUsername,
          supplierPhone:this.supplierPhone,
          supplierEmail:this.supplierEmail,
        }).then(res=>{
          this.$message(res.msg);
          this.supplierName=''
          this.supplierBrand=''
          this.supplierType='1'
          this.supplierUsername=''
          this.supplierPhone=''
          this.supplierEmail=''
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .supModule{
    .suTop{
      width: 100%;
      height: 500px;
    }
    .supTit{
      text-align: center;
      font-size: 26px;
      color: #000000;
      margin-bottom: 40px;
    }
    .suInp{
      margin: 30px auto;
      width: 1200px;
      height: 759px;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 40px 170px;
      box-sizing: border-box;
      .inpSub{
        font-size: 16px;
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        p{
          margin-right: 10px;
          //border: solid 1px;
          width:90px;
          text-align: right;
        }
        span{
          color: #a10000;
        }
        input{
          flex:1;
          width: 749px;
          height: 66px;
          background: #FFFFFF;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #DEDEDE;
        }
      }
      .subBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        p{
          width: 260px;
          line-height: 66px;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
          background: #012F9C;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
    .photoSub{
      margin: 40px 0;
      height: 968px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      padding: 30px;
      box-sizing: border-box;
      .teamModul{
        img{
          height: 265px;
        }
        .teamOne{
          display: flex;
          align-items: center;
          justify-content: space-between;
          img{
            width:364px;
          }
        }
        .teamTwo{
          margin: 20px 0;
          img{
            width:1140px;
          }
        }
        .teamThree{
          display: flex;
          align-items: center;
          justify-content: space-between;
          img{
            width: 364px;
          }
        }
      }
    }
  }
}
</style>