<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="collModule">
        <div class="collBanner">
          <div class="cliCss hand" @click="popClick"></div>
<!--          <div class="collBj">-->
<!--            <p>2023-2024校园实习招募</p>-->
<!--            <h1>成为贞光校企达人 轻松实现云实习</h1>-->
<!--            <img src="@/assets/sub/coll6.png">-->
<!--            <p style="font-size:18px">向下滑动</p>-->
<!--          </div>-->
        </div>
        <div class="infoModule w_1200">
          <div class="infoTit">
            <span class="hand" :class="item.flag?'sActive':''" @click="titClick(item)" v-for="item in titArr">{{item.title}}</span>
          </div>
          <div class="infoPhoto" v-if="infoList.length>0">
            <img class="infoPLeft" :src="$baseUrl+infoList[titVal-1].picture">
            <div class="infoPRig">
                <p>{{infoList[titVal-1].title}}</p>
                <div v-html="infoList[titVal-1].content"></div>
            </div>
          </div>
        </div>
        <div class="wellModule">
          <p>{{$t('高校.薪酬福利')}}</p>
          <div class="wellBot w_1200">
            <div class="wellSub">
              <img src="@/assets/sub/coll3.png" />
              <p>{{$t('高校.锻炼提升职称能力')}}</p>
            </div>
            <div class="wellSub">
              <img src="@/assets/sub/coll4.png" />
              <p>{{$t('高校.扩展积累人脉')}}</p>
            </div>
            <div class="wellSub">
              <img src="@/assets/sub/coll5.png" />
              <p>{{$t('高校.专业FAE技术指导')}}</p>
            </div>
          </div>
        </div>
        <div class="tissueModule w_1200">
          <p class="tissTit">{{$t('高校.组织文化')}}</p>
          <div class="tissBot">
            <div class="tissSub">
              <img src="@/assets/sub/coll7.png" />
              <p class="msgT">{{$t('高校.高效的业务方法')}}</p>
              <div>
                <p><span></span>{{$t('高校.业务主导')}}</p>
                <p><span></span>{{$t('高校.业务集中')}}</p>
              </div>
            </div>
            <div class="tissSub">
              <img src="@/assets/sub/coll8.png" />
              <p class="msgT">{{$t('高校.促进创意性的环境')}}</p>
              <div>
                <p><span></span>{{$t('高校.创意力量')}}</p>
                <p><span></span>{{$t('高校.创意领导力')}}</p>
              </div>
            </div>
            <div class="tissSub">
              <img src="@/assets/sub/coll9.png" />
              <p class="msgT">{{$t('高校.员工间的信赖')}}</p>
              <div>
                <p><span></span>{{$t('高校.凝聚力')}}</p>
                <p><span></span>{{$t('高校.组织参与')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="caseModule">
          <p class="tissTit" style="color: #fff">{{$t('高校.校企合作案例')}}</p>
          <div class="caseBot w_1200">
            <div class="caseSub">
              <img style="width:588px" :src="$baseUrl+schoolList[0].cooperationCasePicture" />
              <p>{{ schoolList[0].cooperationCaseTitle}}</p>
            </div>
            <div class="caseSub">
              <img style="width:282px" :src="$baseUrl+schoolList[1].cooperationCasePicture" />
              <p>{{ schoolList[1].cooperationCaseTitle}}</p>
            </div>
            <div class="caseSub">
              <img style="width:282px" :src="$baseUrl+schoolList[2].cooperationCasePicture" />
              <p>{{ schoolList[2].cooperationCaseTitle}}</p>
            </div>
            <div class="caseSub">
              <img style="width:282px" :src="$baseUrl+schoolList[3].cooperationCasePicture" />
              <p>{{ schoolList[3].cooperationCaseTitle}}</p>
            </div>
            <div class="caseSub">
              <img style="width:588px" :src="$baseUrl+schoolList[4].cooperationCasePicture" />
              <p>{{ schoolList[4].cooperationCaseTitle}}</p>
            </div>
            <div class="caseSub">
              <img style="width:282px" :src="$baseUrl+schoolList[5].cooperationCasePicture" />
              <p>{{ schoolList[5].cooperationCaseTitle}}</p>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
      <el-dialog :visible.sync="dialogFlag" top="7vh" width="700px" :show-close="false">
        <div class="dialogCss">
          <p class="loagTit">{{$t('高校.线上申请')}}</p>
          <div class="logTips">
            <img src="@/assets/sub/yl.png" />
            <span>{{$t('高校.提示')}}</span>
          </div>
          <div class="logInp">
            <div class="logSub">
              <p>{{$t('高校.联系人姓名')}}</p>
              <el-input class="inpSub" v-model="info.contactName" :placeholder="$t('高校.请输入您的真实姓名')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.手机')}}</p>
              <el-input class="inpSub" v-model="info.phone" type="number" :placeholder="$t('高校.请输入您的手机号')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.验证码')}}</p>
              <div>
                <el-input class="inpSub" v-model="info.code" type="number" :placeholder="$t('高校.请输入验证码')"></el-input>
                <!-- <p class="sub hand">{{$t('高校.获取验证码')}}</p> -->
                <span class="sub hand code" @click="getCode()" v-if="!getCodeBlo">{{$t('高校.获取验证码')}}</span>
                <span class="sub code" v-else-if="getCodeBlo && getCodeNum >= 1">{{ getCodeNum }}{{ $t('登录.秒后获取') }}</span>
                <span class="sub code" @click="getCode()" v-else-if="getCodeBlo && getCodeNum < 1">{{ $t('登录.重新获取') }}</span>
                <span class="sub code" v-else>{{ count }}s</span>
              </div>
            </div>
            <div class="logSub">
              <p>{{$t('高校.学校')}}</p>
              <el-input class="inpSub" v-model="info.school" :placeholder="$t('高校.请输入您的学校')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.年级专业')}}</p>
              <el-input class="inpSub" v-model="info.gradeMajor" :placeholder="$t('高校.请输入您的年级专业')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.社团')}}</p>
              <el-input class="inpSub" v-model="info.societies" :placeholder="$t('高校.请填写您的社团')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.微信')}}</p>
              <el-input class="inpSub" v-model="info.weChatQq" :placeholder="$t('高校.请填写您的微信')"></el-input>
            </div>
            <div class="logSub">
              <p>{{$t('高校.对于在校宣传有什么想法')}}</p>
              <el-input class="inpSub" v-model="info.schoolsContent" :placeholder="$t('高校.简单描述一下您的想法')"></el-input>
            </div>
          </div>
          <div class="logBtn">
            <p class="hand" @click="handleClose">{{$t('高校.取消')}}</p>
            <p class="sub hand" @click="submitClick">{{$t('高校.提交')}}</p>
          </div>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {collegeAdd,collegeInfo} from '@/util/http'
import {send} from '@/api/api'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            dialogFlag: false,
            getCodeNum: 5,
            getCodeBlo: false,
            count: '',
            infoList:[],//招聘理念 企业文化 工作内容
            schoolList:[],//校企合作
            titVal:1,
            titArr:[
              {id:1,title:'招聘理念',flag:true},
              {id:2,title:'企业文化',flag:false},
              {id:3,title:'工作内容',flag:false},
            ],
            info:{
              contactName:'',//联系人姓名
              phone:'',//手机
              code:'',//验证码
              school:'',//学校
              gradeMajor:'',//年级专业
              societies:'',//社团
              weChatQq:'',//微信
              schoolsContent:''//对于在校宣传有什么想法
            }
        }
    },
    created(){
      this.getInfo()
    },
    methods:{
      titClick(item){
        this.titArr.map(i=>{
          i.flag=false
          if(i.id==item.id){
            i.flag=true
          console.log(this.picture)
          this.titVal=i.id
          }
        })
        this.titArr=[...this.titArr]
      },
      getInfo(){
        collegeInfo().then(res=>{
          this.infoList = res.data.collegeBranchList
          this.schoolList=res.data.collegeCooperationCaseList
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      submitClick(){
        collegeAdd(this.info).then(res=>{
          this.$message(res.msg)
          this.dialogFlag=false;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      popClick(){//打开弹窗
        this.dialogFlag = true
        this.info={
              contactName:'',//联系人姓名
              phone:'',//手机
              code:'',//验证码
              school:'',//学校
              gradeMajor:'',//年级专业
              societies:'',//社团
              weChatQq:'',//微信
              schoolsContent:''//对于在校宣传有什么想法
        }

      },
      handleClose() {//关闭
        this.dialogFlag=false;
      },
      async getCode() {
      if (this.info.phone == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.手机号不能为空'),
          offset: 100
        });
        return
      }
      const res = await send({ phoneNumber: this.info.phone, type: '2' })//1：注册，2：登录，3：修改密码
      this.loading = true;
      try {
        setTimeout(() => {
          this.loading = false;
          this.codeVal = res.smsCode
          this.$message({
            // showClose: true,
            type: 'warning',
            message: this.$t('登录.验证码已发送'),
            offset: 100
          });
          this.getCodeNum = 60
          this.countDown()
        }, 500)
      } catch { }

    },
    //倒计时
    countDown() {
      this.getCodeBlo = true
      this.setInterval = setInterval(() => {
        if (this.getCodeNum >= 1) {
          this.getCodeNum--
        } else {
          clearInterval(this.setInterval)
        }
      }, 1000)
    },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .collModule{
    .tissTit{
      text-align: center;
      font-size: 24px;
      color: #333333;
      margin-bottom: 20px;
    }
    .collBanner{
      // width: 100%;
      width: 1920px;
      height: 650px;
      background: rgba(1,47,156,0.3);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("@/assets/sub/coll2.png");
      background-size: 100%;
      position: relative;
      .cliCss{
        position: absolute;
        bottom: 210px;
        //left: 0;
        width: 230px;
        height: 60px;
        border-radius: 100px;
        //border: solid red 2px;
      }
      img{
        width: 39px;
        height: 50px;
        margin-top: 60px;
      }
      p{
        font-size: 36px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }
      h1{
        font-weight: bold;
        font-size: 64px;
        color: #FFFFFF;
      }
    }
    .infoModule{
      margin: 60px auto;
      .infoTit{
        display: flex;
        align-items: center;
        span{
          display: inline-block;
          margin-right: 10px;
          padding:0 20px;
          line-height: 44px;
          background: #FFFFFF;
          border-radius: 4px;
          box-sizing: border-box;
        }
        .sActive{
          background: #012F9C;
          color: #FFFFFF;
        }
      }
      .infoPhoto{
        padding:0 100px;
        box-sizing: border-box;
        //margin-top: 40px;
        display: flex;
        align-items: center;
        .infoPLeft{
          width: 588px;
          height: 394px;
          border-radius: 8px;
        }
        .infoPRig{
          padding: 30px;
          box-sizing: border-box;
          width: 588px;
          height: 394px;
          line-height:38px;
          background: #FFFFFF;
          border-radius: 8px;
          margin:100px 0 0 -160px;
          overflow-y: auto;
          p{
            font-size: 24px;
            color: #FF6600;
            margin-bottom: 10px;
          }
        }
      }
    }
    .wellModule{
      width: 100%;
      height: 380px;
      background: #012F9C;
      padding: 40px 0;
      box-sizing: border-box;
      text-align: center;
      p{
        font-size: 24px;
        color: #FFFFFF;
      }
      .wellBot{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 30px;
        box-sizing: border-box;
        .wellSub{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 282px;
          height: 240px;
          background: #FFFFFF;
          border-radius: 8px;
          font-size: 16px;
          color: #333333;
          img{
            width: 144px;
            height: 144px;
            margin-bottom: 26px;
          }
          p{
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
    .tissueModule{
      margin: 60px auto;
      .tissBot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tissSub{
          width: 384px;
          height: 310px;
          background: #FFFFFF;
          border-radius: 8px;
          text-align: center;
          img{
            width: 174px;
            height: 174px;
          }
          .msgT{
            font-size: 24px;
            color: #000000;
            margin-bottom: 20px;
          }
          div{
            p{
              font-size: 16px!important;
              color: #000000;
              text-align: center;
            }
            p span{
              display: inline-block;
              width: 12px;
              height: 12px;
              background: #FF0000;
              border-radius: 50%;
              margin-right: 8px;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .caseModule{
      height: 743px;
      background: #012F9C;
      padding: 30px;
      box-sizing: border-box;
      .caseBot{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .caseSub{
          margin-bottom:20px;
          height: 300px;
          border-radius: 4px;
          display: flex;
          align-items: flex-end;
          font-size: 30px;
          color: #FFFFFF;
          position: relative;
          img{
            height: 100%;
          }
          p{
            position: absolute;
            bottom:30px;
            left: 30px;
          }
        }
      }
    }
  }
  :deep .el-dialog__header{
    padding: 0 !important;
  }
  .dialogCss{
    padding:0 40px;
    box-sizing: border-box;
    .loagTit{
      text-align: center;
      font-size:26px;
      font-weight: 600;
      color: #000;
    }
    .logTips{
      width: 500px;
      padding:7px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      color:#ff6601;
      background: #fadac5;
      border:#ff6a12 solid 1px;
      img{
        width:18px;
        height:18px;
        margin-right: 15px;
      }
    }
    .logInp{
      .logSub{
        font-size: 15px;
        color: #000;
        margin-bottom: 20px;
        .inpSub{
          margin-top: 10px;
        }
        div{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .sub{
          font-size: 14px;
          text-align: center;
          line-height:36px;
          color: #fff;
          background: #0D39A1;
          width: 170px;
          margin-left: 10px;
          border-radius: 20px;
          margin-top: 10px;
        }
      }
    }
    .logBtn{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      p{
        font-size: 18px;
        color: #000;
        line-height:36px;
        text-align: center;
        background: #dcdcdc;
        width: 115px;
        border-radius: 20px;
      }
      .sub{
        color: #fff;
        background: #0D39A1;
      }
    }
    :deep .el-input__inner{
      line-height: 35px;
      height: 35px;
      border: solid 1px #c2c2c2;
      border-radius: 0;
    }
  }
}
</style>