<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="newsModule w_1200">
        <div class="newLeft">
          <div class="divCss pageDiv" v-if="index==0" v-for="(item,index) in pageArr">
            <div>{{$t('资讯.上一篇')}}</div>
            <p class="hand hover" @click="newsClick(item)">{{ item.title }}</p>
          </div>
          <div class="divCss pageDiv" v-if="index==2" v-for="(item,index) in pageArr">
            <div>{{$t('资讯.下一篇')}}</div>
            <p class="hand hover" @click="newsClick(item)">{{ item.title }}</p>
          </div>
          <div class="divCss" style="margin-top:10px" v-if="activeArr.length>0">
            <p class="leftTit">{{$t('资讯.热门文章')}}</p>
            <ul class="newDiv">
              <li v-for="item in activeArr" @click="newsClick(item)">
                <p class="hand hover">{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
              </li>
            </ul>
          </div>
          <div class="divCss" style="margin-top:10px" v-if="likeArr.length>0">
            <p class="leftTit">{{$t('资讯.猜你喜欢')}}</p>
            <div class="wuSub hand" v-for="item in likeArr" @click="newsClick(item)">
              <img :src="$baseUrl+item.exhibitionPicture" />
              <p>{{ $i18n.locale=='zh'?item.title:item.titleEn }}</p>
            </div>
          </div>
        </div>
        <div class="newRig">
          <p class="rigTit">{{$i18n.locale == "zh"?detailObj.title:detailObj.titleEn}}</p>
          <div class="newIcon">
            <p><img src="@/assets/sub/n1.png" /><span>{{ detailObj.createTime }}</span></p>
            <p><img src="@/assets/sub/n2.png" /><span>{{ detailObj.accessNumber }}</span></p>
            <p><img src="@/assets/sub/n3.png" /><span>{{ detailObj.likeNumber }}</span></p>
            <p><img src="@/assets/sub/n4.png" /><span>{{ detailObj.collectNumber }}</span></p>
            <!-- <p><img src="@/assets/sub/n5.png" /><span>618活动大促</span></p> -->
          </div>
          <div class="newsCon">
            <div class="conDiv" v-html="$i18n.locale == 'zh'?detailObj.content:detailObj.contentEn"></div>
            <div class="openDiv">
              <p class="hand" :class="detailObj.likeNumberBoolean?'iActive':''" @click="likeClick">
                <i class="el-icon-thumb iCss"></i><span>{{ detailObj.likeNumber }}</span></p>
              <p class="hand" :class="detailObj.collectNumberBoolean?'iActive':''" @click="collectClick">
                <i class="el-icon-star-off iCss"></i><span>{{ detailObj.collectNumber }}</span></p>
              <p class="hand" @click="pathClick()" v-clipboard:copy="pageUrl"><img src="@/assets/sub/n6.png" /><span>{{ detailObj.subscriptionNumber }}</span></p>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {tidingsDetail,newsActive,likeBrandTidings,newLike,newCollect,upAndDownList} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            pageUrl:'',
            likeArr:[],//猜你喜欢
            activeArr:[],//热门文章
            id:'',
            detailObj:{},
            pageArr:[],//上下篇
        }
    },
    created(){
      this.id=this.$route.query.id
      this.detail()
      this.getActve()
      this.getLike()
      this.getPage()
    },
    beforeRouteUpdate(to, from, next) {//当路径参数变化时，更新 id 并重新获取数据
      console.log(to, from, next)
      this.id = to.query.id;
      this.detail();
      this.getActve()
      this.getLike()
      this.getPage()
      next();
    },
    methods:{
      getPage(){
        upAndDownList({id:this.id}).then(res=>{
          this.pageArr=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      likeClick(){//点赞
        newLike({productBrandTidingsId:this.id}).then(res=>{
          this.$message(res.msg)
          this.detail()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      collectClick(){//收藏
        newCollect({productBrandTidingsId:this.id}).then(res=>{
          this.$message(res.msg)
          this.detail()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      pathClick(){
        this.pageUrl = window.location.href
        console.log()
        this.$message(this.$t('签到.复制成功'))
      },
      getLike(){//猜你喜欢
        likeBrandTidings().then(res=>{
          this.likeArr=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getActve(){//热门文章
        newsActive({popular:1,pageSize:10,pageNum:1}).then(res=>{
          this.activeArr = res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      newsClick(item){//新闻详情
        if(item.id==this.$route.query.id){
          this.$message('已经是第一条了')
          return
        }
        this.$router.push({path:'/newsDetail',query:{id:item.id}});
      },
      detail(){
        tidingsDetail(this.id).then(res=>{
          this.detailObj=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .newsModule{
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    .newLeft{
      width: 277px;
      .divCss{
        background: #FFFFFF;
        padding: 15px 10px;
        box-sizing: border-box;
        font-size: 13px;
        color: #000000;
        margin-bottom: 10px;
      }
      .leftTit{
        font-size: 16px;
        color: #000000;
        padding-left: 10px;
        border-left: #0332A1 3px solid;
        margin-bottom: 15px;
      }
      .pageDiv{
        padding:0!important;
        div{
          padding:12px;
          border-bottom: #E8E8E8 solid 1px;
        }
        p{
          padding:12px;
          line-height: 16px;
        }
      }
      .newDiv{
        padding-left: 18px;
          li{
            list-style: disc;
            margin-bottom: 15px;
            color: #0234A5;
            p{
              color: #000;
            }
          }
      }
      .wuP{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        height: 27px;
        background: #C4D5FF;
        padding: 0 10px;
        box-sizing: border-box;
        span{
          font-size: 16px;
          color: #000000;
        }
      }
      .wuSub{
        box-sizing: border-box;
        display: flex;
        margin-bottom: 15px;
        //align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height:68px;
        p{
          font-size: 13px;
          line-height: 13.5px;
          color: #000000;
        }
        img{
          width: 95px;
          height: 65px;
          margin-right: 10px;
        }
      }
    }
    .newRig{
      width: 899px;
      background: #FFFFFF;
      padding: 50px;
      box-sizing: border-box;
      .rigTit{
        font-weight: bold;
        font-size: 25px;
        color: #000000;
        line-height: 35px;
        text-align: center;
      }
      .newIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 0;
        p{
          display: flex;
          align-items: center;
          margin-right: 20px;
          font-size: 14px;
          color: #AFAFAF;
          img{
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }
      }
    }
    .newsCon{
      .conDiv{
        font-size: 13px;
        color: #000000;
        line-height:25px;
        text-align: left;
      }
      .openDiv{
        display: flex;
        align-items: center;
        justify-content: left;
        margin:25px 0;
        p{
          display: flex;
          align-items: center;
          margin-right:30px;
          font-size: 21px;
          color: #AFAFAF;
          img{
            width:20px;
            height:20px;
            margin-right:8px;
          }
      }
      .iCss{
        margin-right:8px;
        color: #999;
      }
      .iActive,.iActive .iCss{
        color: #0332A1;
      }
      }
    }
  }
}
</style>