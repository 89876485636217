<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <div class="indexModule w_1200">
      <div class="catalogue">
        <span class="hand hover" @click="pageClick('/')">{{ $t('商品详情.首页') }}</span><i
          class="iCss el-icon-d-arrow-right"/>
        <span class="hand hover" @click="pageClick('/catalogueOne')">{{
            $t('商品详情.首页')
          }}商品目录</span><i class="iCss el-icon-d-arrow-right"/>
        <div v-for="item in productCategoryList">
          <span class="hand hover" @click="pageClick('/catalogueOne')">{{
              item.categoryName
            }}</span><i class="iCss el-icon-d-arrow-right"/>
        </div>
        <span class="act">{{
            $i18n.locale == 'zh' ? detailObj.productName : detailObj.productNameEn
          }}</span>
      </div>
      <div class="goodsModule">
        <div class="gModuleLeft divCss">
          <p class="goodsTit">{{
              $i18n.locale == 'zh' ? detailObj.productName : detailObj.productNameEn
            }}</p>
          <p class="msg">{{ $t('商品详情.图片仅供参考') }}</p>
          <div class="goodsBanenr">
            <div class="masterMap">
              <img :src="$baseUrl+mainImgUrl.photo" alt="">
            </div>
            <div class="goodsLeftSlideshow">
              <i class="el-icon-arrow-left" @click="imgLeft"></i>
              <ul class="Img_ul">
                <li v-for="(item, index) in detailObj.picChildren" :key="index"
                    :style="imgStyle" class="Img_li" @click="changeImg(item, index)">
                  <img :class="index === imgActiveIndex ? 'img_activeBorder' : ''" :src="$baseUrl+item.photo">
                </li>
              </ul>
              <i class="el-icon-arrow-right" @click="imgRight"></i>
            </div>
          </div>
          <div class="goodsIcon">
            <img v-clipboard:copy="pageUrl" class="hand" src="@/assets/member/fx.png"
                 @click="pathClick()"/>
            <!-- <img class="hand" src="@/assets/member/yx.png"/> -->
            <img
                :src="require(detailObj.collectBoolean>0?'@/assets/member/sc.png':'@/assets/member/favo.png')"
                class="hand"
                @click="collectClick"/>
          </div>
        </div>
        <div class="gModuleRight divCss">
          <p class="goodsTit">{{ $t('商品详情.商品信息') }}</p>
          <div class="infoModule">
            <p>{{ $t('商品详情.品牌') }}{{
                $i18n.locale == 'zh' ? detailObj.productBrandName : detailObj.productBrandNameEn
              }}</p>
            <p>{{ $t('商品详情.制造商') }}{{ detailObj.manufacturerNumber }}</p>
            <p>{{ $t('商品详情.贞光编号') }}{{ detailObj.zgNumber }}</p>
            <p>{{ $t('商品详情.产品类别') }}{{
                $i18n.locale == 'zh' ? detailObj.productCategoryName
                    : detailObj.productCategoryNameEn
              }}</p>
            <p>{{ $t('商品详情.供货地') }}{{ detailObj.supply }}</p>
            <p>{{ $t('商品详情.库存') }}{{ detailObj.inventory }}</p>
            <p>{{ $t('商品详情.无铅情况/RoHs') }}{{ detailObj.unleaded }}</p>
            <p>{{ $t('商品详情.供应商生产/周期') }}{{ detailObj.productionCycle }}</p>
            <p>{{ $t('商品详情.批次') }}{{ detailObj.batchNumber }}</p>
            <p>{{ $t('商品详情.最小包') }}{{ detailObj.minBao }}</p>
            <p>{{ $t('商品详情.交期') }}{{ detailObj.deliveryCycle }}</p>
            <p>{{ $t('商品详情.起订量') }}{{ detailObj.minNumber }}</p>
            <p>{{ $t('商品详情.倍数') }}{{ detailObj.multiple }}</p>
          </div>
          <div class="goodsEdit">
            <div class="addSub">
              <el-input-number v-model="num" :min="1" @change="handleChange"></el-input-number>
              <div>
                <p v-for="item in detailObj.priceChildren">{{
                    $t('商品详情.商品单价')
                  }}{{ item.quantity }}+{{ $t('公用.：') }}{{ $t('公用.¥') }}{{ item.unitPrice }}</p>
              </div>
            </div>
            <div class="btnDiv hand">
              <p @click="addCarClick">{{ $t('商品详情.加入购物车') }}</p>
              <p style="background:#012F9C;" @click="payClick">{{ $t('商品详情.立即购买') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="goodsModule">
        <div>
          <div class="newsDiv divCss" v-if="newList.length>0">
            <div class="disTit">
              <p class="goodsTit">{{ $t('商品详情.新品资讯') }}</p>
              <span class="hand hover" @click="pageToClick('/news')">{{ $t('商品详情.查看更多') }}<i
                  class="iCss el-icon-d-arrow-right"/></span>
            </div>
            <div v-for="(item,index) in newList" v-if="index<=2" class="newsSub hand hover" @click="newDetailClick(item)">
              <img :src="$baseUrl+item.exhibitionPicture"/>
              <div class="rigDiv">
                <p>{{ $i18n.locale == 'zh' ? item.title : item.titleEn }}</p>
                <p class="msg">{{ $i18n.locale == 'zh' ? item.describeCh : item.describeChEn }}</p>
              </div>
            </div>
          </div>
          <div class="newsDiv divCss" v-if="brandList.length>0">
            <div class="disTit">
              <p class="goodsTit">{{ $t('商品详情.品牌资源') }}</p>
              <span class="hand hover" @click="pageToClick('/brandOne')">{{ $t('商品详情.查看更多') }}<i
                  class="iCss el-icon-d-arrow-right"/></span>
            </div>
            <div v-for="(item,index) in brandList" v-if="index<=2" class="newsSub hand" @click="brandDetailClick(item)">
              <img :src="$baseUrl+item.exhibitionPicture"/>
              <div class="rigDiv">
                <p>{{ $i18n.locale == 'zh' ? item.title : item.titleEn }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="detaidDiv divCss">
          <div class="detailSele">
            <div class="seleLeft">
              <p :class="tabVal==1?'active':'hand hover'" @click="tabClick(1)">{{
                  $t('商品详情.参数问题')
                }}</p>
              <span>|</span>
              <p :class="tabVal==2?'active':'hand hover'" @click="tabClick(2)">{{
                  $t('商品详情.数据手册')
                }}</p>
              <!-- <span>|</span>
              <p :class="tabVal==3?'active':'hand hover'" @click="tabClick(3)">{{
                  $t('商品详情.常见问题')
                }}</p> -->
            </div>
            <div class="seleRight">
              <!-- <p>{{$t('商品详情.找到类似商品')}}<span>0</span></p> -->
              <div class="hand" @click="similarityClick">{{ $t('商品详情.查看相似商品') }}</div>
            </div>
          </div>
          <div v-if="tabVal==1" class="tabDiv">
            <el-table :data="detailObj.children" :stripe="true" style="width: 100%"
                      @selection-change="handleSelectionChange">
              <el-table-column :label="$t('商品详情.参数')" prop="productCategorySpecificationName">
                <template slot-scope="scope">
                  <span>{{
                      $i18n.locale == 'zh' ? scope.row.productCategorySpecificationName
                          : scope.row.productCategorySpecificationNameEn
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('商品详情.参数值')"
                               prop="productCategorySpecificationValue">
                <template slot-scope="scope">
                  <span>{{ scope.row.productCategorySpecificationValue }}</span>
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55"></el-table-column>
            </el-table>
            <div class="detailMsg">
              <img src="@/assets/member/tips.png"/>
              <span>{{ $t('商品详情.技巧') }}</span>
            </div>
          </div>
          <div v-if="tabVal==2" class="detailCon">
            <a :href="$baseUrl+detailObj.dataBook" target="_blank">
              {{ $baseUrl+detailObj.dataBook }}
              <!-- <img class="hotImg hand hoverTo" src="@/assets/images/down.png" /> -->
            </a>
          </div>
          <div v-if="tabVal==3" class="detailCon wenti">
            <p class="wen">问：平台上的商品都是正品吗？</p>
            <p>答：请您放心,我们是国内外知名品牌厂商的电子元件授权经销商，从源头保证品质。</p>
            <p class="wen">问：平台上的商品都是正品吗？</p>
            <p>答：请您放心,我们是国内外知名品牌厂商的电子元件授权经销商，从源头保证品质。</p>
            <p class="wen">问：平台上的商品都是正品吗？</p>
            <p>答：请您放心,我们是国内外知名品牌厂商的电子元件授权经销商，从源头保证品质。</p>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {myCollect, productDetail, tidingsList, userShoppingCar} from '@/util/http'
import Cookies from 'js-cookie'
import {OneUnifiedWayToPlaceYourOrderNow} from "@/api/myInfo/addCarClick";

export default {
  components: {carTopNav, bottomNav},
  data() {
    return {
      id: '',
      loading: false,
      multipleSelection: [],//选中的相似规格列表
      tabVal: 1,//1:参数问题 2数据手册 3常见问题
      detailObj: {},//商品详情
      newList: [],//新品资讯
      brandList: [],//品牌资源
      userInfo: JSON.parse(Cookies.get('userInfo')),
      num: '',
      pageUrl: '',
      mainImgUrl: {photo: ''},//主图
      imgActiveIndex: 0, // 当前移动图片的索引值
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      imgUrlList: [],//轮播图
      productCategoryList: [],//分类数组
    }
  },
  computed: {
    imgStyle() {//左侧商品轮播图移动距离
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    pathClick() {
      this.pageUrl = window.location.href
      console.log()
      this.$message(this.$t('签到.复制成功'))
    },
    tabClick(num) {//详情标题切换
      if (num == this.tabVal) {
        return
      }
      this.tabVal = num
    },
    handleSelectionChange(val) {//全选查找相似商品
      console.log(val);
      this.multipleSelection = val;
    },
    similarityClick() {
      this.$router.push({
        path: '/catalogueTwo',
        query: {
          classfyId: this.detailObj.productCategoryId
        }
      })
    },
    collectClick() {
      myCollect({productId: this.id}).then(res => {
        this.getDetail();
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getDetail() {

      productDetail(this.id).then(res => {
        this.detailObj = res.data
        this.mainImgUrl.photo = res.data.picChildren[0].photo
        this.getNewClick()
        this.getBrand()
        console.log(res.data)
        if (res.data.productCategoryAncestors !== '0') {
          this.productCategoryList = res.data.productCategoryList
        }
        this.productCategoryList.push(
            {
              id: res.data.productCategoryId,
              categoryName: res.data.productCategoryName
            }
        )
      }).catch(err => {
        if (er.msg) {
          this.$message(er.msg)
        }
      })

    },
    getNewClick() {//新品资讯
      tidingsList({sort: 10}).then(res => {
        this.newList = res.rows
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    getBrand() {//品牌资源
      tidingsList({
        brandId: this.detailObj.productBrandId,
        // sort: 1
      }).then(res => {
        this.brandList = res.rows
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    addCarClick() {//加入购物车
      userShoppingCar({
        userId: this.userInfo.userId,
        productId: this.detailObj.id,
        buyNumber: this.num
      }).then(res => {
        this.$message(res.msg)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })

    },
    async payClick() {//立即购买
      let res = await OneUnifiedWayToPlaceYourOrderNow(this.userInfo.userId, this.detailObj.id,
          this.num)
      Cookies.set('carId', res.data)
      await this.$router.push('/order')
    },
    pageClick(path) {//页面导航
      this.$router.push({path: path, query: {subIndex: 1}})
    },
    newDetailClick(item) {
      this.$router.push({ path: "/newsDetail",query: { id: item.id} });
    },
    brandDetailClick(item) {
      this.$router.push({ path: "/bazzaarNewsDetail",query: { id: item.id,brandId:item.brandId} });
    },
    pageToClick(path){
      this.$router.push(path)
    },
    handleChange(e) {//步数器
      console.log(e)
    },
    changeImg(item, index) {//左侧商品轮播图
      this.mainImgUrl = item
      this.imgActiveIndex = index
    },
    imgLeft() {//左侧商品轮播图
      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--  // 索引值-1
        this.detailObj.picChildren.forEach((item, index) => { // 循环小图,通过判断索引值赋值给大图
        console.log(item)
        if (this.imgActiveIndex === index) {
            this.mainImgUrl = item

          }
        })
      }
      if (this.imgActiveIndex >= 4) {
        var index = 0
        const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
          if (index < 33) { // 移动次数(33次)
            this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++
            return
          } else {
            window.clearInterval(temp) // 移动完清除定时器
          }
        }, 10)
      }
    },
    imgRight() {//右侧商品轮播图
      if (this.imgActiveIndex < this.detailObj.picChildren.length - 1) {
        this.imgActiveIndex++
        this.detailObj.picChildren.forEach((item, index) => {
          if (this.imgActiveIndex === index) {
            this.mainImgUrl = item
          }
        })
        if (this.imgActiveIndex >= 5) {
          this.allDistance = -66 * (this.imgActiveIndex - 4)
          var index = 0
          const temp = window.setInterval(() => {
            if (index < 33) {
              this.imgDistance -= 2 // 每次向右移动的距离
              index++
              return
            } else {
              window.clearInterval(temp)
            }
          }, 10)
        }
      } else if (this.imgActiveIndex === this.detailObj.picChildren.length - 1) { // 到达最后一张图片，再点击跳转回第一张
        this.imgActiveIndex = 0;
        this.mainImgUrl = this.detailObj.picChildren[0]
        var index = 0
        const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
          if (index < Math.abs(this.allDistance / 2)) { // 取绝对值再除
            this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++
            return
          } else {
            window.clearInterval(temp) // 移动完清除定时器
          }
        }, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .indexModule {
    margin: 60px auto;

    .catalogue {
      margin: 40px 0;
      font-size: 13px;
      color: #333333;
      display: flex;
      align-items: center;

      .iCss {
        margin: 0 10px;
      }

      .act {
        font-size: 16px;
        color: #012F9C;
      }
    }

    .goodsModule {
      display: flex;
      justify-content: space-between;

      .divCss {
        border-radius: 8px;
        padding: 20px 40px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
        box-sizing: border-box;
        margin-bottom: 25px;
      }

      .goodsTit {
        font-size: 24px;
        color: #333333;
        line-height: 28px;
      }

      .gModuleLeft {
        width: 440px;
        //height: 474px;
        .msg {
          font-size: 13px;
          color: #999999;
          text-align: right;
        }

        .goodsBanenr {
          margin: 5px 0 0px 0;
          //height: 320px;
          //border: solid 1px;
          .masterMap {
            width: 360px;
            height: 258px;
            border-radius: 4px 4px 4px 4px;
            //border: 1px solid #999999;
            img, video {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .goodsLeftSlideshow {
            //border: 1px solid blue;
            margin-top: -20px;

            .el-icon-arrow-left {
              font-size: 30px;
              color: #999;
              font-weight: 600;
              display: inline-block;
              position: relative;
              left: -8px;
              top: 40px;
              cursor: pointer;
              z-index: 9999;
            }

            .Img_ul {
              position: relative;
              //left:30px;
              display: flex;
              width: 310px;
              //border: solid 1px;
              height: 55px;
              overflow: hidden;
              list-style: none;
              margin: 0 0 0 20px;
            }

            .el-icon-arrow-right {
              font-size: 30px;
              color: #999;
              font-weight: 600;
              display: inline-block;
              position: relative;
              left: 338px;
              top: -40px;
              cursor: pointer;
            }

            .Img_li {
              float: left;
              margin: 0 6px;
              cursor: pointer;

              img {
                //border: 1px solid #fff;
                width: 50px;
                height: 50px;
                border-radius: 2px 2px 2px 2px;
                border: 1px solid #fff;
              }
            }

            .img_activeBorder {
              border:2px solid #26A7FF !important;
            }

          }

        }

        .goodsIcon {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .gModuleRight {
        width: 736px;
        height: 474px;

        .infoModule {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 20px;
          margin: 20px 0;

          p {
            font-size: 16px;
            color: #333333;
            line-height: 19px;
          }
        }

        .goodsEdit {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .addSub {
            font-size: 13px;
            color: #333333;
            display: flex;
            align-items: center;

            :deep .el-input-number {
              width: 140px;
            }

            p {
              margin-bottom: 10px;
              margin-left: 10px;
            }
          }

          .btnDiv {
            display: flex;
            align-items: center;

            p {
              width: 146px;
              height: 52px;
              font-size: 16px;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 19px;
              background: #FF6600;
              border-radius: 4px 4px 4px 4px;
              margin-left: 20px;
            }
          }
        }
      }
    }

    .disTit {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 13px;
        color: #333333;
        line-height: 15px;
        vertical-align: middle;
      }
    }

    .newsDiv {
      width: 440px;

      .newsSub:last-child {
        border-bottom: none;
      }

      .newsSub {
        display: flex;
        // justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px #999999 dotted;

        img {
          width: 80px;
          height: 80px;
          border-radius: 4px 4px 4px 4px;
          margin-right: 15px;
        }

        .rigDiv {
          //padding:5px 0;
          box-sizing: border-box;
          font-size: 16px;
          color: #333333;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          p {
            text-overflow: ellipsis;
            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 20px;
          }

          .msg {
            margin-top: 10px;
            font-size: 13px;
            color: #999999;
            line-height: 15px;
            height: 30px;
          }
        }
      }
    }

    .detaidDiv {
      width: 736px;

      .detailSele {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding-bottom: 20px;
        border-bottom: #D9D9D9 solid 1px;

        .seleLeft {
          font-size: 14px;
          color: #333333;
          display: flex;
          align-items: center;

          p {
            padding-bottom: 11px;
          }

          span {
            color: #D9D9D9;
            margin: 0 14px;
          }

          .active {
            font-weight: bold;
            font-size: 16px;
            color: #012F9C;
            line-height: 19px;
            border-bottom: 2px #FF6600 solid;
          }
        }

        .seleRight {
          display: flex;
          align-items: center;
          font-size: 11px;
          color: #333333;
          line-height: 14px;

          span {
            color: #FF6600;
          }

          div {
            margin-left: 20px;
            color: #fff;
            width: 98px;
            height: 26px;
            background: #012F9C;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .detailCon {
        margin: 20px auto;
      }

      .wenti {
        p {
          font-size: 14px;
        }

        .wen {
          font-weight: 600;
          font-size: 15px;
          margin: 15px 0;
        }
      }

      .detailMsg {
        width: 656px;
        height: 44px;
        background: #FFFDED;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FF6600;
        font-size: 13px;
        color: #666666;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
    }
  }

  .tabDiv {
    margin: 20px 0;

    :deep .el-checkbox__inner {
      border: 1px solid #999999;
      width: 18px;
      border-radius: 50%;
      height: 18px;
    }

    :deep .el-checkbox__inner::after {
      height: 8px;
      left: 6px;
      top: 2px;
    }

    :deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 7px;
    }

    :deep .el-table th.el-table__cell {
      background: #012F9C;
      color: #fff;
    }

    :deep th:last-child {
      border-radius: 0 8px 0 0;
    }

    :deep th:first-child {
      border-radius: 8px 0 0 0;
    }
  }
}
</style>
