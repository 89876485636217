<template>
    <div class="hotBg w_1200">
      <p class="gotTit">{{$t('首页.热门代理新品咨询汇总')}}</p>
      <div class="goodsTo">
        <div class="gItem hand hoverTo" v-if="index>=0&&index<=1" v-for="(item,index) in list">
          <img class="itemLeft" :src="$baseUrl + item.productPhoto" alt="" />
          <div class="itemRig">
            <p class="iTit hover">{{$i18n.locale == 'zh' ? item.productName : item.productNameEn}}</p>
            <div class="iDesc">{{item.dataBook}}</div>
            <div class="iBtn">
              <div class="hand" @click="goodsClick(item)">{{$t('首页.查看详情')}}</div>
              <div class="hand" @click="similarityClick(item)">{{$t('首页.相关商品')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="goodsThree">
        <div class="threeItem hoverTo" v-if="index>=2&&index<=5" v-for="(item,index) in list">
          <img class="threeImg" :src="$baseUrl + item.productPhoto" alt="" />
          <div class="itemRig">
            <p class="iTit hover">{{$i18n.locale == 'zh' ? item.productName : item.productNameEn}}</p>
            <div class="iDesc">{{item.dataBook}}</div>
            <div class="iBtn">
              <div class="hand" @click="goodsClick(item)">{{$t('首页.查看详情')}}</div>
              <div class="hand" @click="similarityClick(item)">{{$t('首页.相关商品')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { popularAgents } from '@/util/http'
import Cookies from 'js-cookie';
export default{
    components:{},
    data(){
        return{
          list:[]
        }
    },
    mounted(){
      this.getList()
    },
    methods:{
      getList(){
        popularAgents().then(res=>{
          this.list=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      goodsClick(item){
        console.log(item.id)
        this.$router.push({
          path: '/goods',
          query: {
            id: item.id
          }
        })
      },
      similarityClick(item){
        this.$router.push({
          path: '/catalogueTwo',
          query: {
            classfyId: item.productCategoryId
          }
        })
      }
    }
}
</script>
<style lang="scss" scoped>
  .hotBg{
    margin-bottom: 80px;
    .gotTit{
      width: 100%;
      text-align: center;
      font-size: 24px;
      margin: 40px 0;
    }
    .goodsTo{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .gItem{
        display: flex;
        align-items: center;
        // justify-content: space-between;
        box-sizing: border-box;
        padding:30px 20px;
        width: 588px;
        height: 260px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
        border-radius: 8px;
        .itemLeft{
          width: 200px;
          height: 200px;
          margin-right: 30px;
        }
      }
    }
    .goodsThree{
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 25px;
        .threeItem{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding:20px;
          box-sizing: border-box;
          width: 384px;
          height: 364px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
          border-radius: 8px;
          .threeImg{
            width: 144px;
            height: 144px;
            margin-bottom: 20px;
          }
          .itemBot{

          }
        }
    }
    .itemRig{
      width: 283px;
      font-size: 16px;
      color: #333333;
      .iTit{
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .iDesc{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp:5;
        -webkit-box-orient: vertical;
        height: 76px;
        font-size: 13px;
        color: rgba(153,153,153,0.6);
        line-height: 15px;
      }
      .iBtn{
        display: flex;
        align-items: center;
        div{
          // width: 88px;
          padding:0 5px;
          height: 32px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #012F9C;
          display:flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #012F9C;
          margin-left: 6px;
          margin-top: 10px;
        }
      }
    }
  }
</style>