
import Cookies from 'js-cookie'

export function setToken(token) {
    return Cookies.set('token', token)
}

export function getToken() {
    let token = Cookies.get('token')
    if (token == null || token == undefined || token == 'undefined' || token == 'null' || token == {}) {
        return null
    }
    return token;
}