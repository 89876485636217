<template>
    <div class="indexBg">
      <topNav></topNav>
      <div class="w_1200">
        <div class="contactDiv">
          <span v-for="item in addressArr" :key="item.id" :class="item.flage?'active':''">{{item.name}}</span>
        </div>
        <div class="msgDiv">
          <p><img src="@/assets/images/lx1.png" /><span>{{$t('联系我们.办公地址')}}{{$t('公用.：')}}{{$i18n.locale=='zh'?introduce.companyAddress:introduce.companyAddressEn}}</span></p>
          <p><img src="@/assets/images/lx2.png" /><span>{{$t('联系我们.联系电话')}}{{$t('公用.：')}}{{introduce.companyPhone}}</span></p>
          <p><img src="@/assets/images/lx3.png" /><span>{{$t('联系我们.邮箱')}}{{$t('公用.：')}}{{introduce.companyMailbox}}</span></p>
        </div>
        <div class="gd">
          <iframe frameborder="0" height="445" scrolling="no" src="ditu.html" width="100%"></iframe>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {contactUs} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
          introduce:{},
            addressArr:[
              {id:1,name:'北京(总部)',flage:true},
              {id:2,name:'深圳分公司',flage:false},
              {id:3,name:'香港办事处',flage:false},
              {id:4,name:'苏州分公司',flage:false},
              {id:5,name:'苏州分公司',flage:false},
            ]
        }
    },
    mounted(){
    this.getDetail()
    },
    methods:{
      getDetail(){//资质
        contactUs().then(res=>{
          this.introduce=res.rows[0]
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  background: #fff;
  .contactDiv{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:60px auto 30px auto;
    span{
      //width: 256px;
      padding:20px 30px;
      text-align: center;
      //line-height: 85px;
      background: #E7E7E7;
      border-radius: 9px;
      font-weight: bold;
      font-size:26px;
      color: #000000;
    }
    .active{
      background: #012F9C;
      color: #FFFFFF;
    }
  }
  .msgDiv{
    p{
      margin-top: 17px;
      display: flex;
      align-items: center;
      padding:0 15px;
      img{
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      span{
        font-size: 21px;
        color: #000000;
      }
    }
  }
  .gd{
    // border: solid 1px #999;
    // height:500px;
    margin: 17px auto 60px auto;
  }
}
</style>