<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="feedCon">
      <div class="feedTop">
        <div class="feedLeft">
          <h1>{{ $t('意见反馈.我们在意您的每一句反馈') }}</h1>
          <p>{{ $t('意见反馈.请告诉我们您对贞光商城的意见和建议') }}</p>
        </div>
        <div class="feedRigt">
          <p>{{ $t('意见反馈.我的建议') }}</p>
          <div><span>{{ feedTotal }}</span><span class="rigBtn hand" @click="goPath">{{
              $t('意见反馈.查看')
            }}</span></div>
        </div>
      </div>
      <div class="feedWt w_1200">
        <p class="wtTit"><span>*</span>{{ $t('意见反馈.您想对哪些方面提出意见和建议') }}</p>
        <div class="wtOne hand">
          <span v-for="(item,index) in titArr" :class="item.flag?'tAc':''" @click="titClick(item)">
            {{ item.name }}</span>
        </div>
        <p class="wtTit"><span>*</span>{{ $t('意见反馈.我们存在哪些不足') }}</p>
        <el-input v-model="opinion" :placeholder="$t('意见反馈.请输入')" :rows="7"
                  type="textarea"></el-input>
        <p class="wtTit"><span>*</span>{{ $t('意见反馈.如果用图片来说明问题') }}</p>
        <div class="upImg">
          <el-upload :action="uploadImgUrl" :before-upload="beforeAvatarUpload" :headers="headers"
                     :on-success="handleAvatarSuccess" :show-file-list="false"
                     class="avatar-uploader">
            <img v-if="imageUrl" :src="$baseUrl + imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="subDiv">
          <div class="subBtn hand" @click="submitClick">{{ $t('意见反馈.提交') }}</div>
          <div class="subTips">
            <img src="@/assets/images/dp.png"/>
            <p>{{ $t('意见反馈.意见被采用') }}</p>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {addFeedback, feedbackList, uploadUrl} from '@/util/http'
import Cookies from "js-cookie";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      imageUrl: '',
      feedTotal: 0,//反馈总数
      opinion: '',
      titVal: {},
      uploadImgUrl: uploadUrl,
      headers: {
        Authorization: Cookies.get('token'),
      },
      titArr: [
        {id: 1, name: this.$t('首页.网站功能'), flag: true},
        {id: 2, name: this.$t('首页.订单问题'), flag: false},
        {id: 3, name: this.$t('首页.物流问题'), flag: false},
        {id: 4, name: this.$t('首页.售后服务'), flag: false},
        {id: 5, name: this.$t('首页.其他'), flag: false},
      ]
    }
  },
  created() {
    this.feedList()
  },
  methods: {
    submitClick() {//提交
      addFeedback({
        type: this.titVal.id,
        opinion: this.opinion,
        picUrl: this.imageUrl,
      }).then(res => {
        this.$message(res.msg)
        this.feedList()
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    feedList() {//总条数
      feedbackList().then(res => {
        this.feedTotal = res.total;
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })
    },
    titClick(item) {//切换标题
      this.titArr.map(i => {
        i.flag = false
        if (i.id == item.id) {
          i.flag = true
          this.titVal = i
        }
      })
    },
    goPath() {
      this.$router.push({
        path: '/myOffer',
        query: {
          navVal: '4-7'
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.fileName;
      // URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .feedCon {
    .feedTop {
      padding: 0 240px;
      box-sizing: border-box;
      border: solid 1px #912727;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 346px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("@/assets/images/feedBg.png");

      .feedLeft {
        font-size: 25px;
        color: #FFFFFF;

        h1 {
          font-weight: 400;
          font-size: 57px;
          color: #FFFFFF;
          margin-bottom: 55px;
        }
      }

      .feedRigt {
        width: 288px;
        height: 179px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 40px;
        text-align: center;
        font-size: 23px;
        font-weight: 400;
        color: #000000;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

        p {
          padding-bottom: 28px;
          border-bottom: solid 1px #DADADA;
          margin-bottom: 28px;
        }

        div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }

        .rigBtn {
          font-size: 17px;
          width: 76px;
          display: inline-block;
          text-align: center;
          line-height: 34px;
          background: #FFFFFF;
          border: 1px solid #DADADA;
        }
      }
    }

    .feedWt {
      margin: 20px auto;

      .wtTit {
        font-size: 21px;
        color: #000000;
        margin: 15px 0;

        span {
          color: #FF0000;
        }
      }

      .wtOne {

        span {
          width: 146px;
          text-align: center;
          line-height: 50px;
          background: #FFFFFF;
          border: 1px solid #E4E4E4;
          margin-right: 29px;
          display: inline-block;
        }

        .tAc {
          background: #0332A1;
          color: #FFFFFF;
        }
      }

      :deep .avatar-uploader .el-upload {
        border: 1px solid #d9d9d9;
        //border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 104px;
        height: 104px;
        line-height: 104px;
        text-align: center;
        background: #fff;
      }

      .avatar {
        width: 104px;
        height: 104px;
        display: block;
      }

      .subDiv {
        margin-top: 30px;

        .subBtn {
          margin: auto;
          width: 146px;
          line-height: 52px;
          text-align: center;
          background: #0332A1;
          font-size: 20px;
          color: #FFFFFF;
        }

        .subTips {
          width: 1148px;
          height: 38px;
          background: #FFFDED;
          border: 1px solid #FFE7C5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          text-align: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
