<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft navVal="1-1">
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="infoTop">
            <div class="twoT">
              <p class="orderTit">{{ $t('我的优惠券.我的优惠券') }}</p>
              <div>
                <span class="editBtn hand" style="margin-right:10px;background:#F3F5F8;color:#333">{{
                  $t('我的优惠券.优惠券使用规则') }}</span>
                <span class="editBtn hand">{{ $t('我的优惠券.+ 领券中心') }}</span>
              </div>
            </div>
            <div class="detailSele">
              <div class="seleLeft">
                <p @click="tabClick(1)" :class="state == 1 ? 'active' : 'hand hover'">{{ $t('我的优惠券.所有优惠券') }}</p>
                <span>|</span>
                <p @click="tabClick(2)" :class="state == 2 ? 'active' : 'hand hover'">{{ $t('我的优惠券.未使用') }}</p>
                <span>|</span>
                <p @click="tabClick(3)" :class="state == 3 ? 'active' : 'hand hover'">{{ $t('我的优惠券.已使用') }}</p>
                <span>|</span>
                <p @click="tabClick(4)" :class="state == 4 ? 'active' : 'hand hover'">{{ $t('我的优惠券.已过期') }}</p>
              </div>
            </div>
            <div class="couMaxOut">
              <div class="maxOutSub hand hoverTo" @click="conpouClick(item)" :class="index >= 3 ? 'couActive' : ''"
                v-for="(item, index) in list">
                <p class="max">{{ $t('公用.¥') }}<span>{{ item.discount }}</span>{{ $t('我的优惠券.满') }}{{ item.fullPrice }}{{
                  $t('我的优惠券.可用')
                }}</p>
                <p class="out">{{ item.couponName }}</p>
                <p class="desc">{{ item.effectiveDate }}-{{ item.expiryDate }}</p>
                <p class="desc">{{ $t('我的优惠券.已领') }}{{ item.drawNum }}</p>
                <p @click="receiveHandle(item.id)" class="btn" v-if="index < 3">{{ $t('我的优惠券.立即领取') }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog width="620px" :title="$t('我的优惠券.邮箱绑定')" :visible.sync="emailDialog">
      <div class="dialogThreeCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png" />
          <p>{{ $t('我的优惠券.绑定邮箱') }}</p>
        </div>
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('我的优惠券.邮箱') }}</p>
            <div class="txt">
              <input type="text" :placeholder="$t('我的优惠券.请输入邮箱')" />
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的优惠券.验证码') }}</p>
            <div class="txt">
              <input type="text" :placeholder="$t('我的优惠券.短信验证码')" />
              <p>{{ $t('我的钱包.获取验证码') }}</p>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="emailDialog = false">{{ $t('我的优惠券.提交') }}</p>
        <p class="skip hand">{{ $t('我的优惠券.跳过') }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import { receiveTicket, ticketList } from "@/api/api";
import Cookies from "js-cookie";
export default {
  components: { carTopNav, bottomNav, myInfoLeft },
  data() {
    return {
      loading: false,
      emailDialog: false,//绑定邮箱
      value: '',
      state: 1,//1所有 2未使用 3已使用 4已过期
      options: [],
      list: [],
      flag: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const res = await ticketList({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.list = res.rows
    },
    conpouClick(item) {
      this.emailDialog = true;
    },
    tabClick(num) {
      this.state = num;
    },
    classifyClick(item) {
      this.classifyArr.forEach(i => {
        i.flag = false;
        if (i.id == item.id) {
          i.flag = true;
          this.classifyVal = i.id;
        }
      })
    },
    handleChange(e) {
      console.log(e)
    },
    async receiveHandle(id) {
      const res = await receiveTicket({
        id,
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      if (res.code == 200) {
        this.getData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    .infoTop {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      //margin-top: 20px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .detailSele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: #D9D9D9 solid 1px;

      .seleLeft {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;

        p {
          padding-bottom: 11px;
        }

        span {
          color: #D9D9D9;
          margin: 0 14px;
          padding-bottom: 11px;
        }

        .active {
          font-weight: bold;
          font-size: 16px;
          color: #012F9C;
          line-height: 19px;
          border-bottom: 2px #FF6600 solid;
        }
      }
    }

    .selectDiv {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .seleLeft {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            height: 36px;

          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
        }

        .ac {
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        display: flex;

        .goodsPhoto {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
      }

      .editTab {
        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          width: 106px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 7px 20px;
          box-sizing: border-box;
          font-size: 13px;
          color: #FFFFFF;
          background: #FF6600;
          border-radius: 4px 4px 4px 4px;
          margin-bottom: 10px;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px;

        /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {}

        .trTit {
          height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #FFFFFF;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          text-align: center;
          display: block
        }

        .xj p {
          text-align: center;
          display: block
        }
      }

    }

    .essayDiv {
      margin-bottom: 20px;
      width: 819px;
      height: 94px;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #D0D0D0;
      padding: 10px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .essLeft {
        flex: 1;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }

        div {
          font-size: 16px;
          color: #333333;

          span {
            font-size: 13px;
            margin: 0 30px 0 0;
            display: inline-block;
            margin-top: 14px;
          }
        }
      }

      .essRig {
        font-size: 13px;
        color: #012F9C;
        margin-left: 20px;
      }
    }
  }

  .tips {
    //width: 297px;
    //height: 44px;
    padding: 15px 20px;
    background: #FFFDED;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FF6600;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

  }

  .carAll {
    height: 70px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;

    .allLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        margin: 0 10px;
      }
    }

    .allBtn {
      display: flex;
      align-items: center;
    }

    .allRig {
      display: flex;
      align-items: center;

      .text {
        font-size: 14px;
        color: #333333;
      }

      .catTotal {
        font-weight: bold;
        font-size: 20px;
        margin: 0 15px 0 0;
      }

      .carBtn {
        width: 106px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        color: #FFFFFF;
        background: #FF6600;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  .conDiv {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    width: 260px;
    margin: 30px auto;
    text-align: center;

    img {
      width: 210px;
      height: 210px;
      margin-bottom: 20px;
    }
  }

  .couMaxOut {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    width: 610px;
    margin: auto;

    //margin-top: 40px;
    .maxOutSub {
      background-image: url("@/assets/sub/cou3.png");
      width: 282px;
      height: 207px;
      border-radius: 4px;
      position: relative;
      padding: 0 15px;
      box-sizing: border-box;

      p {
        color: #fff;
      }

      .max {
        margin-top: 28px;
        font-size: 16px;

        span {
          font-size: 40px;
        }
      }

      .out {
        font-weight: 500;
        font-size: 16px;
        margin: 15px 0;
      }

      .desc {
        font-size: 11px;
        margin-bottom: 6px;
      }

      .btn {
        font-size: 16px;
        position: absolute;
        bottom: 17px;
        left: 40%;
      }
    }

    .couActive {
      background-image: url("@/assets/sub/cou4.png");

      p {
        color: #a8a7a7;
      }
    }
  }

  .dialogThreeCss {
    padding: 0 70px;
    box-sizing: border-box;

    .couponDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #FF6600;
      line-height: 22px;
      margin-bottom: 40px;

      img {
        width: 40px;
        height: 40px;
        margin-right: 6px;
      }
    }

    .inpDiv {
      .inpSub {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .title {
          line-height: 19px;
          text-align: right;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          width: 120px;
        }

        .txt {
          display: flex;
          align-items: center;
          width: 240px;
          font-weight: 400;
          font-size: 16px;
          color: #333333;

          input {
            height: 26px;
            text-indent: 5px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #999999;
            flex: 1;
          }

          p {
            margin-left: 10px;
            font-weight: 400;
            font-size: 13px;
            color: #FFFFFF;
            width: 112px;
            height: 26px;
            background: #012F9C;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .sure {
      width: 170px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 30px auto 20px auto;
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
    }

    .skip {
      text-align: center;
      font-size: 13px;
      color: #666666;
    }
  }

  :deep .el-dialog__title {
    font-size: 24px;
    color: #333333;
    line-height: 28px;
  }
}
</style>