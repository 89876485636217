<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft>
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="vipOne">
              <div class="oneRight">
                <div class="twoT">
                  <p class="orderTit">{{$t('会员.会员等级权益')}}</p>
                </div>
                <p class="zhi">{{$t('会员.成长值')}}{{$t('公用.：')}}<span>0</span></p>
                <div class="grade">
                  <div v-for="(item,index) in 6">
                    <p>{{$t('会员.普通会员')}}</p>
                    <span v-if="(index+1)%4!=0&&index!=5"></span>
                  </div>
                </div>
              </div>
              <div class="oneLeft">
               <img src="@/assets/member/v18.png" />
                <div>{{$t('会员.普通会员')}}</div>
                <p>{{$t('会员.积分值按')}}</p>
              </div>
            </div>
            <div class="botVip">
              <div class="botLeft">
                <div class="twoT">
                  <p class="orderTit">{{$t('会员.规则说明')}}</p>
                </div>
                <div class="conText" v-for="(item,index) in 4">
                  {{index+1}}.会员等级共分6级，具体为;普通会员、青铜会员、白银会员、黄金会员、铂金会员和钻石会员;
                </div>
              </div>
              <div class="botRight">
                <div class="twoT">
                  <p class="orderTit">{{$t('会员.会员等级')}}</p>
                </div>
                <table>
                  <tr>
                    <th>{{$t('会员.会员等级')}}</th>
                    <th>{{$t('会员.升级条件')}}</th>
                    <th>{{$t('会员.会员权益')}}</th>
                  </tr>
                  <tr v-for="(item,index) in 6">
                    <td>
                      <img :src="require('@/assets/member/d'+(index+1)+'.png')" />
                      <span>{{$t('会员.普通会员')}}</span>
                    </td>
                    <td>{{$t('会员.成长值为0的客户')}}</td>
                    <td>{{$t('会员.积分值按')}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
            loading: false
        }
    },
    onload(){},
    methods:{

    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 18px 18px 18px 18px;
        img{
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
    }
    .vipOne{
      display: flex;
      justify-content: space-between;
      .oneLeft{
        text-align: center;
        width: 260px;
        height: 243px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 4px 4px 4px 4px;
        overflow: hidden;
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        line-height: 15px;
        img{
          width: 120px;
          height: 120px;
        }
        div{
          font-weight: 400;
          font-size: 20px;
          color: #FF0000;
          line-height: 23px;
          margin: 20px 0;
        }
      }
      .oneRight{
        padding: 20px;
        box-sizing: border-box;
        width: 625px;
        height: 243px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .zhi{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          span{
            font-weight: bold;
            font-size: 24px;
            color: #FF6600;
          }
        }
        .grade{
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          margin: 10px 0;
          div{
            display: flex;
            align-items: center;
            margin:10px 0;
          }
          p{
            font-weight: 400;
            font-size: 13px;
            color: #FF6600;
            line-height: 15px;
            padding: 5px 10px;
            border-radius: 15px 15px 15px 15px;
            border: 1px solid #FF6600;
          }
          span{
            display: inline-block;
            height: 2px;
            background: #E4EFFF;
            width:60px;
            flex: 1;
          }
        }
        .vipTips{
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          line-height: 15px;
        }
      }
    }
    .botVip{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .botLeft{
        width: 376px;
        height: 317px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        padding: 20px;
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        line-height: 17px;
        div{
          margin-bottom:20px;
        }
      }
      .botRight{
        width: 499px;
        height: 317px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        padding: 20px;
        table{
          text-align: center;
          width: 459px;
          border-collapse: collapse; /* 表格边框合并 ，合并后显示细线边框*/
          td, th {
            padding:6px 0;
            border: 1px solid #999999; /* 表格边框颜色 */
          }
          th{
            font-weight: 400;
            font-size: 13px;
            color: #333333;
            line-height: 15px;
          }
          td{
            font-weight: 400;
            font-size: 11px;
            color: #666666;
            line-height: 13px;
            //display: flex;
            //align-items: center;
            img{
              width: 17px;
              height: 17px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>