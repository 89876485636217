<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="orderTit">{{ $t('修改密码.修改密码') }}</div>
          <div class="stepDiv">
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('修改密码.身份认证')"></el-step>
              <el-step :title="$t('修改密码.设置操作')"></el-step>
              <el-step :title="$t('修改密码.完成')"></el-step>
            </el-steps>
          </div>
          <div class="detailSele">
            <div class="seleLeft">
              <p @click="tabClick(1)" :class="state == 1 ? 'active' : 'hand hover'">{{ $t('修改密码.手机号验证') }}</p>
              <span></span>
              <p @click="tabClick(2)" :class="state == 2 ? 'active' : 'hand hover'">{{ $t('修改密码.邮箱验证码') }}</p>
            </div>
          </div>
          <div class="setDiv" v-if="state == 1 && active == 0">
            <div class="inpModule">
              <div class="infoSub">
                <p>{{ $t('修改密码.注册手机号') }}{{ $t('公用.：') }}</p>
                <div>
                  <input v-model="username" />
                </div>
              </div>
              <div class="infoSub">
                <p>{{ $t('修改密码.输入验证码') }}{{ $t('公用.：') }}</p>
                <div>
                  <input v-model="code" />
                  <span @click="getCode" class="codeBtn hand">{{ $t('修改密码.获取验证码') }}</span>
                </div>
              </div>
            </div>
            <div class="btnDiv hand" @click="nextHandle">{{ $t('修改密码.下一步') }}</div>
          </div>
          <div class="setDiv" v-if="state == 1 && active == 1">
            <div class="inpModule">
              <!-- <div class="infoSub">
                <p>{{ $t('修改密码.当前密码') }}{{ $t('公用.：') }}</p>
                <div>
                  <input />
                </div>
              </div> -->
              <div class="infoSub">
                <p>{{ $t('修改密码.新密码') }}{{ $t('公用.：') }}</p>
                <div>
                  <input v-model="password" />
                </div>
              </div>
              <div class="infoSub">
                <p>{{ $t('修改密码.确认新密码') }}{{ $t('公用.：') }}</p>
                <div>
                  <input v-model="password2" />
                </div>
              </div>
            </div>
            <div @click="savePwdHandle" class="btnDiv hand">{{ $t('修改密码.确认修改') }}</div>
            <div @click="active = active - 1" style="margin-top: -60px;" class="btnDiv hand">{{ $t('修改密码.上一步') }}</div>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-alert title="错误提示的文案" type="error">
    </el-alert>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import { editPassword, send } from "@/api/api";
export default {
  components: { carTopNav, bottomNav, myInfoLeft },
  data() {
    return {
      loading: false,
      state: 1,
      active: 0,
      username: '',
      password: '',
      password2: '',
      code: '',
    }
  },
  onload() { },
  methods: {
    tabClick(num) {
      this.state = num;
    },
    async savePwdHandle() {
      if (!this.password) {
        this.$message({
          type: 'error',
          message: `密码不能为空`,
        });
        return
      }
      if (this.password != this.password2) {
        this.$message({
          type: 'error',
          message: `两次密码不一致`,
        });
        return
      }
      const res = await editPassword({
        username: this.username,
        password: this.password,
        code: this.code
      })
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '修改成功',
          offset: 100
        });
      }
    },
    nextHandle() {
      if (this.username && this.code) {
        this.active = this.active + 1;
      } else if (!this.username) {
        this.$message({
          type: 'error',
          message: `手机号不能为空`,
        });
        return
      } else if (!this.code) {
        this.$message({
          type: 'error',
          message: `验证码不能为空`,
        });
        return
      }
    },
    async getCode() {
      if (!this.username) {
        this.$message({
          type: 'error',
          message: `手机号不能为空`,
        });
        return
      }
      const res = await send({
        phoneNumber: this.username,
        type: 3
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .detailSele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: #D9D9D9 solid 1px;

      .seleLeft {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;

        p {
          padding-bottom: 11px;
        }

        span {
          color: #D9D9D9;
          margin: 0 14px;
          padding-bottom: 11px;
        }

        .active {
          font-weight: bold;
          font-size: 16px;
          color: #012F9C;
          line-height: 19px;
          border-bottom: 2px #FF6600 solid;
        }
      }
    }

    .stepDiv {
      width: 400px;
      margin: 40px auto;

      :deep .el-step.is-horizontal .el-step__line {
        //横线
        top: 16px;
        height: 8px;
      }

      :deep .el-step__icon.is-text {
        //未选中圆圈
        background: #D9D9D9;
        font-size: 13px;
        color: #999;
        width: 40px;
        height: 40px;
        border: #e8eefc 4px solid;
      }

      :deep .el-step__title {
        //未选中标题
        font-size: 13px;
        color: #999999;
      }

      :deep .el-step__title.is-process {
        //选中标题
        font-weight: 400;
        font-size: 13px;
        color: #333333;
      }

      :deep .el-step__head.is-process .is-text {
        //选中圆圈
        border: #BED1FF 4px solid !important;
        background: #012F9C !important;
        color: #fff;
      }

      :deep .el-step__head.is-success {
        border-color: #D9D9D9;
      }

      :deep .el-step__line {
        background: #D9D9D9;
      }
    }
  }

  .inpModule {
    .infoSub {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      p {
        text-align: right;
        width: 101px;
      }

      div {
        width: 344px;
        display: flex;
        align-items: center;
      }

      input {
        flex: 1;
        height: 40px;
        background: #F3F5F8;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #D8D8D8;
      }

      .codeBtn {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        width: 114px;
        height: 40px;
        background: #FF6600;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #D8D8D8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }

  .btnDiv {
    width: 344px;
    height: 40px;
    background: #012F9C;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    margin-left: 100px;
    color: #FFFFFF;
    line-height: 19px;
  }
}
</style>