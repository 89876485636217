<template>
    <div class="indexBg">
      <topNav></topNav>
      <div class="honorDiv">
        <div class="honorTop">
          <h1>{{$t('资质.资质证书')}}</h1>
          <div class="zsArr">
            <img v-for="item in introduce" :src="$baseUrl+item.honorPic" :alt="$i18n.locale=='zh'?item.honorName:item.honorNameEn" />
          </div>
        </div>
        <div class="w_1200">
          <p class="abTit">{{$t('资质.企业介绍')}}</p>
          <div class="teamModul">
            <div class="teamOne">
              <img style="width:752px" :src="$baseUrl+superiority[0].teamPic" :alt="$i18n.locale=='zh'?superiority[0].teamIntroduce:superiority[0].teamIntroduceEn" />
              <img :src="$baseUrl+superiority[1].teamPic" :alt="$i18n.locale=='zh'?superiority[1].teamIntroduce:superiority[1].teamIntroduceEn" />
            </div>
            <div class="teamTwo">
              <img :src="$baseUrl+superiority[2].teamPic" :alt="$i18n.locale=='zh'?superiority[2].teamIntroduce:superiority[2].teamIntroduceEn" />
            </div>
            <div class="teamThree">
              <img :src="$baseUrl+superiority[3].teamPic" :alt="$i18n.locale=='zh'?superiority[3].teamIntroduce:superiority[3].teamIntroduceEn" />
              <img :src="$baseUrl+superiority[4].teamPic" :alt="$i18n.locale=='zh'?superiority[4].teamIntroduce:superiority[4].teamIntroduceEn" />
              <img :src="$baseUrl+superiority[5].teamPic" :alt="$i18n.locale=='zh'?superiority[5].teamIntroduce:superiority[5].teamIntroduceEn" />
            </div>
          </div>
          <p class="abTit">{{$t('资质.我们的优势')}}</p>
          <div class="supeModule">
            <div class="supeTop">
              <div  class="stopSub" v-for="(item,index) in affair" v-if="index<=2">
                <p class="clasP"><span>{{item.advantageNumber}}</span><span>{{$i18n.locale=='zh'?item.advantageUnit:item.advantageUnitEn}}</span></p>
                <p>{{$i18n.locale=='zh'?item.advantageContent:item.advantageContentEn}}</p>
              </div>
            </div>
            <div class="supeTop">
              <div  class="stopSub" v-for="(item,index) in affair" v-if="index<=5&&index>=3">
                <p class="clasP"><span>{{item.advantageNumber}}</span><span>{{$i18n.locale=='zh'?item.advantageUnit:item.advantageUnitEn}}</span></p>
                <p>{{$i18n.locale=='zh'?item.advantageContent:item.advantageContentEn}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {honorMien,honorTeam,honorAdvantage} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            introduce:[],//介绍
            superiority:[],//优势
            affair:[]//事件
        }
    },
    mounted() {
    this.getDetail()
    this.getDetailTwo()
    this.getDetailThree()
  },
    methods:{
      getDetail(){//资质
        honorMien().then(res=>{
          this.introduce=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      getDetailTwo(){//团队 企业介绍
        honorTeam().then(res=>{
          this.superiority=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
      getDetailThree(){//优势
        honorAdvantage().then(res=>{
          this.affair=res.rows
        }).catch(err=>{
          this.$message(err.msg);
        })
      },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .honorDiv{
    .honorTop{
      margin-bottom: 40px;
      height:500px;
      background-image: url("@/assets/images/honor.png");
      background-size: 110% 100%;
      background-repeat: no-repeat;
      padding:50px 182px;
      box-sizing: border-box;
    
      h1{
        font-weight: bold;
        font-size: 48px;
        color: #FFFFFF;
        padding-left: 24px;
        border-left:4px solid #fff;
      }
      .zsArr{
        margin-top: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          width: 361px;
          height: 227px;
        }
      }
    }
    .abTit{
      text-align: center;
      font-weight: bold;
      font-size: 33px;
      color: #000000;
    }
    .teamModul{
      margin: 40px 0;
      width:1140px;
      .teamOne{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          width:364px;
          height:321px;
        }
      }
      .teamTwo{
        margin: 40px 0;
        img{
          width:1140px;
          height:313px;
        }
      }
      .teamThree{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          width: 364px;
          height: 321px;
        }
      }
    }
    .supeModule{
      width: 800px;
      margin: 0 auto 40px auto;
      .supeTop{
        margin-top:40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        p{
          font-weight: 400;
          font-size: 21px;
          color: #000000;
          margin-top:10px;
        }
        .clasP{
          display: flex;
          align-items: self-start;
          color: #012F9C;
        }
        .clasP span:first-child{
          font-weight: bold;
          font-size: 42px;
        }
        .clasP span:last-child{
          font-weight: 400;
          font-size: 13px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>