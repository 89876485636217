<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="linkTop">
            <div class="QRcode divCss">
              <p class="orderTit">{{$t('专属链接.专属二维码')}}</p>
              <div class="qrDiv">
                <div class="qrSub">
                  <p>{{$t('专属链接.我的专属二维码')}}</p>
<!--                  <img src="@/assets/member/qe.png"/>-->
                  <div class="qr-code" ref="qrCodeUrl"></div>
                </div>
              </div>
              <div class="tips">
                <div class="step">
                  <div class="num">1</div>
                  <p>{{$t('专属链接.分享给好友')}}</p>
                </div>
                <i class="el-icon-more" style="color:#FFAD76;font-size:22px;margin-top:5px;"/>
                <div class="step">
                  <div class="num">2</div>
                  <p>{{$t('专属链接.好友扫一扫识别二维码')}}</p>
                </div>
                <i class="el-icon-more" style="color:#FFAD76;font-size:22px;margin-top:5px;"/>
                <div class="step">
                  <div class="num">3</div>
                  <p>{{$t('专属链接.积分到账')}}</p>
                </div>
              </div>
            </div>
            <div class="linkModule">
              <div class="lMTop divCss">
                <p class="orderTit">{{$t('专属链接.专属链接')}}</p>
                <p class="lTtips">{{$t('专属链接.向好友发送专属邀请链接')}}</p>
                <div class="copyLink">
                  <p class="linkV">http://192.168.3.66:8081/#/login?superiorUserId={{userInfo.userId}}</p>
                  <p class="copyBtn hand" @click="pathClick" v-clipboard:copy="$baseUrl+'/#/login?superiorUserId='+userInfo.userId">{{$t('专属链接.点击分享链接')}}</p>
                </div>
              </div>
              <div class="lMBot divCss">
                <img src="@/assets/member/ink.png"/>
                <div>{{$t('专属链接.商城即日起')}}</div>
              </div>
            </div>
          </div>
          <div class="linkBot divCss">
            <div class="tab">
              <table>
                <tr class="trTit">
                  <th>{{$t('专属链接.受邀账号')}}</th>
                  <th>{{$t('专属链接.邀请奖励')}}</th>
                  <th>{{$t('专属链接.首单下单时间')}}</th>
                  <th>{{$t('专属链接.首单金额')}}</th>
                  <th>{{$t('专属链接.注册时间')}}</th>
                  <th>{{$t('专属链接.首单发货状态')}}</th>
                </tr>
                <tr class="trCon" v-for="(item,index) in tableData">
                  <td>
                    <div class="descCss" style="width:128px">
                      <p class="allBtn" style="margin-right:10px">232372386372863</p>
                    </div>
                  </td>
                  <td class="dj">
                    <p style="width:90px">XXXXXXXXXX</p>
                  </td>
                  <td class="xj"><p style="width:140px">2024-03-15 16:26:01</p></td>
                  <td class="xj"><p style="width:100px">{{$t('公用.¥')}}879.08</p></td>
                  <td class="xj"><p style="width:140px">2024-03-15 16:26:01</p></td>
                  <td class="xj"><p style="width:100px">已完成</p></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import Cookies from "js-cookie";

import QRCode from 'qrcodejs2';
export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      tableData: [],
      userInfo:{}
    }
  },
  mounted() {
    this.creatQrCode(); // 创建二维码
    this.load()
  },
  methods: {
    /* 创建二维码 */
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: 'http://192.168.3.66:8080/#/login?superiorUserId='+this.userInfo.userId, // 二维码的内容
        width: 180,
        height: 180,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    load(){
      this.userInfo = JSON.parse(Cookies.get('userInfo'))
    },
    pathClick(){
      this.$message(this.$t('签到.复制成功'))
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .linkTop {
      display: flex;
      justify-content: space-between;

      .QRcode {
        width: 322px;
        height: 434px;
        box-sizing: border-box;
        text-align: center;
        padding: 20px 10px;
        position: relative;

        .qrDiv {
          background-image: url("@/assets/member/ld.png");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: bottom;
          box-sizing: border-box;
          padding: 15px;
          width: 300px;
          height: 350px;

          .qrSub {
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            font-size: 16px;
            box-shadow: 0 0 4px #e1e1e1;
            color: #000000;
            line-height: 19px;
            padding: 20px;
            box-sizing: border-box;

            .qr-code{
            margin-left: 20px;
            }
            img {
              width: 180px;
              height: 180px;
            }
          }
        }

        .tips {
          position: absolute;
          bottom: 18px;
          background-image: url("@/assets/member/hd.png");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: bottom;
          width: 301px;
          height: 113px;
          padding: 28px;
          box-sizing: border-box;
          display: flex;
          //align-items: center;
          justify-content: space-between;

          .step {
            font-size: 12px;
            color: #333333;
            width: 60px;
            txt-align: center;

            div {
              margin: auto;
              margin-bottom: 8px;
              width: 28px;
              height: 28px;
              background: #FFAD76;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
          }
        }
      }

      .linkModule {
        width: 553px;
        .lMTop{
          height: 241px;
          .lTtips{
            font-size: 13px;
            color: #FF6600;
            margin: 40px 0 20px 0;
          }
          .copyLink{
            display: flex;
            align-items: center;
            p{
              height: 53px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .linkV{
              width: 379px;
              background: #F9F9F9;
              border-radius: 7px 0px 0px 7px;
              border: 1px solid #F3F3F3;
              font-size: 11px;
            }
            .copyBtn{
              width: 96px;
              background: #012F9C;
              border-radius: 0px 7px 7px 0px;
              color: #fff;
              font-size: 13px;
            }
          }
        }
        .lMBot{
          height: 169px;
          padding: 30px;
          font-size: 16px;
          color: #FF6600;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img{
            width: 106px;
            height: 104px;
          }
          div{
            width: 356px;
            line-height: 20px;
          }
        }
      }
    }

    .linkBot {
      .tab{
        font-size: 13px;
        color: #333333;
        line-height: 22px;
        p{
          margin-bottom:8px;
          display: flex;
          align-items: center;
          input{
            width:80px;
          }
        }
        .descCss{
          display: flex;
          .goodsPhoto{
            width: 80px;
            height: 80px;
            margin-right:10px;
            border-radius: 4px;
          }
        }
        .numEd{
          display: flex;
          align-items: center;
        }
        td{
          //border: solid 1px;
          word-break: break-all;
        }
        .editTab{
          display: flex;
          align-items: center;
          img{
            width: 30px;
            height: 30px;
            margin:0 5px;
          }
          p{
            font-size: 13px;
            color: #012F9C;
            line-height: 22px;
            margin-right: 16px;
          }
          .activeP{
            color: #FF0000;
          }
        }
        table{
          width: 100%;
          border-collapse: separate; /* 保持单元格分开 */
          //border-collapse: collapse; /* 保持单元格分开 */
          border-spacing: 0 20px; /* 行之间的距离 */
          td{
            padding:20px 0;
            box-sizing: border-box;
            //vertical-align: top;
            //border: solid 1px #D0D0D0;
            background: #FFFFFF;
          }
          td:first-child{
            padding-left:20px;
          }
          td:last-child{
          }
          .trTit{
            line-height: 48px;
            height: 48px;
            background: #012F9C;
            font-size: 16px;
            color: #FFFFFF;
            th:nth-child(1){
              border-radius: 8px 0px 0px 0px;
            }
            th:last-child{
              border-radius: 0px 8px 0px 0px;
            }
          }
          .trCon{
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #D0D0D0;
            padding: 20px;
            box-sizing: border-box;
          }
          .dj p{
            //color:#FF6600;
            text-align: center;
            display: block
          }
          .xj p{
            text-align: center;
            display: block
          }
        }

      }
    }
  }
}
</style>
