export default {
  "公用": {
    "¥": "¥",
    "元": "RMB",
    "至": "to",
    "开始日期": "Start Date",
    "结束日期": "End Date",
    "：": ":",
    "没有更多了": "No more"
  },
  "登录": {
    "微信扫码登录": "WeChat Scan Login",
    "账号登录": "Account Login",
    "短信登录": "SMS Login",
    "请输入手机号": "Please enter your phone number",
    "请输入登录密码": "Please enter your login password",
    "请输入短信验证码": "Please enter the SMS verification code",
    "发送验证码": "Send Verification Code",
    "秒后获取": "Get after seconds",
    "重新获取": "Retrieve again",
    "记录登录状态": "Remember login status",
    "忘记密码": "Forgot password",
    "微登录信扫码登录": "Login",
    "立即注册": "Register Now",
    "未注册的手机号验证后自动注册": "The unregistered phone number will be automatically registered after verification!",
    "账号密码登录": "Account and Password Login",
    "登录": "Login",
    "手机号不能为空": "Phone number cannot be empty",
    "密码不能为空": "Password cannot be empty",
    "验证码不能为空": "Verification code cannot be empty",
    "验证码已发送": "Verification code has been sent, please check",
    "已有账号": "Already have an account, login now",
    "请输入注册手机号": "Please enter the registration phone number",
    "请输入用户密码": "Please enter user password",
    "提交": "Submit",
    "用户注册协议": "User Registration Agreement",
    "隐私保护协议": "Privacy Protection Agreement",
    "密码有误": "Incorrect password",
    "请设置用户密码": "Please set a user password",
    "请确认用户密码": "Please confirm user password",
    "邀请码": "Invitation code (optional)",
    "我已阅读并同意": "I have read and agree",
    "注册成功": "Registration Successful"
  },
  "首页": {
    "登录": "Login",
    "点击展开":"Click to expand",
    "样品申请流程":"Sample application process",
    "退出登录": "Logout",
    "注册": "Register",
    "样品查询": "Sample Inquiry",
    "签到送积分": "Sign In for Points",
    "我的订单": "My Orders",
    "消息": "Messages",
    "会员中心": "Member Center",
    "语言": "English",
    "加入收藏": "Add to Favorites",
    "手机贞光": "Phone Zhenguang",
    "请输入内容": "Please enter content",
    "首页": "Home",
    "商品目录": "Product Catalog",
    "品牌专区": "Brand Zone",
    "BOM配单": "BOM Matching",
    "授权代理": "Authorized Agents",
    "供应商合作": "Supplier Cooperation",
    "我的购物车": "My Cart",
    "在线销售": "Online Sales",
    "购物车": "Shopping Cart",
    "我的收藏": "My Favorites",
    "浏览历史": "Browsing History",
    "意见反馈": "Feedback",
    "活动咨询": "Event Consultation",
    "查看全部分类": "View All Categories",
    "电阻": "Resistor",
    "查看全电容部分类": "View All Capacitor Categories",
    "值得信赖的代理商伙伴共前行": "Trusted partners moving forward together",
    "电感器变压器": "Inductors/Transformers",
    "滤波器": "Filters",
    "二极管": "Diodes",
    "晶体管": "Transistors",
    "集成电路": "Integrated Circuits",
    "处理器": "Processors",
    "晶振": "Crystal Oscillators",
    "连接器": "Connectors",
    "电路保护": "Circuit Protection",
    "传感器": "Sensors",
    "光电子产品": "Optoelectronic Products",
    "开关继电器": "Switches/Relays",
    "开发板套件": "Development Boards/Kits",
    "电源": "Power Supplies",
    "无线射频": "Wireless RF",
    "模块": "Modules",
    "机电产品": "Electromechanical Products",
    "工具仪器仪器": "Tools/Instruments/Instruments",
    "免费样品申请": "Free Sample Request",
    "最新活动": "Latest Events",
    "高校计划": "University Program",
    "新人大礼包": "Newbie Gift Package",
    "积分商城": "Points Mall",
    "品牌资源": "Brand Resources",
    "领券中心": "Coupon Center",
    "代理品牌商品精选推荐": "Recommended Products from Agents",
    "热门代理新品咨询汇总": "Popular New Agent Products Consultation",
    "产品名称": "Product Name",
    "查看更多": "See More",
    "服务时间": "Service Hours",
    "媒体合作": "Media Cooperation",
    "关注贞光公众号": "Follow Zhenguang WeChat Official Account",
    "字母索引": "Alphabet Index",
    "新手上路": "New User Guide",
    "免责声明": "Disclaimer",
    "销售条款": "Sales Terms",
    "联系我们": "Contact Us",
    "贞光科技有限公司": "Zhenguang Technology Co., Ltd.",
    "公网安备": "Public Network Security No.",
    "号": "No.",
    "备": "Backup",
    "原厂授权": "Authorized by Manufacturer, Genuine Guarantee",
    "自营现货": "Self-operated Stock, Fast Shipping",
    "品类齐全": "Complete Categories, One-stop Shopping",
    "满99包邮": "Free Shipping on Orders over 99",
    "关于贞光": "About Zhenguang",
    "关于我们": "About Us",
    "新手入门": "Beginner's Guide",
    "注册登录": "Register/Login",
    "找回密码": "Recover Password",
    "收藏功能": "Favorites Function",
    "购物指南": "Shopping Guide",
    "购物流程": "Shopping Process",
    "搜索商品": "Search Products",
    "订单管理": "Order Management",
    "合同下载": "Contract Download",
    "配送支付": "Shipping and Payment",
    "支付方式": "Payment Methods",
    "账户信息": "Account Information",
    "支付安全": "Payment Security",
    "运费说明": "Shipping Costs",
    "售后服务": "After-sales Service",
    "发票须知": "Invoice Information",
    "重新交付": "Reshipment",
    "查看详情": "View Details",
    "相关商品": "Related Products",
    "简单易用的在线换算工具专区": "Easy-to-use Online Conversion Tools Section",
    "电容换算器": "Capacitance Converter",
    "电感换算器": "Inductance Converter",
    "长度换算器": "Length Converter",
    "压力换算器": "Pressure Converter",
    "温度换算器": "Temperature Converter",
    "体积换算器": "Volume Converter",
    "重量换算器": "Weight Converter",
    "特色产品行业独特解决方案一览": "Special Products Industry Unique Solutions Overview",
    "IOT物联网": "IOT (Internet of Things)",
    "车载": "Automotive",
    "人工智能": "Artificial Intelligence",
    "工业应用": "Industrial Applications",
    "消费电子": "Consumer Electronics",
    "医疗产品": "Medical Products",
    "丰富多元新闻咨询等你来": "Rich and Diverse News Consultation Waiting for You",
    "新闻动态": "News",
    "原厂动态": "Manufacturer News",
    "型号": "Model",
    "自定义料号": "Custom Part Number",
    "单价": "Unit Price (Tax Included)",
    "数量": "Quantity",
    "合计": "Total",
    "进入我的收藏": "Go to My Favorites",
    "我的足迹": "My Footprints",
    "清空浏览历史": "Clear Browsing History",
    "产品型号": "Product Model",
    "品牌": "Brand",
    "分类": "Category",
    "描述": "Description",
    "最大申请数量": "Maximum Application Quantity",
    "操作": "Action",
    "荣誉风采": "Honors and Glory",
    "加入样品篮": "Add to Sample Basket",
    "样品筛选": "Sample Filter",
    "我的样品篮": "My Sample Basket",
    "选择样品": "Select Samples",
    "提交申请信息": "Submit Application Information",
    "平台审核": "Platform Review",
    "等待收货": "Waiting for Delivery",
    "收货成功": "Successful Delivery",
    "样品申请规则": "Sample Request Rules",
    "每位会员每月最多可申请1次": "Each member can apply up to once per month",
    "每次最多限5种型号且总金额有限制": "Each time limited to 5 models and total amount restriction",
    "邀请好友注册后": "After inviting friends to register, can increase 1 more time per month",
    "样品订单通过审核后": "Sample orders will be shipped within 7 working days after approval",
    "若有刷单": "If there is fraudulent order, the sample request will be canceled",
    "我的钱包": "My Wallet",
    "优惠券规则": "Coupon Rules",
    "协议条款": "Agreement Terms",
    "隐私协议": "Privacy Agreement",
    "注册协议": "Registration Agreement",
    "订单提交协议": "Order Submission Agreement",
    "网站功能": "Website Features",
    "订单问题": "Order Issues",
    "物流问题": "Logistics Issues",
    "其他": "Other",
    "移除": "Remove",
    "提交申请": "Submit Application",
    "请问需要什么帮助": "What assistance do you need?",
    "帮助中心": "Help Center",
    "请输入问题关键词": "Please enter keywords such as 'Order Inquiry'",
    "样品申请信息填写": "Sample Request Information Form",
    "提示": "Tip: To facilitate approval, please fill in accurate information",
    "公司名称": "Company Name:",
    "职务": "Job Title:",
    "行业类型": "Industry Type:",
    "邮箱": "Email:",
    "项目描述": "Project Description:",
    "姓名": "Name:",
    "联系电话": "Contact Number:",
    "收货地区": "Delivery Area:",
    "收货地址": "Delivery Address:",
    "取消": "Cancel",
    "会员首页": "Member Homepage",
    "账号设置": "Account Settings",
    "我的优惠券": "My Coupons",
  },
  "最新活动": {
    "正在进行": "In Progress",
    "往期活动": "Past Events",
    "正在进行中的热门活动": "Popular Events in Progress",
    "活动时间": "Event Time",
    "正在进行中所有活动": "All Events in Progress"
  },
  "意见反馈": {
    "我们在意您的每一句反馈": "We value every piece of your feedback!",
    "请告诉我们您对贞光商城的意见和建议": "Please tell us your opinions and suggestions about Zhenguang Mall. We will refer to your feedback to continuously improve our products and services.",
    "我的建议": "My Suggestions (Items)",
    "查看": "View",
    "您想对哪些方面提出意见和建议": "1. What aspects would you like to provide feedback on?",
    "我们存在哪些不足": "2. What are the areas where we are lacking?",
    "如果用图片来说明问题": "3. If you want to explain the issue with an image, please upload (up to 3 images)",
    "请输请输入入": "Please enter",
    "提交": "Submit",
    "意见被采用": "Your suggestion has been adopted. We will offer certain rewards (such as resistor or capacitor sample books, development tools, electronic module mall coupons, or points, etc.)."
  },
  "关于我们": {
    "关于贞光": "About Zhenguang",
    "荣誉风采": "Honors and Achievements",
    "授权代理": "Authorized Agent",
    "加入我们": "Join Us",
    "联系我们": "Contact Us",
    "贞光大事件": "Zhenguang Major Events",
    "优势模块": "Key Modules",
    "企业介绍": "Company Introduction"
  },
  "资质": {
    "资质证书": "Qualification Certificates",
    "企业介绍": "Company Introduction",
    "我们的优势": "Our Advantages",
    "万条": "10,000 Items",
    "余年": "Years of Experience",
    "行业经验": "Industry Experience",
    "服务客户数": "Number of Customers Served",
    "品牌代理": "Brand Representation",
    "智能仓储": "Smart Warehousing",
    "现货SKU": "Stocked SKUs"
  },
  "授权代理": {
    "代理品牌": "Agent Brands",
    "合作伙伴": "Partners",
    "请输入类别关键字": "Please enter category keywords",
    "搜索": "Search",
    "授权代理": "Authorized Agent",
    "应用领域": "Application Fields:"
  },
  "联系我们": {
    "办公地址": "Office Address",
    "联系电话": "Contact Number",
    "邮箱": "Email"
  },
  "签到": {
    "积分": "Points",
    "赚积分": "Earn Points",
    "今日签到": "Check-in Today",
    "您已连签到": "You have checked in continuously",
    "复制成功": "Copied Successfully",
    "天": "Days",
    "我的礼券": "My Coupons",
    "活动说明": "Event Description",
    "公告": "Announcement",
    "完成任务可获得丰厚积分": "Complete tasks to earn generous points",
    "恭喜": "Congratulations",
    "签到领": "Check-in to Get",
    "积分!": "Points!",
    "奖励": "Reward",
    "每日签到": "Daily Check-in",
    "关注公众号": "Follow WeChat Official Account",
    "邀请注册": "Invite to Register",
    "上传数据手册": "Upload Data Manual",
    "完善个人信息": "Complete Personal Information",
    "支付订单": "Pay Order",
    "已签到": "Checked In",
    "未关注": "Not Followed",
    "未邀请": "Not Invited",
    "未上传": "Not Uploaded",
    "未完善": "Not Completed",
    "无上限": "No Limit"
  },
  "高校": {
    "薪酬福利": "Compensation and Benefits",
    "锻炼提升职称能力": "Enhance Professional Title and Skills",
    "扩展积累人脉": "Expand and Accumulate Connections",
    "专业FAE技术指导": "Professional FAE Technical Guidance",
    "组织文化": "Organizational Culture",
    "高效的业务方法": "Efficient Business Methods",
    "业务主导": "Business Driven/Role Satisfaction/Energy",
    "业务集中": "Business Centralization/Business Environment/Business Improvement",
    "促进创意性的环境": "Fostering a Creative Environment",
    "创意力量": "Creative Power/Team Collaboration Effect/Results Achieved",
    "创意领导力": "Creative Leadership/Creative Atmosphere/Creative Environment",
    "员工间的信赖": "Trust Among Employees",
    "凝聚力": "Cohesion/Team Pride/Mutual Trust",
    "组织参与": "Organizational Participation/Organizational Attention/Company Trust",
    "校企合作案例": "School-Enterprise Cooperation Case",
    "线上申请": "Online Application",
    "提示": "Tip: For smoother approval, please provide accurate information",
    "联系人姓名": "Contact Person's Name",
    "手机": "Phone",
    "验证码": "Verification Code",
    "获取验证码": "Get Verification Code",
    "学校": "School",
    "年级专业": "Grade and Major",
    "社团": "Club",
    "微信": "WeChat/QQ",
    "对于在校宣传有什么想法": "What are your thoughts on campus promotion?",
    "取消": "Cancel",
    "提交": "Submit",
    "请输入您的真实姓名": "Please enter your real name",
    "请输入您的手机号": "Please enter your phone number",
    "请输入验证码": "Please enter the verification code",
    "请输入您的学校": "Please enter your school",
    "请输入您的年级专业": "Please enter your grade and major",
    "请填写您的社团": "Please fill in your club",
    "请填写您的微信": "Please enter your WeChat/QQ number",
    "简单描述一下您的想法": "Briefly describe your thoughts"
  },
  "积分商城": {
    "我的积分": "My Points",
    "兑换记录": "Exchange History",
    "热门产品": "Popular Products",
    "赚取积分": "Earn Points",
    "积分": "Points",
    "商品标签": "Product Tags",
    "类型": "Type",
    "每月最多可兑换3次当前还剩下": "You can exchange up to 3 times per month, currently remaining",
    "次": "times",
    "全部": "All",
    "只显示积分够兑换的物品": "Only show items that can be exchanged with available points",
    "立即兑换": "Exchange Now"
  },
  "品牌资源": {
    "精选品牌授权代理": "Selected Brand Authorized Agents",
    "品牌": "Brand",
    "全部": "All",
    "热门文档": "Popular Documents",
    "所有文档": "All Documents",
    "时间": "Time",
    "立即下载": "Download Now"
  },
  "领券中心": {
    "贞光领券规则": "ZhenGuang Coupon Rules",
    "贞光商城的券": "ZhenGuang Mall coupons are divided into product coupons, discount coupons, and shipping coupons. All coupons are non-refundable, non-transferable, and automatically expire after the expiration date.",
    "同一种类型的券": "Coupons of the same type cannot be used together. When using a coupon, please note the restrictions. Only eligible items can use the coupon when ordering.",
    "法律允许范围内": "Within the limits allowed by law, all other matters and the final interpretation rights belong to ZhenGuang Mall.",
    "商品券": "Product Coupon",
    "优惠券": "Discount Coupon",
    "运费券": "Shipping Coupon",
    "新用户专享优惠券": "New User Exclusive Coupon",
    "注册完成后": "After registration is complete, the coupon will be delivered to the member center.",
    "元无门槛": "No minimum purchase required",
    "满": "Minimum purchase",
    "减": "Discount",
    "已经领取过了～": "Already claimed～",
    "已领取": "Claimed",
    "折扣券": "Discount Coupon",
    "可用": "Available",
    "失效日期": "Expiration Date",
    "元使用": "Use for",
    "有效期": "Validity Period",
    "日": "Days",
    "领券立减": "Immediate Discount with Coupon",
    "品牌优惠券": "Brand Discount Coupon",
    "已领": "Claimed",
    "立即领取": "Claim Now"
  },
  "商品目录": {
    "全部分类": "All Categories",
    "元器件共计": "Total Components:",
    "请输入关键字": "Please enter keywords",
    "搜索": "Search",
    "当前": "Current",
    "共": "Total",
    "共2": "Total 2",
    "件相关商品": "related items",
    "从结果中搜索": "Search within results",
    "输入搜索": "Enter search",
    "仅显示授权代理品牌": "Only show authorized agent brands",
    "综合排序": "Comprehensive Sort",
    "库存": "Stock",
    "价格": "Price",
    "型号": "Model",
    "条": "Items",
    "商品信息": "Product Information",
    "单价(含税)": "Unit Price (Tax Included)",
    "按需求数量显示价格": "Display price based on required quantity",
    "交期(工作日)": "Lead Time (Business Days)",
    "起订/递增": "Minimum Order / Increment",
    "数量": "Quantity",
    "操作": "Action",
    "供应商": "Supplier:",
    "品牌": "Brand:",
    "封装": "Package:",
    "分类": "Category:",
    "描述": "Description:",
    "库存：": "Stock:",
    "起订量": "Minimum Order Quantity:",
    "递增量": "Increment Quantity:",
    "合计": "Total:",
    "立即购买": "Buy Now",
    "加入购物车": "Add to Cart",
    "有库存": "in stock",
    "有价格": "Have a price",
    "符合RoHS": "ROHS compliant",
    "有数据手册": "Data book available",
    "隐藏不可选": "Hide not optional",
    "大陆7-9": "Mainland 7-9",
    "海外3-6": "Overseas 3-6",
    "精选品牌":"Select brand"
  },
  "BOM": {
    "询价说明": "Inquiry Instructions:",
    "如采购数量接近整包装数量": "If the purchase quantity is close to the full package quantity, it is recommended to inquire and order by full package for easier transportation and storage.",
    "为保证服务质量和效率": "To ensure service quality and efficiency, the inquiry models must meet the following conditions to receive a quotation response.",
    "多型号拷贝": "Multiple Model Copy",
    "Excel导入": "Excel Import",
    "品牌": "e.g., Zhenguang",
    "参数描述": "e.g., AC0201FR-0710KL2500300010uF+10% 16V18000603 +20% 10V",
    "需求数量": "e.g., 100",
    "清除": "Clear",
    "只匹配自营商品": "Only match self-operated products",
    "开始匹配BOM": "Start Matching BOM",
    "选择文件": "Select File",
    "备注": "Notes",
    "上传BOM需求文件": "Upload BOM Requirement File",
    "请输入询价的备注内容": "Please enter the remarks for the inquiry",
    "智能配单": "Smart Order Matching",
    "将文件拖到此处": "Drag the file here, or",
    "点击上传": "Click to upload",
  },
  "供应商合作": {
    "合作申请": "Cooperation Application",
    "公司名称": "Company Name",
    "商品品牌": "Product Brand:",
    "类别": "Category:",
    "原厂": "Original Manufacturer",
    "代理商": "Distributor",
    "供应商合作": "Supplier Cooperation",
    "联系人": "Contact Person:",
    "手机号": "Mobile Number:",
    "邮箱": "Email:",
    "提交": "Submit",
    "入住优势": "Advantages of Joining"
  },
  "资讯": {
    "热门标签": "Popular Tags",
    "热门文章": "Popular Articles",
    "热门物料": "Popular Materials",
    "型号": "Model",
    "价格": "Price",
    "全部资讯": "All News",
    "请输入关键字": "Enter Keywords",
    "搜索": "Search",
    "全部": "All",
    "方案": "Solution",
    "技术": "Technology",
    "问答": "Q&A",
    "动态": "Updates",
    "新闻": "News",
    "活动": "Events",
    "发现": "Discover",
    "猜你喜欢": "You May Like",
    "上一篇": "Previous Article:",
    "下一篇": "Next Article:"
  },
  "品牌专区": {
    "订阅": "Subscribe",
    "已订阅": "Subscribed",
    "贞光已成为": "Zhengguang has become",
    "官方授权代理商": "Official Authorized Distributor",
    "订阅可获得最新品牌资讯品牌资源和促销活动等": "Subscribing will get you the latest brand news, brand resources, and promotional activities, etc.",
    "该品牌共有": "This brand has",
    "种商品": "products",
    "人订阅": "subscribers",
    "授权证明": "Authorization Proof",
    "品牌介绍": "Brand Introduction",
    "关于品牌": "About the Brand",
    "授权代理": "Authorized Distributor",
    "产品线": "Product Line",
    "市场新闻": "Market News",
    "授权信息": "Authorization Information",
    "请输入关键字": "Enter Keywords",
    "搜索": "Search",
    "授权代理证明": "Authorized Distributor Certificate",
    "+订阅": "+ Subscribe",
    "图片": "Image",
    "制造商零件编号": "Manufacturer Part Number",
    "描述": "Description",
    "可供货数量": "Available Quantity",
    "操作": "Action",
    "查看详情": "View Details",
    "请输入类别关键字": "Enter Category Keywords",
    "人订阅": "Person subscription",
    "订阅可获得最新品牌资讯": "Subscribe to get the latest brand news, brand resources and promotions, etc",
  },
  "产品方案": {
    "车载": "Automotive",
    "IoT物联网": "IoT (Internet of Things)",
    "人工智能": "Artificial Intelligence",
    "工业应用": "Industrial Applications",
    "消费电子": "Consumer Electronics",
    "医疗产品": "Medical Products",
    "产品方案导航": "Product Solution Navigation",
    "推荐产品列表": "Recommended Product List",
    "产品类别": "Product Categories",
    "应用位置": "Application Location",
    "品牌": "Brand"
  },
  "换算工具": {
    "贞光商城在线工具": "Zhengguang Mall Online Tools provides one-stop resource services for electronic industry calculations.",
    "推荐工具": "Recommended Tools",
    "推荐商品": "Recommended Products",
    "数值": "Value",
    "单位": "Unit",
    "科学计数法显示": "Scientific Notation Display",
    "关于": "About",
    "提示": "Tip: The conversion result is for reference only!"
  },
  "购物车": {
    "全部": "All",
    "国内": "Domestic",
    "海外": "Overseas",
    "购物车": "Shopping Cart",
    "全选": "Select All",
    "按时间排序": "Sort by Time",
    "请选择要更改的商品": "Please select the products to change",
    "继续购物": "Continue Shopping",
    "订单已满": "Order is full",
    "已免运费！": ", shipping is free!",
    "商品编号": "Product Number",
    "商品描述": "Product Description",
    "单价（含税）": "Unit Price (Tax Included)",
    "订单数量": "Order Quantity",
    "小计": "Subtotal",
    "操作": "Actions",
    "商品编号：": "Product Number:",
    "自定义料号": "Custom Part Number:",
    "品牌": "Brand:",
    "商品描述：": "Product Description:",
    "商品单价": "Unit Price:",
    "需求数量": "Required Quantity:",
    "库存": "Stock:",
    "批量删除": "Bulk Delete",
    "批量更改国内外": "Bulk Change Domestic/Overseas",
    "清除失效商品": "Clear Out of Stock Items",
    "导出商品": "Export Products",
    "批量添加料号": "Bulk Add Part Numbers",
    "已选择": "Selected",
    "个型号": "Models",
    "总金额(不含运费):": "Total Amount (Excluding Shipping):",
    "积分!": "Points!",
    "您现在下单发货后还可获得": "You can still earn after placing the order",
    "去结算": "Go to Checkout",
    "热销商品": "Hot Sale Products",
    "我的收藏夹": "My Favorites",
    "我买过的": "My Purchases",
    "产品名称": "Product Name:",
    "加入购物车": "Add to Cart",
    "请选择要删除的商品": "Please select products to delete",
    "请选择要下单的商品": "Please select products to place an order"
  },
  "商品详情": {
    "首页": "Home",
    "图片仅供参考": "The image is for reference only, actual product prevails",
    "商品信息": "Product Information",
    "供应商编号": "Supplier Product Number:",
    "品牌": "Brand:",
    "制造商": "Manufacturer:",
    "厂家型号": "Manufacturer Part Number:",
    "贞光编号": "ZhenGuang Part Number:",
    "产品类别": "Product Category:",
    "供货地": "Supply Location:",
    "库存": "Stock:",
    "操作": "Actions",
    "无铅情况/RoHs": "Lead-Free / RoHS:",
    "供应商生产/周期": "Supplier Production / Cycle:",
    "描述": "Description:",
    "批次": "Batch:",
    "最小包": "Minimum Package:",
    "交期": "Lead Time:",
    "起订量": "Minimum Order Quantity:",
    "倍数": "Multiple:",
    "商品单价": "Unit Price",
    "参数值": "Parameter Value",
    "参数": "Parameters",
    "加入购物车": "Add to Cart",
    "立即购买": "Buy Now",
    "新品资讯": "New Product Information",
    "查看更多": "See More",
    "品牌资源": "Brand Resources",
    "参数问题": "Parameter Issues",
    "数据手册": "Datasheet",
    "常见问题": "Frequently Asked Questions",
    "找到类似商品": "Found Similar Products:",
    "查看相似商品": "View Similar Products",
    "技巧": "Tip: Check main parameters, leave some optional parameters blank, then click to view similar products to quickly find alternatives!"
  },
  "订单信息": {
    "订单信息": "Order Information",
    "微信支付": "WeChat Pay",
    "支付宝支付": "Alipay",
    "对公转账": "Bank Transfer",
    "默认地址": "Default Address",
    "修改": "Edit",
    "删除": "Delete",
    "添加收货地址": "Add Shipping Address",
    "您可以选择": "You can choose",
    "请选择": "Please select",
    "商品名称": "Product Name",
    "当前订单已": "The current order has Free Shipping",
    "下单联系人": "Order Contact",
    "保存": "Save",
    "联系人": "Contact Person:",
    "QQ": "QQ:",
    "手机": "Mobile:",
    "微信": "WeChat:",
    "若订单执行中有需确认的项目": "If there are items that need confirmation during the order execution, please ensure you answer calls from 0592.",
    "发票信息": "Invoice Information",
    "数电增值税(专用)发票(可抵扣)": "Digital Electronics VAT (Special) Invoice (Tax Deductible)",
    "数电增值税(普通)发票(不可抵扣)": "Digital Electronics VAT (Regular) Invoice (Non-Deductible)",
    "不开发票(没有发票)": "No Invoice (No Invoice)",
    "开票公司名称": "Invoice Company Name:",
    "统一社会信用代码": "Unified Social Credit Code:",
    "注册地址": "Registered Address:",
    "公用电话": "Public Phone:",
    "邮箱": "Email:",
    "开户银行": "Bank Name:",
    "银行账号": "Bank Account Number:",
    "敝司已纳入全面数字化的电子发票试点": "Our company has entered the comprehensive digital electronic invoice pilot program, and electronic invoices will be sent to your reserved email.",
    "发送至您预留的邮箱": "Sent to your reserved email. If you need a paper invoice, you can print it yourself, which has the same legal effect.",
    "确认订单信息": "Confirm Order Information",
    "商品编号": "Product Number",
    "自定义料号": "Custom Part Number:",
    "品牌": "Brand:",
    "商品描述": "Product Description",
    "单价": "Unit Price",
    "订单数量": "Order Quantity",
    "合计": "Total",
    "订单备注": "Order Notes:",
    "合同号": "Contract Number:",
    "支付方式": "Payment Method",
    "优惠券": "Coupon",
    "商品券": "Product Coupon",
    "运费券": "Shipping Coupon",
    "暂无优惠券！": "No Coupons Available!",
    "全部优惠": "Total Discount:",
    "商品总额": "Total Product Price:",
    "运费": "Shipping Fee:",
    "应付总额": "Total Payable:",
    "我已阅读并同意": "I have read and agree",
    "订单提交协议": "Order Submission Agreement",
    "和": "and",
    "销售条款": "Sales Terms",
    "提交订单": "Submit Order",
    "贞光商城": "ZhenGuang Mall Order Submission Agreement and Sales Terms Confirmation",
    "尊敬的用户": "Dear User, hello! Please make sure you carefully read and fully understand the terms of the agreement, so you can make the appropriate choice based on the instructions.",
    "不同意": "Disagree",
    "已阅读并同意继续": "I have read and agree to continue",
    "请确认发票信息是否正确": "Please confirm if the invoice information is correct. Once issued, it cannot be changed or refunded.",
    "当前发票信息": "Current Invoice Information, please verify:",
    "我已确认无误": "I confirm the information is correct!",
    "返回修改": "Return to Edit",
    "设置开票单位": "Set Invoice Unit",
    "开票单位指发票上的数量单位": "The invoice unit refers to the unit of quantity on the invoice, please choose based on your company's needs.",
    "选择开票单位": "Choose Invoice Unit:",
    "确定": "Confirm",
    "确认提交": "Confirm Submission",
    "注意1": "Note 1: After setting, all invoices for this account will use this unit.",
    "注意2": "Note 2: After setting, it cannot be changed. To modify, please contact online sales.",
    "您购买的商品中有特惠库存的商品": "Your order includes discounted inventory items, please note the production cycle. These items cannot be returned or exchanged once sold.",
    "取消": "Cancel",
    "支付信息": "Payment Information",
    "订单详情": "Order Details",
    "请使用微信扫一扫": "Please use WeChat to scan the QR code",
    "扫描二维码支付": "Scan the QR code to pay",
    "距离二维码过期还剩": "Time remaining until QR code expires",
    "秒": "seconds, after expiration, please refresh the page to get a new QR code",
    "请您在24小时内完成支付": "Please complete the payment within 24 hours, otherwise the order will be automatically canceled (for inventory-tight products, the payment deadline may vary according to the order details page)",
    "订单号": "Order Number:",
    "其他支付方式": "Other Payment Methods",
    "订单状态": "Order Status",
    "物流信息": "Logistics Information",
    "查看物流信息": "View Logistics Information",
    "收货地址": "Shipping Address",
    "付款信息": "Payment Information",
    "修改发票": "Edit Invoice",
    "配送信息": "Shipping Information",
    "合同信息": "Contract Information",
    "热销商品": "Hot Selling Products",
    "开票信息": "Invoice Information",
    "开票详情": "Invoice Details",
    "订单提交成功": "Order Submitted Successfully, please complete the payment as soon as possible!",
    "待审核": "Pending Review",
    "待支付": "Pending Payment",
    "已支付/待发货": "Paid / Awaiting Shipment",
    "待发货": "Pending Shipment",
    "待收货": "Pending Receipt",
    "已收货": "Received",
    "已发货": "Shipped",
    "退货待审核": "Return Pending Review",
    "退货中": "Returning",
    "已退货": "Returned",
    "已取消": "Canceled",
    "已完成": "Completed",
    "打印收据": "Print Receipt",
    "打印合同": "Print Contract",
    "打印订单": "Print Order",
    "打印收货单": "Print Delivery Receipt",
    "订单线路": "Order Routing",
    "审核订单": "Review Order",
    "付款成功": "Payment Successful",
    "仓库备货": "Warehouse Preparation",
    "发货完成": "Shipment Completed",
    "物流公司": "Logistics Company:",
    "物流单号": "Logistics Tracking Number:",
    "发货清单": "Shipping List",
    "收货人": "Recipient:",
    "地址": "Address:",
    "付款时间": "Payment Time:",
    "余额支付": "Balance Payment:",
    "发票类型": "Invoice Type:",
    "发票抬头": "Invoice Title:",
    "公司注册地址": "Company Registered Address:",
    "公司电话": "Company Phone:",
    "统一信用代码": "Unified Credit Code:",
    "配送方式": "Shipping Method:",
    "运费付款方式": "Shipping Fee Payment Method:",
    "运费优惠券抵扣金额": "Shipping Coupon Discount Amount:",
    "合同下载": "Contract Download:",
    "状态": "Status:",
    "点击复制": "Click to Copy",
    "国内单号": "Domestic Tracking Number",
    "编辑发票": "Edit Invoice",
    "只能修改一次": "The invoice information can only be modified once. After financial review, the electronic invoice will be sent to your reserved email.",
    "开户银行账号": "Bank Account Number",
    "将本次发票信息同步到开票资料中": "Sync this invoice information to invoicing details",
    "邮件通知财务开票": "Email notification to finance for invoicing",
    "重新交付": "Resend",
    "邮政快递": "Postal Express:",
    "快递件数": "Number of Packages:",
    "商品型号": "Product Model:",
    "商品数量": "Product Quantity:",
    "快递快递单号": "Courier Tracking Number:",
    "数电增值税(专用)发票": "Digital Electronics VAT (Special) Invoice",
    "点击复制清单": "Click to Copy List",
    "数电增值税(普通)发票": "Digital Electronics VAT (Regular) Invoice",
    "发票号": "Invoice Number:",
    "请阅读并同意订单提交协议": "Please read and agree to the Order Submission Agreement",
    "请添加收获地址": "Please add a shipping address",
    "请选择配送方式": "Please select a shipping method",
    "请填写下单联系人": "Please provide the order contact",
    "请选择支付方式": "Please select a payment method"
  },
  "会员中心": {
    "个人信息": "Personal Information",
    "基本信息": "Basic Information",
    "收货地址": "Shipping Address",
    "开票资料": "Invoice Information",
    "修改密码": "Change Password",
    "账户安全": "Account Security",
    "订单管理": "Order Management",
    "我的订单": "My Orders",
    "售后服务": "After-Sales Service",
    "资产中心": "Asset Center",
    "我的钱包": "My Wallet",
    "我的积分": "My Points",
    "我的礼券": "My Gift Vouchers",
    "我的优惠券": "My Coupons",
    "服务中心": "Service Center",
    "我的样品": "My Samples",
    "我的收藏": "My Favorites",
    "我的料号": "My Part Numbers",
    "专属链接": "Exclusive Link",
    "我的BOM": "My BOM",
    "我的消息": "My Messages",
    "我的建议": "My Suggestions",
    "品牌订阅": "Brand Subscription",
    "提醒设置": "Reminder Settings",
    "我买过的": "My Purchases",
    "发票查询": "Invoice Query",
    "电子对账单": "Electronic Statement"
  },
  "我的样品": {
    "样品申请单": "Sample Application Form",
    "免费样品申请单": "Free Sample Application Form",
    "搜索样品": "Search for Samples:",
    "类别": "Category:",
    "审核状态": "Audit Status:",
    "状态": "Status:",
    "请选择": "Please Select",
    "查询": "Search",
    "重置": "Reset",
    "申请单号": "Application Number",
    "申请类别": "Application Category",
    "申请样品": "Applied Samples",
    "数量": "Quantity",
    "样品申请单提交后": "After the sample application form is submitted, we will complete the review within 5 business days."
  },
  "我的收藏": {
    "我的收藏": "My Favorites",
    "查询": "Search",
    "请选择": "Please Select",
    "收藏夹": "Favorites:",
    "搜索商品": "Search for Products:",
    "文章收藏": "Article Favorites",
    "搜索文章": "Search for Articles:",
    "浏览量": "Views",
    "点赞量": "Likes",
    "收藏量": "Favorites",
    "移除": "Remove",
    "商品收藏": "Product Favorites",
    "生成据价单": "Generate Quotation",
    "商品信息": "Product Information",
    "单价（含税）": "Unit Price (Tax Included)",
    "需求数量": "Required Quantity",
    "合计": "Total",
    "操作": "Action",
    "库存": "Stock:",
    "取消收藏": "Unfavorite",
    "加入购物车": "Add to Cart",
    "全选": "Select All",
    "批量移除": "Batch Remove",
    "清空失效": "Clear Invalid",
    "转移收藏夹": "Transfer to Favorites",
    "已选": "Selected",
    "件商品": "items, total (excluding shipping)"
  },
  "我的料号": {
    "我的料号": "My Part Numbers",
    "全选": "Select All",
    "添加自定义料号": "Add Custom Part Number",
    "搜索料号": "Search for Part Numbers:",
    "输入单号": "Enter Order Number/Sample Name",
    "删除所选": "Delete Selected",
    "导出": "Export",
    "批量导入": "Batch Import",
    "料号": "Part Number",
    "制造商编号": "Manufacturer Number",
    "自定义料号": "Custom Part Number",
    "更新时间": "Update Time",
    "操作": "Action",
    "编辑": "Edit",
    "删除": "Delete",
    "自定义料号只支持数字": "Custom part numbers only support numbers, letters, and \"\" and \"\" characters!",
    "编号": "Number:",
    "自定义料号2": "Custom Part Number:",
    "保存": "Save",
    "取消": "Cancel"
  },
  "专属链接": {
    "专属二维码": "Exclusive QR Code",
    "我的专属二维码": "My Exclusive QR Code",
    "分享给好友": "Share with Friends",
    "好友扫一扫识别二维码": "Friends scan the QR code to recognize",
    "积分到账": "Points Credited",
    "专属链接": "Exclusive Link",
    "向好友发送专属邀请链接": "Send the exclusive invitation link to friends. After their successful registration, you will receive point rewards!",
    "点击分享链接": "Click to share the link",
    "商城即日起": "Starting today, existing members can earn 500 points for each new member they invite, with a maximum reward of 2500 points. Any points earned beyond that will not be given.",
    "受邀账号": "Invited Account",
    "邀请奖励": "Invitation Reward",
    "首单下单时间": "First Order Time",
    "首单金额": "First Order Amount",
    "注册时间": "Registration Time",
    "首单发货状态": "First Order Shipment Status",
    "我的专属链接": "My Exclusive Link"
  },
  "我的BOM": {
    "我的BOM": "My BOM",
    "新建BOM配单": "Create New BOM Matching",
    "BOM名称": "BOM Name:",
    "商品型号": "Product Model/Custom Part Number",
    "时间": "Time:",
    "至": "To",
    "开始日期": "Start Date",
    "结束日期": "End Date",
    "查询": "Search",
    "重置": "Reset",
    "BOM编号": "BOM Number",
    "BOM名称2": "BOM Name",
    "创建时间": "Creation Time",
    "操作": "Actions",
    "查看详情": "View Details",
    "移除": "Remove"
  },
  "我的消息": {
    "我的消息": "My Messages",
    "已提交": "已提交",
    "查看详情": "View Details",
    "移除": "Remove",
    "操作": "Actions",
    "发送时间": "Sent Time",
    "状态": "Status",
    "信息标题": "Message Title",
    "全部": "All",
    "未读": "Unread",
    "已读": "Read",
    "(": "(",
    ")": ")",
    "全部标记为已读": "Mark All as Read",
    "删除已读消息": "Delete Read Messages",
    "消息详情": "Message Details",
    "返回消息列表": "Return to Message List",
    "删除消息": "Delete Message",
    "我的建议": "My Suggestions",
    "去提建议": "Go to Suggest",
    "已采纳": "Adopted",
    "待处理": "Pending:",
    "反馈时间": "Feedback Time:",
    "反馈类型": "Feedback Type:",
    "品牌订阅": "Brand Subscription",
    "订阅更多品牌": "Subscribe to More Brands",
    "搜索品牌": "Search Brand:",
    "查询": "Search",
    "图片": "Image",
    "品牌": "Brand",
    "订阅时间": "Subscription Time",
    "共有": "Total",
    "种样品": "Samples",
    "人订阅": "People Subscribed",
    "取消": "Cancel",
    "提醒设置": "Reminder Settings",
    "关闭全部提醒": "Turn Off All Reminders",
    "提醒项目": "Reminder Items",
    "描述": "Description",
    "短信提醒": "SMS Reminder",
    "微信提醒": "WeChat Reminder",
    "微信": "WeChat",
    "短信": "SMS"
  },
  "我的钱包": {
    "钱包余额": "Wallet Balance",
    "备注": "Remarks",
    "账户余额": "Account Balance",
    "创建时间": "Creation Time",
    "金额": "Amount",
    "交易类型": "Transaction Type",
    "交易流水号": "Transaction Serial Number",
    "重置": "Reset",
    "查询": "Search",
    "时间": "Time",
    "单据类型": "Bill Type:",
    "状态": "Status:",
    "提现记录": "Withdrawal Records",
    "充值记录": "Recharge Records",
    "支付明细": "Payment Details",
    "所有明细": "All Details",
    "交易记录明细": "Transaction Record Details",
    "绑定": "Bind",
    "安全手机": "Security Phone:",
    "未绑定": "Unbound",
    "提现账号": "Withdrawal Account:",
    "修改": "Modify",
    "支付密码": "Payment Password:",
    "冻结金额": "Frozen Amount:",
    "钱包账户信息": "Wallet Account Information",
    "提现": "Withdraw",
    "线下对公充值": "Offline Corporate Recharge",
    "充值金额": "Recharge Amount",
    "充值方式": "Recharge Method",
    "打款银行": "Bank of Payment",
    "打款账户": "Payment Account",
    "支付订单号": "Payment Order Number",
    "支付时间": "Payment Time",
    "状态2": "Status",
    "类别": "Category",
    "提交时间": "Submission Time",
    "银行账号": "Bank Account",
    "首款开户名": "Account Holder Name of First Payment",
    "提现时间": "Withdrawal Time",
    "银行单号": "Bank Order Number",
    "绑定银行账号": "Bind Bank Account",
    "短信验证码": "SMS Verification Code",
    "验证码": "Verification Code:",
    "获取验证码": "Get Verification Code",
    "请输入新的支付密码": "Please Enter New Payment Password",
    "下一步": "Next",
    "开户名": "Account Holder Name:",
    "请输入开户名": "Please Enter Account Holder Name",
    "开户银行": "Bank Name:",
    "请输入开户银行": "Please Enter Bank Name",
    "银行账号2": "Bank Account:",
    "请输入银行账号": "Please Enter Bank Account",
    "保存": "Save",
    "修改安全手机号码": "Modify Security Phone Number",
    "新安全手机": "New Security Phone:",
    "请输入新安全手机": "Please Enter New Security Phone",
    "请输入支付密码": "Please Enter Payment Password",
    "设置支付密码": "Set Payment Password",
    "设置支付密码2": "Set Payment Password:",
    "确认支付": "Confirm Payment:",
    "请确认支付密码": "Please Confirm Payment Password"
  },
  "我的积分": {
    "订单号": "Order Number",
    "积分类型": "Point Type",
    "积分": "Points",
    "时间": "Time",
    "备注": "Remarks",
    "查询": "Search",
    "重置": "Reset",
    "时间2": "Time:",
    "名称": "Name",
    "使用积分": "Use Points",
    "状态": "Status",
    "兑换类型": "Exchange Type",
    "我的积分": "My Points",
    "将于": "Will expire on",
    "过期": "Expired",
    "进入积分商城": "Enter Points Mall",
    "赚取积分": "Earn Points",
    "积分分类信息": "Points Category Information",
    "消费赠送": "Points for Consumption",
    "注册获得积分": "Points for Registration",
    "完善信息积分": "Points for Profile Completion",
    "绑定邮箱": "Bind Email",
    "关注公众号": "Follow Official Account",
    "快过期积分": "Points About to Expire",
    "签到获得积分": "Points for Signing In",
    "积分记录明细": "Points Record Details",
    "所有明细": "All Details",
    "积分记录": "Points Record",
    "兑换记录": "Exchange Record",
    "积分类型2": "Point Type:"
  },
  "我的礼券": {
    "我的礼券": "My Vouchers",
    "所有礼券": "All Vouchers",
    "待兑换": "To Be Redeemed",
    "发放中": "Issuing",
    "已完成": "Completed",
    "已过期": "Expired",
    "抱歉": "Sorry, you don't have any related vouchers yet!"
  },
  "我的优惠券": {
    "我的优惠券": "My Coupons",
    "立即领取": "Claim Now",
    "已领": "Claimed",
    "品牌优惠券": "Brand Coupons",
    "满": "Minimum Spend",
    "可用": "Available",
    "已过期": "Expired",
    "已使用": "Used",
    "未使用": "Unused",
    "所有优惠券": "All Coupons",
    "+ 领券中心": "+ Coupon Center",
    "优惠券使用规则": "Coupon Usage Rules",
    "邮箱绑定": "Email Binding",
    "绑定邮箱": "Bind your email to receive the latest coupon messages every month!",
    "邮箱": "Email:",
    "请输入邮箱": "Please enter your email",
    "验证码": "Verification Code:",
    "短信验证码": "SMS Verification Code",
    "获取验证码": "Get Verification Code",
    "提交": "Submit",
    "跳过": "Skip, remind me in 7 days"
  },
  "基本信息": {
    "保存": "Save",
    "获取验证码": "Get Verification Code",
    "短信验证码": "SMS Verification Code",
    "账号基础信息": "Account Basic Information",
    "姓名": "Name",
    "手机号码": "Mobile Number",
    "会员登记": "Membership Registration",
    "修改手机": "Change Mobile Number",
    "积分": "Points",
    "首次验证邮箱送": "First Email Verification Bonus",
    "绑定": "Bind",
    "邮箱": "Email",
    "账号类型": "Account Type",
    "平台客服": "Platform Customer Service",
    "账号信息": "Account Information",
    "昵称": "Nickname",
    "性别": "Gender",
    "职务": "Position",
    "职业类型": "Job Type",
    "行业类型": "Industry Type",
    "公司名称": "Company Name",
    "所在区域": "Region",
    "详情地址": "Detailed Address",
    "确认修改": "Confirm Modification",
    "完善每一项资料": "Complete each item to earn",
    "最近已有": "points, recently",
    "人领取共": "people have received a total of",
    "积分!": "points!",
    "修改邮箱": "Change Email",
    "当前账号密码": "Current Account Password",
    "请输入当前账号的密码": "Please enter the current account password",
    "原注册邮箱": "Original Registered Email",
    "请输入原注册邮箱": "Please enter the original registered email",
    "原验证码": "Original Verification Code",
    "修改邮箱号": "Change Email Address",
    "请输入要修改的邮箱号": "Please enter the email address to modify",
    "修改邮箱验证码": "Change Email Verification Code",
    "请输入邮箱验证码": "Please enter the email verification code",
    "修改手机号": "Change Mobile Number",
    "注册手机": "Registered Mobile",
    "验证码": "Verification Code",
    "需要修改手机号": "Need to change mobile number",
    "请输入需要的修改手机号": "Please enter the mobile number to be changed",
    "请输入验证码": "Please enter the verification code"
  },
  "收货地址": {
    "收货地址": "Shipping Address",
    "取消": "Cancel",
    "保存": "Save",
    "设为默认地址": "Set as Default Address",
    "收货人座机": "Recipient's Landline",
    "收货人手机": "Recipient's Mobile",
    "详细地址": "Detailed Address",
    "所在区域": "Area",
    "收货人": "Recipient",
    "应快递公司要求": "As requested by the courier company, the recipient's name must be real-name verified for shipment. Thank you for your cooperation.",
    "添加新的收货地址": "Add New Shipping Address",
    "编辑": "Edit",
    "设为默认": "Set as Default",
    "默认地址": "Default Address",
    "操作": "Action",
    "手机电话": "Mobile Phone",
    "详情地址": "Detailed Address",
    "所在地区": "Region",
    "添加地址": "Add Address",
    "序号": "Serial Number",
    "删除地址": "Delete Address"
  },
  "开票资料": {
    "开票资料": "Billing Information",
    "删除资料": "Delete Information",
    "添加资料": "Add Information",
    "数电增值税(专用)开票资料": "Digital Electronics VAT (Special) Billing Information",
    "数电增值税(普通)开票资料": "Digital Electronics VAT (General) Billing Information",
    "操作": "Operation",
    "邮箱": "Email",
    "开户银行账号": "Bank Account Number",
    "公司电话": "Company Phone",
    "公司地址": "Company Address",
    "发票抬头": "Invoice Title",
    "统一社会信用代码": "Unified Social Credit Code",
    "企业名称": "Company Name",
    "修改": "Modify",
    "编辑发票": "Edit Invoice",
    "鄙司已纳入全面数字化的电子发票试点": "Our company has been included in the comprehensive digital electronic invoice pilot. Electronic invoices will be sent to your reserved email address from 'oneyac invoice@oneyac.com'. If you require a paper invoice, you may print it yourself, which holds the same legal validity.",
    "公司注册地址": "Company Registration Address",
    "开户银行": "Bank of Account",
    "保存": "Save",
    "取消": "Cancel",
    "修改发票抬头": "Modify Invoice Title",
    "修改抬头后": "After modifying the title, please make sure to update the relevant information for both general and special invoices!",
    "确认修改": "Confirm Modification",
    "添加发票": "Add Invoice",
    "发票抬头类型": "Invoice Title Type",
    "企业": "Enterprise",
    "个人": "Individual"
  },
  "修改密码": {
    "手机号验证": "Phone Number Verification",
    "邮箱验证码": "Email Verification Code",
    "修改密码": "Change Password",
    "身份认证": "Identity Authentication",
    "设置操作": "Set Operation",
    "完成": "Complete",
    "注册手机号": "Registered Phone Number",
    "输入验证码": "Enter Verification Code",
    "获取验证码": "Get Verification Code",
    "下一步": "Next Step",
    "上一步": "Previous Step",
    "当前密码": "Current Password",
    "新密码": "New Password",
    "确认新密码": "Confirm New Password",
    "确认修改": "Confirm Changes"
  },
  "账户安全": {
    "登录方式": "Login Method",
    "登录地点": "Login Location (Queried through your IP address)",
    "登录IP": "Login IP",
    "登录时间": "Login Time (Shows only the last 3 months)",
    "样品申请单提交后": "After submitting the sample application form",
    "绑定微信": "Bind WeChat",
    "账户安全登录信息": "Account Security Login Information",
    "账号": "Account",
    "邮箱": "Email",
    "最近登录": "Last Login",
    "普通会员": "Regular Member",
    "详情": "Details",
    "安全等级": "Security Level",
    "密码强度": "Password Strength",
    "密码强度为": "Password Strength is",
    "高强度密码且定期更换密码可以使账号更安全": "A strong password and regular password changes can make the account more secure",
    "低": "Low",
    "修改": "Modify",
    "绑定邮箱": "Bind Email",
    "已绑定邮箱": "Email Bound",
    "自由": "Free",
    "微信账号": "WeChat Account",
    "若微信丢失或停用": "If WeChat is lost or deactivated, please update promptly",
    "绑定手机": "Bind Phone",
    "已经绑定手机": "Phone Bound",
    "登录情况": "Login Status",
    "修改登录密码": "Change Login Password",
    "登录记录若存在非本人或异地登录记录，帐号可能存在风险，建议": "If there are login records from unrecognized locations or users, the account may be at risk. It is recommended to",
    "微信扫码绑定": "Bind via WeChat Scan - Stay updated with mall news",
    "提交": "Submit"
  },
  "我的订单": {
    "我的订单": "My Orders",
    "订单回收站": "Order Recycling Bin",
    "所有订单": "All Orders",
    "待审核": "Pending Review",
    "待付款": "Pending Payment",
    "待发货": "Pending Shipment",
    "待收货": "Pending Receipt",
    "订单搜索": "Order Search",
    "更多筛选条件": "More Filtering Conditions",
    "批量导出订单": "Batch Export Orders",
    "全部订单": "All Orders",
    "订单详情": "Order Details",
    "收货人": "Recipient",
    "金额": "Amount",
    "订单状态": "Order Status",
    "操作": "Action",
    "当前时段暂时没有订单哦": "No orders available in this time period~",
    "查看全部订单": "View All Orders",
    "去选购": "Go Shopping",
    "订单号": "Order Number",
    "订单型号": "Order Model",
    "品牌": "Brand",
    "单价": "Unit Price",
    "订单数量": "Order Quantity",
    "已完成": "Completed",
    "再次购买": "Buy Again",
    "去支付": "Go to Payment"
  },
  "售后服务": {
    "售后服务": "After-sales Service",
    "订单搜索": "Order Search",
    "更多筛选条件": "More Filtering Conditions",
    "批量导出订单": "Batch Export Orders",
    "抱歉暂时无相关售后服务记录": "Sorry, there are no relevant after-sales service records at the moment~",
    "详情": "Details",
    "订单号": "Order Number",
    "退货单号": "Return Number",
    "申请时间": "Application Time",
    "退款金额": "Refund Amount",
    "退款状态": "Refund Status",
    "操作": "Action",
    "已完成": "Completed",
    "提交信息": "Submit Information",
    "审核通过": "Approved",
    "退款完成": "Refund Completed",
    "订单详情": "Order Details",
    "商品总额": "Total Product Amount",
    "运费": "Shipping Fee",
    "订单总额": "Total Order Amount",
    "支付信息": "Payment Information",
    "退款完成相关金额已经退回": "Refund completed, the related amount has been refunded!",
    "余额支付": "Balance Payment",
    "商品信息": "Product Information",
    "商品名称": "Product Name",
    "商品数量": "Product Quantity",
    "退款信息": "Refund Information",
    "退款方式": "Refund Method",
    "退款说明": "Refund Description",
    "退款商品": "Refunded Product"
  },
  "我买过的": {
    "我买过的": "I Have Purchased",
    "商品型号": "Product Model",
    "商城购买": "Mall Purchase",
    "商品信息": "Product Information",
    "其他分类": "Other Categories",
    "商品列表": "Product List",
    "参数": "Parameters",
    "品牌": "Brand",
    "单价": "Unit Price",
    "购买次数": "Purchase Count",
    "最近购买": "Most Recent Purchase",
    "操作": "Action",
    "点击询价": "Click to Inquire"
  },
  "发票查询": {
    "发票查询": "Invoice Inquiry",
    "全部": "All",
    "待开票": "Pending Invoice",
    "已开票": "Invoiced",
    "不开票": "No Invoice",
    "订单号": "Order Number",
    "时间": "Time",
    "查询": "Search",
    "重置": "Reset",
    "订单金额": "Order Amount",
    "订单状态": "Order Status",
    "发票类型": "Invoice Type",
    "下单时间": "Order Time",
    "开票状态": "Invoice Status"

  },
  "电子对账单": {
    "电子对账单": "Electronic Statement",
    "全部": "All",
    "待开票": "Pending Invoice",
    "已开票": "Invoiced",
    "不开票": "No Invoice",
    "订单号": "Order Number",
    "时间": "Time",
    "批量导出订单": "Batch Export Orders",
    "重置": "Reset",
    "商品型号": "Product Model",
    "自定义料号": "Custom Part Number",
    "品牌": "Brand",
    "商品分类": "Product Category",
    "购买数量": "Purchase Quantity",
    "下单时间": "Order Time",
    "合计金额": "Total Amount"
  },
  "会员": {
    "手机": "Phone",
    "邮箱": "Email",
    "修改": "Modify",
    "提升": "Upgrade",
    "低": "Low",
    "安全": "Security",
    "完善资料赚积分": "Complete Profile to Earn Points",
    "普通会员": "Regular Member",
    "会员等级权益": "Membership Level Benefits",
    "赚取积分": "Earn Points",
    "成长值": "Growth Points",
    "普通会员权益": "Regular Member Benefits",
    "积分值按": "Points are accumulated at 1.00x the order amount",
    "去绑定": "Go to Bind",
    "绑定邮箱可加强账户安全": "Binding an email enhances account security. First-time email verification also earns 200 points.",
    "会员权益": "Membership Benefits",
    "升等级享更多权益": "Upgrade to Enjoy More Benefits",
    "积分任务": "Point Tasks",
    "做任务赢翻倍积分": "Complete Tasks to Win Double Points",
    "签到送礼": "Sign-in Gifts",
    "签到送10元京东卡": "Sign-in to Receive a 10 CNY JD Card",
    "每日抽奖": "Daily Lottery",
    "抽奖赢巨多好礼": "Win Great Prizes in the Lottery",
    "商城公告": "Store Announcements",
    "查看更多": "See More",
    "余额": "Balance",
    "积分": "Points",
    "收藏": "Favorites",
    "未读消息": "Unread Messages",
    "待审核": "Pending Review",
    "待付款": "Pending Payment",
    "优惠券": "Coupons",
    "购物车": "Shopping Cart",
    "待发货": "Pending Shipment",
    "待收货": "Pending Receipt",
    "投诉建议": "Complaints and Suggestions",
    "删除订单": "Delete Order",
    "规则说明": "Rules Explanation",
    "会员等级": "Membership Level",
    "升级条件": "Upgrade Requirements",
    "成长值为0的客户": "Customers with 0 Growth Points",
    "两次输入密码不一致": "The two entered passwords do not match",
    "用户协议未勾选": "User Agreement Not Checked",
    "密码修改成功": "Password Change Successful"
  }
}
