export const COMMON_CODE = {
    OK: {
        code: 200,
        message: '成功',
    },
    ERROR: {
        code: 0,
        message: '失败'
    },
    NOT_FOUND: {
        code: 404,
        message: '资源不存在'
    },
    INTERNAL_SERVER_ERROR: {
        code: 500,
        message: '服务器内部错误'
    },
    UNAUTHORIZED: {
        code: 401,
        message: '未授权',
        redirect: '/login'
    },
}
