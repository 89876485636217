<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft navVal="1-1">
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="infoTop">
              <div class="twoT">
                <p class="orderTit">{{$t('我的收藏.我的收藏')}}</p>
                <span class="editBtn hand">{{$t('我的收藏.生成据价单')}}</span>
              </div>
              <div class="detailSele">
                <div class="seleLeft">
                  <p @click="tabClick(1)" :class="state==1?'active':'hand hover'">{{$t('我的收藏.商品收藏')}}</p>
                  <span>|</span>
                  <p @click="tabClick(2)" :class="state==2?'active':'hand hover'">{{$t('我的收藏.文章收藏')}}</p>
                </div>
              </div>
              <div v-if="state==1">
                <div class="selectDiv">
                  <div class="seleLeft">
                    <div class="sub">
                      <span>{{$t('我的收藏.搜索商品')}}</span>
                      <input class="subInp" />
                    </div>
                    <div class="sub">
                      <span>{{$t('我的收藏.收藏夹')}}</span>
                      <el-select class="subInp" v-model="value" :placeholder="$t('我的收藏.请选择')">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="seleRig">
                    <p class="hand">{{$t('我的收藏.查询')}}</p>
                  </div>
                </div>
                <div class="tab">
                  <table>
                    <tr class="trTit">
                      <th>{{$t('我的收藏.商品信息')}}</th>
                      <th>{{$t('我的收藏.单价（含税）')}}</th>
                      <th>{{$t('我的收藏.需求数量')}}</th>
                      <th>{{$t('我的收藏.合计')}}</th>
                      <th>{{$t('我的收藏.操作')}}</th>
                    </tr>
                    <tr class="trCon" v-for="(item,index) in tableData">
                      <td style="width:300px">
                        <div class="descCss">
                          <p class="allBtn" style="margin-right:10px">
                            <img @click="flag=!flag" :src="flag?require('@/assets/member/xz.png'):require('@/assets/member/wxz.png')" />
                          </p>
                          <div>
                            <p>商品名称：插件压敏电阻插件压敏电阻插件压敏电阻插件压敏电</p>
                            <p>{{$t('购物车.自定义料号')}}<input type="text" /></p>
                          </div>
                        </div>
                      </td>
                      <td class="dj" style="width:135px">
                        <p>单价{{$t('我的收藏.操作')}}{{$t('公用.¥')}}{{item.price}}</p>
                      </td>
                      <td style="width:150px">
                        <p class="numEd">
                          <el-input-number v-model="item.num" size="mini" @change="handleChange" :min="1"></el-input-number>
                        </p>
                        <p>{{$t('我的收藏.库存')}}{{item.repertory}}</p>
                      </td>
                      <td style="width:110px" class="xj"><p>{{$t('公用.¥')}}{{item.price*item.num}}</p></td>
                      <td>
                        <div class="editTab hand">
                          <p>{{$t('我的收藏.取消收藏')}}</p>
                          <p>{{$t('我的收藏.加入购物车')}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div  v-if="state==2">
                <div class="selectDiv">
                  <div class="seleLeft">
                    <div class="sub">
                      <span>{{$t('我的收藏.搜索文章')}}</span>
                      <input style="width:190px" class="subInp" />
                    </div>
                  </div>
                  <div class="seleRig">
                    <p class="hand">{{$t('我的收藏.查询')}}</p>
                  </div>
                </div>
                <div class="essayDiv" v-for="item in 0">
                  <div class="essLeft">
                    <img @click="flag=!flag" :src="flag?require('@/assets/member/xz.png'):require('@/assets/member/wxz.png')" />
                    <div>
                      <p>618年中钜惠|首单礼/满额礼/Apple walch/大疆无人机等超级好礼已就绪</p>
                      <p><span>浏览量：679</span><span>点赞量：1</span><span>收藏量：1</span></p>
                    </div>
                  </div>
                  <div class="essRig hand">移除</div>
                </div>
              </div>
            </div>
            <div class="carAll" v-if="false">
              <div class="allLeft hand">
                <p class="allBtn"><img @click="flag=!flag" :src="flag?require('@/assets/member/xz.png'):require('@/assets/member/wxz.png')" /><span>{{$t('我的收藏.全选')}}</span></p>
                <p>{{$t('我的收藏.批量移除')}}</p>
                <p>{{$t('我的收藏.清空失效')}}</p>
                <p>{{$t('我的收藏.转移收藏夹')}}</p>
              </div>
              <div class="allRig">
                <div class="text">
                  <p>{{$t('我的收藏.已选')}}2{{$t('我的收藏.件商品')}}:</p>
                </div>
                <p class="catTotal">{{$t('公用.¥')}}</p>
                <div class="carBtn">{{$t('我的收藏.加入购物车')}}</div>
              </div>
            </div>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {collectProductList,collectArticleList} from '@/util/http'
export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
          loading: false,
          value:'',
          state:1,//1商品收藏，2文章收藏
          options:[],
          flag:false,
          collList:[],//商品收藏
          acrtileList:[],//文章收藏
          tableData: [
          ]
        }
    },
    created(){
      this.getColl()
      this.getArticle()
    },
    methods:{
      getColl(){
        collectProductList({pageNum:1,pageSize:20}).then(res=>{
          this.collList=res.rows;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getArticle(){
        collectArticleList({pageNum:1,pageSize:20}).then(res=>{
          this.acrtileList=res.rows;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      tabClick(num){
        this.state=num;
      },
      classifyClick(item){
        this.classifyArr.forEach(i=>{
          i.flag=false;
          if(i.id==item.id){
            i.flag=true;
            this.classifyVal=i.id;
          }
        })
      },
      handleChange(e){
        console.log(e)
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    .infoTop{
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      //margin-top: 20px;
    }
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .detailSele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: #D9D9D9 solid 1px;

      .seleLeft {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;

        p {
          padding-bottom: 11px;
        }

        span {
          color: #D9D9D9;
          margin: 0 14px;
          padding-bottom: 11px;
        }

        .active {
          font-weight: bold;
          font-size: 16px;
          color: #012F9C;
          line-height: 19px;
          border-bottom: 2px #FF6600 solid;
        }
      }
    }
    .selectDiv{
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .seleLeft{
        display: grid;
        grid-template-columns:repeat(2,1fr);
        grid-gap: 20px;
        .sub{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          .subInp{
            flex: 1;
          }
          input{
            border: 1px solid #DCDFE6;
            border-radius:4px;
            height:36px;

          }
        }
      }
      .seleRig{
        display: flex;
        align-items: center;
        p{
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
        }
        .ac{
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
      }
    }
    .tab{
      font-size: 13px;
      color: #333333;
      line-height: 22px;
      p{
        margin-bottom:8px;
        display: flex;
        align-items: center;
        input{
          width:80px;
        }
      }
      .descCss{
        display: flex;
        .goodsPhoto{
          width: 80px;
          height: 80px;
          margin-right:10px;
          border-radius: 4px;
        }
      }
      .numEd{
        display: flex;
        align-items: center;
      }
      td{
        //border: solid 1px;
      }
      .editTab{
        img{
          width: 30px;
          height: 30px;
          margin:0 5px;
        }
        p{
          width: 106px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 7px 20px;
          box-sizing: border-box;
          font-size: 13px;
          color: #FFFFFF;
          background: #FF6600;
          border-radius: 4px 4px 4px 4px;
          margin-bottom:10px;
        }
      }
      table{
        width: 100%;
        border-collapse: separate; /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px; /* 行之间的距离 */
        td{
          padding:20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }
        td:first-child{
          padding-left:20px;
        }
        td:last-child{
        }
        .trTit{
          height: 48px;
          line-height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #FFFFFF;
          th:nth-child(1){
            border-radius: 8px 0px 0px 0px;
          }
          th:last-child{
            border-radius: 0px 8px 0px 0px;
          }
        }
        .trCon{
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }
        .dj p{
          //color:#FF6600;
          text-align: center;
          display: block
        }
        .xj p{
          text-align: center;
          display: block
        }
      }

    }
    .essayDiv{
      margin-bottom: 20px;
      width: 819px;
      height: 94px;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #D0D0D0;
      padding: 10px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .essLeft{
        flex:1;
        display: flex;
        align-items: center;
        img{
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
        div{
          font-size: 16px;
          color: #333333;
          span{
            font-size: 13px;
            margin:0 30px 0 0;
            display: inline-block;
            margin-top: 14px;
          }
        }
      }
      .essRig{
        font-size: 13px;
        color: #012F9C;
        margin-left: 20px;
      }
    }
  }
  .tips {
    //width: 297px;
    //height: 44px;
    padding: 15px 20px;
    background: #FFFDED;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FF6600;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

  }
  .carAll{
    height: 70px;
    padding:0 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;
    .allLeft{
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      p{
        margin: 0 10px;
      }
    }
    .allBtn{
      display: flex;
      align-items: center;
    }
    .allRig{
      display: flex;
      align-items: center;
      .text{
        font-size: 14px;
        color: #333333;
      }
      .catTotal{
        font-weight: bold;
        font-size: 20px;
        margin:0 15px 0 0;
      }
      .carBtn{
        width: 106px;
        height: 32px;
        text-align: center;
        line-height:32px;
        font-size: 14px;
        color: #FFFFFF;
        background: #FF6600;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}
</style>
