<template>
    <div class="toolBg">
      <p class="toolTit">{{$t('首页.简单易用的在线换算工具专区')}}</p>
      <div class="iconArr w_1200">
        <div class="iconItem hand hoverTo" v-for="item in toolArr" @click="subClick(item)">
          <img :src="require('@/assets/home/'+item.img+'.png')" />
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
</template>
<script>
export default{
    components:{},
    data(){
        return{
          toolArr:[
            {id:1,name:this.$t('首页.电容换算器'),img:'i1'},
            {id:2,name:this.$t('首页.电感换算器'),img:'i2'},
            {id:3,name:this.$t('首页.长度换算器'),img:'i3'},
            {id:4,name:this.$t('首页.压力换算器'),img:'i4'},
            // {id:5,name:this.$t('首页.温度换算器'),img:'i5'},
            // {id:6,name:this.$t('首页.体积换算器'),img:'i6'},
            // {id:7,name:this.$t('首页.重量换算器'),img:'i7'},
            // {id:8,name:'BTU/焦耳换算器',img:'i8'}
          ]
        }
    },
    onload(){},
    methods:{
      subClick(item){
        this.$router.push({path:'/conversionTool',query:{num:item.id}})
      }
    }
}
</script>
<style lang="scss" scoped>
.toolBg{
    width:100%;
    height: 337px;
    background: #012F9C;
    padding: 40px 0;
    box-sizing: border-box;
  .toolTit{
    text-align: center;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 40px;
  }
  .iconArr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconItem{
      img{
        width: 132px;
        height: 132px;
        background: #FFFFFF;
        border-radius:50%;
      }
      p{
        text-align: center;
        font-size: 13px;
        color: #FFFFFF;
        margin-top: 20px;
      }
    }
  }
}
</style>
