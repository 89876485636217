<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft>
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="vipOne">
              <div class="oneLeft">
                <div class="vipInfo">
                  <div class="photo">
                    <img src="@/assets/member/mo.png" />
                    <div>
                      <p>{{$t('会员.手机')}}{{$t('公用.：')}}14375652616 <span class="hand">{{$t('会员.修改')}}</span></p>
                      <p>{{$t('会员.邮箱')}}{{$t('公用.：')}}14375652616 <span class="hand">{{$t('会员.修改')}}</span></p>
                    </div>
                  </div>
                  <p>{{$t('会员.安全')}}{{$t('公用.：')}} {{$t('会员.低')}} <span class="hand">{{$t('会员.提升')}}</span></p>
                  <p>{{$t('会员.完善资料赚积分')}}</p>
                </div>
                <div class="vipBtn hand" @click="vipClick"><p>{{$t('会员.普通会员')}}</p></div>
              </div>
              <div class="oneRight">
                <div class="twoT">
                  <p class="orderTit">{{$t('会员.会员等级权益')}}</p>
                  <span class="editBtn hand" style="margin-right:10px;">
                    <img src="@/assets/member/jf.png" />
                    <span @click="urlInfo(2)">{{$t('会员.赚取积分')}}</span>
                  </span>
                </div>
                <p class="zhi">{{$t('会员.成长值')}}{{$t('公用.：')}}<span>0</span></p>
                <ProgressBar current-points="6666"/>
              </div>
            </div>
            <div class="tips">
              <img src="@/assets/login/icon10.png" alt="" />
              <p>{{$t('会员.绑定邮箱可加强账户安全')}}<span>{{$t('会员.去绑定')}}</span></p>
            </div>
            <div class="twoVip">
              <div class="twoSub" v-for="(item,index) in funList">
                <div class="img"><img :src="require('@/assets/member/'+item.img+'.png')" /></div>
                <p>{{item.name}}<span>{{item.val}}</span></p>
              </div>
            </div>
            <div class="threeVip">
              <div class="threeLeft">
                <div class="threeSub hand" style="border-bottom:solid 2px #FFFFFF;border-right:solid 2px #FFFFFF;">
                  <div class="topImg">
                    <p>{{$t('会员.会员权益')}}</p>
                    <img src="@/assets/member/v14.png" />
                  </div>
                  <p>{{$t('会员.升等级享更多权益')}}<i class="el-icon-arrow-right" /></p>
                </div>
                <div class="threeSub hand" style="border-bottom:solid 2px #FFFFFF">
                  <div class="topImg">
                    <p>{{$t('会员.积分任务')}}</p>
                    <img src="@/assets/member/v15.png" />
                  </div>
                  <p>{{$t('会员.做任务赢翻倍积分')}}<i class="el-icon-arrow-right" /></p>
                </div>
                <div class="threeSub hand" style="border-right:solid 2px #FFFFFF;">
                  <div class="topImg">
                    <p>{{$t('会员.签到送礼')}}</p>
                    <img src="@/assets/member/v16.png" />
                  </div>
                  <p>{{$t('会员.签到送10元京东卡')}}<i class="el-icon-arrow-right" /></p>
                </div>
                <div class="threeSub hand">
                  <div class="topImg">
                    <p>{{$t('会员.每日抽奖')}}</p>
                    <img src="@/assets/member/v17.png" />
                  </div>
                  <p>{{$t('会员.抽奖赢巨多好礼')}}<i class="el-icon-arrow-right" /></p>
                </div>
              </div>
              <div class="threeRight">
                <div class="twoT">
                  <p class="orderTit" style="margin-bottom:0">{{$t('会员.商城公告')}}</p>
                  <span class="hand hover">{{$t('会员.查看更多')}}<i class="el-icon-d-arrow-right" /></span>
                </div>
                <div class="ulCss">
                  <p class="hand hover" v-for="item in 5"><span></span>免邮门槛将调整为299元</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import ProgressBar from "@/views/myInfo/component/ProgressBar.vue";
export default{
  components: {ProgressBar, carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
            loading: false,
            funList:[
                {id:1,name:this.$t('会员.余额'),img:'v1',path:'',val:'780'},
                {id:2,name:this.$t('会员.积分'),img:'v2',path:'',val:'964'},
                {id:3,name:this.$t('会员.收藏'),img:'v3',path:'',val:'75'},
                {id:4,name:this.$t('会员.未读消息'),img:'v4',path:'',val:'68'},
                {id:5,name:this.$t('会员.待审核'),img:'v5',path:'',val:'6'},
                {id:6,name:this.$t('会员.待付款'),img:'v6',path:'',val:'3'},
                {id:7,name:this.$t('会员.优惠券'),img:'v7',path:'',val:'5'},
                {id:8,name:this.$t('会员.购物车'),img:'v8',path:'',val:'23'},
                {id:9,name:this.$t('会员.待发货'),img:'v9',path:'',val:'3'},
                {id:10,name:this.$t('会员.待收货'),img:'v10',path:'',val:'5'},
                {id:11,name:this.$t('会员.投诉建议'),img:'v11',path:'',val:'23'},
                {id:12,name:this.$t('会员.删除订单'),img:'v12',path:'',val:''}
            ],
        }
    },
    onload(){},
    methods:{
      urlInfo(val){
        if(val === 1){
          this.$router.push({
            path: '/integralShop'
          })
        }
        if(val === 2){
          this.$router.push({
            path: '/signScore'
          })
        }
      },
      vipClick(){
        this.$router.push('/vipGrade')
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 18px 18px 18px 18px;
        img{
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
    }
    .tips {
      //width: 297px;
      //height: 44px;
      padding: 15px 20px;
      background: #FFFDED;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF6600;
      margin: 20px 0;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span{
        color:#012F9C;
      }
    }
    .vipOne{
      display: flex;
      justify-content: space-between;
      .oneLeft{
        width: 260px;
        height: 243px;
        box-sizing: border-box;
        background: #012F9C;
        border-radius: 4px 4px 4px 4px;
        overflow: hidden;
        .vipInfo{
          padding:15px;
          box-sizing: border-box;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 19px;
          p{
            margin-top: 20px;
          }
          .photo{
            display: flex;
            align-items: center;
            font-size: 13px;
            p{
              margin:10px 0;
            }
            img{
              border-radius: 50%;
              width: 66px;
              height: 66px;
              margin-right: 6px;
            }
          }
        }
        .vipBtn{
          width: 260px;
          margin-top: 12px;
          height: 56px;
          background: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          p{
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 35px;
            background: #012F9C;
            border-radius: 18px 18px 18px 18px;
          }
        }
      }
      .oneRight{
        padding: 20px;
        box-sizing: border-box;
        width: 625px;
        height: 243px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .zhi{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          span{
            font-weight: bold;
            font-size: 24px;
            color: #FF6600;
          }
        }
        .grade{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
             margin: 10px 0;
          div{
            display: flex;
            align-items: center;
            margin:10px 0;
          }
          p{
            font-weight: 400;
            font-size: 13px;
            color: #FF6600;
            line-height: 15px;
            padding: 5px 10px;
            border-radius: 15px 15px 15px 15px;
            border: 1px solid #FF6600;
          }
          span{
            display: inline-block;
            height: 2px;
            background: #E4EFFF;
            width:60px;
            flex: 1;
          }
        }
        .vipTips{
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          line-height: 15px;
        }
      }
    }
    .twoVip{
      width: 899px;
      height: 236px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
      margin-bottom: 20px;
      .twoSub{
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        line-height: 15px;
        //border: solid 1px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom:10px;
        span{
          color: #FF6600;
        }
        .img{
          margin-bottom: 10px;
          width: 66px;
          height: 66px;
          border-radius: 50%;
          background: #F5F6F9;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 36px;
            height: 36px;
          }
        }
      }
    }
    .threeVip{
      display: flex;
      justify-content: space-between;
      .threeLeft{
        padding:20px;
        box-sizing: border-box;
        width: 437px;
        height: 218px;
        background: #1E212B;
        border-radius: 4px 4px 4px 4px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .threeSub{
          padding: 10px 38px;
          box-sizing: border-box;
          //margin:10px 0;
          font-weight: 400;
          font-size: 13px;
          color: #FFFFFF;
          line-height: 15px;
          .topImg{
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 19px;
            img{
              width: 44px;
              height: 44px;
              margin-left: 6px;
            }
          }
        }
      }
      .threeRight{
        padding:20px;
        box-sizing: border-box;
        width: 438px;
        height: 219px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .ulCss{
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          p{
            margin-top: 13px;
          }
          span{
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #FF6600;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
