<template>
  <div class="w_1200">
    <div class="banner" @mouseleave="classifyPop = false">
      <div class="left">
        <div class="l_top">
          <!-- <div class="l_t_item hand hover" @mouseover="showCategoryTwo(item.id, item.categoryName)" v-for="(item, index) in left" :key="index"> -->
            <div class="l_t_item hand hover" @click="showCategoryTwo(item.id, item.categoryName)" v-for="(item, index) in left" :key="index">
            <img :src="baseUrl + item.photo" />
            {{ $i18n.locale == "zh" ? item.categoryName : item.categoryNameEn }}
          </div>
        </div>
        <div class="l_btm hover hand">
          <router-link to="/catalogueOne">{{
            $t("首页.查看全部分类")
          }}</router-link>
        </div>
      </div>
      <div class="classifyDiv" v-if="classifyPop" @mouseleave="classifyPop = false">
        <div class="classArr">
          <div class="classifyTit">
            <span>{{ centerName }}</span
            >&gt;
          </div>
          <div class="chassModule">
            <div class="classMItem" v-for="(item, index) in center" :key="item.id">
              <p class="classMP hand hover" @click="similarityClick(item)">
                {{$i18n.locale == "zh" ? item.categoryName : item.categoryNameEn}}
              </p>
              <p class="classMS" v-if="index < center.length - 1">|</p>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <!-- <div class="c_cover"> </div> -->
        <div class="c_main">
          <div class="c_m_top hand">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="item in swiperList"
                  :key="item.id"
                >
                  <img :src="baseUrl + item.bannerUrl" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="c_m_btm">
            <img class="hand" v-for="(item,index) in classList" :src="$baseUrl+item.brandShowPic" alt="" />
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="r_item hand hover"
          @click="goUrl(item)"
          v-for="(item, index) in right"
          :key="index"
          v-if="item.f"
        >
          <img
            :src="require('@/assets/home/icon/' + 'r_' + (index + 1) + '.png')"
          />
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {categoryList, swiperList, SystemConfigurationInfoDetailed} from "@/api/api";
import{brandBotList} from '@/util/http'
export default {
  components: {},
  data() {
    return {
      classifyPop: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      left: [],
      center: [],
      classList:[],//分类专区
      right: [
        {
          id:1,
          text: this.$t("首页.免费样品申请"),
          url: "/sample",
          f:true,
        },
        {
          id:2,
          text: this.$t("首页.最新活动"),
          url: "/newActivity",
          f:true,
        },
        {
          id:3,
          text: this.$t("首页.高校计划"),
          url: "/college",
          f:true,
        },
        // {
          //          id:4,
        //   text: this.$t("首页.新人大礼包"),
          //          f:true,
        // },
        {
          id:5,
          text: this.$t("首页.积分商城"),
          url: "/integralShop",
          f:true,
        },
        {
          id:6,
          text: this.$t("首页.品牌资源"),
          url: "/brandResource",
          f:true,
        },
        {
          id:7,
          text: this.$t("首页.供应商合作"),
          url: "/supplier",
          f:true,
        },
        {
          id:8,
          text: this.$t("首页.领券中心"),
          url: "/coupon",
          f:true,
        },
      ],
      rightName: "",
      swiperList: [],
      systemConfigurationInfo:{},
    };
  },
  mounted() {
    this.getData();
    this.getClass()
    this.getSwiperListHandle();
    new Swiper(".swiper-container", {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        dynamicMainBullets: 2,
        clickable: true,
      },
    });
    this.load();
  },
  methods: {
    load(){
      SystemConfigurationInfoDetailed().then(res=>{
        console.log(res)
        this.systemConfigurationInfo = res.data
        localStorage.setItem("systemConfigurationInfo",JSON.stringify(this.systemConfigurationInfo))
        this.right[0].f = this.systemConfigurationInfo.freeSampleRequest
        this.right[1].f = this.systemConfigurationInfo.latestActivities
        this.right[2].f = this.systemConfigurationInfo.collegePlan
        this.right[3].f = this.systemConfigurationInfo.pointsMall
        this.right[4].f = this.systemConfigurationInfo.brandResources
        this.right[5].f = this.systemConfigurationInfo.supplierCooperation
        this.right[6].f = this.systemConfigurationInfo.voucherCollectionCenter
      })
    },
    similarityClick(item){
        this.$router.push({
          path: '/catalogueTwo',
          query: {
            classfyId: item.id
          }
        })
      },
    getClass(){
      brandBotList().then(res=>{
        console.log(111111)
        this.classList = res.data
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    goUrl(item) {
      this.$router.push(item.url);
    },
    async getData() {
      const res = await categoryList({
        parentId: 0,
      });
      this.left = res.data;
      console.log(this.left, "left");
    },
    async getSwiperListHandle() {
      const res = await swiperList({});
      console.log("swiper:", res);
      this.swiperList = res.rows;
    },
    async showCategoryTwo(parentId, name) {
      const res = await categoryList({
        parentId,
      });
      this.center = res.data;
      this.centerName = name;
      this.classifyPop = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  padding-top: 30px;
  display: flex;
  height: 453px;
  color: #000000;
  justify-content: space-between;
  .left::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
  .left {
    box-sizing: border-box;
    padding: 20px 14px;
    width: 242px;
    background-color: #ffffff;
    border-radius: 6px 6px 6px 6px;
    overflow-y: auto;
    .l_top {
      display: flex;
      min-width: 0;
      flex-wrap: wrap;

      .l_t_item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        width: 106px;
        height: 40px;
        // display: flex;
        align-items: center;

        img {
          top: 4px;
          position: relative;
          margin-right: 5px;
          // width: 20px;
          // height: 20px;
        }
      }
    }

    .l_top .l_t_item:nth-child(2n) {
      box-sizing: border-box;
      padding-left: 14px;
    }

    .l_btm {
      font-size: 13px;
      text-align: center;
    }
  }

  .center {
    box-sizing: border-box;
    width: 676px;
    position: relative;

    .c_cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: skyblue;
    }

    .c_main {
      box-sizing: border-box;
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      //   background-color: skyblue;
      box-sizing: border-box;

      .c_m_top {
        width: 100%;
        height: 312px;
        // background: pink;
      }

      .c_m_btm {
        display: flex;
        justify-content: space-between;
        img{
          width: 198px;
          height: 125px;
          border-radius: 6px 6px 6px 6px;
        }
      }
    }
  }

  .right {
    width: 282px;
    background-color: #ffffff;
    padding: 20px 30px 30px 30px;
    border-radius: 6px 6px 6px 6px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .r_item {
      margin-bottom: 19px;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 88px;
      font-size: 13px;

      img {
        margin-bottom: 5px;
        width: 54px;
        height: 54px;
        border-radius: 6px 6px 6px 6px;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 6px 6px;

  .swiper-slide {
    overflow: hidden;
    border-radius: 6px 6px 6px 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 6px 6px;
    }
  }
}

.classifyDiv {
  position: absolute;
  left: 242px;
  z-index: 100;
  width: 674px;
  height: 452px;
  border-radius: 4px;
  border: solid 1px #4f77d3;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;

  .classArr {
  }

  .classifyTit {
    font-size: 16px;
    color: #888;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-weight: 600;
      margin-right: 10px;
      font-size: 18px;
      color: #333333;
    }
  }

  .chassModule {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #888;

    .classMItem {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;

      .classMP {
      }

      .classMS {
        margin: 0 15px;
        color: #d2d2d2;
      }
    }
  }
}
</style>
