<template>
  <div v-loading="loading" class="indexBg">
    <topNav :active="4"></topNav>
    <div class="bomBanner">
      <img class="bomBan" src="@/assets/sub/bomBanner.png"/>
      <div class="bomModule w_1200">
        <div class="newTit">
          <span v-for="(item,index) in titArr" :class="item.flag?'newActive':''" class="hand hover"
                @click="tabClick(item)">{{ item.tit }}</span>
        </div>
        <div class="bomMsg">
          <p>{{ $t('BOM.询价说明') }}</p>
          <p>1、{{ $t('BOM.如采购数量接近整包装数量') }}</p>
          <p>2、{{ $t('BOM.为保证服务质量和效率') }}</p>
        </div>
        <div v-if="titVal==1">
          <div class="bomBtn">
            <span class="active">{{ $t('BOM.多型号拷贝') }}</span>
            <span class="hand" @click="tabClick(2)">{{ $t('BOM.Excel导入') }}</span>
          </div>
          <div class="textInp">
            <el-input v-model="value" :placeholder="$t('BOM.品牌')" :rows="13"
                      type="textarea"></el-input>
            <el-input v-model="value2" :placeholder="$t('BOM.参数描述')" :rows="13"
                      type="textarea"></el-input>
            <el-input v-model="value3" :placeholder="$t('BOM.需求数量')" :rows="13"
                      type="textarea"></el-input>
          </div>

          <div class="bomBot">
            <p class="claBtn hand">{{ $t('BOM.清除') }}</p>
            <div>
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="$t('BOM.只匹配自营商品')"></el-checkbox>
              </el-checkbox-group>
              <p class="hand" @click="load()">{{ $t('BOM.开始匹配BOM') }}</p>
            </div>
          </div>
        </div>
        <div v-if="titVal==2" class="bomDou">
          <div class="bomSub">
            <p><span>*</span>{{ $t('BOM.上传BOM需求文件') }}</p>
            <div class="exDiv hand">
              <!--              <img src="@/assets/sub/ex.png"/>-->
              <!--              <span>{{ $t('BOM.选择文件') }}</span>-->

              <el-upload
                  :action="uploadUrlInfo"
                  :before-upload="beforeAvatarUpload"
                  :headers="headers"
                  :on-success="handleAvatarSuccess" :show-file-list="false"
                  class="upload-demo" drag
                  multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">{{$t('BOM.将文件拖到此处')}}<em>{{$t('BOM.点击上传')}}</em></div>
              </el-upload>
            </div>
          </div>
          <div class="bomSub">
            <p>{{ $t('BOM.备注') }}</p>
            <div class="textInp2">
              <el-input v-model="value4" :placeholder="$t('BOM.请输入询价的备注内容')" :rows="13"
                        class="textInp"
                        type="textarea"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {uploadUrl} from "@/util/http";
import Cookies from "js-cookie";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      titArr: [
        {id: 1, tit: this.$t('BOM.智能配单'), url: '', flag: true},
        {id: 2, tit: this.$t('BOM.上传BOM需求文件'), url: '', flag: false},
      ],
      value: "",
      value2: "",
      value3: "",
      value4: "",
      imageUrl: "",
      uploadUrlInfo: uploadUrl,
      headers: {
        Authorization: Cookies.get('token'),
      },
      titVal: 1,
      checkList: []
    }
  },
  onload() {
  },
  methods: {
    load() {
      this.$router.push({
        path: '/catalogueTwo?classfyId=72&categoryName=电阻&categoryNameEn=电阻&num=2',
        query: {
          navVal: '4-7'
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.fileName;
      // URL.createObjectURL(file.raw);
      this.load()
    },
    beforeAvatarUpload(file) {
      return true;
    },
    tabClick(item) {
      this.titArr.map((i) => {
        i.flag = false;
        if (item.id == i.id) {
          i.flag = true;
          this.titVal = item.id;
        } else if (item == i.id) {
          i.flag = true;
          this.titVal = item;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .bomBanner {
    .bomBan {
      width: 100%;
      height: 500px;
    }

    .bomModule {
      margin: 40px auto;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      padding: 60px;
      box-sizing: border-box;

      .newTit {
        font-size: 20px;
        color: #333333;

        span {
          margin-right: 30px;
        }

        .newActive {
          font-weight: bold;
          font-size: 26px;
          color: #012F9C;
          padding-bottom: 8px;
          border-bottom: 4px solid #FF6600;
        }
      }

      .bomMsg {
        margin: 50px 0;
        font-size: 13px;
        color: #999999;
        line-height: 15px;

        p {
          margin-top: 10px;

        }
      }

      .bomBtn {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          color: #999999;
          margin-right: 20px;
        }

        .active {
          color: #333333;
        }
      }

      .textInp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }

      .textInp2 {
        width: 400px;

        :deep .el-textarea {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        :deep .el-textarea__inner {
          box-sizing: border-box;
          margin: 10px 0;
          width: 100%;
          height: 320px;
          background: #F2F2F2;
          border-radius: 8px 8px 8px 8px;
          padding: 20px;
          border: none;
        }
      }

      :deep .el-textarea {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 30%;
      }

      :deep .el-textarea__inner {
        box-sizing: border-box;
        margin: 10px 0;
        width: 100%;
        height: 320px;
        background: #F2F2F2;
        border-radius: 8px 8px 8px 8px;
        padding: 20px;
        border: none;
      }

      .bomBot {
        margin-top: 10px;
        display: flex;
        width: 456px;
        justify-content: space-between;

        .claBtn {
          font-size: 16px;
          color: #FF0000;
        }

        div p {
          width: 118px;
          line-height: 32px;
          text-align: center;
          background: #FF0000;
          border-radius: 4px;
          font-size: 13px;
          color: #FFFFFF;
          margin-top: 25px;
        }
      }

      .bomDou {
        display: flex;

        .bomSub {
          margin-right: 60px;

          p {
            font-size: 16px;
            color: #333333;

            span {
              color: #ad0000;
            }
          }

          .exDiv {
            margin-top: 10px;
            width: 456px;
            height: 320px;
            background: #F2F2F2;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #666666;

            img {
              width: 42px;
              height: 42px;
              margin-right: 10px;
            }
          }
        }
      }

    }
  }
}
</style>
