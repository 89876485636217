<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="twoModule w_1200">
        <div class="twoTop">
          <brandLeft ref="childClick" :detail="detail"></brandLeft>
          <div class="rigIntro">
            <div style="display: flex;align-items: center;justify-content: space-between">
              <p class="infroTit">{{$t('品牌专区.授权信息')}}</p>
              <div class="classSera">
                <input type="text" v-model="search" :placeholder="$t('品牌专区.请输入类别关键字')" />
                <div class="hand" @click="getData">{{$t('品牌专区.搜索')}}</div>
              </div>
            </div>
            <div class="classDetail">
              <div class="classItem" v-if="item.children.length>0" v-for="item in list" @click="goUrl(item)">
                <div class="clasTit hand hover">
                  <span>{{ $i18n.locale == "zh" ?item.categoryName:item.categoryNameEn }}</span>
                  ({{ item.num }})
                  <img src="@/assets/sub/claRig.png" alt="">
                </div>
                <ul>
                  <li class="hand" v-for="(item2,index2) in item.children" @click.stop="goUrl(item2)">
                    <span class="hover">{{ $i18n.locale == "zh" ?item2.categoryName:item2.categoryNameEn }}({{ item2.num }})</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import brandLeft from '@/component/home/brandLeft.vue'
import {productLine} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav, brandLeft },
  data(){
        return{
            loading: false,
            search:'',
            list:[]
        }
    },
    onload(){},
    mounted() {
      // 页面加载时立即调用子组件方法
      this.$refs.childClick.tabClick(3)
      this.getData();
  },
    methods:{
      goUrl(item) {
        this.$router.push({ path: "/catalogueTwo",query: { classfyId: item.id}})
      },
      async getData() {//查询子类
      const res = await productLine({
        categoryName:this.search,
        id:Cookies.get('brandId')
      });
      this.list = res.data;
    },
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .twoModule{
    margin: 40px auto;
    .twoTop {
      display: flex;
      justify-content: space-between;
      .rigIntro {
        width: 900px;
        background: #FFFFFF;
        padding: 24px 16px;
        box-sizing: border-box;
        .infroTit{
          font-weight: bold;
          font-size: 20px;
          color: #000000;
          padding-left: 15px;
          border-left: solid 4px #0332A1;
          //margin-bottom: 25px;
        }
        .classSera{
          display: flex;
          align-items: center;
          input{
            height: 40px;
            width: 284px;
            background: #FFFFFF;
            text-indent: 1rem;
            border: 1px solid #CCCCCC;
          }
          div{
            width: 93px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #012F9C;
            font-size: 18px;
            border: 1px solid #012F9C;
            color: #FFFFFF;
          }
        }
        .classDetail{
          margin-top: 30px;
          display: grid;
          grid-template-columns: repeat(2,1fr);
          .classItem{
            width: 380px;
            height:280px;
            box-sizing: border-box;
            background: #fff;
            overflow: hidden;
            border: 1px solid #EEEEEE;
            .clasTit{
              background: #F5F6F9;
              font-size: 20px;
              color: #000000;
              padding:10px 30px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              img{
                width: 10px;
                height: 19px;
                margin-left: 10px;
              }
            }
            ul{
              padding:30px 50px;
              li{
                list-style: disc;
                padding:8px 0;
                color: #012F9C;
                span{
                  font-size: 18px;
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>