<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <div class="indexModule w_1200">
        <div class="indexLeft">
          <p class="orderTit">{{$t('订单信息.支付信息')}}</p>
          <div class="info">
            <p>{{$t('订单信息.订单提交成功')}}</p>
            <p>{{$t('订单信息.订单号')}}2406281524515158</p>
            <p>{{$t('订单信息.请您在24小时内完成支付')}}</p>
          </div>
          <div class="total">
            <p>{{$t('订单信息.应付总额')}} <span>{{$t('公用.¥')}}1,243.00</span></p>
            <div class="hand" @click="orderClick">{{$t('订单信息.订单详情')}}</div>
          </div>
        </div>
        <div class="indexRight">
          <div class="twoT">
            <p class="orderTit">{{$t('订单信息.微信支付')}}</p>
            <span class="editBtn hand">{{$t('订单信息.其他支付方式')}}</span>
          </div>
          <div class="ewm">
            <img src="@/assets/member/qe.png" />
            <div>
              <p>{{$t('订单信息.请使用微信扫一扫')}}</p>
              <p>{{$t('订单信息.扫描二维码支付')}}</p>
            </div>
          </div>
          <p class="msg">{{$t('订单信息.距离二维码过期还剩')}}40{{$t('订单信息.秒')}}</p>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
export default{
  components: {carTopNav, bottomNav },
  data(){
        return{
            loading: false
        }
    },
    onload(){},
    methods:{
      orderClick(){
        this.$router.push({path:'/orderState',query:{subIndex:1}})
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .indexModule{
    margin:60px auto;
    display: flex;
    justify-content: space-between;
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        width: 98px;
        height: 26px;
        line-height: 26px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .indexLeft{
      width: 588px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      box-sizing: border-box;
      .info{
        font-weight: 400;
        width: 306px;
        font-size: 14px;
        color: #333333;
        line-height:20px;
        p{
          margin-bottom: 10px;
        }
        p:last-child{
          margin-top: 20px;
        }
      }
      .total{
        text-align: right;
        margin-top: 100px;
        p {
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          span {
            vertical-align: text-top;
            font-weight: bold;
            font-size: 14px;
            color: #FF6600;
            line-height: 15px;
            display: inline-block;
          }
        }
        div{
          font-size: 12px;
          color: #012F9C;
          line-height: 13px;
          margin-top: 7px;
          padding-right:50px;
        }
      }
    }
    .indexRight{
      width: 588px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      box-sizing: border-box;
      .ewm{
        width: 270px;
        //height: 300px;
        //padding: 20px;
        text-align: center;
        box-sizing: border-box;
        margin: auto;
        img{
          width: 200px;
          height: 200px;
        }
        div{
          text-align: center;
          margin-top: 10px;
          font-size: 14px;
          color: #fff;
          line-height: 20px;
          background:#f27c7c;
          padding:5px 0;
        }
      }
      .msg{
        font-size: 13px;
        color: #333333;
        margin: 15px 0;
        text-align: center;
      }
    }
  }
}
</style>