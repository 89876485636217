import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/style/base.css"
import "@/assets/style/public.css"
import "@/assets/style/color.css"
import "swiper/css/swiper.min.css";
import VueClipboard from "vue-clipboard2";

import i18n from './lang/index.js';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
import $ from 'jquery'
import dragVerify from 'vue-drag-verify2'
// import { Value } from 'sass';

Vue.prototype.$baseUrl=process.env.VUE_APP_BASE_URL
Vue.prototype.$searchMain = ''
Vue.prototype.$pop = false;

Vue.config.productionTip = false
Vue.use(dragVerify)
Vue.use(ElementUI);
Vue.use(router)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')

