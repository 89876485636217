<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="basicModule" v-if="!detailState">
          <div class="basicTop">
            <div class="basicLeft divCss">
              <p class="orderTit">{{$t('账户安全.账户安全登录信息')}}</p>
              <div class="basicDiv">
                <p>{{$t('账户安全.账号')}}{{$t('公用.：')}}CC</p>
                <p>{{$t('账户安全.邮箱')}}{{$t('公用.：')}}16799066564</p>
                <p>{{$t('账户安全.最近登录')}}{{$t('公用.：')}}{{$t('账户安全.普通会员')}}<span class="hand" @click="detailState=true">{{$t('账户安全.详情')}}</span></p>
              </div>
            </div>
            <div class="basicRight divCss">
              <p class="orderTit">{{$t('账户安全.安全等级')}}</p>
              <el-progress :percentage="80" />
            </div>
          </div>
          <div class="accountDottom">
            <div class="accDSub">
              <div class="iconDiv">
                <div>
                  <img src="@/assets/member/pwd.png" />
                  <p>{{$t('账户安全.密码强度')}}</p>
                </div>
                <img src="@/assets/member/ed.png" />
              </div>
              <div class="textSub">
                <div class="left">
                  <p>{{$t('账户安全.密码强度为')}}{{$t('公用.：')}}{{$t('账户安全.低')}}</p>
                  <p>{{$t('账户安全.高强度密码且定期更换密码可以使账号更安全')}}</p>
                </div>
                <div class="right hand">{{$t('账户安全.修改')}}</div>
              </div>
            </div>
            <div class="accDSub">
              <div class="iconDiv">
                <div>
                  <img src="@/assets/member/email.png" />
                  <p>{{$t('账户安全.绑定邮箱')}}</p>
                </div>
                <img src="@/assets/member/payxz.png" />
              </div>
              <div class="textSub">
                <div class="left">
                  <p>{{$t('账户安全.已绑定邮箱')}}{{$t('公用.：')}}16799066564</p>
                </div>
                <div class="right hand">{{$t('账户安全.修改')}}</div>
              </div>
            </div>
            <div class="accDSub">
              <div class="iconDiv">
                <div>
                  <img src="@/assets/member/pwd.png" />
                  <p>{{$t('账户安全.绑定微信')}}</p>
                </div>
                <img src="@/assets/member/ed.png" />
              </div>
              <div class="textSub">
                <div class="left">
                  <p>{{$t('账户安全.微信账号')}}{{$t('公用.：')}}{{$t('账户安全.自由')}}</p>
                  <p>{{$t('账户安全.若微信丢失或停用')}}</p>
                </div>
                <div class="right hand" @click="wxDialog=true">{{$t('账户安全.绑定微信')}}</div>
              </div>
            </div>
            <div class="accDSub">
              <div class="iconDiv">
                <div>
                  <img src="@/assets/member/ph.png" />
                  <p>{{$t('账户安全.绑定手机')}}</p>
                </div>
                <img src="@/assets/member/payxz.png" />
              </div>
              <div class="textSub">
                <div class="left">
                  <p>{{$t('账户安全.已经绑定手机')}}{{$t('公用.：')}}18888888888</p>
                </div>
                <div class="right hand">{{$t('账户安全.修改')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="basicModule" v-if="detailState">
          <div style="background:#fff;border-radius:4px;padding:20px 40px;">
            <p class="orderTit">{{$t('账户安全.登录情况')}}</p>
            <el-table :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column prop="num" :label="$t('账户安全.登录时间')"></el-table-column>
              <el-table-column prop="type" :label="$t('账户安全.登录IP')"></el-table-column>
              <el-table-column prop="num2" :label="$t('账户安全.登录地点')"></el-table-column>
              <el-table-column width="115px" prop="num3" :label="$t('账户安全.登录方式')"></el-table-column>
            </el-table>
          </div>
          <p class="tips">
            <img src="@/assets/login/icon10.png" alt="" />
            <span>{{$t('账户安全.登录记录若存在非本人或异地登录记录')}}<span class="hand" style="color:#012F9C">{{$t('账户安全.修改登录密码')}}</span></span>
          </p>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog width="626px" :title="$t('账户安全.绑定微信')" :visible.sync="wxDialog">
      <div class="dialogThreeCss">
        <img src="@/assets/member/qe.png" />
        <p>{{$t('账户安全.微信扫码绑定')}}</p>
        <div @click="wxDialog=false">{{$t('账户安全.提交')}}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      phoneDialog: false,//修改手机号
      emainDialog: false,//修改邮箱
      value:'',
      options:[],
      wxDialog:false,//微信弹窗
      detailState:false,//登录详情页面
      tableData: [
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
        {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'}
      ]
    }
  },
  onload() {
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? '满' : `${percentage}%`;
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;
  .basicModule{
    .divCss{
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .basicTop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .basicLeft{
        width: 525px;
        height: 172px;
        .basicDiv{
          font-size: 14px;
          color: #333333;
          line-height: 15px;
          p{
            margin-top: 13px;
          }
          .two{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          span{
            font-size: 14px;
            color: #012F9C;
            margin-left: 10px;
          }
        }
      }
      .basicRight{
        width: 350px;
        height: 172px;
        font-size: 16px;
        color: #000000;
        img{
          width: 118px;
          height: 103px;
          margin-bottom: 10px;
        }
      }
      :deep .el-progress-bar__inner{
        background-color: #ff6601;
      }
      :deep .el-progress-bar__outer{
        height: 10px !important;
      }
    }
    .tips {
      //width: 297px;
      //height: 44px;
      padding: 15px 20px;
      background: #FFFDED;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF6600;
      margin-top: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span{
        color: #FF6600;
      }
    }
    .accountDottom{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 20px;
      .accDSub{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 437px;
        height: 154px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        padding: 20px;
        box-sizing: border-box;
        .iconDiv{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 19px;
          img{
            width: 54px;
            height: 54px;
          }
          img:first-child{
            width: 34px;
            height: 34px;
            margin-bottom: 10px;
          }
        }
        .textSub{
          display: flex;
          align-items: end;
          justify-content: space-between;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          line-height: 15px;
          .left{
            p{
              margin-top: 5px;
            }
          }
          .right{
            color: #012F9C;
            margin-right: 8px;
          }
        }
      }
    }
  }
 :deep .el-dialog__title{
    font-size: 24px;
    color: #333333;
    line-height: 28px;
  }
  .dialogThreeCss{
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    line-height: 19px;
    img{
      width: 287px;
      height: 287px;
    }
    p{
      width: 150px;
      margin:20px auto;
    }
    div{
      width: 170px;
      height: 52px;
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;
      margin: auto;
    }
  }
  :deep .el-table th.el-table__cell{
    background: #012F9C;
    color:#fff;
  }
  :deep th:last-child{
    border-radius:0 8px 0 0;
  }
  :deep th:first-child{
    border-radius:8px 0 0 0;
  }
}
</style>