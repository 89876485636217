import request from '@/util/request'


export function userIntegralList(data) {
  return request({
    url: `/zgkj/userIntegral/list`,
    method: 'GET',
    params: data
  })
}

export function getListMaterialNumber(data) {
  return request({
    url: `/zgkj/materialNumber/getList`,
    method: 'GET',
    params: data
  })
}




