import request from '../util/request'
import Cookies from "js-cookie";

let lang = Cookies.get('lang')//从缓存中获取语言类型

//关于我们
export function aboutUs(params) {
  return request({
    method: 'GET',
    url: '/zgkj/aboutUs/list',
    params: params
  })
}

//关于我们-优势模块
export function abousUsAdvantage(params) {
  return request({
    method: 'GET',
    url: '/zgkj/abousUsAdvantage/list',
    params: params
  })
}

//关于我们-贞光大事件
export function aboutUsIncident(params) {
  return request({
    method: 'GET',
    url: '/zgkj/aboutUsIncident/list',
    params: params
  })
}

//查询荣誉风采列表
export function honorMien(params) {
  return request({
    method: 'GET',
    url: '/zgkj/honorMien/list',
    params: params
  })
}

//查询荣誉风采-团队风采列表
export function honorTeam(params) {
  return request({
    method: 'GET',
    url: '/zgkj/honorTeam/list',
    params: params
  })
}

//查询荣誉风采-我们的优势列表
export function honorAdvantage(params) {
  return request({
    method: 'GET',
    url: '/zgkj/honorAdvantage/list',
    params: params
  })
}

//查询联系我们列表
export function contactUs(params) {
  return request({
    method: 'GET',
    url: '/zgkj/contactUs/list',
    params: params
  })
}

//授权代理 查询所有商品品牌
export function productBrand(params) {
  return request({
    method: 'GET',
    url: '/zgkj/productBrand/list',
    params: params
  })
}

//授权代理 查询合作伙伴列表
export function cooperativePartner(params) {
  return request({
    method: 'GET',
    url: '/zgkj/cooperativePartner/list',
    params: params
  })
}

//帮助中心-登录注册
export function typeDl(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeDl',
    params: params
  })
}

//帮助中心-BOM配单
export function typeBOM(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeBOM',
    params: params
  })
}

//帮助中心-我的钱包
export function typeQb(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeQb',
    params: params
  })
}

//帮助中心-找回密码
export function typeZmm(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeZmm',
    params: params
  })
}

//帮助中心-收藏功能
export function typeSc(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeSc',
    params: params
  })
}

//帮助中心-购物流程
export function typeGw(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeGw',
    params: params
  })
}

//帮助中心-商品搜索
export function typeSp(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeSp',
    params: params
  })
}

//帮助中心-优惠券规则
export function typeYh(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeYh',
    params: params
  })
}

//帮助中心-合同下载
export function typeHt(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeHt',
    params: params
  })
}

//帮助中心-订单管理
export function typeDd(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeDd',
    params: params
  })
}

//帮助中心-账户信息
export function typeZh(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeZh',
    params: params
  })
}

//帮助中心-支付安全
export function typeZfSafe(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeZfSafe',
    params: params
  })
}

//帮助中心-运费说明
export function typeYf(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeYf',
    params: params
  })
}

//帮助中心-支付方式
export function typeZf(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeZf',
    params: params
  })
}

//帮助中心-售后服务
export function typeSH(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeSH',
    params: params
  })
}

//帮助中心-重新交付
export function typeJf(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeJf',
    params: params
  })
}

//帮助中心-发票须知
export function typeFp(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeFp',
    params: params
  })
}

//帮助中心-隐私协议
export function typeYs(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeYs',
    params: params
  })
}

//帮助中心-免责声明
export function typeMz(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeMz',
    params: params
  })
}

//帮助中心-注册协议
export function typeZc(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeZc',
    params: params
  })
}

//帮助中心-销售条款
export function typeXs(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeXs',
    params: params
  })
}

//帮助中心-订单提交协议
export function typeDdTj(params) {
  return request({
    method: 'GET',
    url: '/zgkj/helpCenter/typeDdTj',
    params: params
  })
}

//查询商品分类列表
export function productCategory(params) {
  return request({
    method: 'GET',
    url: '/zgkj/productCategory/list',
    params: params
  })
}

//查询商品列表
export function product(params) {
  return request({
    method: 'GET',
    url: '/zgkj/product/getList',
    params: params
  })
}

//新增用户样品篮
export function userSampleBasket(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userSampleBasket',
    data: data
  })
}

//查询我的样品蓝列表
export function basketGetList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userSampleBasket/getList',
    params: params
  })
}

//删除用户样品蓝
export function basketGetDel(ids) {
  return request({
    method: 'DELETE',
    url: `/zgkj/userSampleBasket/${ids}`
  })
}

//查询样品记录分类列表
export function sampleBasketApplySort(params) {
  return request({
    method: 'GET',
    url: '/zgkj/sampleBasketApplySort/list',
    params: params
  })
}

//新增样品申请
export function sampleBasketApply(data) {
  return request({
    method: 'POST',
    url: '/zgkj/sampleBasketApply',
    data: data
  })
}

//查询地址簿列表
export function userAddress(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userAddress/getList',
    params: params
  })
}

//代理品牌商品精选推荐
export function getHandpickList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/product/getHandpickList',
    params: params
  })
}

//获取商品详细信息
export function productDetail(id) {
  return request({
    method: 'GET',
    url: `/zgkj/product/${id}`
  })
}

//查询商品品牌新闻列表
export function tidingsList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/tidings/list`,
    params: params
  })
}

//查询所有商品品牌
export function getAll(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/getAll`,
    params: params
  })
}

//新增用户购物车
export function userShoppingCar(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userShoppingCar',
    data: data
  })
}

//下单的时候新增用户购物车
export function userShoppingCar2(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userShoppingCar/add2',
    data: data
  })
}

//查询我的购物车列表
export function shoppingCarList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userShoppingCar/getList',
    params: params
  })
}

//修改用户购物车
export function editShoppingCar(data) {
  return request({
    method: 'PUT',
    url: '/zgkj/userShoppingCar',
    data: data
  })
}

//删除用户购物车
export function delCar(ids) {
  return request({
    method: 'DELETE',
    url: `/zgkj/userShoppingCar/${ids}`,
  })
}

//热销商品
export function productList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userShoppingCar/productList',
    params: params
  })
}

//我的收藏
export function collectList(params) {
  return request({
    method: 'GET',
    // url: '/zgkj/userShoppingCar/collectList',
    url: '/frontEnd/productUserLog/getList',
    params: params
  })
}

//我购买过的
export function buyList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userShoppingCar/buyList',
    params: params
  })
}

//新增购物车下单
export function insertSave(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userShoppingCarInfo/insertSave',
    params: params
  })
}

//查询用户购物车列表
export function carList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userShoppingCarInfo/list',
    params: params
  })
}

//查询我的地址簿列表
export function getAddList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userAddress/getList',
    params: params
  })
}

//删除地址簿
export function delAddress(ids) {
  return request({
    method: 'DELETE',
    url: `/zgkj/userAddress/${ids}`,
  })
}

//查询配送方式列表
export function regionDelivery(params) {
  return request({
    method: 'GET',
    url: '/zgkj/regionDelivery/list',
    params: params
  })
}

//查询我的发票资料列表
export function invoiceGetList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userInvoice/getList',
    params: params
  })
}

//新增发票资料
export function userInvoice(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userInvoice',
    data: data
  })
}

//查询下单联系人列表
export function userLinkman(params) {
  return request({
    method: 'GET',
    url: '/zgkj/userLinkman/list2',
    params: params
  })
}

//新增下单联系人
export function addLinkman(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userLinkman/insertSave',
    data: data
  })
}

//下单
export function placeOrder(data) {
  return request({
    method: 'POST',
    url: '/zgkj/order/insertSave',
    data: data
  })
}

//前端查询优惠券列表
export function frontEndList(params) {
  return request({
    method: 'GET',
    url: '/zgkj/discountCoupon/frontEndList',
    params: params
  })
}

//领取优惠券
export function receive(params) {
  return request({
    method: 'GET',
    url: '/zgkj/discountCoupon/receive',
    params: params
  })
}

//获取用户积分详细信息
export function userIntegral(id) {
  return request({
    method: 'GET',
    url: `/zgkj/userIntegral/${id}`
  })
}

//获取用户积分详细信息
export function selectUser(params) {
  return request({
    method: 'GET',
    url: `/selectUserDetailsByUserId`,
    params: params
  })
}

//查询积分商城列表
export function creditsShop(params) {
  return request({
    method: 'GET',
    url: `/zgkj/creditsShop/list`,
    params: params
  })
}

//查询所有商品品牌
// export function getAllBrand(params) {
//     return request({
//         method:'GET',
//         url:`/zgkj/productBrand/getAll`,
//         params:params
//     })
// }
//新增供应商合作
export function supplier(data) {
  return request({
    method: 'POST',
    url: '/zgkj/supplier',
    data: data
  })
}

//查询商品品牌新闻列表
// export function tidings(params) {
//     return request({
//         method:'GET',
//         url:`/zgkj/tidings/list`,
//         params:params
//     })
// }
//查询我的优惠券列表
export function myCouponList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/discountCoupon/myList`,
    params: params
  })
}

//查询我的商品浏览历史列表
export function browsingHistory(params) {
  return request({
    method: 'GET',
    url: `/zgkj/browsingHistory/getList1`,
    params: params
  })
}

//删除浏览历史
export function delHistory(ids) {
  return request({
    method: 'DELETE',
    url: `/zgkj/browsingHistory/delete/${ids}`,
  })
}

//新增积分兑换
export function insertAdd(data) {
  return request({
    method: 'POST',
    url: '/zgkj/userCreditsExchange/insertSave',
    data: data
  })
}

//首页-热门代理新品咨询汇总
export function popularAgents(params) {
  return request({
    method: 'GET',
    url: `/zgkj/product/popularAgents`,
    params: params
  })
}

//首页轮播图下面-分类专区
export function brandBotList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/list2`,
    params: params
  })
}

//产品线/商品目录
export function productLine(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/productLine`,
    params: params
  })
}

//查询商品列表
export function goodsGetList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/product/getList`,
    params: params
  })
}

//查询所有精选品牌
export function getAllJx(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/getAllJx`,
    params: params
  })
}

//品牌字母搜索
export function getBrandList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/getBrandList`,
    params: params
  })
}

//获取商品品牌详细信息
export function brandDetail(id) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrand/${id}`
  })
}

//新增意见反馈
export function addFeedback(data) {
  return request({
    method: 'POST',
    url: '/zgkj/feedback/insertSave',
    data: data
  })
}

//查询我的意见反馈列表
export function feedbackList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/feedback/getList`,
    params: params
  })
}

//查询新闻资讯标签列表
export function labelList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productBrandTidingsLabel/list`,
    params: params
  })
}

//获取商品品牌新闻详细信息
export function tidingsDetail(id) {
  return request({
    method: 'GET',
    url: `/zgkj/tidings/${id}`
  })
}

//查询产品方案导航分类列表
export function classList(id) {
  return request({
    method: 'GET',
    url: `/zgkj/productProgramClassify/list`
  })
}

//查询产品方案导航列表
export function productProgramList(params) {
  return request({
    method: 'GET',
    url: `/zgkj/productProgram/list`,
    params: params
  })
}

//获取产品方案导航详细信息
export function programDetail(id) {
  return request({
    method: 'GET',
    url: `/zgkj/productProgram/${id}`
  })
}

//查询正在进行中的全部活动列表
export function activityInfo(params) {
  return request({
    method: 'GET',
    url: `/frontEnt/activityInfo/list`,
    params: params
  })
}

//查询全部活动列表
export function activityList(params) {
  return request({
    method: 'GET',
    url: `/frontEnt/activityInfo/list3`,
    params: params
  })
}

//查询正在进行中的热门活动列表
export function activList(params) {
  return request({
    method: 'GET',
    url: `/frontEnt/activityInfo/list2`,
    params: params
  })
}

//立即加入
export function collegeAdd(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/collegeInfo/save`,
    params: params
  })
}

//查询高校信息
export function collegeInfo(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/collegeInfo/info`,
    params: params
  })
}

//获取当前月可兑换次数
export function collegeCount(params) {
  return request({
    method: 'GET',
    url: `/zgkj/userCreditsExchange/count`,
    params: params
  })
}

//积分商品分类
export function collegeType(params) {
  return request({
    method: 'GET',
    url: `/zgkj/userCreditsExchange/type`,
    params: params
  })
}

//查询品牌文档列表
export function productBrandInfo(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandInfo/list`,
    params: params
  })
}

//查询当前是否设置单位
export function getOne(params) {
  return request({
    method: 'GET',
    url: `/zgkj/sysUserUnit/getOne`,
    params: params
  })
}

//设置当前用户的单位
export function saveSysUserUnit(params) {
  return request({
    method: 'GET',
    url: `/zgkj/sysUserUnit/saveSysUserUnit`,
    params: params
  })
}

//获得丰厚积分
export function configManage(params) {
  return request({
    method: 'GET',
    url: `/zgkj/configurationManagement/list`,
    params: params
  })
}

//批量更改国内外
export function homeAndAbroad(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/userShoppingCar/batchChangesAtHomeAndAbroad`,
    params: params
  })
}

//批量更改料号
export function addItemNumber(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/userShoppingCar/addItemNumber`,
    params: params
  })
}

//查询热门新闻列表
export function newsActive(params) {
  return request({
    method: 'GET',
    url: `/zgkj/tidings/rmList`,
    params: params
  })
}

//查询商品品牌新闻猜你喜欢列表(固定5条)
export function likeBrandTidings(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandTidings/list`,
    params: params
  })
}

//新闻点赞
export function newLike(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandTidings/like`,
    params: params
  })
}

//新闻收藏
export function newCollect(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandTidings/collect`,
    params: params
  })
}

//查询热门积分商品列表
export function creditsShopTo(params) {
  return request({
    method: 'GET',
    url: `/zgkj/creditsShop/list2`,
    params: params
  })
}

//收藏商品
export function myCollect(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productUserLog/collect`,
    params: params
  })
}

//订阅品牌
export function subscription(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandInfoUserLog/subscription`,
    params: params
  })
}

//查询新闻的上一页下一页与当前页
export function upAndDownList(params) {
  return request({
    method: 'GET',
    url: `/frontEnd/productBrandInfo/upAndDownList`,
    params: params
  })
}
//获取订单详细信息
export function orderDetail(id) {
    return request({
        method:'GET',
        url:`/zgkj/order/detailed/${id}`,
    })
}
//我的收藏商品列表
export function collectProductList(params) {
    return request({
        method:'GET',
        url:`/zgkj/userInteraction/collectProductList`,
        params:params
    })
}
//我的收藏文章列表
export function collectArticleList(params) {
    return request({
        method:'GET',
        url:`/zgkj/userInteraction/collectArticleList`,
        params:params
    })
}
//一键已读
export function editRead(data) {
    return request({
        method:'POST',
        url:`/zgkj/message/editRead`,
        data:data
    })
}
//删除消息
export function msgdelete(ids) {
    return request({
      method: 'DELETE',
      url: `/zgkj/message/delete/${ids}`
    })
  }
  //获取消息详细信息
export function msgDetail(id) {
    return request({
        method:'GET',
        url:`/zgkj/message/detailed/${id}`,
    })
}

//上传接口
export const uploadUrl = process.env.VUE_APP_BASE_URL + '/common/upload'
