<template>
  <div class="infoBg">
    <div class="infoDiv w_1200">
      <div class="infoLeft">
        <div class="myPhoto">
          <img :src="$baseUrl + userInfo.avatar" />
          <h1>{{ userInfo.userName }}</h1>
        </div>
        <div class="funModule">
          <!--          <div class="funTit"></div>-->
          <!--          <div class="funSub"></div>-->
          <el-menu :default-active="navVal" :default-openeds="defaultOpeneds" @select="selectClick"
            class="el-menu-vertical-demo">
            <el-submenu :index="item.id" v-for="(item, index) in navArr">
              <template slot="title">
                <p class="iCss"></p><span>{{ item.name }}</span>
              </template>
              <el-menu-item-group v-for="(item2, index2) in item.children">
                <el-menu-item @click="menuItemClick(item2)" :index="item2.id">{{ item2.name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="infoRight">
        <slot name="infoRight"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {addInvoice, getUserInfo, invoiceList} from '@/api/api';
import Cookies from "js-cookie";

export default {
  name: "index",
  props: {},
  data() {
    return {
      navVal: '1-1',
      defaultOpeneds: ['1', '2', '3', '4'],
      navArr: [
        {
          id: '1',
          name: this.$t('会员中心.个人信息'),
          children: [
            { id: '1-1', name: this.$t('会员中心.基本信息'), url: '/myInfo' },
            { id: '1-2', name: this.$t('会员中心.收货地址'), url: '/address' },
            { id: '1-3', name: this.$t('会员中心.开票资料'), url: '/makeOut' },
            { id: '1-4', name: this.$t('会员中心.修改密码'), url: '/editPwd' },
            { id: '1-5', name: this.$t('会员中心.账户安全'), url: '/account' }
          ]
        },
        {
          id: '2',
          name: this.$t('会员中心.订单管理'),
          children: [
            { id: '2-1', name: this.$t('会员中心.我的订单'), url: '/myOrder' },
            { id: '2-2', name: this.$t('会员中心.售后服务'), url: '/after' },
            { id: '2-3', name: this.$t('会员中心.我买过的'), url: '/buyUp' },
            { id: '2-4', name: this.$t('会员中心.发票查询'), url: '/getBill' },
            { id: '2-5', name: this.$t('会员中心.电子对账单'), url: '/electronicStatement' }
          ]
        },
        {
          id: '3',
          name: this.$t('会员中心.资产中心'),
          children: [
            { id: '3-1', name: this.$t('会员中心.我的钱包'), url: '/purse' },
            { id: '3-2', name: this.$t('会员中心.我的积分'), url: '/integral' },
            { id: '3-3', name: this.$t('会员中心.我的礼券'), url: '/gify' },
            { id: '3-4', name: this.$t('会员中心.我的优惠券'), url: '/myCoupon' },
          ]
        },
        {
          id: '4',
          name: this.$t('会员中心.服务中心'),
          children: [
            // { id: '4-1', name: this.$t('会员中心.我的样品'), url: '/mySample' },
            { id: '4-2', name: this.$t('会员中心.我的收藏'), url: '/myFavorite' },
            { id: '4-3', name: this.$t('会员中心.我的料号'), url: '/liaoNum' },
            { id: '4-4', name: this.$t('会员中心.专属链接'), url: '/link' },
            // { id: '4-5', name: this.$t('会员中心.我的BOM'), url: '/myBom' },
            { id: '4-6', name: this.$t('会员中心.我的消息'), url: '/myMsg' },
            { id: '4-7', name: this.$t('会员中心.我的建议'), url: '/myOffer' },
            // { id: '4-8', name: this.$t('会员中心.品牌订阅'), url: '/myBrand' },
            // { id: '4-9', name: this.$t('会员中心.提醒设置'), url: '/warnSetting' }
          ]
        },
      ],
      userInfo:{}
    }
  },
  mounted() {
    this.load()
    this.$nextTick(() => {
      this.navVal = this.$route.query.navVal || '1-1';
    });
  },
  onLoad() { },
  methods: {
    selectClick(e) {
      // console.log(this.navVal)
    },
    async load() {
      const res = await getUserInfo({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.userInfo = res.userDetails
    },
    menuItemClick(item) {
      if (item.url == this.$route.path) return
      this.navVal = item.id
      this.$router.push({ path: item.url, query: { navVal: item.id } })
    }
  }
}
</script>

<style scoped lang="scss">
.infoBg {
  .infoDiv {
    margin: 60px auto;
    display: flex;
    justify-content: space-between;

    .infoLeft {
      width: 277px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      //height: 1250px;
      padding: 20px;
      box-sizing: border-box;

      .myPhoto {
        text-align: center;

        h1 {
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 28px;
          margin: 0;
        }

        img {
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
        }
      }

      .funModule {
        .iCss {
          display: inline-block;
          margin-right: 6px;
          width: 6px;
          height: 20px;
          background: #FF6600;
          border-radius: 30px 30px 30px 30px;
        }

        :deep .el-submenu__title {
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 28px;
          display: flex;
          align-items: center;
        }

        :deep .el-submenu .el-menu-item {
          font-size: 16px;
          color: #333333;
          height: 42px;
          line-height: 42px;
        }

        :deep .el-menu-item-group__title {
          padding: 0 0 0 20px;
        }

        :deep .el-menu-item:focus,
        .el-menu-item:hover {
          color: #012F9C;
          background: #fff;
        }

        :deep .el-menu {
          border-right: none;
        }

        :deep .el-submenu__icon-arrow {
          font-size: 13px;
        }

        :deep .el-submenu__title i {
          color: #333;
          font-weight: 600;
        }

        .funTit {}

        .funSub {}
      }
    }

    .infoRight {
      width: 900px;
    }
  }
}
</style>
