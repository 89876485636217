<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <div class="indexModule w_1200">
      <div class="oneDiv">
        <div class="oneLeft divCss">
          <p class="orderTit">{{$t('售后服务.订单详情')}}</p>
          <div class="con">
            <p>{{$t('售后服务.订单号')}}{{$t('公用.：')}} <span style="color:#012F9C">2405141559182718</span> </p>
            <p>{{$t('售后服务.运费')}}{{$t('公用.：')}}{{$t('公用.¥')}}10.00</p>
            <p>{{$t('售后服务.商品总额')}}{{$t('公用.：')}}{{$t('公用.¥')}}128.00</p>
            <p>{{$t('售后服务.订单总额')}}{{$t('公用.：')}}{{$t('公用.¥')}}128.00</p>
          </div>
        </div>
        <div class="oneRig divCss">
          <p class="orderTit">{{$t('售后服务.退款状态')}}</p>
          <div class="stepDiv">
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('售后服务.提交信息')" description="2024.08.25 16:27:03"></el-step>
              <el-step :title="$t('售后服务.审核通过')" description="2024.08.25 16:27:03"></el-step>
              <el-step :title="$t('售后服务.退款完成')" description="2024.08.25 16:27:03"></el-step>
            </el-steps>
          </div>
          <div class="afterTips">{{$t('售后服务.退款完成相关金额已经退回')}}</div>
        </div>
      </div>
      <div class="twoDiv">
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{$t('售后服务.支付信息')}}</p>
          </div>
          <div class="con">
            <p>{{$t('售后服务.余额支付')}}{{$t('公用.：')}}CCMNCSBH</p>
          </div>
        </div>
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{$t('售后服务.商品信息')}}</p>
          </div>
          <div class="con">
            <p>{{$t('售后服务.商品名称')}}{{$t('公用.：')}}XXXXXXXXXXX</p>
            <p>{{$t('售后服务.商品数量')}}{{$t('公用.：')}}XXXXXXXXXXX</p>
          </div>
        </div>
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{$t('售后服务.退款信息')}}</p>
          </div>
          <div class="con">
            <p>{{$t('售后服务.退款金额')}}{{$t('公用.：')}}XXXXXXX</p>
            <p>{{$t('售后服务.退款方式')}}{{$t('公用.：')}}XXXXXXX</p>
            <p>{{$t('售后服务.退款说明')}}{{$t('公用.：')}}XXXXXXX</p>
            <p>{{$t('售后服务.退款商品')}}{{$t('公用.：')}}XXXXXXX</p>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";

export default {
  components: {carTopNav, bottomNav},
  data() {
    return {
      loading: false,
      active:1
    }
  },
  onload() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .indexModule {
    margin: 60px auto;

    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        width: 98px;
        height: 26px;
        line-height: 26px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .con {
      p {
        margin-bottom: 17px;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }

    .oneDiv {
      height: 260px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .oneLeft {
        width: 427px;

        .num {
          margin: 20px 0;
          text-align: center;
          font-size: 16px;
          color: #333333;
          line-height: 19px;
        }

        h2 {
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          color: #13DF4C;
          line-height: 23px;
          margin: 20px 0 30px 0;
        }
      }

      .oneRig {
        width: 749px;
        .afterTips{
          font-weight: 400;
          font-size: 16px;
          color: #E90505;
          line-height: 19px;
        }
        .stepDiv{
        //width:400px;
        margin:30px auto;
        :deep .el-step.is-horizontal .el-step__line{//横线
          top:16px;
          height: 8px;
        }
        :deep .el-step__icon.is-text{//未选中圆圈
          background: #13DF4C;
          font-size: 13px;
          color: #fff;
          width:40px;
          height:40px;
          border: #e8eefc 4px solid;
        }
        :deep .el-step__title{//未选中标题
          font-size: 13px;
          color: #999999;
        }
        :deep .el-step__title.is-process{//选中标题
          font-weight: 400;
          font-size: 13px;
          color: #333333;
        }
        :deep .el-step__description.is-success,:deep .el-step__title{
          color: #303133;
        }
        :deep .el-step__head.is-process .is-text{//选中圆圈
          border:#BED1FF 4px solid!important;
          background: #13DF4C!important;
          color: #fff;
        }
        :deep .el-step__head.is-success{
          border-color: #D9D9D9;
        }
        :deep .el-step__line{
          background: #D9D9D9;
        }
      }
      }
    }

    .twoDiv {
      height: 230px;
      display: flex;
      justify-content: space-between;

      .conBtn {
        width: 98px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #999999;
        font-size: 11px;
        color: #333333;
      }
    }
 }
}
</style>