<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft navVal="1-1">
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="infoTop">
              <div class="twoT">
                <p class="orderTit">{{$t('我的样品.样品申请单')}}</p>
                <span class="editBtn hand">{{$t('我的样品.免费样品申请单')}}</span>
              </div>
              <div class="selectDiv">
                <div class="seleLeft">
                  <div class="sub">
                    <span>{{$t('我的样品.搜索样品')}}</span>
                    <input class="subInp" />
                  </div>
                  <div class="sub">
                    <span>{{$t('我的样品.类别')}}</span>
                    <el-select class="subInp" v-model="value" :placeholder="$t('我的样品.请选择')">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="sub">
                    <span>{{$t('我的样品.审核状态')}}</span>
                    <el-select class="subInp" v-model="value" :placeholder="$t('我的样品.请选择')">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="sub">
                    <span>{{$t('我的样品.状态')}}</span>
                    <el-select class="subInp" v-model="value" :placeholder="$t('我的样品.请选择')">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="seleRig">
                  <p class="hand">{{$t('我的样品.查询')}}</p>
                  <p class="ac hand">{{$t('我的样品.重置')}}</p>
                </div>
              </div>
              <el-table :data="tableData" :stripe="true" style="width: 100%">
                <el-table-column prop="num" :label="$t('我的样品.申请单号')"></el-table-column>
                <el-table-column prop="type" :label="$t('我的样品.申请类别')"></el-table-column>
                <el-table-column prop="num2" :label="$t('我的样品.申请样品')"></el-table-column>
                <el-table-column prop="num3" :label="$t('我的样品.数量')"></el-table-column>
                <el-table-column prop="state" :label="$t('我的样品.状态')"></el-table-column>
                <el-table-column prop="date" :label="$t('我的样品.申请单号')"></el-table-column>
              </el-table>
            </div>
            <p class="tips">
              <img src="@/assets/login/icon10.png" alt="" />
              <span>{{$t('我的样品.样品申请单提交后')}}</span>
            </p>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
          loading: false,
          value:'',
          options:[],
          tableData: [
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'},
              {num:'9435894',type:'订单支付',num2:'-8787',num3:'3',state:'审核中',date: '2016-05-02'}
          ]
        }
    },
    onload(){},
    methods:{

    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    .infoTop{
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      //margin-top: 20px;
    }
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .selectDiv{
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .seleLeft{
        display: grid;
        grid-template-columns:repeat(2,1fr);
        grid-gap: 20px;
        .sub{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          .subInp{
            flex: 1;
          }
          input{
            border: 1px solid #DCDFE6;
            border-radius:4px;
            height:36px;

          }
        }
      }
      .seleRig{
        display: flex;
        align-items: center;
        p{
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
        }
        .ac{
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
      }
    }
    :deep .el-table th.el-table__cell{
      background: #012F9C;
      color:#fff;
    }
    :deep th:last-child{
      border-radius:0 8px 0 0;
    }
    :deep th:first-child{
      border-radius:8px 0 0 0;
    }
  }
  .tips {
    //width: 297px;
    //height: 44px;
    padding: 15px 20px;
    background: #FFFDED;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FF6600;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

  }
}
</style>