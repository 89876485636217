<template>
  <div v-loading="loading" class="indexBg">
    <topNav></topNav>
    <div class="signInModule">
      <div class="dignTop">
        <div class="w_1200">
          <p class="sinTit">连续签到七天可获得10元XX卡券</p>
          <div class="msgTips">
            <img src="@/assets/images/sigin1.png"/>
            <div class="scroll-container">
              <div class="scroll-content">
                <p class="scroll-item" v-for="(item, index) in userSignNotifyList" :key="'item-' + index">恭喜 <span>{{ maskPhoneNumber(item.createBy) }}</span>签到领50积分！</p>
              </div>
              <div class="scroll-content2">
                <p class="scroll-item" v-for="(item, index) in userSignNotifyList" :key="'item-' + index">恭喜 <span>{{ maskPhoneNumber(item.createBy) }}</span>签到领50积分！</p>
              </div>
            </div>
          </div>
          <div class="siginBtn">
            <p class="btnP hand" @click="signClick">{{
                $t('签到.积分')
              }}：<span>{{ userInfo.totalIntegral }}</span>{{
                $t('签到.赚积分')
              }}>></p>
            <div class="siginBCen hand" @click="userSign()">
              <div v-if="!f" class="cenRedio">{{ $t('签到.今日签到') }}</div>
              <div v-if="f" class="cenRedio1">{{ $t('签到.您已连签到') }}</div>
              <p>{{ $t('签到.您已连签到') }}{{ count }}{{ $t('签到.天') }}</p>
            </div>
            <p class="btnP">{{ $t('签到.我的礼券') }}：<span>0</span></p>
          </div>
        </div>
      </div>
      <div class="signBot w_1200">
        <div class="botLeft">
          <div class="botDiv">
            <p class="activeTit">{{ $t('签到.活动说明') }}</p>
            <div class="activeDesc">
              <p>1、每月连续签到7天可领一张10元某某卡券，
                每月限一次；</p>
              <p>2、连续签到仅限当月有效，跨月失效；</p>
              <p>3、领到10元某某卡券后需完成1个用户邀请
                才可兑换；</p>
              <p>4、某某卡券总数为200张，数量有限，先到
                先得，领完为止 <span>（某某卡券已领完，不再发放）</span></p>
            </div>
          </div>
          <div class="msgGg">
            <img src="@/assets/images/sinGg.png">
            <p>{{ $t('签到.公告') }}：本月某某卡券已领完，请下个月再来！</p>
          </div>
        </div>
        <div class="botRig botDiv">
          <div v-for="item in list  " class="div1">
            <div v-if="!item.status && !item.thatDay1" class="cc">漏</div>
            <div v-else :class="item.status ? 'cc1': item.thatDay1 ? 'cc2' : 'cc'">
              +{{ item.integral }}
            </div>
            <span v-if="!item.thatDay2">{{ item.day }}</span>
            <span v-else>今日</span>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {all, getCurrentMonthSignList, userSignAdd, userSignNotify} from "@/api/home/signIn";
import Cookies from "js-cookie";
import {getUserInfo} from "@/api/api";

export default {
  components: {topNav, bottomNav},
  data() {
    return {
      loading: false,
      f: false,
      count: 0,
      list: [],
      userInfo: {},
      userSignNotifyList: [],
    }
  },
  mounted() {
    this.load();
    this.allInfo();
  },
  methods: {
    maskPhoneNumber(number) {
      // 将中间四位替换为星号
      return number.slice(0, 3) + '****' + number.slice(7);
    },
    allInfo() {
      all().then(res => {
        this.f = res.data
        this.count = res.count
      })
    },
    userSign() {
      userSignAdd().then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
          });
          this.load();
          this.allInfo();
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      })
    },
    async load() {
      const res = await getUserInfo({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.userInfo = res.userDetails
      getCurrentMonthSignList().then(res => {
        this.list = res.data
        console.log(this.list)
      })
      userSignNotify().then(res => {
        this.userSignNotifyList = res.data
      })
    },
    signClick() {
      this.$router.push({
        path: '/signScore'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-container {
  width: 300px;  /* 容器宽度 */
  height: 44px;  /* 容器高度 */
  overflow: hidden;  /* 隐藏超出的内容 */
  position: relative;
}
.scroll-item {
  height: 50px;  /* 每个项的高度，与容器高度一致 */
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px solid #ccc;  /* 可视化的边框 */
  font-size: 17px;
  color: #FFFFFF;
  span {
    color: #FECC3F;
  }
}
.scroll-content {
  display: flex;
  flex-direction: column;
  height: 88px;  /* 确保内容的高度可以容纳两倍的项目 */
  animation: scroll 10s linear infinite;  /* 动画：滚动 */
}
.scroll-content2 {
  display: flex;
  flex-direction: column;
  height: 88px;  /* 确保内容的高度可以容纳两倍的项目 */
  animation: scroll2 10s linear infinite;  /* 动画：滚动 */
}

/* 滚动动画 */
@keyframes scroll2 {
  0% {
    transform: translateY(0);  /* 初始位置 */
  }
  50% {
    transform: translateY(-50%);  /* 滚动至半个高度 */
  }
  100% {
    transform: translateY(-100%);  /* 滚动至整个内容 */
  }
}
/* 滚动动画 */
@keyframes scroll {
  0% {
    transform: translateY(0);  /* 初始位置 */
  }
  50% {
    transform: translateY(-50%);  /* 滚动至半个高度 */
  }
  100% {
    transform: translateY(-100%);  /* 滚动至整个内容 */
  }
}


.indexBg {
  width: 100%;

  .signInModule {
    .dignTop {
      width: 100%;
      height: 560px;
      background: url("@/assets/images/qd1.jpg");
      background-size: 102%;
      background-position-x: center;

      .sinTit {
        font-weight: bold;
        font-size: 39px;
        color: #FFFFFF;
        padding: 63px 0 0px 0;
        text-align: center;
      }

      .msgTips {
        display: flex;
        align-items: center;
        width: 589px;
        height: 44px;
        border-radius: 24px 24px 24px 24px;
        border: 1px solid #FFFFFF;
        padding: 11px 17px;
        box-sizing: border-box;
        margin: 42px auto;

        img {
          width: 19px;
          height: 19px;
          margin-right: 17px;
        }

        p {

        }
      }

      .siginBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btnP {
          background: rgba(0, 30, 108, 20%);
          border-radius: 8px 8px 8px 8px;
          //opacity: 0.21;
          padding: 10px 16px;
          font-size: 18px;
          color: #FFFFFF;

          span {
            font-weight: bold;
            font-size: 20px;
            color: #FFAD3C;
          }
        }

        .siginBCen {
          .cenRedio {
            width: 158px;
            height: 158px;
            border-radius: 50%;
            background-image: url("@/assets/images/sigin2.png");
            background-size: 100%;
            font-size: 29px;
            color: #FFFFFF;
            display: flex;
            line-height: 40px;
            align-items: center;
            justify-content: center;
            padding: 50px;
            box-sizing: border-box;
          }

          .cenRedio1 {
            width: 158px;
            height: 158px;
            border-radius: 100%;
            background-color: rgb(255, 255, 255);
            font-size: 20px;
            color: black;
            display: flex;
            line-height: 40px;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          }

          p {
            font-size: 21px;
            color: #FFFFFF;
            margin: 20px 0;
            text-align: center;
          }
        }
      }
    }

    .signBot {
      margin-top: -60px;
      margin-bottom: 60px;
      //border: solid 1px;
      display: flex;
      justify-content: space-between;

      .botDiv {
        background: #FFFFFF;
        box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 100px);
        grid-gap: 10px;

        .div1 {

          width: 100px;
          height: 80px;
          margin: 10px 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          .cc {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #adb0b6;
          }

          .cc1 {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #FFAD3C;
          }

          .cc2 {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: rgb(195, 210, 248);
          }


          span {
            width: 100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          }

        }


      }

      .botLeft {
        width: 384px;

        .activeTit {
          font-weight: 400;
          font-size: 28px;
          color: #0B75CC;
          text-align: center;
        }

        .activeDesc {
          margin-top: 20px;
          font-size: 16px;
          color: #000;
          line-height: 40px;

          span {
            color: #F52D12;
          }
        }

        .msgGg {
          //border: solid 1px;
          width: 290px;
          display: flex;
          font-weight: 400;
          font-size: 20px;
          margin: 15px auto;
          color: #000000;
          text-align: center;
          line-height: 30px;

          img {
            width: 23px;
            height: 23px;
            margin-right: 3px;
          }
        }
      }

      .botRig {
        width: 795px;
      }
    }
  }
}
</style>
