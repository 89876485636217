<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{ $t("收货地址.收货地址") }}</p>
            <div>
              <span @click="delHandle" class="editBtn hand"
                style="margin-right: 10px; background: #f3f5f8; color: #333"><i class="el-icon-delete" />{{
                  $t("收货地址.删除地址") }}</span>
              <span class="editBtn hand" @click="showAddAddressHandle">+{{ $t("收货地址.添加地址") }}</span>
            </div>
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{ $t("收货地址.序号") }}</th>
                <th>{{ $t("收货地址.收货人") }}</th>
                <th>{{ $t("收货地址.所在地区") }}</th>
                <th>{{ $t("收货地址.详情地址") }}</th>
                <th>{{ $t("收货地址.手机电话") }}</th>
                <th>{{ $t("收货地址.操作") }}</th>
              </tr>
              <tr class="trCon" v-for="item in tableData" :key="item.id">
                <td style="padding-left: 0">
                  <div class="descCss" style="width: 80px; position: relative">
                    <p class="allBtn" style="
                        position: absolute;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                      ">
                      <img @click="changeSelect(item.id)" :src="selectArr.includes(item.id)
                        ? require('@/assets/member/xz.png')
                        : require('@/assets/member/wxz.png')
                        " />
                    </p>
                    <div>{{ item.id }}</div>
                  </div>
                </td>
                <td class="dj">
                  <p style="width: 100px">{{ item.userName }}</p>
                </td>
                <td class="xj">
                  <div style="width: 170px">
                    <p>{{ item.provinc + item.city + item.county }}</p>
                    <p v-if="item.type == 1" style="color: #ff6600">
                      {{ $t("收货地址.默认地址") }}
                    </p>
                  </div>
                </td>
                <td class="xj">
                  <p style="width: 200px">
                    {{ item.provinc + item.city + item.county + item.details }}
                  </p>
                </td>
                <td class="xj">
                  <p style="width: 115px">{{ item.userMobile }}</p>
                </td>
                <td>
                  <div class="editTab hand">
                    <p @click="setDefaultHandle(item)" v-if="item.type != 1" class="activeP">
                      {{ $t("收货地址.设为默认") }}
                    </p>
                    <p @click="editAddressHandle(item)">
                      {{ $t("收货地址.编辑") }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog width="600px" :title="edit ? $t('收货地址.编辑') : $t('收货地址.添加新的收货地址')" :visible.sync="dialogFlag">
      <el-form>
        <div class="dialogThreeCss">
          <div class="couponDiv">
            <img src="@/assets/member/tx.png" />
            <p>{{ $t("收货地址.应快递公司要求") }}</p>
          </div>
          <div class="twoInfo">
            <div class="infoSub">
              <p>
                <span>*</span>{{ $t("收货地址.收货人") }}{{ $t("公用.：") }}
              </p>
              <input v-model="form.userName" />
            </div>
            <div class="infoSub">
              <p>
                <span>*</span>{{ $t("收货地址.所在区域") }}{{ $t("公用.：") }}
              </p>

              <el-cascader :options="options" :props="{ value: 'label' }" change-on-select v-model="form.area"
                @change="handleChange" class="wd400" placeholder="请选择">
              </el-cascader>
            </div>
            <div class="infoSub">
              <p>
                <span>*</span>{{ $t("收货地址.详细地址") }}{{ $t("公用.：") }}
              </p>
              <input v-model="form.details" />
            </div>
            <div class="infoSub">
              <p>
                <span>*</span>{{ $t("收货地址.收货人手机") }}{{ $t("公用.：") }}
              </p>
              <input v-model="form.userMobile" />
            </div>
            <div class="infoSub">
              <p>
                <span>*</span>{{ $t("收货地址.收货人座机") }}{{ $t("公用.：") }}
              </p>
              <input v-model="form.zuoPhone" />
            </div>
          </div>
          <el-checkbox v-model="form.type">{{
            $t("收货地址.设为默认地址")
          }}</el-checkbox>
          <div class="diaBtn" style="justify-content: center">
            <p class="yes hand" @click="addAddressHandle">
              {{ $t("收货地址.保存") }}
            </p>
            <p class="no hand" @click="dialogFlag = false">
              {{ $t("收货地址.取消") }}
            </p>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import areajson from "@/assets/js/area";
import { addAddress, addressList, delAddress, editAddress } from "@/api/api";
export default {
  components: { carTopNav, bottomNav, myInfoLeft },
  data() {
    return {
      options: areajson,
      form: {
        // provinc: "",
        // city: "",
        // county: "",
        area: [],
        details: "",
        userName: "",
        userMobile: "",
        zuoPhone: "",
        type: 0,
      },
      loading: false,
      checked: false,
      dialogFlag: false,
      value: "",
      flag: false,
      tableData: [],
      selectArr: [],
      edit: false,
    };
  },
  mounted() {
    console.log(1);
    console.log(areajson, 11);
    console.log(2);
    this.getData();
  },
  methods: {
    setDefaultHandle(item) {
      this.edit = true;
      this.form = item;
      this.form.area = [item.provinc, item.city, item.county];
      this.form.type = 1;
      this.addAddressHandle();
    },
    editAddressHandle(item) {
      this.dialogFlag = true;
      this.edit = true;
      this.form = item;
      this.form.area = [item.provinc, item.city, item.county];
    },
    async getData() {
      const res = await addressList({});
      console.log(res);
      this.tableData = res.rows;
    },
    async delHandle() {
      const res = await delAddress(this.selectArr.join(","))
      this.getData();
    },
    showAddAddressHandle() {
      this.form = {
        area: [],
        details: "",
        userName: "",
        userMobile: "",
        zuoPhone: "",
        type: 0,
      };
      this.dialogFlag = true;
    },
    async addAddressHandle() {
      let res;
      console.log(this.edit, "edit");
      if (this.edit) {
        res = await editAddress({
          ...this.form,
          provinc: this.form.area[0],
          city: this.form.area[1],
          county: this.form.area[2],
          type: this.form.type ? "1" : "0",
        });
        this.edit = false;
      } else {
        res = await addAddress({
          ...this.form,
          provinc: this.form.area[0],
          city: this.form.area[1],
          county: this.form.area[2],
          type: this.form.type ? "1" : "0",
        });
      }
      this.dialogFlag = false;
      this.getData();
    },
    billClick() { },
    handleChange(e) {
      console.log(e);
    },
    changeSelect(id) {
      if (this.selectArr.includes(id)) {
        this.selectArr.splice(this.selectArr.indexOf(id), 1);
      } else {
        this.selectArr.push(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-input) {
  width: 325px !important;
}

.indexBg {
  width: 100%;

  .infoModule {
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        background: #012f9c;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .selectDiv {
      display: flex;
      align-items: center;

      //justify-content: space-between;
      .seleLeft {
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        //grid-gap: 20px;
        margin-right: 30px;

        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            width: 265px;
            height: 26px;
            border-radius: 4px;
            border: 1px solid #999999;
          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012f9c;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #ffffff;
          line-height: 13px;
          margin-right: 10px;
        }

        .ac {
          border: 1px solid #012f9c;
          color: #012f9c;
          background: #fff;
          margin-left: 15px;
        }

        .exportbtn {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
          color: #333333;
          background: #fff;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        display: flex;
        align-items: center;
        justify-content: center;

        .goodsPhoto {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
        word-break: break-all;
      }

      .editTab {
        display: flex;
        align-items: center;
        justify-content: end;

        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          font-size: 13px;
          color: #012f9c;
          line-height: 22px;
          margin-right: 16px;
        }

        .activeP {
          //color: #FF0000;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px;

        /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #ffffff;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {}

        .trTit {
          height: 48px;
          background: #f6f6f6;
          font-size: 16px;
          color: #333;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #ffffff;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d0d0d0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          text-align: center;
          display: block;
        }

        .xj p {
          text-align: center;
          display: block;
        }
      }
    }
  }

  .dialogThreeCss {
    padding: 0 60px;
    margin: auto;

    .checkCss {
      text-align: center;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;

      span {
        font-weight: bold;
        color: #eb0000;
        font-size: 18px;
        margin-right: 3px;
      }

      input {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #dcdfe6;
        width: 278px;
        height: 40px;
        flex: 1;
      }

      .infoSub {
        display: flex;
        align-content: center;
        margin: 20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;

        .subInp {
          flex: 1;
        }
      }

      p {
        //border: solid 1px;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right: 15px;
        width: 100px;
      }
    }
  }

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012f9c;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 30px;
      line-height: 19px;
      margin-left: 20px;
      color: #ffffff;
    }

    .no {
      background: #ebeaea;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ff6600;
    line-height: 22px;

    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
}
</style>