<template>
  <div class="bg">
    <loginTop></loginTop>
    <div class="bor">
      <div class="loginRight">
        <div class="wxLogin">
          <div @click="navToLogin" class="wxTips">{{ $t('登录.已有账号') }}</div>
          <router-link to="/login">
            <img class="rigWx hand" src="@/assets/login/icon8.png" />
          </router-link>
        </div>
        <div class="inpDiv">
          <div class="tabLogin">
            <span class="active">{{ $t('登录.立即注册') }}</span>
          </div>
          <div class="inputDiv">
            <div class="input">
              <img src="@/assets/login/icon3.png" alt="">
              <input type="number" maxlength="11" v-model="loginBody.username" :placeholder="$t('登录.请输入手机号')">
            </div>
            <div class="input inputCode" v-loading="loading">
              <img src="@/assets/login/icon4.png" alt="">
              <input type="number" v-model="codeVal" :placeholder="$t('登录.请输入短信验证码')">
              <span class="code" @click="getCode()" v-if="!getCodeBlo">{{ $t('登录.发送验证码') }}</span>
              <span class="code" v-else-if="getCodeBlo && getCodeNum >= 1">{{ getCodeNum }}{{ $t('登录.秒后获取') }}</span>
              <span class="code" @click="getCode()" v-else-if="getCodeBlo && getCodeNum < 1">{{ $t('登录.重新获取') }}</span>
              <span class="code" v-else>{{ count }}s</span>
            </div>
            <div class="input">
              <img src="@/assets/login/icon2.png" alt="">
              <input type="password" v-model="loginBody.password" :placeholder="$t('登录.请设置用户密码')">
              <img class="cloIcon hand" src="@/assets/login/icon6.png" alt="">
            </div>
            <div class="input">
              <img src="@/assets/login/icon2.png" alt="">
              <input type="password" v-model="loginBody.surePwd" :placeholder="$t('登录.请确认用户密码')">
              <img class="cloIcon hand" src="@/assets/login/icon6.png" alt="">
            </div>
            <div class="input">
              <img src="@/assets/login/icon2.png" alt="">
              <input type="password" v-model="loginBody.inCode" :placeholder="$t('登录.邀请码')">
              <img class="cloIcon hand" src="@/assets/login/icon5.png" alt="">
            </div>
          </div>
          <div class="loginBtn" v-loading="loading" @click="loginClick">{{ $t('登录.立即注册') }}</div>
          <div class="stateBtn" @click="privetaState = !privetaState">
            <img :src="privetaState ? require('@/assets/login/icon14.png') : require('@/assets/login/icon13.png')"
              alt="">
            {{ $t('登录.我已阅读并同意') }}<router-link to="/"> 《{{ $t('登录.用户注册协议') }}》</router-link>
            <router-link to="/">《{{ $t('登录.隐私保护协议') }}》</router-link>
          </div>
        </div>
      </div>
    </div>
    <loginBot></loginBot>
  </div>
</template>

<script>
import loginTop from '@/component/login/loginTop.vue'
import loginBot from '@/component/login/loginBot.vue'

import { setToken, getToken } from '../../util/cookie.js'
import Cookies from 'js-cookie'
import { regist, send } from '@/api/api.js'
export default {
  name: "login",
  components: { loginTop, loginBot },
  data() {
    return {
      privetaState: false,
      loginBody: {
        username: '',
        password: '',
        surePwd: '',
        inCode: ''
      },
      codeVal: '',//验证码
      getCodeNum: 5,
      getCodeBlo: false,
      count: '',
      loading: false
    }
  },
  onload: {},
  mounted() {

  },
  methods: {
    loginClick() {//注册
      if (this.loginBody.username == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.手机号不能为空'),
          offset: 100
        });
        return
      }
      if (this.codeVal == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.验证码不能为空'),
          offset: 100
        });
        return
      }
      if (this.loginBody.surePwd != this.loginBody.password) {
        this.$message({
          type: 'warning',
          message: this.$t('登录.两次输入密码不一致'),
          offset: 100
        });
        return
      }
      if (this.loginBody.password == '' || this.loginBody.surePwd == '' || (this.loginBody.surePwd !== this.loginBody.password)) {
        this.$message({
          type: 'warning',
          message: this.$t('登录.密码有误'),
          offset: 100
        });
        return
      }
      if (!this.privetaState) {
        this.$message({
          type: 'warning',
          message: this.$t('登录.用户协议未勾选'),
          offset: 100
        });
        return
      }
      if (this.loading) return;
      // this.$router.push('/login')
      this.loading = true;
      regist({
        username: this.loginBody.username,
        password: this.loginBody.password,
        code: this.codeVal,
        invitationCode: this.loginBody.inCode
      }).then(res => {
        this.loading = false;
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: this.$t('登录.注册成功'),
            offset: 100
          });
          setTimeout(() => {
            this.$router.push('/login')
          }, 500);
        }
      }).catch(err => {
        this.loading = false;
        this.$message({
          type: 'warning',
          message: err.msg,
          offset: 100
        });
      })
    },
    navToLogin() {
      this.$router.push('/login')
    },
    async getCode() {
      if (this.loginBody.username == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.手机号不能为空'),
          offset: 100
        });
        return
      }
      const res = await send({ phoneNumber: this.loginBody.username, type: '1' })//1：注册，2：登录，3：修改密码
      this.loading = true;
      try {
        setTimeout(() => {
          this.loading = false;
          this.codeVal = res.smsCode
          this.$message({
            // showClose: true,
            type: 'warning',
            message: this.$t('登录.验证码已发送'),
            offset: 100
          });
          this.getCodeNum = 60
          this.countDown()
        }, 500)
      } catch { }
    },
    //倒计时
    countDown() {
      this.getCodeBlo = true
      this.setInterval = setInterval(() => {
        if (this.getCodeNum >= 1) {
          this.getCodeNum--
        } else {
          clearInterval(this.setInterval)
        }
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("/src/assets/login/logoBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh);

  /*background: antiquewhite;*/
  /*width: 100%;*/
  /*height:100%;*/
  /*height:100vh;*/
  /*padding:57px 210px;*/
  .bor {
    height: 820px;
    padding: 0 360px 0 0;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    .loginRight {
      width: 574px;
      height: 694px;
      background: #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px 30px;

      .wxLogin {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .wxTips {
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #999999;
          font-size: 13px;
          color: #999999;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 135px;
          height: 30px;
          cursor: pointer;

          img {
            width: 17px;
            height: 17px;
            margin-right: 3px;
          }
        }

        .rigWx {
          width: 49px;
          height: 49px;
          margin-left: 8px;
        }
      }

      .inpDiv {
        width: 335px;
        margin: auto;
      }

      .tabLogin {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 36px 0;
        border-bottom: solid 2px #D9D9D9;
        height: 30px;

        span {
          font-size: 20px;
          display: inline-block;
          width: 167px;
          text-align: center;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999;
          line-height: 21px;
          padding-bottom: 11px;
          border-bottom: solid 2px #D9D9D9;
          height: 19px;
        }

        .active {
          color: #000;
          border-bottom: solid 2px #012F9C;
        }
      }


      .inputDiv {
        .inputCode {
          input {
            width: 50% !important;
          }

          span {
            text-align: right;
            //border: solid;
            display: inline-block;
            width: 35%;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #012F9C;
            line-height: 21px;
            cursor: pointer;
          }
        }

        .input {
          //height: 60px;
          padding: 13px 20px;
          background: #FFFFFF;
          width: 334px;
          height: 46px;
          box-sizing: border-box;
          border-radius: 23px 23px 23px 23px;
          border: 1px solid #999999;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          img {
            width: 17px;
            height: 17px;
          }

          .cloIcon {
            width: 20px;
            height: 20px;
          }

          input {
            width: 78%;
            margin: 0 10px;
            border: none;
            font-size: 16px;
          }

          input::-webkit-input-placeholder {
            /*WebKit browsers*/
            line-height: 32px;
            font-size: 13px;
            color: #333333;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }

          input::-moz-input-placeholder {
            /*Mozilla Firefox*/
            line-height: 32px;
            font-size: 13px;
            color: #333333;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }

          input::-ms-input-placeholder {
            /*Internet Explorer*/
            line-height: 32px;
            font-size: 13px;
            color: #333333;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }
        }
      }

      .loginBtn {
        width: 330px;
        background: #012F9C;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        cursor: pointer;
      }

      .stateBtn {
        font-size: 13px;
        color: #999999;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 20px;

        a {
          color: #999999;
        }

        img {
          margin-right: 6px;
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
    }
  }

}
</style>
