import request from '@/util/request'

export function ModifyMobilePhone(data) {
  return request({
    url: `/frontEnt/userInfo/modifyMobilePhone`,
    method: 'GET',
    params: data
  })
}

export function ModifyEmailInfo(data) {
  return request({
    url: `/frontEnt/userInfo/modifyEmail`,
    method: 'GET',
    params: data
  })
}

