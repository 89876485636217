<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <div class="indexModule w_1200">
      <div class="oneDiv">
        <div class="oneLeft divCss">
          <p class="orderTit">{{$t('订单信息.订单状态')}}</p>
          <p class="num">{{$t('订单信息.订单号')}}{{info.orderNumber}}</p>
          <h2 v-if="info.orderType==0">{{$t('订单信息.待审核')}}</h2>
          <h2 v-if="info.orderType==1">{{$t('订单信息.待支付')}}</h2>
          <h2 v-if="info.orderType==2">{{$t('订单信息.已支付/待发货')}}</h2>
          <h2 v-if="info.orderType==3">{{$t('订单信息.已发货')}}</h2>
          <h2 v-if="info.orderType==4">{{$t('订单信息.已收货')}}</h2>
          <h2 v-if="info.orderType==99">{{$t('订单信息.已取消')}}</h2>
          <!-- <div class="dyDiv">
            <div class="hand hover">
              <img src="@/assets/member/dy.png" alt=""/>
              <span>{{$t('订单信息.打印收据')}}</span>
            </div>
            <div class="hand hover">
              <img src="@/assets/member/dy.png" alt=""/>
              <span>{{$t('订单信息.打印合同')}}</span>
            </div>
            <div class="hand hover">
              <img src="@/assets/member/dy.png" alt=""/>
              <span>{{$t('订单信息.打印订单')}}</span>
            </div>
            <div class="hand hover">
              <img src="@/assets/member/dy.png" alt=""/>
              <span>{{$t('订单信息.打印收货单')}}</span>
            </div>
          </div> -->
        </div>
        <div class="oneRig divCss">
          <p class="orderTit">{{$t('订单信息.订单线路')}}</p>
          <div class="line">
            <div class="lineLeft" style="margin-bottom:20px">
              <div class="lineSub" v-for="(item,index) in 5">
                <div class="lineText">
                  <img :src="require('@/assets/member/i'+(index+1)+'.png')"/>
                  <div>
                    <p v-if="index==0">{{$t('订单信息.提交订单')}}</p>
                    <p v-if="index==1">{{$t('订单信息.审核订单')}}</p>
                    <p v-if="index==2">{{$t('订单信息.付款成功')}}</p>
                    <p v-if="index==3">{{$t('订单信息.仓库备货')}}</p>
                    <p v-if="index==4">{{$t('订单信息.发货完成')}}</p>
                    <p>2024.02.29</p>
                    <p>15:32:01</p>
                  </div>
                </div>
                <img class="iconJt" v-if="index!=4" src="@/assets/member/j1.png"/>
              </div>
            </div>
            <!--              <img class="inconImg" src="@/assets/member/j2.png" />-->
          </div>
        </div>
      </div>
      <!-- <div class="twoDiv">
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.物流信息') }}</p>
            <span class="editBtn hand" @click="emsDialog=true">{{ $t('订单信息.查看物流信息') }}</span>
          </div>
          <div class="con">
            <p>{{$t('订单信息.物流公司')}}CCMNCSBH</p>
            <p>{{$t('订单信息.物流单号')}}326327846327683287232</p>
          </div>
          <div class="conBtn">{{$t('订单信息.发货清单')}}</div>
        </div>
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.收货地址') }}</p>
          </div>
          <div class="con">
            <p>{{$t('订单信息.收货人')}}{{ info.name }}</p>
            <p>{{$t('订单信息.手机')}}{{info.mobile}}</p>
            <p>{{$t('订单信息.地址')}}{{ info.addressDetails }}</p>
          </div>
        </div>
        <div class="divCss" style="width:384px;">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.付款信息') }}</p>
          </div>
          <div class="con">
            <p>{{$t('订单信息.付款时间')}}{{ info.name }}</p>
            <p>{{$t('订单信息.商品总额')}}{{ info.productPrice }}</p>
            <p>{{$t('订单信息.余额支付：')}}{{ info.totalPrice }}</p>
          </div>
        </div>
      </div> -->
      <div class="threeDiv">
        <div class="divCss threeLeft">
          <div class="twoT">
            <p class="orderTit">{{ $t('订单信息.发票信息') }}</p>
            <span class="editBtn hand" @click="billingDialog=true">{{ $t('订单信息.修改发票') }}</span>
          </div>
          <div class="con">
            <!-- <p>{{$t('订单信息.发票类型')}}{{ info.name }}</p> -->
            <!-- <p>{{$t('订单信息.发票抬头')}}{{ info.name }}</p> -->
            <p>{{$t('订单信息.公司注册地址')}}{{ info.registerAddress }}</p>
            <p>{{$t('订单信息.公司电话')}}{{ info.companyMobile }}</p>
            <p>{{$t('订单信息.统一信用代码')}}{{ info.creditCode }}</p>
            <p>{{$t('订单信息.开户银行')}}{{ info.openBank }}</p>
            <p>{{$t('订单信息.邮箱')}}{{ info.mailbox }}</p>
          </div>
        </div>
        <div class="threeRight">
          <!-- <div class="divCss" style="width:384px;height: 168px;"> -->
            <div class="divCss" style="width:384px;height:130px;margin-bottom:0px">
            <div class="twoT">
              <p class="orderTit" style="margin-bottom:10px;">{{ $t('订单信息.配送信息') }}</p>
            </div>
            <div class="con pBot">
              <p>{{$t('订单信息.配送方式：')}}{{info.logisticsName}}</p>
              <p>{{$t('订单信息.运费优惠券抵扣金额')}}{{info.freightDeductionPrice}}</p>
              <!-- <p>{{$t('订单信息.运费付款方式')}}XXXXXXXXXXX心</p> -->
              
            </div>
          </div>
          <div class="divCss" style="width:384px;height:130px;margin-bottom:0px">
          <div class="twoT">
            <p class="orderTit" style="margin-bottom: 10px;">{{ $t('订单信息.付款信息') }}</p>
          </div>
          <div class="con pBot">
            <!-- <p>{{$t('订单信息.付款时间')}}{{ info.name }}</p> -->
            <p>{{$t('订单信息.商品总额')}}{{ info.productPrice }}</p>
            <p>{{$t('订单信息.余额支付：')}}{{ info.totalPrice }}</p>
          </div>
        </div>
          <div class="divCss" style="width:384px;height:150px;margin-bottom:0px">
          <div class="twoT">
            <p class="orderTit" style="margin-bottom: 10px;">{{ $t('订单信息.收货地址') }}</p>
          </div>
          <div class="con pBot">
            <p>{{$t('订单信息.收货人')}}{{ info.name }}</p>
            <p>{{$t('订单信息.手机')}}{{info.mobile}}</p>
            <p>{{$t('订单信息.地址')}}{{ info.addressDetails }}</p>
          </div>
        </div>
          <!-- <div class="divCss" style="width:384px;height: 168px;">
            <div class="twoT">
              <p class="orderTit">{{ $t('订单信息.合同信息') }}</p>
            </div>
            <div class="con">
              <p>{{$t('订单信息.合同下载')}}XXXXXXXXXXX心</p>
            </div>
          </div>
          <div class="divCss" style="width:384px;height: 168px;">
            <div class="twoT">
              <p class="orderTit">{{ $t('订单信息.开票信息') }}</p>
              <span class="editBtn hand">{{ $t('订单信息.开票详情') }}</span>
            </div>
            <div class="con">
              <p>{{$t('订单信息.状态')}}XXXXXXXXXXX心</p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="fourDiv divCss">
        <div class="twoT">
          <p class="orderTit">{{ $t('订单信息.订单信息') }}</p>
          <!-- <span class="editBtn hand" @click="detailDialog=true">{{ $t('订单信息.保存') }}</span> -->
        </div>
        <div v-for="item in info.children">
          <div class="orderGoods">
          <img :src="$baseUrl+item.productPhoto"/>
          <div class="fouDiv" style="min-width:200px">
            <p class="threeTit">{{ $t('订单信息.商品编号') }}</p>
            <!-- <p class="threeCon" style="text-align:left;">{{ $t('订单信息.自定义料号') }}{{ item.name }}</p> -->
            <p class="threeCon" style="text-align:left;">{{ $t('订单信息.品牌') }}{{ $i18n.locale=='zh'?item.productBrandName:item.productBrandNameEn }}</p>
          </div>
          <div class="fouDiv">
            <p class="threeTit">{{ $t('订单信息.商品名称') }}</p>
            <p class="threeCon" style="text-align:left;">{{ $i18n.locale=='zh'?item.productName:item.productNameEn }}</p>
          </div>
          <!-- <div class="fouDiv">
            <p class="threeTit">{{ $t('订单信息.单价') }}</p>
            <p class="threeCon">{{ $t('公用.¥') }}{{ item.productPrice }}</p>
          </div> -->
          <div class="fouDiv">
            <p class="threeTit">{{ $t('订单信息.订单数量') }}</p>
            <p class="threeCon">{{ info.buyNumber }}</p>
          </div>
          <!-- <div class="fouDiv">
            <p class="threeTit">{{ $t('订单信息.合计') }}</p>
            <p class="threeCon">{{ $t('公用.¥') }}{{ info.totalPrice }}</p>
          </div> -->
        </div>
        <div class="threeRemark">
          <div class="remaDiv">
            <p>{{ $t('订单信息.订单备注') }}</p>
            <input v-model="info.remark" disabled/>
          </div>
          <!-- <div class="remaDiv">
            <p>{{ $t('订单信息.合同号') }}</p>
            <input v-model="info.name" disabled/>
          </div> -->
        </div>
        </div>
      </div>
      <div class="five divCss">
        <p>{{$t('订单信息.商品总额')}}<span>{{$t('公用.¥')}}{{ info.productPrice }}</span></p>
        <p>{{$t('订单信息.运费')}}<span>{{$t('公用.¥')}}{{ info.freightPrice }}</span></p>
        <p>{{$t('订单信息.应付总额')}}<span>{{$t('公用.¥')}}{{ info.totalPrice }}</span></p>
      </div>
      <div class="sixDiv divCss">
        <p class="orderTit">{{ $t('订单信息.热销商品') }}</p>
        <div class="goodsModule">
          <div class="goodSub hand" v-for="item2 in hotList" @click="pathClick(item2)">
            <img :src="$baseUrl+item2.productPhoto" />
            <div>
              <p>{{$t('购物车.产品名称')}}{{$i18n.locale=='zh'?item2.productName:item2.productNameEn}}</p>
              <p>{{$t('购物车.品牌：')}}{{$i18n.locale=='zh'?item2.productBrandName:item2.productBrandNameEn}}</p>
              <p class="pri" v-if="index3==0" v-for="(index3,item3) in item2.priceChildren">{{$t('公用.¥')}}{{item3.unitPrice}}</p>
              <p class="addBtn" @click.stop="addCarClick(item2)">{{$t('购物车.加入购物车')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
    <el-dialog width="620px" :title="$t('订单信息.发货清单')" :visible.sync="detailDialog">
      <div class="dialogOneCss">
        <p class="detailP">{{$t('订单信息.邮政快递')}}快递单号：2394723874893743</p>
        <p class="detailP">{{$t('订单信息.快递件数')}}1</p>
        <p class="detailP">{{$t('订单信息.商品型号')}}oifHHGU1</p>
        <p class="detailP">{{$t('订单信息.商品数量')}}4354</p>
        <div class="diaBtn" style="justify-content:center">
          <p class="no hand" @click="subDialog=false">{{$t('订单信息.点击复制')}}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="890px" :title="$t('订单信息.国内单号')" :visible.sync="emsDialog">
      <div class="dialogTwoCss">
        <p class="num">邮政{{$t('订单信息.快递快递单号')}}34543543534</p>
        <div class="wuliu">
          <div class="wuliuSub" v-for="(item,index) in 6" :class="index>=3?'hui':''">
            <p>2024-05-16 14:27:28</p>
            <p style="color:#666">【深圳市】 您的快件已由本人签收，如有疑问请电话联系
              快递员：钟宝文
              电话：13642361843
              感谢您使用顺丰，期待再次为您服务。(主单总件数:1件)</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="760px" :title="$t('订单信息.编辑发票')" :visible.sync="billingDialog">
      <div class="dialogThreeCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png" />
          <p>{{$t('订单信息.只能修改一次')}}</p>
        </div>
        <div class="twoInfo">
          <div class="infoSub">
            <p>{{$t('订单信息.发票类型')}}</p>
            <div>
              <el-radio v-model="radio" label="1">{{$t('订单信息.数电增值税(专用)发票')}}</el-radio>
              <el-radio v-model="radio" label="2">{{$t('订单信息.数电增值税(普通)发票')}}</el-radio>
            </div>
          </div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.发票抬头')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.统一社会信用代码')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.公司注册地址')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.公司电话')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.开户银行')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.开户银行账号')}}</p><input /></div>
          <div class="infoSub"><span>*</span><p>{{$t('订单信息.邮箱')}}</p><input /></div>
        </div>
        <div class="checkCss"><el-checkbox v-model="checked">{{$t('订单信息.将本次发票信息同步到开票资料中')}}</el-checkbox></div>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="billClick">{{$t('订单信息.邮件通知财务开票')}}</p>
          <p class="no hand" @click="billDialog=false">{{$t('订单信息.取消')}}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="620px" :title="$t('订单信息.开票详情')" :visible.sync="billDetailDialog">
      <div class="dialogOneCss">
        <p class="detailP">{{$t('订单信息.发票号')}}394723874893743</p>
        <p class="detailP">{{$t('订单信息.商品型号')}}1</p>
        <p class="detailP">{{$t('订单信息.商品数量')}}oifHHGU1</p>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="billClick">{{$t('订单信息.重新交付')}}</p>
          <p class="no hand" @click="billDetailDialog=false">{{$t('订单信息.点击复制清单')}}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {orderDetail,productList,userShoppingCar} from '@/util/http'
import Cookies from 'js-cookie'

export default {
  components: {carTopNav, bottomNav},
  data() {
    return {
      id:'',
      loading: false,
      info:{},
      detailDialog:false,//发货清单
      emsDialog:false,//物流信息
      billingDialog:false,//修改发票
      billDetailDialog:false,//开票详情
      radio:'',
      hotList:[],
      checked:''
    }
  },
  created() {
    this.$route.query.id?this.id=this.$route.query.id:''
    this.getDetail()
    this.getHotList()
  },
  methods: {
    pathClick(item){
      this.$router.push({
          path: '/goods',
          query: {
            id: item.id
          }
        })
    },
    addCarClick(item) {//加入购物车
      userShoppingCar({
        userId:JSON.parse(Cookies.get('userInfo')),
        productId:item.id,
        buyNumber:1
      }).then(res => {
        this.$message(res.msg)
      }).catch(err => {
        if (err.msg) {
          this.$message(err.msg)
        }
      })

    },
    getHotList(){
      productList().then(res=>{
        this.hotList=res.rows
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    getDetail(){
      orderDetail(this.id).then(res=>{
        this.info=res.data
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    billClick(){
      this.billingDialog=false
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .indexModule {
    margin: 60px auto;

    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        width: 98px;
        height: 26px;
        line-height: 26px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .con {
      p {
        margin-bottom: 17px;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
      .pBot p{
        margin-bottom: 5px;
      }

    .oneDiv {
      height: 260px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .oneLeft {
        width: 427px;

        .num {
          margin: 20px 0;
          text-align: center;
          font-size: 16px;
          color: #333333;
          line-height: 19px;
        }

        h2 {
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          color: #13DF4C;
          line-height: 23px;
          margin: 20px 0 30px 0;
        }

        .dyDiv {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 13px;
          color: #999999;
          justify-content: space-evenly;

          div {
            text-align: center;
            margin: 0 30px;
            width: 110px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

      }

      .oneRig {
        width: 749px;

        .line {
          width: 669px;
          display: flex;
          align-items: center;

          .lineLeft {
            display: flex;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 20px;

            .reverse {
              //flex-direction: row-reverse; /* 反转布局方向 */
            }

            .lineSub {
              display: flex;
              align-items: center;
              //border: solid 1px red;
              .lineText {
                display: flex;
                font-size: 13px;
                color: #333333;
                line-height: 15px;
                margin: 0 10px;

                img {
                  width: 40px;
                  height: 40px;
                }

                p:nth-child(1) {
                  font-size: 16px;
                  color: #333333;
                  line-height: 22px;
                }
              }

              .iconJt {
                width: 60px;
                height: 20px;
                margin: 0 10px;
              }

              .actF {
                transform: rotate(180deg);
              }
            }
          }

          .inconImg {
            width: 100px;
            height: 125px;
          }
        }
      }
    }

    .twoDiv {
      height: 230px;
      display: flex;
      justify-content: space-between;

      .conBtn {
        width: 98px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #999999;
        font-size: 11px;
        color: #333333;
      }
    }

    .threeDiv {
      display: flex;
      justify-content: space-between;
      // height: 352px;

      .threeLeft {
        width: 384px;
        // height: 352px;
        margin-right: 20px;
      }

      .threeRight {
        flex: 1;
        height: 352px;
        height: 330px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .fourDiv {
      margin-top: 20px;
      .orderGoods {
        border-bottom: 2px solid #D9D9D9;
        padding-bottom: 18px;
        margin-bottom: 18px;
        display: flex;
        //height: 94px;
        //align-content: center;
        justify-content: space-between;

        img {
          width: 94px;
          height: 94px;
        }

        .fouDiv {
          //margin:0 20px;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 16px;
          min-width: 150px;
          color: #333333;
          line-height: 22px;
          text-align: center;

          .threeTit {
            margin-bottom: 20px;
          }

          .threeCon {
            font-size: 13px;
            color: #333333;
            line-height: 22px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

          }
        }
      }
      .threeRemark {
        display: flex;
        align-content: center;
        justify-content: space-between;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        //height: 26px;
        .remaDiv {
          display: flex;
          align-content: center;
          justify-content: space-between;
        }

        input {
          flex: 1;
          line-height: 22px;
          width: 429px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
        }
      }
    }
    .five{
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        font-size: 12px;
        color: #333333;
        span{
          font-weight: bold;
          font-size: 14px;
          color: #FF6600;
        }
      }
    }
    .sixDiv{
      .goodsTit{
        font-size: 24px;
        color: #333333;
      }
      .goodsModule{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        .goodSub{
          //width:350px;
          display: flex;
          font-size: 14px;
          color: #000000;
          img{
            width: 120px;
            height: 120px;
            margin-right: 20px;
            border-radius: 4px 4px 4px 4px;
          }
          div{
            p{
              margin-bottom:10px;
            }
            .pri{
              color: #FF6600;
            }
            .addBtn{
              width: 153px;
              height: 40px;
              text-align: center;
              color: #fff;
              line-height: 40px;
              background: #012F9C;
              border-radius: 4px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .dialogOneCss {
    padding: 0 70px;
    box-sizing: border-box;
    p{
      font-size: 16px;
      color: #000000;
      margin-bottom: 20px;
    }
  }
  .dialogTwoCss{
    //width:;
    font-size: 16px;
    color: #000000;
    overflow-x: auto;
    .wuliu{
      margin: 40px 0;
      width:830px;
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      box-sizing: border-box;
      .wuliuSub{
        background-image: url("@/assets/member/x1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        padding:0 20px;
        box-sizing: border-box;
        width:205px;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        //white-space: normal; /* 保持文字的正常换行 */
        flex-shrink: 0; /* 防止子元素被压缩 */
        p{
          margin-top: 15px;
        }
      }
      .hui{
        background-image: url("@/assets/member/x2.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  .dialogThreeCss{
    padding: 0 60px;
    margin: auto;
    .checkCss{
      text-align: center;
    }
    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;
      span{
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }
      input{
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #999999;
        width: 278px;
        height: 26px;
      }
      .infoSub {
        display: flex;
        align-content: center;
        margin:20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
      }
      p{
        color: #999999;
        margin-right:15px;
      }
    }
  }
  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;
    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }
}
</style>