import request from '@/util/request'

export function getCurrentMonthSignList(data) {
  return request({
    url: `/zgkj/userSign/getCurrentMonthSignList`,
    method: 'GET',
    params: data
  })
}


export function userSignAdd(data) {
  return request({
    url: `/zgkj/userSign`,
    method: 'POST',
    data: data
  })
}

export function all(data) {
  return request({
    url: `/zgkj/userSign/all`,
    method: 'GET',
    data: data
  })
}

export function userSignNotify(data) {
  return request({
    url: `/zgkj/userSign/userSignNotify`,
    method: 'GET',
    data: data
  })
}

