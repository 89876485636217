<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="twoModule w_1200">
        <div class="schemeLeft">
          <h2>{{$t('产品方案.产品方案导航')}}</h2>
          <div class="schemeLine">
            <p :class="item.id==tabVal?'active':'hand hover'" @click="tabClick(item)" v-for="item in tabArr">{{$i18n.locale == "zh" ?item.classifyName:item.classifyNameEn}}</p>
          </div>
        </div>
        <div class="schemeRight">
          <div class="schemeSub hand hoverTo" v-for="item in list" @click="detailClick(item)">
            <img :src="$baseUrl+item.articlePic" />
            <p>{{ $i18n.locale == "zh" ?item.articleTitle:item.articleTitleEn }}</p>
            <div v-html="$i18n.locale =='zh'?item.articleDescribe:item.articleDescribeEn"></div>
          </div>
          <div style="text-align:right;margin-top:30px;" v-if="list.length>0">
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {classList,productProgramList} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            tabVal:3,
            tabArr:[],
            list:[],
            pageSize:10,//每页显示条数
            pageNum:1,
            total:0,
        }
    },
  mounted() {
    this.getTabList()
    this.getList()
    this.tabVal=this.$route.query.num
  },
  created(){},
    methods:{
      getList(){
        productProgramList({
          classifyId:this.tabVal,
          pageNum:this.pageNum,
          pageSize:this.pageSize
        }).then(res=>{
          this.list=res.rows
          this.total=res.total
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getTabList(){
        classList().then(res=>{
          this.tabArr=res.rows
          console.log(this.tabArr)
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      pageChange(e){//当前页
        this.pageNum=e
        this.getList()
        console.log(e)
      },
      tabClick(item){
        this.tabArr.map((i)=>{
          if(item.id==i.id){
            this.tabVal=i.id;
            this.pageNum=1
            this.getList()
          }
        })
      },
      detailClick(item){
        this.$router.push({
          path: '/schemeDetail',
          query: {id:item.id}
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .twoModule{
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    .schemeLeft{
      width: 277px;
      background: #FFFFFF;
      //padding: 25px 0;
      box-sizing: border-box;
      height: 415px;
      h2{
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        line-height: 40px;
        padding: 16px 29px;
      }
      .schemeLine{
        font-size: 21px;
        color: #000000;
        p{
          padding:5px 0 5px 26px;
          border-left: 3px solid #fff;
          margin-bottom: 25px;
        }
        .active{
          color: #0332A1;
          border-left: #0332A1 solid 3px;
        }
      }
    }
    .schemeRight{
      width: 899px;
      background: #FFFFFF;
      padding: 30px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 30px;
      .schemeSub{
        width: 402px;
        height: 425px;
        background: #FFFFFF;
        border: 1px solid #CDCDCD;
        padding:20px;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        text-align: center;
        p{
          margin:25px 0 15px 0;
        }
        img{
          width: 263px;
          height: 171px;
        }
        div{
          text-align: left;
          font-size: 13px;
          height: 157px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          line-height: 30px;
        }
      }
    }
  }
}
</style>