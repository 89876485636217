import request from '@/util/request'



export function SysUserWalletLogInfoList(data) {
  return request({
    url: `/system/sysUserWalletLogInfo/list`,
    method: 'GET',
    params: data
  })
}
