import Cookies from 'js-cookie'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import locale from 'element-ui/lib/locale'
import en from './en'
import zh from './zh'
Vue.use(VueI18n)

const messages={
    en:{
        ...en,
        ...enLocale
    },
    zh:{
        ...zh,
        ...zhLocale
    }
}

const i18n=new VueI18n({
    locale:Cookies.get('lang')||'zh',//set locale 默认中文
    messages,
    silentFallbackWarn: true, // 抑制警告
    globalInjection: true // 全局注入
})
// locale.i18n((key,value)=>i18n.t(key,value))
export default i18n