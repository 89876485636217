<template>
  <div class="" v-loading="loading">
    <!-- <pageTop></pageTop> -->
    <topNav :active="1"></topNav>
    <rightNav></rightNav>
    <banner></banner>
    <recommend></recommend>
    <hot></hot>
    <tool></tool>
    <feature></feature>
    <news></news>
    <agent></agent>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import pageTop from "@/component/home/pageTop.vue";
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import banner from "@/component/home/banner.vue";
import rightNav from "@/component/home/rightNav.vue";
import recommend from "@/component/home/recommend.vue";
import hot from "@/component/home/hot.vue";
import tool from "@/component/home/tool.vue";
import feature from "@/component/home/feature.vue";
import news from "@/component/home/news.vue";
import agent from "@/component/home/agent.vue";

export default {
  components: { pageTop, topNav, bottomNav, banner, rightNav ,recommend,hot,tool,feature,news,agent},
  data() {
    return {
      loading: false,
    };
  },
  onload() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;
}
</style>
