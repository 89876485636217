<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{ $t('我买过的.我买过的') }}</p>
            <span class="editBtn hand" style="margin-right:10px;">{{
                $t('我买过的.商城购买')
              }}</span>
          </div>
          <div class="selectDiv">
            <div class="seleLeft">
              <div class="sub">
                <span>{{ $t('我买过的.商品信息') }}{{ $t('公用.：') }}</span>
                <input :placeholder="$t('我买过的.商品型号')" class="subInp"/>
              </div>
              <div class="sub">
                <span>{{ $t('我买过的.其他分类') }}{{ $t('公用.：') }}</span>
                <el-select v-model="value" class="subInp">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{ $t('我买过的.商品列表') }}</th>
                <th>{{ $t('我买过的.商品型号') }}</th>
                <th>{{ $t('我买过的.品牌') }}</th>
                <!--                <th>{{ $t('我买过的.参数') }}</th>-->
                <th>{{ $t('我买过的.单价') }}</th>
                <th>{{ $t('我买过的.购买次数') }}</th>
                <th>{{ $t('我买过的.最近购买') }}</th>
                <!--                <th>{{ $t('我买过的.操作') }}</th>-->
              </tr>
              <tr v-for="(item,index) in tableData" class="trCon">
                <td>
                  <div v-for="item1 in item.children" class="descCss" style="width:130px">
                    {{ item1.productName }}
                  </div>
                </td>
                <td>
                  <div v-for="item1 in item.children" class="descCss" style="width:130px">
                    {{ item1.manufacturerNumber }}
                  </div>
                </td>
                <td>
                  <div v-for="item1 in item.children" class="descCss" style="width:130px">
                    {{ item1.productBrandName }}
                  </div>
                </td>
                <!--                <td class="xj"><p style="width:100px"></p></td>-->
                <td class="xj"><p style="width:80px">{{ $t('公用.¥') }}{{ item.productPrice }}</p>
                </td>
                <td class="xj"><p style="width:65px">1</p></td>
                <td class="xj"><p style="width:132px">{{ item.createTime }}</p></td>
                <!--                <td>-->
                <!--                  <div class="editTab hand">-->
                <!--                    <p>{{ $t('我买过的.点击询价') }}</p>-->
                <!--                  </div>-->
                <!--                </td>-->
              </tr>
            </table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import Cookies from "js-cookie";
import {orderList} from "@/api/myInfo/after";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      flag: false,
      options: [],
      value: '',
      value1: '',
      tableData: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let info = {
        orderType: 1,
        userId: JSON.parse(Cookies.get('userInfo')).userId,
      }
      orderList(info).then(res => {
        this.tableData = res.rows
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .selectDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .seleLeft {
        display: flex;
        flex-wrap: wrap;
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        grid-gap: 20px;
        //border: solid 1px;
        //margin-right: 30px;
        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            width: 200px;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            height: 36px;
          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }

        .ac {
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }

        .exportbtn {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        text-align: center;
        display: flex;

        .goodsPhoto {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
        word-break: break-all;
      }

      .editTab {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }

        .activeP {
          color: #FF0000;
        }
      }

      table {
        width: 100%;
        border-collapse: separate; /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px; /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {
        }

        .trTit {
          line-height: 48px;
          height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #FFFFFF;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          text-align: center;
          display: block;
        }

        .xj p {
          text-align: center;
          display: block
        }
      }

    }
  }

  .dialogThreeCss {
    padding: 0 60px;
    margin: auto;

    .checkCss {
      text-align: center;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;

      span {
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }

      input {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #999999;
        width: 278px;
        height: 26px;
      }

      .infoSub {
        display: flex;
        align-content: center;
        margin: 20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right: 15px;
        width: 100px;
      }
    }
  }

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;

    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
}
</style>
