import request from '@/util/request'


export function orderList(data) {
  return request({
    url: `/zgkj/order/list`,
    method: 'GET',
    params: data
  })
}

export function pay(data) {
  return request({
    url: `/zgkj/order/alipayOrder`,
    method: 'GET',
    params: data
  })
}
