<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="couponDiv">
        <div class="couBanner"></div>
        <div class="couModule w_1200">
          <div class="douponRole">
            <div class="roleLeft">
              <p class="inpTIt">{{$t('领券中心.贞光领券规则')}}</p>
              <div class="roleCon">
                <p>1、{{$t('领券中心.贞光商城的券')}}</p>
                <p>2、{{$t('领券中心.同一种类型的券')}}</p>
                <p>3、{{$t('领券中心.法律允许范围内')}}</p>
              </div>
            </div>
            <div class="roleRig">
              <p class="roleRT">{{$t('领券中心.新用户专享优惠券')}}</p>
              <span>{{$t('领券中心.注册完成后')}}</span>
              <div class="packetModule">
                <div class="packetSub hand hoverTo" v-for="item in xinList" @click="couponClick(item)">
                  <p class="subTit" v-if="item.couponType==1">{{$t('领券中心.满')}}{{item.fullPrice}}{{$t('领券中心.减')}}{{item.subtractPrice}}</p>
                  <p class="subTit" v-if="item.couponType==2">{{item.discount}}{{$t('领券中心.元无门槛')}}</p>
                  <p class="subTit" v-if="item.couponType==3">{{item.discount}}{{$t('领券中心.折扣券')}}</p>
                  <div>
                    <p>{{ $i18n.locale=='zh'?item.couponName:item.couponNameEn }}</p>
                    <!-- <p>{{ item.effectiveDate }}</p> -->
                    <p v-if="item.expiryDate">{{$t('领券中心.失效日期')}}{{ item.expiryDate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="couBottom">
            <p class="botTit">{{$t('领券中心.领券立减')}}</p>
            <div class="newTit">
              <span @click="tabClick(item)" class="hand hover" :class="item.flag?'newActive':''" v-for="(item,index) in titArr">{{item.tit}}</span>
            </div>
            <div class="couMaxOut">
              <div class="maxOutSub hand hoverTo" v-for="item in list" @click="couponClick(item)">
                <p class="max" v-if="item.couponType==1">{{$t('公用.¥')}}<span>{{item.subtractPrice}}</span>{{$t('领券中心.满')}}{{item.fullPrice}}{{$t('领券中心.可用')}}</p>
                <p class="max" v-if="item.couponType==2"><span>{{item.discount}}</span>{{$t('领券中心.元无门槛')}}</p>
                <p class="max" v-if="item.couponType==3"><span>{{item.discount}}</span>{{$t('领券中心.折扣券')}}</p>
                <p class="out">{{ $i18n.locale=='zh'?item.couponName:item.couponNameEn }}</p>
                <p class="desc">{{ item.effectiveDate }} ～ {{ item.expiryDate}}</p>
                <p class="desc">{{$t('领券中心.已领')}}{{ item.drawNum }}</p>
                <p class="btn" v-if="!item.received">{{$t('领券中心.立即领取')}}</p>
                <p class="btn" v-else>{{$t('领券中心.已领取')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import Cookies from "js-cookie";
import {frontEndList,receive} from '@/util/http'
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            list:[],
            xinList:[],
            tabId:1,
            titArr:[
              {id:1,tit:this.$t('领券中心.商品券'),url:'',flag:true},
              {id:2,tit:this.$t('领券中心.优惠券'),url:'',flag: false},
              {id:3,tit:this.$t('领券中心.运费券'),url:'',flag: false},
            ]
        }
    },
    created(){
      this.getList()
      this.getXinList()
    },
    mounted(){},
    methods:{
      couponClick(item){
        if(item.received){
          this.$message(this.$t('领券中心.已经领取过了～'))
          return
        }
        receive({
          id:item.id,
          userId:JSON.parse(Cookies.get('userInfo')).userId
        }).then(res=>{
          this.$message(res.msg)
          this.getList()
          this.getXinList()
        }).catch(err=>{
          this.$message(err.msg)
        })
      },
      getXinList(){//新用户专享优惠券
      frontEndList({
          userId:JSON.parse(Cookies.get('userInfo')).userId,
          couponClassify:4,
        }).then(res=>{
          this.xinList=res.rows
          console.log(res)
        }).catch(err=>{
          console.log(err)
        })
      },
      getList(){//优惠券列表
      frontEndList({
          userId:JSON.parse(Cookies.get('userInfo')).userId,
          couponClassify:this.tabId,
        }).then(res=>{
          this.list=res.rows
          console.log(res)
        }).catch(err=>{
          console.log(err)
        })
      },
      tabClick(item){
        this.titArr.map((i)=>{
          i.flag=false;
          if(item.id==i.id){
            i.flag=true;
            this.tabId=i.id
            this.getList()
          }
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .couponDiv{
    .couBanner{
      background-image: url("@/assets/sub/cou.png");
      background-size: 100% 100%;
      width: 100%;
      height: 500px;
    }
    .couModule{
      margin:40px auto;
      .inpTIt{
        font-size: 24px;
        color: #333333;
        padding-left: 2px;
        border-left:6px #FF6600 solid;
      }
      .douponRole{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .roleLeft{
          height: 308px;
          width: 384px;
          width: 384px;
          padding:30px 20px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          .roleCon{
            p{
              font-size: 16px;
              color: #333333;
              margin-top: 20px;
              line-height:22px;
            }
          }
        }
        .roleRig{
          height: 308px;
          width: 792px;
          padding:30px 20px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          .roleRT{
            font-size: 24px;
            color: #333333;
            line-height: 28px;
          }
          span{
            margin-top: 6px;
            font-size: 13px;
            color: #999999;
          }
          .packetModule{
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .packetSub{
              text-align: center;
              padding:20px;
              box-sizing: border-box;
              //border: solid 1px red;
              width: 182px;
              height: 182px;
              background-image: url("@/assets/sub/cou2.png");
              background-size: 100% 100%;
              background-position: center;
              .subTit{
                font-weight: bold;
                font-size: 16px;
                color: #FF6600;
              }
              div{
                margin-top: 20px;
                font-size: 13px;
                color: #FF6600;
                p{
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
      .couBottom{
        background: #FFFFFF;
        border-radius: 4px;
        padding: 30px 0;
        box-sizing: border-box;
        margin-top:30px;
        .botTit{
          font-size: 40px;
          color: #333333;
          text-align: center;
          margin-bottom: 38px;
        }
        .newTit{
          font-size: 20px;
          color: #333333;
          margin-bottom: 60px;
          span{
            margin-left:60px;
          }
          .newActive{
            font-weight: bold;
            font-size: 26px;
            color: #012F9C;
            padding-bottom: 8px;
            border-bottom: 4px solid #FF6600;
          }
        }
        .couMaxOut{
          display: grid;
          grid-template-columns: repeat(4,1fr);
          grid-gap: 25px;
          //margin-top: 40px;
          .maxOutSub{
            background-image: url("@/assets/sub/cou3.png");
            width: 282px;
            height: 207px;
            border-radius: 4px;
            position: relative;
            padding:0 15px;
            box-sizing: border-box;
            p{
              color: #fff;
            }
            .max{
              margin-top: 28px;
              font-size: 16px;
              span{
                font-size: 40px;
              }
            }
            .out{
              font-weight: 500;
              font-size: 16px;
              margin: 15px 0;
            }
            .desc{
              font-size: 11px;
              margin-bottom: 6px;
            }
            .btn{
              font-size: 16px;
              position: absolute;
              bottom:17px;
              left:40%;
            }
          }
        }
      }
    }
  }
}
</style>