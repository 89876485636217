import Vue from "vue";
import VueRouter from "vue-router";
import modules from './modules'

import Login from '../views/login/login'
import Regist from '../views/login/regist'
import Forget from '../views/login/forget'
import about from '../views/aboutUs/index'
import footClass from '../views/aboutUs/footClass'
import feedBack from '../views/aboutUs/feedBack'
import honor from '../views/aboutUs/honor'
import agency from '../views/aboutUs/agency'
import contact from '../views/aboutUs/contactUs'
import Home from '../views/home/index'
import sample from '../views/home/sample'
import newActivity from '../views/home/newActivity'
import signIn from '../views/home/signIn'
import signScore from '../views/home/signScore'
import college from '../views/home/college'
import integralShop from '../views/home/integralShop'
import brandResource from '../views/home/brandResource'
import coupon from '../views/home/coupon'
import catalogueOne from '../views/home/catalogueOne'
import catalogueTwo from '../views/home/catalogueTwo'
import brandOne from '../views/home/brandOne'
import BOM from '../views/home/BOM'
import authorized from '../views/home/authorized'
import supplier from '../views/home/supplier'
import news from '../views/home/news'
import newsDetail from '../views/home/newsDetail'
import scheme from '../views/home/scheme'
import schemeDetail from '../views/home/schemeDetail'
import conversionTool from '../views/home/conversionTool'
import brandTwo from '../views/brand/brandTwo.vue'
import authInfo from '../views/brand/authInfo.vue'
import product from '../views/brand/product.vue'
import bazaarNews from '../views/brand/bazaarNews.vue'
import bazzaarNewsDetail from '../views/brand/bazzaarNewsDetail.vue'
import carIndex from '../views/shoppingCar/index.vue'
import goods from '../views/shoppingCar/goods.vue'
import order from '../views/shoppingCar/order.vue'
import payInfo from '../views/shoppingCar/payInfo.vue'
import orderState from '../views/shoppingCar/orderState.vue'
import mySample from '../views/myInfo/mySample.vue'
import myFavorite from '../views/myInfo/myFavorite.vue'
import liaoNum from '../views/myInfo/liaoNum.vue'
import link from '../views/myInfo/link.vue'
import myBom from '../views/myInfo/BOM.vue'
import myMsg from '../views/myInfo/msg.vue'
import msgDetail from '../views/myInfo/msgDetail.vue'
import myOffer from '../views/myInfo/offer.vue'
import myBrand from '../views/myInfo/brand.vue'
import warnSetting from '../views/myInfo/warnSetting.vue'
import purse from '../views/myInfo/purse.vue'
import integral from '../views/myInfo/integral.vue'
import gify from '../views/myInfo/gify.vue'
import myCoupon from '../views/myInfo/myCoupon.vue'
import myInfo from '../views/myInfo/info.vue'
import address from '../views/myInfo/address.vue'
import makeOut from '../views/myInfo/makeOut.vue'
import editPwd from '../views/myInfo/editPwd.vue'
import account from '../views/myInfo/account.vue'
import myOrder from '../views/myInfo/myOrder.vue'
import after from '../views/myInfo/after.vue'
import afterDetail from '../views/myInfo/afterDetail.vue'
import buyUp from '../views/myInfo/buyUp.vue'
import getBill from '../views/myInfo/getBill.vue'
import electronicStatement from '../views/myInfo/electronicStatement.vue'
import vip from '../views/myInfo/vip.vue'
import vipGrade from '../views/myInfo/vipGrade.vue'

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        ...modules,
        {
            path: "/",
            redirect: "/home"
        },
        {
            path: "/home",
            component: Home
        },
        {//登陆
            path: "/login",
            component: Login
        },
        {//注册
            path: "/regist",
            component: Regist
        },
        {//忘记密码
            path: "/forget",
            component: Forget
        },
        {//关于我们
            path: "/about",
            component: about
        },
        {//免费申请样品
            path: "/sample",
            component: sample
        },
        {//最新活动
            path: "/newActivity",
            component: newActivity
        },
        {//底部 新手入门
            path: "/footClass",
            component: footClass
        },
        {//意见反馈
            path: "/feedBack",
            component: feedBack
        },
        {//关于贞光 荣誉风采
            path: "/honor",
            component: honor
        },
        {//关于贞光 授权代理
            path: "/agency",
            component: agency
        },
        {//关于贞光 联系我们
            path: "/contact",
            component: contact
        },
        {//签到
            path: "/signIn",
            component: signIn
        },
        {//获得积分
            path: "/signScore",
            component: signScore
        },
        {//高校计划
            path: "/college",
            component: college
        },
        {//积分商城
            path: "/integralShop",
            component: integralShop
        },
        {//品牌资源
            path: "/brandResource",
            component: brandResource
        },
        {//领券中心
            path: "/coupon",
            component: coupon
        },
        {//商品目录
            path: "/catalogueOne",
            component: catalogueOne
        },
        {//商品目录2
            path: "/catalogueTwo",
            component: catalogueTwo
        },
        {//品牌专区
            path: "/brandOne",
            component: brandOne
        },
        {//BOM配单
            path: "/BOM",
            component: BOM
        },
        {//授权代理
            path: "/authorized",
            component: authorized
        },
        {//授权信息
            path: "/authInfo",
            component: authInfo
        },
        {//产品线
            path: "/product",
            component: product
        },
        {//市场新闻
            path: "/bazaarNews",
            component: bazaarNews
        },
        {//市场新闻详情
            path: "/bazzaarNewsDetail",
            component: bazzaarNewsDetail
        },
        {//供应商合作
            path: "/supplier",
            component: supplier
        },
        {//新闻
            path: "/news",
            component: news
        },
        {//新闻详情
            path: "/newsDetail",
            component: newsDetail
        },
        {//品牌专区 精选品牌
            path: "/brandTwo",
            component: brandTwo
        },
        {//行业方案
            path: "/scheme",
            component: scheme
        },
        {//行业方案详情
            path: "/schemeDetail",
            component: schemeDetail
        },
        {//换算工具
            path: "/conversionTool",
            component: conversionTool
        },
        {//购物车
            path: "/carIndex",
            component: carIndex
        },
        {//商品
            path: "/goods",
            component: goods
        },
        {//订单信息
            path: "/order",
            component: order
        },
        {//支付信息
            path: "/payInfo",
            component: payInfo
        },
        {//订单状态
            path: "/orderState",
            component: orderState
        },
        {//我的样品
            path: "/mySample",
            component: mySample
        },
        {//我的收藏
            path: "/myFavorite",
            component: myFavorite
        },
        {//我的料号
            path: "/liaoNum",
            component: liaoNum
        },
        {//专属链接
            path: "/link",
            component: link
        },
        {//我的BOM
            path: "/myBom",
            component: myBom
        },
        {//我的消息
            path: "/myMsg",
            component: myMsg
        },
        {//我的消息详情
            path: "/msgDetail",
            component: msgDetail
        },
        {//我的建议
            path: "/myOffer",
            component: myOffer
        },
        {//我的品牌
            path: "/myBrand",
            component: myBrand
        },
        {//提醒设置
            path: "/warnSetting",
            component: warnSetting
        },
        {//我的钱包
            path: "/purse",
            component: purse
        },
        {//我的积分
            path: "/integral",
            component: integral
        },
        {//我的礼券
            path: "/gify",
            component: gify
        },
        {//我的优惠券
            path: "/myCoupon",
            component: myCoupon
        },
        {//基本信息
            path: "/myInfo",
            component: myInfo
        },
        {//收货地址
            path: "/address",
            component: address
        },
        {//开票资料
            path: "/makeOut",
            component: makeOut
        },
        {//修改密码
            path: "/editPwd",
            component: editPwd
        },
        {//账户安全
            path: "/account",
            component: account
        },
        {//我的订单
            path: "/myOrder",
            component: myOrder
        },
        {//售后服务
            path: "/after",
            component: after
        },
        {//售后服务详情
            path: "/afterDetail",
            component: afterDetail
        },
        {//我买过的
            path: "/buyUp",
            component: buyUp
        },
        {//发票查询
            path: "/getBill",
            component: getBill
        },
        {//电子对账单
            path: "/electronicStatement",
            component: electronicStatement
        },
        {//会员中心
            path: "/vip",
            component: vip
        },
        {//会员等级
            path: "/vipGrade",
            component: vipGrade
        }
    ]
})
