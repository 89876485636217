<template>
  <div class="indexBg" v-loading="loading">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="twoT">
            <p class="orderTit">{{ $t('开票资料.开票资料') }}</p>
            <div>
              <span @click="delHandle" class="editBtn hand" style="margin-right:10px;background:#F3F5F8;color:#333"><i
                  class="el-icon-delete" />{{ $t('开票资料.删除资料') }}</span>
              <span class="editBtn hand" @click="addDialog = true">+{{ $t('开票资料.添加资料') }}</span>
            </div>
          </div>
          <div class="detailSele">
            <div class="seleLeft">
              <p @click="tabClick(1)" :class="state == 1 ? 'active' : 'hand hover'">{{ $t('开票资料.数电增值税(专用)开票资料') }}</p>
              <span>|</span>
              <p @click="tabClick(2)" :class="state == 2 ? 'active' : 'hand hover'">{{ $t('开票资料.数电增值税(普通)开票资料') }}</p>
            </div>
          </div>
          <div class="tab">
            <table>
              <tr class="trTit">
                <th>{{ $t('开票资料.发票抬头') }}</th>
                <th>{{ $t('开票资料.公司地址') }}</th>
                <th>{{ $t('开票资料.公司电话') }}</th>
                <th>{{ $t('开票资料.开户银行账号') }}</th>
                <th>{{ $t('开票资料.邮箱') }}</th>
                <th>{{ $t('开票资料.操作') }}</th>
              </tr>
              <tr class="trCon" v-if="item.type==state" v-for="(item, index) in tableData">
                <td>
                  <div class="descCss" style="width:170px">
                    <p @click="selectHandle(item.id)" class="allBtn hand" style="margin-right:10px">
                      <img
                        :src="selectList.indexOf(item.id) > -1 ? require('@/assets/member/xz.png') : require('@/assets/member/wxz.png')" />
                    </p>
                    <div>
                      <p>{{ $t('开票资料.发票抬头') }}{{ $t('公用.：') }}{{ item.companyName }}</p>
                      <p>{{ $t('开票资料.统一社会信用代码') }}{{ $t('公用.：') }}{{ item.creditCode }}</p>
                    </div>
                  </div>
                </td>
                <td class="xj">
                  <div style="width:160px">
                    <p>{{ item.registerAddress }}</p>
                  </div>
                </td>
                <td class="xj">
                  <div style="width:110px">{{ item.companyMobile }}</div>
                </td>
                <td class="xj">
                  <div style="width:110px">
                    <p>{{ item.openBank }}</p>
                    <p>{{ item.openAccount }}</p>
                  </div>
                </td>
                <td class="dj">
                  <p style="width:110px">{{ item.mailbox }}</p>
                </td>
                <td>
                  <div class="editTab hand">
                    <p @click="editHandle(item)">{{ $t('收货地址.编辑') }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="botDiv">
          <div style="display:flex">
            <p>{{ $t('开票资料.企业名称') }}{{ $t('公用.：') }}xxxxxxxxxxxxx</p>
            <p style="margin-left:20px">{{ $t('开票资料.统一社会信用代码') }}{{ $t('公用.：') }}xxxxxxxxxxxxx</p>
          </div>
          <div class="editBtn hand" @click="billDialogialog = true">{{ $t('开票资料.修改') }}</div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog width="760px" :title="$t('开票资料.编辑发票')" :visible.sync="dialogFlag">
      <div class="dialogThreeCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png" />
          <p>{{ $t('开票资料.鄙司已纳入全面数字化的电子发票试点') }}</p>
        </div>
        <div class="twoInfo">
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.发票抬头') }}{{ $t('公用.：') }}</p><input v-model="formData.companyName" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.统一社会信用代码') }}{{ $t('公用.：') }}</p><input v-model="formData.creditCode" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司注册地址') }}{{ $t('公用.：') }}</p><input v-model="formData.registerAddress" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司电话') }}{{ $t('公用.：') }}</p><input v-model="formData.companyMobile" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行') }}{{ $t('公用.：') }}</p><input v-model="formData.openBank" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行账号') }}{{ $t('公用.：') }}</p><input v-model="formData.openAccount" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.邮箱') }}{{ $t('公用.：') }}</p><input v-model="formData.mailbox" />
          </div>
        </div>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="eidtSaveHandle">{{ $t('开票资料.保存') }}</p>
          <p class="no hand" @click="dialogFlag = false">{{ $t('开票资料.取消') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="760px" :title="$t('开票资料.修改发票抬头')" :visible.sync="billDialogialog">
      <div class="dialogThreeCss">
        <div class="couponDiv">
          <img src="@/assets/member/tx.png" />
          <p>{{ $t('开票资料.修改抬头后') }}</p>
        </div>
        <div class="twoInfo">
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.发票抬头') }}{{ $t('公用.：') }}</p><input v-model="formData.companyName" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.统一社会信用代码') }}{{ $t('公用.：') }}</p><input v-model="formData.creditCode" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司注册地址') }}{{ $t('公用.：') }}</p><input v-model="formData.registerAddress" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司电话') }}{{ $t('公用.：') }}</p><input v-model="formData.companyMobile" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行') }}{{ $t('公用.：') }}</p><input v-model="formData.openBank" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行账号') }}{{ $t('公用.：') }}</p><input v-model="formData.openAccount" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.邮箱') }}{{ $t('公用.：') }}</p><input v-model="formData.mailbox" />
          </div>
        </div>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="billClick">{{ $t('开票资料.确认修改') }}</p>
          <p class="no hand" @click="billDialogialog = false">{{ $t('开票资料.取消') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="760px" :title="$t('开票资料.添加发票')" :visible.sync="addDialog">
      <div class="dialogThreeCss">
        <div class="twoInfo">
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.发票抬头类型') }}{{ $t('公用.：') }}</p>
            <div style="flex:1;text-align: left;">
              <el-radio v-model="radio" label="1">{{ $t('开票资料.企业') }}</el-radio>
              <el-radio v-model="radio" label="2">{{ $t('开票资料.个人') }}</el-radio>
            </div>
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.发票抬头') }}{{ $t('公用.：') }}</p><input v-model="formData.companyName" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.统一社会信用代码') }}{{ $t('公用.：') }}</p><input v-model="formData.creditCode" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司注册地址') }}{{ $t('公用.：') }}</p><input v-model="formData.registerAddress" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.公司电话') }}{{ $t('公用.：') }}</p><input v-model="formData.companyMobile" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行') }}{{ $t('公用.：') }}</p><input v-model="formData.openBank" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.开户银行账号') }}{{ $t('公用.：') }}</p><input v-model="formData.openAccount" />
          </div>
          <div class="infoSub">
            <p><span>*</span>{{ $t('开票资料.邮箱') }}{{ $t('公用.：') }}</p><input v-model="formData.mailbox" />
          </div>
        </div>
        <div class="diaBtn" style="justify-content:center">
          <p class="yes hand" @click="addInvoiceHandle">{{ $t('开票资料.添加资料') }}</p>
          <p class="no hand" @click="addDialog = false">{{ $t('开票资料.取消') }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import { addInvoice, delInvoice, editInvoice, invoiceList } from "@/api/api";
import Cookies from "js-cookie";
export default {
  components: { carTopNav, bottomNav, myInfoLeft },
  data() {
    return {
      loading: false,
      radio: '1',
      state: 1,//1数电增值税(专用)开票资料 2数电增值税(普通)开票资料
      checked: false,
      dialogFlag: false,//编辑发票信息弹窗
      billDialogialog: false,//修改发票抬头弹窗
      addDialog: false,//添加资料弹窗
      value: '',
      options: '',
      flag: false,
      tableData: [
      ],
      formData: {},
      selectList: []
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    tabClick(num){
      if(num==this.state) return
      this.state=num
      this.getData()
    },
    async getData() {
      const res = await invoiceList();
      console.log(res)
      if (res.code === 200) {
        this.tableData = res.rows
      }
    },
    billClick() {
      this.addInvoiceHandle();
    },

    async addInvoiceHandle() {
      console.log('add')
      const res = await addInvoice({
        ...this.formData,
        type: this.state,
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      if (res.code == 200) {
        this.getData()
        this.addDialog = false
      }
    },
    selectHandle(id) {
      if (this.selectList.indexOf(id) > -1) {
        this.selectList.splice(this.selectList.indexOf(id), 1)
      } else {
        this.selectList.push(id)
      }
    },
    async eidtSaveHandle() {
      console.log(JSON.parse(Cookies.get('userInfo')).userId)
      return
      const res = await editInvoice({
        ...this.formData,
        type: 1,
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      if (res.code == 200) {
        this.getData()
        this.dialogFlag = false
      }
    },
    editHandle(item) {
      this.formData = { ...item }
      this.dialogFlag = true
    },
    async delHandle() {
      const res = await delInvoice(this.selectList.join(","))
      this.getData();
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .infoModule {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }

    .selectDiv {
      display: flex;
      align-items: center;

      //justify-content: space-between;
      .seleLeft {
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        //grid-gap: 20px;
        margin-right: 30px;

        .sub {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;

          .subInp {
            flex: 1;
          }

          input {
            width: 265px;
            height: 26px;
            border-radius: 4px;
            border: 1px solid #999999;

          }
        }
      }

      .seleRig {
        display: flex;
        align-items: center;

        p {
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }

        .ac {
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }

        .exportbtn {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
          color: #333333;
          background: #fff;
        }
      }
    }

    .tab {
      font-size: 13px;
      color: #333333;
      line-height: 22px;

      p {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }
      }

      .descCss {
        display: flex;

        .goodsPhoto {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }

      .numEd {
        display: flex;
        align-items: center;
      }

      td {
        //border: solid 1px;
        word-break: break-all;
      }

      .editTab {
        display: flex;
        align-items: center;
        justify-content: end;

        img {
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }

        p {
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }

        .activeP {
          //color: #FF0000;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px;

        /* 行之间的距离 */
        td {
          padding: 20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }

        td:first-child {
          padding-left: 20px;
        }

        td:last-child {}

        .trTit {
          height: 48px;
          background: #F6F6F6;
          font-size: 16px;
          color: #333;

          th:nth-child(1) {
            border-radius: 8px 0px 0px 0px;
          }

          th:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        .trCon {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }

        .dj p {
          //color:#FF6600;
          //text-align: center;
          display: block
        }

        .xj p {
          //text-align: center;
          display: block
        }
      }

    }
  }

  .dialogThreeCss {
    padding: 0 60px;
    margin: auto;

    .checkCss {
      text-align: center;
    }

    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;

      span {
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }

      input {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #DCDFE6;
        width: 278px;
        height: 26px;
        flex: 1;
      }

      .infoSub {
        display: flex;
        align-content: center;
        margin: 20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;

        .subInp {
          flex: 1;
        }
      }

      p {
        //border: solid 1px;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right: 15px;
        width: 100px;
      }
    }
  }

  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 30px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;

    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }

  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }

  .botDiv {
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #000000;
    justify-content: space-between;

    .editBtn {
      background: #012F9C;
      border-radius: 2px 2px 2px 2px;
      font-size: 11px;
      color: #FFFFFF;
      padding: 5px 15px;
    }
  }

  :deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #012F9C;
    background: #012F9C;
  }

  :deep .el-radio__input.is-checked+.el-radio__label {
    color: #333;
  }
}
</style>