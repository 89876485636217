<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="basicModule">
          <div class="basicTop">
            <div class="basicLeft divCss">
              <p class="orderTit">{{ $t('基本信息.账号基础信息') }}</p>
              <div class="basicDiv">
                <p>{{ $t('基本信息.姓名') }}{{ $t('公用.：') }}{{ userInfo.nickName }}</p>
                <div class="two">
                  <p>{{ $t('基本信息.手机号码') }}{{ $t('公用.：') }}{{ userInfo.phonenumber }}<span
                      class="hand"
                      @click="phoneDialog = true">{{
                      $t('基本信息.修改手机')
                    }}</span></p>
                  <p>{{ $t('基本信息.会员登记') }}{{ $t('公用.：') }}{{
                      userInfo.userType == '10' ? '普通会员' : '高级会员'
                    }}</p>
                </div>
                <div class="two">
                  <p>{{ $t('基本信息.邮箱') }}{{ $t('公用.：') }}{{
                      userInfo.email ? userInfo.email : '未绑定'
                    }}<span class="hand"
                            @click="emainDialog = true">{{
                        $t('基本信息.绑定')
                      }}</span><span style="color:#FF6600">{{ $t('基本信息.首次验证邮箱送') }}{{
                        $t('基本信息.积分')
                      }}</span></p>
                  <p>{{ $t('基本信息.账号类型') }}{{ $t('公用.：') }}{{
                      userInfo.userType == '10' ? '个人用户' : '公司用户'
                    }}</p>
                </div>
              </div>
            </div>
            <div class="basicRight divCss">
              <img src="@/assets/sub/fu.png"/>
              <p>{{ $t('基本信息.平台客服') }}</p>
            </div>
          </div>
          <div class="basicBottom divCss">
            <p class="orderTit">{{ $t('基本信息.账号信息') }}</p>
            <div class="inpDiv">
              <div class="inpSub">
                <p>{{ $t('基本信息.昵称') }}{{ $t('公用.：') }}</p>
                <input v-model="userInfo.nickName"/>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.性别') }}{{ $t('公用.：') }}</p>
                <el-select v-model="userInfo.sex" class="subInp">
                  <el-option v-for="item in genders" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.职务') }}{{ $t('公用.：') }}</p>
                <input v-model="userInfo.roles"/>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.职业类型') }}{{ $t('公用.：') }}</p>
                <el-select v-model="value" class="subInp">
                  <el-option v-for="item in options0" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.行业类型') }}{{ $t('公用.：') }}</p>
                <input/>
              </div>
              <div class="inpSub">
                <p>QQ{{ $t('公用.：') }}</p>
                <input v-model="userInfo.qq"/>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.公司名称') }}{{ $t('公用.：') }}</p>
                <input v-model="userInfo.companyName"/>
              </div>
              <div class="inpSub">
                <p>{{ $t('基本信息.所在区域') }}{{ $t('公用.：') }}</p>
                <el-cascader v-model="userInfo.area" :options="options" :props="{ value: 'label' }"
                             change-on-select
                             class="wd400" placeholder="请选择" @change="handleChange">
                </el-cascader>
              </div>
              <div class="inpSub" style="width:90%;">
                <p>{{ $t('基本信息.详情地址') }}{{ $t('公用.：') }}</p>
                <input v-model="userInfo.addressDetails"/>
              </div>
            </div>
            <div class="hand sureBtn" @click="editSave">
              <p>{{ $t('基本信息.确认修改') }}</p>
            </div>
          </div>
          <div class="tips">
            <img alt="" src="@/assets/login/icon10.png"/>
            <p>
              {{ $t('基本信息.完善每一项资料') }}<span>50</span>{{ $t('基本信息.最近已有') }}<span>3,838</span>{{
                $t('基本信息.人领取共')
              }}<span>1,535.200</span>{{ $t('基本信息.积分!') }}
            </p>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog :title="$t('基本信息.修改邮箱')" :visible.sync="emainDialog" width="570px">
      <div class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.当前账号密码') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="password"
                     :placeholder="$t('基本信息.请输入当前账号的密码')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.原注册邮箱') }}{{ $t('公用.：') }}</p>
            <div v-if="userInfo.email === '' && userInfo.email === null " class="txt">
              <input v-model="modifyEmail.mail" :placeholder="$t('基本信息.请输入原注册邮箱')"
                     type="text"/>
            </div>
            <div v-else class="txt">
              <input v-model="userInfo.email" :disabled="true"
                     :placeholder="$t('基本信息.请输入原注册邮箱')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.原验证码') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="modifyEmail.code" :placeholder="$t('基本信息.短信验证码')"
                     type="text"/>
              <p v-if="userInfo.email === '' && userInfo.email === null "
                 @click="sendCode(modifyEmail.mail)">{{
                  $t('基本信息.获取验证码')
                }}</p>
              <p v-else
                 @click="sendCode(userInfo.email)">{{
                  $t('基本信息.获取验证码')
                }}</p>
            </div>
          </div>
          <div v-if="userInfo.email !== '' && userInfo.email !== null " class="inpSub">
            <p class="title">{{ $t('基本信息.修改邮箱号') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="modifyEmail.againMail"
                     :placeholder="$t('基本信息.请输入要修改的邮箱号')" type="text"/>
            </div>
          </div>
          <div v-if="userInfo.email !== '' && userInfo.email !== null " class="inpSub">
            <p class="title">{{ $t('基本信息.修改邮箱验证码') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="modifyEmail.againCode" :placeholder="$t('基本信息.请输入邮箱验证码')"
                     type="text"/>
              <p @click="sendCode(modifyEmail.againMail)">{{ $t('基本信息.获取验证码') }}</p>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="saveMail">{{ $t('基本信息.保存') }}</p>
      </div>
    </el-dialog>
    <el-dialog :title="$t('基本信息.修改手机号')" :visible.sync="phoneDialog" width="570px">
      <div class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.注册手机') }}{{ $t('公用.：') }}</p>
            <div class="txt">{{ userInfo.phonenumber }}</div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.验证码') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="mobilePhone.code" :placeholder="$t('基本信息.短信验证码')"
                     type="text"/>
              <p @click="sendCode(userInfo.phonenumber)">{{ $t('基本信息.获取验证码') }}</p>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.需要修改手机号') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="mobilePhone.againPhone"
                     :placeholder="$t('基本信息.请输入需要的修改手机号')"
                     type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('基本信息.验证码') }}{{ $t('公用.：') }}</p>
            <div class="txt">
              <input v-model="mobilePhone.againCode" :placeholder="$t('基本信息.请输入验证码')"
                     type="text"/>
              <p @click="sendCode(mobilePhone.againCode)">{{ $t('基本信息.获取验证码') }}</p>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="savePhone">{{ $t('基本信息.保存') }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {editUser, getUserInfo, send} from "@/api/api";
import areajson from "@/assets/js/area";
import Cookies from "js-cookie";
import {ModifyEmailInfo, ModifyMobilePhone} from "@/api/myInfo/myInfo";
import {encrypt} from "@/util/encryption";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      options0: [],
      options: areajson,
      loading: false,
      phoneDialog: false,//修改手机号
      emainDialog: false,//修改邮箱
      value: '',
      userInfo: {},
      genders: [
        {
          label: '男',
          value: '1'
        },
        {
          label: '女',
          value: '0'
        }
      ],
      mobilePhone: {
        phone: "",
        code: "",
        againPhone: "",
        againCode: "",
      },
      password: "",
      modifyEmail: {
        password: "",
        mail: "",
        code: "",
        againMail: "",
        againCode: "",
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async editSave() {
      const res = await editUser({
        ...this.userInfo,
        areaAddress: this.userInfo.area.join(',')
      })
      if (res.code === 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }
    },
    handleChange(e) {
      console.log(e);
    },
    async getData() {
      const res = await getUserInfo({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.userInfo = res.userDetails
    },
    async sendCode(val) {
      let res = await send({
        phoneNumber: val,
        type: 2
      })
      this.getMsg(res);
    },
    async savePhone() {
      this.mobilePhone.phone = this.userInfo.phonenumber
      const res = await ModifyMobilePhone(this.mobilePhone);
      this.getMsg(res);
      await this.getData()
      this.phoneDialog = false
    },
    async saveMail() {
      if (this.userInfo.email !== '' && this.userInfo.email !== null) {
        this.modifyEmail.mail = this.userInfo.email
      }
      this.modifyEmail.password = encrypt(this.password)
      const res = await ModifyEmailInfo(this.modifyEmail);
      this.getMsg(res);
      await this.getData()
      if (res.code === 200) {
        this.modifyEmail = {
          password: "",
          mail: "",
          code: "",
          againMail: "",
          againCode: "",
        }
        this.emainDialog = false
      }
    },
    getMsg(res) {
      if (res.code === 200) {
        this.$message({
          message: res.msg,
          type: 'success'
        });
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .basicModule {
    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px 40px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .basicTop {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .basicLeft {
        width: 599px;
        height: 172px;

        .basicDiv {
          font-size: 14px;
          color: #333333;
          line-height: 15px;

          p {
            margin-top: 13px;
          }

          .two {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          span {
            font-size: 14px;
            color: #012F9C;
            margin-left: 10px;
          }
        }
      }

      .basicRight {
        width: 276px;
        height: 172px;
        font-size: 16px;
        color: #000000;
        text-align: center;

        img {
          width: 118px;
          height: 103px;
          margin-bottom: 10px;
        }
      }
    }

    .basicBottom {
      .inpDiv {
        display: flex;
        flex-wrap: wrap;
        //grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;

        .inpSub {
          display: flex;
          align-items: center;
          margin-right: 60px;
          width: 40%;

          p {
            width: 80px;
            text-align: right;
          }

          input {
            flex: 1;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            height: 36px;
          }
        }
      }

      .sureBtn {
        display: flex;
        justify-content: end;
        margin-top: 30px;

        p {
          font-weight: 400;
          font-size: 13px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 113px;
          height: 32px;
          background: #012F9C;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }

    .tips {
      //width: 297px;
      //height: 44px;
      padding: 15px 20px;
      background: #FFFDED;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF6600;
      margin-top: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span {
        color: #FF6600;
      }
    }
  }

  .dialogThreeCss {
    padding: 0 70px;
    box-sizing: border-box;

    .inpDiv {
      .inpSub {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .title {
          line-height: 19px;
          text-align: right;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          width: 130px;
        }

        .txt {
          display: flex;
          align-items: center;
          width: 240px;
          font-weight: 400;
          font-size: 16px;
          color: #333333;

          input {
            height: 26px;
            text-indent: 5px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #999999;
            flex: 1;
          }

          p {
            margin-left: 10px;
            font-weight: 400;
            font-size: 10px;
            color: #FFFFFF;
            width: 112px;
            height: 26px;
            background: #012F9C;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .sure {
      width: 96px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 20px auto;
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
    }
  }

  :deep .el-dialog__title {
    font-size: 24px;
    color: #333333;
    line-height: 28px;
  }
}
</style>
