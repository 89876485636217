<template>
    <div class="indexBg" v-loading="loading">
      <topNav></topNav>
      <div class="twoModule w_1200">
        <div class="schemeLeft">
          <h2>{{$t('产品方案.产品方案导航')}}</h2>
          <div class="schemeLine">
            <p :class="item.id==tabVal?'active':'hand hover'" @click="tabClick(item)" v-for="item in tabArr">{{$i18n.locale == "zh" ?item.classifyName:item.classifyNameEn}}</p>
          </div>
        </div>
        <div class="schemeRight">
          <div class="schemeSub" v-html="$i18n.locale=='zh'?detail.articleContent:detail.articleContentEn"></div>
         <!-- <div class="tableDiv">
           <h2>{{$t('产品方案.推荐产品列表')}}</h2>
           <table>
             <tr>
               <th>No.</th>
               <th>{{$t('产品方案.产品类别')}}</th>
               <th>{{$t('产品方案.应用位置')}}</th>
               <th>{{$t('产品方案.品牌')}}</th>
             </tr>
             <tr v-for="(item,index) in 6">
               <td>{{index+1}}</td>
               <td>普通电阻</td>
               <td>所有位置</td>
               <td>SHGDO、JGshdf asfj</td>
             </tr>
           </table>
         </div> -->
        </div>
      </div>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {classList,programDetail} from '@/util/http'
import Cookies from "js-cookie";
export default{
  components: {topNav, bottomNav },
  data(){
        return{
            loading: false,
            id:'',
            tabVal:3,
            tabArr:[],
            detail:{},
        }
    },
  mounted() {
    this.id=this.$route.query.id
    this.getTabList()
    this.getDetail()

  },
    methods:{
      getDetail(){
        programDetail(this.id).then(res=>{
          this.detail=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      getTabList(){
        classList().then(res=>{
          this.tabArr=res.rows
          console.log(this.tabArr)
        }).catch(err=>{
          if(err.msg) this.$message(err.msg)
        })
      },
      tabClick(item){
        this.tabArr.map((i)=>{
          i.flag=false;
          if(item.id==i.id){
            this.$router.push({path:'/scheme',query:{num:i.id}})
          }
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .twoModule{
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    .schemeLeft{
      width: 277px;
      background: #FFFFFF;
      //padding: 25px 0;
      box-sizing: border-box;
      height: 415px;
      h2{
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        line-height: 40px;
        padding: 16px 29px;
      }
      .schemeLine{
        font-size: 21px;
        color: #000000;
        p{
          padding:5px 0 5px 26px;
          border-left: 3px solid #fff;
          margin-bottom: 25px;
        }
        .active{
          color: #0332A1;
          border-left: #0332A1 solid 3px;
        }
      }
    }
    .schemeRight{
      width: 899px;
      //display: flex;
      //flex-direction: column;
      .schemeSub{
        background: #FFFFFF;
        padding: 30px;
        box-sizing: border-box;
      }
      .tableDiv{

        margin-top: 15px;
        padding: 20px 15px;
        box-sizing: border-box;
        background: #FFFFFF;
        h2{
          margin-bottom: 15px;
          font-weight: bold;
          font-size: 16px;
          color: #0332A1;
        }
        table{
          width: 100%;
          text-align: center;
          border: 1px solid #CECECE;
          tr th{
            background: #0332A1;
            font-weight: bold;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            color: #FFFFFF;
          }
          tr td{
            font-weight: 400;
            font-size: 13px;
            color: #000000;
            padding:10px 0;
          }
        }
      }

    }
  }
}
</style>