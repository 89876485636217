import request from '@/util/request'

export function orderList2(data) {
  return request({
    url: `/zgkj/order/list2`,
    method: 'GET',
    params: data
  })
}
export function orderList(data) {
  return request({
    url: `/zgkj/order/list`,
    method: 'GET',
    params: data
  })
}

export function getListUserInvoice(data) {
  return request({
    url: `/zgkj/userInvoice/getList`,
    method: 'GET',
    params: data
  })
}

