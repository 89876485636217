<template>
    <div class="indexBg">
      <topNav></topNav>
      <div class="sampCon">
        <img class="sampleBanner" src="@/assets/home/mf.png">
        <div class="w_1200">
          <div class="inpDiv">
            <div class="inpSub">
              <p class="inpTIt">{{$t('首页.样品筛选')}}</p>
              <div class="inpTxt">
                <p>{{$t('首页.品牌')}}</p>
                <el-select class="select_inp" v-model="brandObj.name" @change="brandChange" :placeholder="$t('我的样品.请选择')">
                  <el-option v-for="item in brandArr" :key="item.id" :label="$i18n.locale=='zh'?item.nickName:item.nickNameEn" :value="item"></el-option>
                </el-select>
              </div>
              <div class="inpTxt">
                <p>{{$t('首页.分类')}}</p>
                <el-select class="select_inp" v-model="classfyObj.name" @change="chassfyChange" :placeholder="$t('我的样品.请选择')">
                  <el-option v-for="item in classfyArr" :key="item.id" :label="$i18n.locale=='zh'?item.categoryName:item.categoryNameEn" :value="item"></el-option>
                </el-select>
              </div>
              <div class="inpTxt">
                <p>{{$t('首页.产品型号')}}</p>
                <input v-model="product" />
              </div>
              <div class="inpBtn">
                <div class="inpCar hand" @click="selectClick">
                  <span>{{$t('首页.样品查询')}}</span>
                </div>
                <div class="inpCar hand" @mouseover="carClick">
                  <img class="sampleBanner" src="@/assets/home/icon/r_n_2.png">
                  <span>{{$t('首页.我的样品篮')}}</span>
                </div>
              </div>
              <div class="carDiv" v-if="carPop" @mouseleave="carPop=false">
                <el-table :data="basketList" height="175" style="width: 100%">
                  <el-table-column prop="manufacturerNumber" :label="$t('首页.型号')" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="num" :label="$t('首页.合计')">
                    <template slot-scope="scope">
                      <span>¥{{ scope.row.price}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" :label="$t('首页.操作')" width="100">
                    <template slot-scope="scope">
                      <el-button @click="byIdClick(scope.row)" type="text" size="small">{{$t('首页.移除')}}</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="carBtn">
                  <div class="cL">{{$t('首页.合计')}}：<span>¥{{ totPrice }}</span></div>
                  <div class="cB hand" @click="handleClick">{{$t('首页.提交申请')}}</div>
                </div>
              </div>
            </div>
            <div class="inpSub">
              <p class="inpTIt">{{$t('首页.样品申请流程')}}</p>
              <p class="inpTo">1.{{$t('首页.选择样品')}}</p>
              <p class="inpTo">2.{{$t('首页.提交申请信息')}}</p>
              <p class="inpTo">3.{{$t('首页.平台审核')}}</p>
              <p class="inpTo">4.{{$t('首页.等待收货')}}</p>
              <p class="inpTo">5.{{$t('首页.收货成功')}}</p>
            </div>
            <div class="inpSub">
              <p class="inpTIt">{{$t('首页.样品申请规则')}}</p>
              <p class="inpTo">1.{{$t('首页.每位会员每月最多可申请1次')}}</p>
              <p class="inpTo">2.{{$t('首页.每次最多限5种型号且总金额有限制')}}</p>
              <p class="inpTo">3.{{$t('首页.邀请好友注册后')}}</p>
              <p class="inpTo">4.{{$t('首页.样品订单通过审核后')}}</p>
              <p class="inpTo">5.{{$t('首页.若有刷单')}}</p>
            </div>
          </div>
          <div class="tabDiv">
            <el-table :data="tableData" stripe>
              <el-table-column prop="manufacturerNumber" :label="$t('首页.产品型号')">
              </el-table-column>
              <el-table-column prop="productBrandName" :label="$t('首页.品牌')">
                <template slot-scope="scope">
                  <span>{{$i18n.locale=='zh'?scope.row.productBrandName:scope.row.productBrandNameEn}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="productCategoryName" :label="$t('首页.分类')">
                <template slot-scope="scope">
                  <span>{{$i18n.locale=='zh'?scope.row.productCategoryName:scope.row.productCategoryNameEn}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="children" :label="$t('首页.描述')">
                <template slot-scope="scope">
                  <span v-for="item in scope.row.children">{{item.productCategorySpecificationValue}};</span>
                </template>
              </el-table-column>
              <el-table-column prop="applyForNumber" :label="$t('首页.最大申请数量')">
              </el-table-column>
              <el-table-column :label="$t('首页.操作')">
                <template slot-scope="scope">
                  <el-button @click="addBasket(scope.row)" type="text" size="small">{{$t('首页.加入样品篮')}}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align:right;margin-top:30px;" >
               <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <bottomNav></bottomNav>
      <el-dialog :visible.sync="dialogFlag" width="580px" :show-close="false">
      <div class="dialogCss">
        <p class="loagTit">{{$t('首页.样品申请信息填写')}}</p>
        <div class="logTips">
          <img src="@/assets/sub/yl.png" />
          <span>{{$t('首页.提示')}}</span>
        </div>
        <div class="logInp">
          <div class="logSub">
            <p><span>*</span>{{$t('首页.公司名称')}}</p>
            <el-input class="inpSub" v-model="formObj.companyName"></el-input>
          </div>
          <div class="logSub">
            <p><span>*</span>{{$t('首页.职务')}}</p>
            <el-select class="inpSub" v-model="zwObj.name" :placeholder="$t('我的样品.请选择')" @change="zwChange">
              <el-option v-for="item in zwList" :key="item.id" :label="$i18n.locale=='zh'?item.nickName:item.nickNameEn" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="logSub">
            <p><span>*</span>{{$t('首页.行业类型')}}</p>
            <el-select class="inpSub" v-model="hyObj.name" :placeholder="$t('我的样品.请选择')" @change="hyChange">
              <el-option v-for="item in hyList" :key="item.id" :label="$i18n.locale=='zh'?item.nickName:item.nickNameEn" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="logSub">
            <p><span>*</span>{{$t('首页.邮箱')}}</p>
            <el-input class="inpSub" v-model="formObj.mailbox"></el-input>
          </div>
          <div class="logSub">
            <p><span>*</span>{{$t('首页.项目描述')}}</p>
            <el-input class="inpSub" v-model="formObj.applyDescribe" type="textarea" :rows="3"></el-input>
          </div>
          <div class="logSub">
            <p><span>*</span>{{$t('首页.收货地区')}}</p>
            <el-select class="inpSub" v-model="addObj.name" :placeholder="$t('我的样品.请选择')" @change="addressChange">
              <el-option v-for="item in addressList" :key="item.id" :label="item.userName+' '+item.userMobile" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="logBtn">
          <p class="sub hand" @click="submitClick">{{$t('首页.提交申请')}}</p>
          <p class="hand" @click="handleClose">{{$t('首页.取消')}}</p>
        </div>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import {productBrand,productCategory,product,userSampleBasket,basketGetList,basketGetDel,sampleBasketApplySort,userAddress,sampleBasketApply} from '@/util/http'
import Cookies from 'js-cookie'

export default{
  components: {topNav, bottomNav },
  data(){
        return{
          carPop:false,
          dialogFlag: false,
          brandObj:{id:'',name:''},//品牌id
          brandArr:[],//品牌列表
          classfyObj:{id:'',name:''},//分类id
          classfyArr:[],//分类列表
          product:'',//产品型号
          pageSize:10,//每页显示条数
          pageNum:1,
          total:0,
          userInfo:JSON.parse(Cookies.get('userInfo')),
          tableData: [],//样品列表
          basketList:[],//我的样品篮
          zwList:[],//职务
          zwObj:{id:'',name:''},//选中的职务
          hyList:[],//行业
          hyObj:{id:'',name:''},//选中的行业
          formObj:{//表单信息
            companyName:'',//公司名称
            mailbox:'',//邮箱
            applyDescribe:'',//描述
            productId:'',//商品ID（多个商品,分隔
          },
          addressList: [],//地址
          addObj:{id:'',name:''},//选中的地址
        }
    },
    computed:{
      totPrice(){
        let total = 0;
        this.basketList.forEach(item => {
          total += item.price
        })
        return total
      },
      },
    mounted(){
      this.getBrand()
      this.getClassfy()
      this.basketGetList()
      this.pageNum=1
      this.tableData=[]
      this.getGoods()
    },
    methods:{
      getBrand(){//品牌列表
        productBrand().then(res=>{
          this.brandArr=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getClassfy(){//分类列表
        productCategory().then(res=>{
          this.classfyArr=res.data
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      getGoods(){//样品列表
        product({
          pageSize:this.pageSize,//每页显示条数
          pageNum:this.pageNum,
          specimen:1,
          productBrandId:this.brandObj.id,
          productCategoryId:this.classfyObj.id,
          manufacturerNumber:this.product
        }).then(res=>{
          this.tableData = res.rows;
          this.total = res.total;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      basketGetList(){//我的样品篮
        basketGetList().then(res=>{
          this.basketList=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      selectClick(e){//样品查询
        this.pageNum=1;
        this.tableData=[]
        this.getGoods()
      },
      chassfyChange(e){//选中的分类
        console.log(e)
        this.classfyObj.id=e.id
        this.$i18n.locale=='zh'?this.classfyObj.name=e.categoryName:this.classfyObj.name=e.categoryNameEn
      },
      brandChange(e){//选中的品牌
        console.log(e)
        this.brandObj.id=e.id
        this.$i18n.locale=='zh'?this.brandObj.name=e.nickName:this.brandObj.name=e.nickNameEn
      },
      pageChange(e){//当前页
        this.pageNum=e
        this.getGoods()
        console.log(e)
      },
      addBasket(row){//加入样品篮
        userSampleBasket({
          userId:this.userInfo.userId,
          productId:row.id,
          applyForNumber:row.applyForNumber
        }).then(res=>{
          this.$message(res.msg);
          this.basketGetList()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      byIdClick(row){//移除样品
        console.log(row)
        basketGetDel(row.id).then(res=>{
          this.$message(res.msg);
          this.basketGetList()
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
          
        })
      },
      carClick(){//我的样品栏
        this.carPop=true;
      },
      zwChange(e){//选中的职务
        this.zwObj.id=e.id
        this.$i18n.locale=='zh'?this.zwObj.name=e.nickName:this.zwObj.name=e.nickNameEn
      },
      hyChange(e){//选中的行业
        this.hyObj.id=e.id
        this.$i18n.locale=='zh'?this.hyObj.name=e.nickName:this.hyObj.name=e.nickNameEn
      },
      handleClose() {//取消申请样品弹窗
        this.dialogFlag=false;
        this.zwObj={id:'',name:''}
        this.hyObj={id:'',name:''}
      },
      addressChange(e){//选中地址
        this.addObj.id=e.id
        this.addObj.name=e.userName+' '+e.userMobile
      },
      handleClick(){//提交样品申请弹窗
        this.dialogFlag=true;
        sampleBasketApplySort({sort:2}).then(res=>{//职务
          this.zwList=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
        sampleBasketApplySort({sort:1}).then(res=>{//行业
          this.hyList=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
        userAddress().then(res=>{//地址
          this.addressList=res.rows
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      },
      submitClick(){
        let productId='';//商品ID
        this.basketList.map(i=>{
          productId!=''?productId+=i.productId+',':productId=i.productId
        })
        sampleBasketApply({
            userId:this.userInfo.userId,
            companyName:this.formObj.companyName,//公司名称
            jobTypeId:this.zwObj.id,//职务ID
            industryTypeId:this.hyObj.id,//行业类型ID
            mailbox:this.formObj.mailbox,//邮箱
            applyDescribe:this.formObj.applyDescribe,//描述
            productId:productId,//商品ID（多个商品,分隔）
            addressId:this.addObj.id,//省市区ID
        }).then(res=>{
          this.$message(res.msg);
          this.dialogFlag=false;
        }).catch(err=>{
          if(err.msg) this.$message(err.msg);
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .sampCon{
    .sampleBanner{
      width: 100%;
      height: 500px;
    }
    .inpDiv{
      display: flex;
      align-items: self-start;
      justify-content: space-between;
      margin:40px auto;
      .inpSub{
        width: 384px;
        height: 388px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        .inpTo{
          margin-top: 30px;
          font-size: 16px;
          color: #333333;
        }
        .inpTIt{
          font-size: 24px;
          color: #333333;
          padding-left: 2px;
          border-left:6px #FF6600 solid;
        }
        .inpTxt{
          margin-top: 20px;
          font-size: 16px;
          color: #000000;
          input{
            margin-top:6px;
            width:330px;
            height: 40px;
            border: 1px solid #999999;
            border-radius: 4px;
          }
          .select_inp{
            margin-top:6px;
            width:330px;
            height: 40px;
            border-radius: 4px;
            :deep .el-input__inner{
            border: 1px solid #999999!important;
            }
          }
        }
        .inpBtn{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
        .inpCar{
          margin-top: 20px;
          width: 146px;
          height: 40px;
          background: #F3F5F8;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #333333;
          img{
            width:11px;
            height:11px;
            margin-right: 6px;
          }
        }
      }
    }
    .tabDiv{
      width: 1200px;
      max-height: 944px;
      overflow-y: auto;
      margin-bottom:40px;
      background: #FFFFFF;
      border-radius: 8px;
      padding:30px;
      box-sizing: border-box;

      :deep .el-table{
        border-radius: 8px;
        border: solid 1px #d7d7d7;

      }
      :deep .el-table th.el-table__cell{
        background: #012F9C;
        color: #fff;
        font-weight: 400;
        height: 80px;
      }
      :deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border: none!important;
        //padding:13px 0;
        color: #333;
      }
    }
  }
  .carDiv{
    position: absolute;
    bottom:-235px;
    left: 200px;
    z-index: 100;
    width: 608px;
    max-height: 261px;
    overflow-y:auto;
    padding:20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border:solid 1px #bebebe;
    :deep .el-table th.el-table__cell{
      background: #012F9C;
      color: #fff;
      font-weight: 400;
    }
    :deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
      border: none!important;
      padding:4px 0;
      color: #222;
    }
    .carBtn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      padding:0 20px;
      .cL{
        span{
          color: #c20606;
          font-weight: 600;
        }
      }
      .cB{
        background: #012F9C;
        color: #fff;
        padding:8px 15px;
        //border-radius: 3px;
      }
    }
  }
  .dialogCss{
     padding:0 40px;
     box-sizing: border-box;
     .loagTit{
       font-size: 24px;
       color: #333333;
     }
     .logTips{
       width: 500px;
       padding:7px;
       display: flex;
       align-items: center;
       justify-content: center;
       margin: 20px auto;
       color:#FF6600;
       font-size: 16px;
       img{
         width:18px;
         height:18px;
         margin-right: 15px;
       }
     }
     .logInp{
       .logSub{
         padding:0 30px;
         box-sizing: border-box;
         font-size: 15px;
         color: #000;
         margin-bottom: 20px;
         display: flex;
         align-items: center;
         p{
           //border: solid 1px red;
           width:115px;
           text-align: right;
         }
         p span{
           color: #a80000;
            font-weight: 600;
         }
         .inpSub{
           margin-top: 10px;
           flex: 1;
         }
         div{
           display: flex;
           align-items: center;
           justify-content: space-between;
         }
         .sub{
           font-size: 14px;
           text-align: center;
           line-height:36px;
           color: #fff;
           background: #0D39A1;
           width: 170px;
           margin-left: 10px;
           border-radius: 20px;
           margin-top: 10px;
         }
       }
     }
     .logBtn{
       display: flex;
       align-items: center;
       justify-content: space-evenly;
       p{
         font-size: 18px;
         color: #000;
         line-height:46px;
         text-align: center;
         background: #dcdcdc;
         width: 115px;
         border-radius:4px;
       }
       .sub{
         color: #fff;
         background: #0D39A1;
       }
     }
    :deep .el-input__inner,:deep .el-textarea__inner{
      //line-height:26px;
      //height:26px;
      line-height:30px;
      height:30px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #999999;
    }
    :deep .el-dialog__header{
      padding: 0;
    }
    :deep .el-input__suffix{
      top:5px;
    }
    :deep .is-reverse{
      line-height: 0px!important;
    }
   }
}
</style>