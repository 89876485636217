<template>
  <div class="loginTop">
    <div class="topCon">
      <div class="navLeft">
        <span>登录</span>
        <span>注册</span>
      </div>
      <div class="navRight">
        <!-- <span>签到送积分{{ $t('首页.语言') }}</span> -->
        <span>我的订单</span>
        <span>消息</span>
        <span>会员中心</span>
        <span>400-870=9968</span>
        <span>English</span>
        <span>加入收藏</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTit",
  props: {
    title: ''
  },
  data() {
    return {

    }
  },
  mounted() { },
  methods: {}
}
</script>

<style scoped lang="scss">
.loginTop {
  width: 100%;
  height: 40px;
  background: #E6E6E6;
  padding: 10px 0;
  box-sizing: border-box;

  .topCon {
    width: 1200px;
    margin: auto;
    // border: solid 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: #333333;
  }
}
</style>