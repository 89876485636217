<template>
  <div class="agentBg" v-loading="loading">
    <p class="toolTit">{{ $t("首页.值得信赖的代理商伙伴共前行") }}</p>
    <div class="agentPhoto w_1200">
      <div class="sp">
        <!--          <div class="l_btn hand">-->
        <!--            <img src="@/assets/home/icon/rI2.png" alt="" />-->
        <!--          </div>-->
        <div class="xd">
          <div class="swiper-container_1 hand">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in list"
                :key="index"
                @click="agentClick(item)"
              >
                <img :src="baseUrl + item.brandShowPic" alt="" />
                <div class="agentName">
                  <img :src="baseUrl + item.brandLogo" alt="" />
                  <p>
                    {{
                      $i18n.locale == "zh"
                        ? item.nickName
                        : item.nickNameEn
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--          <div class="r_btn hand">-->
        <!--            <img src="@/assets/home/icon/rI.png" alt="" />-->
        <!--          </div>-->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import { partnerList, supplyList } from "@/api/api";

export default {
  components: {},
  data() {
    return {
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      list: [],
    };
  },
  mounted() {
    this.getData();
    new Swiper(".swiper-container_1", {
      loop: true,
      slidesPerView: 5,
      spaceBetween: 30,
      freeMode: true,
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        dynamicMainBullets: 2,
        clickable: true,
      },
      navigation: {
        // 指定导航按钮的类
        nextEl: ".r_btn",
        prevEl: ".l_btn",
      },
    });
  },
  methods: {
    async getData() {
      const res = await partnerList({});
      this.list = res.rows;
      console.log(res.rows)
    },
    agentClick(item) {
      this.$router.push({ path: `brandTwo?brandId=${item.id}`, });
    },
  },
};
</script>
<style lang="scss" scoped>
.agentBg {
  .toolTit {
    text-align: center;
    font-size: 24px;
    color: #333333;
    margin: 40px 0;
  }
  .agentPhoto {
    //border: solid 1px;
    .sp {
      position: relative;
      display: flex;
      justify-content: space-between;
      //align-items: center;
      height: 265px;
      //border: solid 1px red;
      margin-bottom: 40px;
      .l_btn {
        width: 54px;
        height: 70px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .r_btn {
        width: 54px;
        height: 70px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .xd {
        width: 100%;
        //border: solid;
      }
    }
    .swiper-pagination {
      bottom: 0px;
      text-align: center;
      width: 1197px !important;
      margin: auto;
    }
    .swiper-container_1 {
      overflow: hidden;
    }
    .swiper-wrapper {
      height: 245px;
      // border: solid 1px olive;
    }
    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //height: 220px;
      width: 208px;
      height: 245px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(1, 47, 156, 0.1);
      border-radius: 4px;
      margin: 0 12px;
      .agentName {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #333333;
        margin-top: 13px;
        img {
          width: 54px;
          height: 40px;
          //border: solid 1px;
          flex-shrink: 0;
        }
      }
      img {
        width: 168px;
        height: 168px;
        border-radius: 4px;
      }
    }
  }
}
</style>
