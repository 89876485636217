<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="purseTop">
            <div class="purseLeft divCss">
              <p class="orderTit">{{ $t('我的钱包.钱包余额') }}</p>
              <div class="money">
                <div class="mL">
                  <img src="@/assets/member/mo.png"/>
                  <p>{{ $t('公用.¥') }}<span>{{ userInfo.totalWallet }}</span></p>
                </div>
                <div class="mR">
                  <p>{{ $t('我的钱包.线下对公充值') }}</p>
                  <p style="display:inline-block">{{ $t('我的钱包.提现') }}</p>
                </div>
              </div>
            </div>
            <div class="purseRight divCss">
              <p class="orderTit">{{ $t('我的钱包.钱包账户信息') }}</p>
              <div class="purssText">
                <p>{{ $t('我的钱包.冻结金额') }}{{ $t('公用.¥') }}{{
                    userInfo.freezeTotalWallet
                  }}</p>
                <p>{{ $t('我的钱包.支付密码') }}******

<!--     userInfo.paymentPassword             -->
                  <span class="hand"
                                                                              @click="pwdDialog=true">{{
                    $t('我的钱包.修改')
                  }}</span></p>
                <p>{{ $t('我的钱包.提现账号') }}{{ $t('我的钱包.未绑定') }}<span class="hand"
                                                                                 @click="accountDialog=true">{{
                    $t('我的钱包.绑定')
                  }}</span></p>
                <p>{{ $t('我的钱包.安全手机') }}：{{ userInfo.securePhone }}<span class="hand"
                                                                  @click="phoneDialog=true">{{
                    $t('我的钱包.绑定')
                  }}</span></p>
              </div>
            </div>
          </div>
          <div class="purseBot divCss">
            <p class="orderTit">{{ $t('我的钱包.交易记录明细') }}</p>
            <div class="detailSele">
              <div class="seleLeft">
                <p :class="state==1?'active':'hand hover'" @click="tabClick(1)">{{
                    $t('我的钱包.所有明细')
                  }}</p>
                <span>|</span>
                <p :class="state==2?'active':'hand hover'" @click="tabClick(2)">{{
                    $t('我的钱包.支付明细')
                  }}</p>
                <span>|</span>
                <p :class="state==3?'active':'hand hover'" @click="tabClick(3)">{{
                    $t('我的钱包.充值记录')
                  }}</p>
                <span>|</span>
                <p :class="state==4?'active':'hand hover'" @click="tabClick(4)">{{
                    $t('我的钱包.提现记录')
                  }}</p>
              </div>
            </div>
            <div class="selectDiv">
              <div class="seleLeft">
                <div v-if="state!=4" class="sub">
                  <span>{{ $t('我的钱包.单据类型') }}</span>
                  <el-select v-model="value" class="subInp">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div v-if="state==3" class="sub">
                  <span>{{ $t('我的钱包.状态') }}</span>
                  <el-select v-model="value" class="subInp">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="sub">
                  <span style="width:50px">{{ $t('我的钱包.时间') }}</span>
                  <el-date-picker v-model="value1" :end-placeholder="$t('公用.结束日期')"
                                  :range-separator="$t('公用.至')"
                                  :start-placeholder="$t('公用.开始日期')"
                                  type="datetimerange">
                  </el-date-picker>
                </div>
              </div>
              <div class="seleRig">
                <p class="hand">{{ $t('我的钱包.查询') }}</p>
                <p class="hand exportbtn">{{ $t('我的钱包.重置') }}</p>
              </div>
            </div>
            <el-table v-if="state==1||state==2" :data="tableData" :stripe="true"
                      style="width: 100%">
              <el-table-column :label="$t('我的钱包.交易流水号')" prop="id"></el-table-column>
              <el-table-column :label="$t('我的钱包.交易类型')" prop="sort">
                <template slot-scope="scope">
                  <div v-if="scope.row.sort === 1">订单支付</div>
                  <div v-if="scope.row.sort === 2">订单退款</div>
                  <div v-if="scope.row.sort === 3">余额充值</div>
                  <div v-if="scope.row.sort === 4">余额提现</div>
                  <div v-if="scope.row.sort === 5">财务扣款</div>
                  <div v-if="scope.row.sort === 6">后台修改</div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('我的钱包.金额')" prop="wallet"></el-table-column>
              <el-table-column :label="$t('我的钱包.创建时间')" prop="createTime"></el-table-column>
              <el-table-column :label="$t('我的钱包.账户余额')"
                               prop="totalWallet"></el-table-column>
              <el-table-column :label="$t('我的钱包.备注')" prop="remark"></el-table-column>
            </el-table>
            <el-table v-if="state==3" :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column :label="$t('我的钱包.充值金额')" prop="wallet"></el-table-column>
              <el-table-column :label="$t('我的钱包.充值方式')" prop="sort">
                <template slot-scope="scope">
                  <div v-if="scope.row.sort === 1">订单支付</div>
                  <div v-if="scope.row.sort === 2">订单退款</div>
                  <div v-if="scope.row.sort === 3">余额充值</div>
                  <div v-if="scope.row.sort === 4">余额提现</div>
                  <div v-if="scope.row.sort === 5">财务扣款</div>
                  <div v-if="scope.row.sort === 6">后台修改</div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('我的钱包.打款银行')" prop="num2"></el-table-column>
              <el-table-column :label="$t('我的钱包.打款账户')" prop="num2"></el-table-column>
              <el-table-column :label="$t('我的钱包.支付订单号')" prop="id"></el-table-column>
              <el-table-column :label="$t('我的钱包.支付时间')" prop="createTime"></el-table-column>
              <el-table-column :label="$t('我的钱包.状态2')" prop="date"></el-table-column>
            </el-table>
            <el-table v-if="state==4" :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column :label="$t('我的钱包.类别')" prop="sort">
                <template slot-scope="scope">
                  <div v-if="scope.row.sort === 1">订单支付</div>
                  <div v-if="scope.row.sort === 2">订单退款</div>
                  <div v-if="scope.row.sort === 3">余额充值</div>
                  <div v-if="scope.row.sort === 4">余额提现</div>
                  <div v-if="scope.row.sort === 5">财务扣款</div>
                  <div v-if="scope.row.sort === 6">后台修改</div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('我的钱包.提交时间')" prop="createTime"></el-table-column>
              <el-table-column :label="$t('我的钱包.金额')" prop="wallet"></el-table-column>
              <el-table-column :label="$t('我的钱包.首款开户名')" prop="num3"></el-table-column>
              <el-table-column :label="$t('我的钱包.银行账号')" prop="state"></el-table-column>
              <el-table-column :label="$t('我的钱包.提现时间')" prop="updateTime"></el-table-column>
              <el-table-column :label="$t('我的钱包.银行单号')" prop="date"></el-table-column>
              <el-table-column :label="$t('我的钱包.状态2')" prop="sort">
                <template slot-scope="scope">
                  <div v-if="scope.row.sort === 1">订单支付</div>
                  <div v-if="scope.row.sort === 2">订单退款</div>
                  <div v-if="scope.row.sort === 3">余额充值</div>
                  <div v-if="scope.row.sort === 4">余额提现</div>
                  <div v-if="scope.row.sort === 5">财务扣款</div>
                  <div v-if="scope.row.sort === 6">后台修改</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
    <el-dialog :title="$t('我的钱包.绑定银行账号')" :visible.sync="accountDialog" width="570px">
      <div v-if="accountStep==1" class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.安全手机') }}</p>
            <div class="txt">15978976546</div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.验证码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.短信验证码')" type="text"/>
              <p>{{ $t('我的钱包.获取验证码') }}</p>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.支付密码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入新的支付密码')" type="text"/>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="accountClick">{{ $t('我的钱包.下一步') }}</p>
      </div>
      <div v-if="accountStep==2" class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.开户名') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入开户名')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.开户银行') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入开户银行')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.银行账号2') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入银行账号')" type="text"/>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="accountDialog=false">{{ $t('我的钱包.保存') }}</p>
      </div>
    </el-dialog>
    <el-dialog :title="$t('我的钱包.修改安全手机号码')" :visible.sync="phoneDialog" width="570px">
      <div class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.安全手机') }}</p>
            <div class="txt">15978976546</div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.验证码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.短信验证码')" type="text"/>
              <p>{{ $t('我的钱包.获取验证码') }}</p>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.新安全手机') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入新安全手机')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.支付密码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入支付密码')" type="text"/>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="phoneDialog=false">{{ $t('我的钱包.保存') }}</p>
      </div>
    </el-dialog>
    <el-dialog :title="$t('我的钱包.设置支付密码')" :visible.sync="pwdDialog" width="570px">
      <div class="dialogThreeCss">
        <div class="inpDiv">
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.安全手机') }}</p>
            <div class="txt">15978976546</div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.验证码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.短信验证码')" type="text"/>
              <p>{{ $t('我的钱包.获取验证码') }}</p>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.设置支付密码') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请输入新的支付密码')" type="text"/>
            </div>
          </div>
          <div class="inpSub">
            <p class="title">{{ $t('我的钱包.确认支付') }}</p>
            <div class="txt">
              <input :placeholder="$t('我的钱包.请确认支付密码')" type="text"/>
            </div>
          </div>
        </div>
        <p class="sure hand" @click="pwdDialog=false">{{ $t('我的钱包.保存') }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {SysUserWalletLogInfoList} from "@/api/myInfo/purse";
import Cookies from "js-cookie";
import {getUserInfo} from "@/api/api";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      phoneDialog: false,//修改手机号码
      pwdDialog: false,//设置支付密码
      accountDialog: false,//银行账号
      accountStep: 1,//绑定银行账户步骤
      state: 1,//1所有明细 2支付明细 3充值记录 4提现记录
      value: '',
      value1: '',
      options: '',
      tableData: [],
      userInfo: {}
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const res = await getUserInfo({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.userInfo = res.userDetails
      console.log(this.userInfo)
      let sort = 0;
      if (this.state === 1) {
        sort = ""
      }

      if (this.state === 2) {
        sort = "1"
      }

      if (this.state === 3) {
        sort = "3"
      }

      if (this.state === 4) {
        sort = "4"
      }

      SysUserWalletLogInfoList({
        sort: sort
      }).then(res => {
        this.tableData = res.rows
      })
    },
    accountClick() {
      this.accountStep = 2;
    },
    tabClick(num) {
      this.state = num;
      this.load();
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }

  .selectDiv {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .seleLeft {
      display: flex;
      flex-wrap: wrap;
      //display: grid;
      //grid-template-columns:repeat(2,1fr);
      grid-gap: 20px;
      //border: solid 1px;
      //margin-right: 30px;
      .sub {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        color: #666666;

        .subInp {
          flex: 1;
        }

        input {
          width: 200px;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          height: 36px;
        }
      }
    }

    .seleRig {
      display: flex;
      align-items: center;

      p {
        width: 54px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 13px;
        margin-right: 10px;
      }

      .ac {
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
        margin-left: 15px;
      }

      .exportbtn {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
      }
    }
  }

  .infoModule {
    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .purseTop {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }

    .purseLeft {
      box-sizing: border-box;
      width: 437px;
      height: 172px;

      .money {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mL {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #FF6600;
          line-height: 19px;

          img {
            width: 74px;
            height: 74px;
            margin-right: 10px;
          }

          span {
            font-size: 24px;
          }
        }

        .mR {
          font-size: 13px;
          color: #FFFFFF;
          line-height: 15px;
          text-align: right;

          p {
            background: #012F9C;
            border-radius: 4px 4px 4px 4px;
            padding: 7px 15px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .purseRight {
      width: 438px;
      height: 172px;

      .purssText {
        font-size: 14px;
        color: #333333;
        line-height: 15px;
        display: grid;
        grid-template-columns:repeat(2, 1fr);
        grid-gap: 20px;

        span {
          font-size: 12px;
          color: #012F9C;
          margin-left: 6px;
        }
      }
    }

    .purseBot {
      :deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 300px;
      }

      :deep .el-table th.el-table__cell {
        background: #012F9C;
        color: #fff;
      }

      :deep th:last-child {
        border-radius: 0 8px 0 0;
      }

      :deep th:first-child {
        border-radius: 8px 0 0 0;
      }
    }
  }

  .dialogThreeCss {
    padding: 0 70px;
    box-sizing: border-box;

    .inpDiv {
      .inpSub {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .title {
          line-height: 19px;
          text-align: right;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          width: 120px;
        }

        .txt {
          display: flex;
          align-items: center;
          width: 240px;
          font-weight: 400;
          font-size: 16px;
          color: #333333;

          input {
            height: 26px;
            text-indent: 5px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #999999;
            flex: 1;
          }

          p {
            margin-left: 10px;
            font-weight: 400;
            font-size: 13px;
            color: #FFFFFF;
            width: 112px;
            height: 26px;
            background: #012F9C;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .sure {
      width: 96px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 20px auto;
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
    }
  }

  :deep .el-dialog__title {
    font-size: 24px;
    color: #333333;
    line-height: 28px;
  }
}
</style>
