<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft>
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="twoT">
              <p class="orderTit">{{$t('我的消息.品牌订阅')}}</p>
              <span class="editBtn hand" style="margin-right:10px;">{{$t('我的消息.订阅更多品牌')}}</span>
            </div>
            <div class="selectDiv">
              <div class="seleLeft">
                <div class="sub">
                  <span>{{$t('我的消息.搜索品牌')}}</span>
                  <input class="subInp" />
                </div>
              </div>
              <div class="seleRig">
                <p class="hand">{{$t('我的消息.查询')}}</p>
              </div>
            </div>
            <div class="tab">
              <table>
                <tr class="trTit">
                  <th>{{$t('我的消息.图片')}}</th>
                  <th>{{$t('我的消息.品牌')}}</th>
                  <th>{{$t('我的消息.订阅时间')}}</th>
                  <th>{{$t('我的消息.操作')}}</th>
                </tr>
                <tr class="trCon" v-for="(item,index) in tableData">
                  <td>
                    <div class="descCss">
                      <img src="@/assets/member/ink.png" />
                    </div>
                  </td>
                  <td class="dj" style="width:300px">
                    <p>XXXXXXXXXXXXXXXXXX</p>
                  </td>
                  <td class="xj" style="width:280px">
                    <div>
                      <p>2024-03-15 16:26:01</p>
                      <p>{{$t('我的消息.共有')}}24,389{{$t('我的消息.种样品')}},10{{$t('我的消息.人订阅')}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="editTab hand">
                      <p class="activeP">{{$t('我的消息.取消')}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
          loading: false,
          tableData: [
            {id:1,desc: 'BHGDljks',price:'759.00',repertory:'2450',num:'1'},
            {id:2,desc: 'HkOnsfhGi',price:'759.00',repertory:'1243',num:'1'},
            {id:3,desc: 'GFkjkOBHH',price:'759.00',repertory:'4999',num:'1'},
            {id:4,desc: 'LMHG980',price:'759.00',repertory:'1686',num:'1'},
          ]
        }
    },
    onload(){},
    methods:{
      billClick(){}
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .selectDiv{
      display: flex;
      align-items: center;
      //justify-content: space-between;
      .seleLeft{
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        //grid-gap: 20px;
        margin-right: 30px;
        .sub{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          .subInp{
            flex: 1;
          }
          input{
            width: 265px;
            height: 26px;
            border-radius:4px;
            border: 1px solid #999999;

          }
        }
      }
      .seleRig{
        display: flex;
        align-items: center;
        p{
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }
        .ac{
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
        .exportbtn{
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #999999;
          color: #333333;
          background: #fff;
        }
      }
    }
    .tab{
      font-size: 13px;
      color: #333333;
      line-height: 22px;
      p{
        margin-bottom:8px;
        display: flex;
        align-items: center;
        input{
          width:80px;
        }
      }
      .descCss{
        display: flex;
        .goodsPhoto{
          width: 80px;
          height: 80px;
          margin-right:10px;
          border-radius: 4px;
        }
        img{
          width: 80px;
          height:50px;
        }
      }
      .numEd{
        display: flex;
        align-items: center;
      }
      td{
        //border: solid 1px;
        word-break: break-all;
      }
      .editTab{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 30px;
          height: 30px;
          margin:0 5px;
        }
        p{
          font-size: 13px;
          color: #012F9C;
          line-height: 22px;
          margin-right: 16px;
        }
        .activeP{
          color: #FF0000;
        }
      }
      table{
        width: 100%;
        border-collapse: separate; /* 保持单元格分开 */
        //border-collapse: collapse; /* 保持单元格分开 */
        border-spacing: 0 20px; /* 行之间的距离 */
        td{
          padding:20px 0;
          box-sizing: border-box;
          //vertical-align: top;
          //border: solid 1px #D0D0D0;
          background: #FFFFFF;
        }
        td:first-child{
          padding-left:20px;
        }
        td:last-child{
        }
        .trTit{
          height: 48px;
          background: #012F9C;
          font-size: 16px;
          color: #FFFFFF;
          th:nth-child(1){
            border-radius: 8px 0px 0px 0px;
          }
          th:last-child{
            border-radius: 0px 8px 0px 0px;
          }
        }
        .trCon{
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(1,47,156,0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #D0D0D0;
          padding: 20px;
          box-sizing: border-box;
        }
        .dj p{
          //color:#FF6600;
          display: block;
          text-align: center;
        }
        .xj p{
          text-align: center;
          display: block;
        }
      }

    }
  }
  .dialogThreeCss{
    padding: 0 60px;
    margin: auto;
    .checkCss{
      text-align: center;
    }
    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;
      span{
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }
      input{
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #999999;
        width: 278px;
        height: 26px;
      }
      .infoSub {
        display: flex;
        align-content: center;
        margin:20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
      }
      p{
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right:15px;
        width:100px;
      }
    }
  }
  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;
    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
}
</style>