<template>
  <div v-loading="loading" class="indexBg">
    <carTopNav></carTopNav>
    <myInfoLeft>
      <template v-slot:infoRight>
        <div class="infoModule">
          <div class="purseTop">
            <div class="purseLeft divCss">
              <p class="orderTit">{{ $t('我的积分.我的积分') }}</p>
              <div class="money">
                <div class="mL">
                  <img src="@/assets/member/ink.png"/>
                  <div>
                    <p>{{ userInfo.totalIntegral }}</p>
<!--                    <span>0 {{ $t('我的积分.将于') }}2024-08-31{{ $t('我的积分.过期') }}</span>-->
                  </div>
                </div>
                <div class="mR">
                  <p @click="urlInfo(1)">{{ $t('我的积分.进入积分商城') }}</p>
                  <p @click="urlInfo(2)" style="display:inline-block">{{ $t('我的积分.赚取积分') }}</p>
                </div>
              </div>
            </div>
            <div class="purseRight divCss">
              <p class="orderTit">{{ $t('我的积分.积分分类信息') }}</p>
              <div class="purssText hand">
                <div><p>46712</p><span>{{ $t('我的积分.消费赠送') }}</span></div>
                <div><p>98</p><span>{{ $t('我的积分.注册获得积分') }}</span></div>
                <div><p>3234</p><span>{{ $t('我的积分.完善信息积分') }}</span></div>
                <div><p>100</p><span>{{ $t('我的积分.绑定邮箱') }}</span></div>
                <div><p>78</p><span>{{ $t('我的积分.关注公众号') }}</span></div>
                <div><p>987</p><span>{{ $t('我的积分.快过期积分') }}</span></div>
                <div><p>50</p><span>{{ $t('我的积分.签到获得积分') }}</span></div>
              </div>
            </div>
          </div>
          <div class="purseBot divCss">
            <p class="orderTit">{{ $t('我的积分.积分记录明细') }}</p>
            <div class="detailSele">
              <div class="seleLeft">
                <p :class="state==1?'active':'hand hover'" @click="tabClick(1)">{{
                    $t('我的积分.所有明细')
                  }}</p>
                <span>|</span>
                <p :class="state==2?'active':'hand hover'" @click="tabClick(2)">{{
                    $t('我的积分.积分记录')
                  }}</p>
                <span>|</span>
                <p :class="state==3?'active':'hand hover'" @click="tabClick(3)">{{
                    $t('我的积分.兑换记录')
                  }}</p>
              </div>
            </div>
            <div class="selectDiv">
              <div class="seleLeft">
                <div v-if="state!=3" class="sub">
                  <span>{{ $t('我的积分.积分类型2') }}</span>
                  <el-select v-model="value" class="subInp">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="sub">
                  <span style="width:50px">{{ $t('我的积分.时间2') }}</span>
                  <el-date-picker v-model="value1" :end-placeholder="$t('公用.结束日期')"
                                  :range-separator="$t('公用.至')"
                                  :start-placeholder="$t('公用.开始日期')"
                                  type="datetimerange">
                  </el-date-picker>
                </div>
              </div>
              <div class="seleRig">
                <p class="hand">{{ $t('我的积分.查询') }}</p>
                <p class="hand exportbtn">{{ $t('我的积分.重置') }}</p>
              </div>
            </div>
            <el-table v-if="state!=3" :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column :label="$t('我的积分.订单号')" prop="id"></el-table-column>
              <el-table-column :label="$t('我的积分.积分类型')" prop="variableType"></el-table-column>
              <el-table-column :label="$t('我的积分.积分')" prop="variableIntegral"></el-table-column>
              <el-table-column :label="$t('我的积分.时间')" prop="createTime"></el-table-column>
              <el-table-column :label="$t('我的积分.备注')" prop="remark"></el-table-column>
            </el-table>
            <el-table v-if="state==3" :data="tableData" :stripe="true" style="width: 100%">
              <el-table-column :label="$t('我的积分.订单号')" prop="id"></el-table-column>
              <el-table-column :label="$t('我的积分.兑换类型')" prop="variableType"></el-table-column>
              <el-table-column :label="$t('我的积分.时间')" prop="createTime"></el-table-column>
<!--              <el-table-column :label="$t('我的积分.名称')" prop="num2"></el-table-column>-->
              <el-table-column :label="$t('我的积分.使用积分')" prop="variableIntegral"></el-table-column>
              <el-table-column :label="$t('我的积分.状态')" prop="state"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </myInfoLeft>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import Cookies from "js-cookie";
import {userIntegralList} from "@/api/myInfo/integral";
import {getUserInfo} from "@/api/api";

export default {
  components: {carTopNav, bottomNav, myInfoLeft},
  data() {
    return {
      loading: false,
      state: 1,//1所有明细 2积分记录 3兑换记录
      value: '',
      value1: '',
      options: '',
      tableData: [],
      userInfo:{}
    }
  },
  mounted() {
    this.load()
    this.getData()
  },
  methods: {
    urlInfo(val){
      if(val === 1){
        this.$router.push({
          path: '/integralShop'
        })
      }
      if(val === 2){
        this.$router.push({
          path: '/signScore'
        })
      }
    },
    async getData() {
      const res = await getUserInfo({
        userId: JSON.parse(Cookies.get('userInfo')).userId
      })
      this.userInfo = res.userDetails
    },
    load(val) {
      let info = {
        userId: JSON.parse(Cookies.get('userInfo')).userId,
      }
      userIntegralList(info).then(res => {
        console.log(res.rows)
        this.tableData = res.rows
      })
    },
    tabClick(num) {
      this.state = num;
    },
  }
}
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;

  .detailSele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #D9D9D9 solid 1px;

    .seleLeft {
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;

      p {
        padding-bottom: 11px;
      }

      span {
        color: #D9D9D9;
        margin: 0 14px;
        padding-bottom: 11px;
      }

      .active {
        font-weight: bold;
        font-size: 16px;
        color: #012F9C;
        line-height: 19px;
        border-bottom: 2px #FF6600 solid;
      }
    }
  }

  .selectDiv {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .seleLeft {
      display: flex;
      flex-wrap: wrap;
      //display: grid;
      //grid-template-columns:repeat(2,1fr);
      grid-gap: 20px;
      //border: solid 1px;
      //margin-right: 30px;
      .sub {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        color: #666666;

        .subInp {
          flex: 1;
        }

        input {
          width: 200px;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          height: 36px;
        }
      }
    }

    .seleRig {
      display: flex;
      align-items: center;

      p {
        width: 54px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 13px;
        margin-right: 10px;
      }

      .ac {
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
        margin-left: 15px;
      }

      .exportbtn {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #012F9C;
        color: #012F9C;
        background: #fff;
      }
    }
  }

  .infoModule {
    .divCss {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .purseTop {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }

    .purseLeft {
      box-sizing: border-box;
      width: 317px;
      height: 222px;

      .money {

        .mL {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #FF6600;
          line-height: 19px;

          img {
            width: 74px;
            height: 74px;
            margin-right: 10px;
          }

          p {
            font-size: 24px;
          }

          span {
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-top: 15px;
          }
        }

        .mR {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 13px;
          color: #FFFFFF;
          margin-top: 20px;
          line-height: 15px;
          text-align: right;

          p {
            background: #012F9C;
            border-radius: 4px 4px 4px 4px;
            padding: 7px 15px;
          }
        }
      }
    }

    .purseRight {
      width: 557px;
      height: 222px;

      .purssText {
        font-weight: 400;
        font-size: 16px;
        color: #FF6600;
        line-height: 15px;
        display: grid;
        grid-template-columns:repeat(4, 1fr);
        grid-gap: 30px;
        text-align: center;

        span {
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          margin-top: 10px;
          display: inline-block;
        }
      }
    }

    .purseBot {
      :deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 300px;
      }

      :deep .el-table th.el-table__cell {
        background: #012F9C;
        color: #fff;
      }

      :deep th:last-child {
        border-radius: 0 8px 0 0;
      }

      :deep th:first-child {
        border-radius: 8px 0 0 0;
      }
    }
  }
}
</style>
