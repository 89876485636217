<template>
    <div class="indexBg" v-loading="loading">
      <carTopNav></carTopNav>
      <myInfoLeft>
        <template v-slot:infoRight>
          <div class="infoModule">
            <div class="twoT">
              <p class="orderTit">{{$t('我的消息.消息详情')}}</p>
              <div>
                <span class="editBtn hand" @click="backClick" style="margin-right:10px;background:#F3F5F8;color:#333">{{$t('我的消息.返回消息列表')}}</span>
                <!-- <span class="editBtn hand">{{$t('我的消息.删除消息')}}</span> -->
              </div>
            </div>
            <div class="tit">
              <h1>{{ $i18n.locale=='zh'?info.messageName:info.messageNameEn }}</h1>
              <p>{{info.createTime}}</p>
            </div>
            <div class="msgCon" v-html="$i18n.locale=='zh'?info.messageContent:info.messageContentEn"></div>
          </div>
        </template>
      </myInfoLeft>
      <bottomNav></bottomNav>
    </div>
</template>
<script>
import carTopNav from "@/component/carTopNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import myInfoLeft from "@/component/myInfoLeft/index.vue";
import {msgDetail} from '@/util/http'

export default{
  components: {carTopNav, bottomNav,myInfoLeft },
  data(){
        return{
          loading: false,
          id:'',
          info:{}
        }
    },
    created(){
      this.id=this.$route.query.id
      msgDetail(this.id).then(res=>{
        this.info=res.data
      }).catch(err=>{
        if(err.msg) this.$message(err.msg)
      })
    },
    methods:{
      backClick(){
        this.$router.go(-1)
      }
    }
}
</script>
<style lang="scss" scoped>
.indexBg{
    width:100%;
  .infoModule{
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 20px 40px;
    .orderTit {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .twoT {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .editBtn {
        margin-bottom: 20px;
        line-height: 26px;
        padding: 5px 15px;
        font-size: 11px;
        color: #FFFFFF;
        text-align: center;
        background: #012F9C;
        border-radius: 2px 2px 2px 2px;
      }
    }
    .selectDiv{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .seleLeft{
        display: flex;
        flex-wrap: wrap;
        //display: grid;
        //grid-template-columns:repeat(2,1fr);
        grid-gap: 20px;
        //border: solid 1px;
        //margin-right: 30px;
        .sub{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          .subInp{
            flex: 1;
          }
          input{
            width: 200px;
            border: 1px solid #DCDFE6;
            border-radius:4px;
            height:36px;
          }
        }
      }
      .seleRig{
        display: flex;
        align-items: center;
        p{
          width: 54px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #012F9C;
          border-radius: 2px 2px 2px 2px;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 13px;
          margin-right: 10px;
        }
        .ac{
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
          margin-left: 15px;
        }
        .exportbtn{
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #012F9C;
          color: #012F9C;
          background: #fff;
        }
      }
    }
   }
  .dialogThreeCss{
    padding: 0 60px;
    margin: auto;
    .checkCss{
      text-align: center;
    }
    .twoInfo {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      text-align: center;
      line-height: 15px;
      span{
        font-weight: bold;
        color: #EB0000;
        font-size: 18px;
        margin-right: 3px;
      }
      input{
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #999999;
        width: 278px;
        height: 26px;
      }
      .infoSub {
        display: flex;
        align-content: center;
        margin:20px 0;
        font-size: 13px;
        color: #333333;
        justify-content: center;
      }
      p{
        display: flex;
        align-items: center;
        justify-content: end;
        color: #999999;
        margin-right:15px;
        width:100px;
      }
    }
  }
  .diaBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    p {
      background: #012F9C;
      border-radius: 4px 4px 4px 4px;
      padding: 15px 20px;
      line-height: 19px;
      margin-left: 20px;
      color: #FFFFFF;
    }

    .no {
      background: #EBEAEA;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #999999;
      font-size: 16px;
      color: #333333;
    }

    .yes {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .couponDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #FF6600;
    line-height: 22px;
    img {
      width: 40px;
      height: 40px;
      //margin-right: 6px;
    }
  }
  .tit{
    font-size: 13px;
    color: #999999;
    padding: 20px 0;
    border-bottom: 2px dotted #EEEEEE;
    margin-bottom: 20px;
    h1{
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
  .msgCon{
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    line-height: 15px;
  }
}
</style>