export default {
    "公用": {
        "¥": "¥",
        "元": "元",
        "至": "至",
        "开始日期": "开始日期",
        "结束日期": "结束日期",
        "：": "：",
        "没有更多了":"没有更多了",
    },
    "登录": {
        "微信扫码登录": "微信扫码登录",
        "账号登录": "账号登录",
        "短信登录": "短信登录",
        "请输入手机号": "请输入手机号",
        "请输入登录密码": "请输入登录密码",
        "请输入短信验证码": "请输入短信验证码",
        "发送验证码": "发送验证码",
        "秒后获取": "秒后获取",
        "重新获取": "重新获取",
        "记录登录状态": "记录登录状态",
        "忘记密码": "忘记密码",
        "微登录信扫码登录": "登录",
        "立即注册": "立即注册",
        "未注册的手机号验证后自动注册": "未注册的手机号验证后自动注册!",
        "账号密码登录": "账号密码登录",
        "登录": "登录",
        "手机号不能为空": "手机号不能为空",
        "密码不能为空": "密码不能为空",
        "验证码不能为空": "验证码不能为空",
        "验证码已发送": "验证码已发送，请注意查收",
        "已有账号": "已有账号，马上登录",
        "请输入注册手机号": "请输入注册手机号",
        "请输入用户密码": "请输入用户密码",
        "提交": "提交",
        "用户注册协议": "用户注册协议",
        "隐私保护协议": "隐私保护协议",
        "密码有误": "密码有误",
        "请设置用户密码": "请设置用户密码",
        "请确认用户密码": "请确认用户密码",
        "邀请码": "邀请码(没有可不填)",
        "我已阅读并同意": "我已阅读并同意",
        "注册成功": "注册成功",
    },
    "首页": {
        "登录": "登录",
        "样品申请流程":"样品申请流程",
        "点击展开":"点击展开",
        "退出登录": "退出登录",
        "注册": "注册",
        "样品查询": "样品查询",
        "签到送积分": "签到送积分",
        "我的订单": "我的订单",
        "消息": "消息",
        "会员中心": "会员中心",
        "语言": 'English',
        "加入收藏": "加入收藏",
        "手机贞光": "手机贞光",
        "请输入内容": "请输入内容",
        "首页": "首页",
        "商品目录": "商品目录",
        "品牌专区": "品牌专区",
        "BOM配单": "BOM配单",
        "授权代理": "授权代理",
        "供应商合作": "供应商合作",
        "我的购物车": "我的购物车",
        "在线销售": "在线销售",
        "购物车": "购物车",
        "我的收藏": "我的收藏",
        "浏览历史": "浏览历史",
        "意见反馈": "意见反馈",
        "活动咨询": "活动咨询",
        "查看全部分类": "查看全部分类",
        "电阻": "电阻",
        "查看全电容部分类": "查看全电容部分类",
        "值得信赖的代理商伙伴共前行": "值得信赖的代理商伙伴共前行",
        "电感器变压器": "电感器/变压器",
        "滤波器": "滤波器",
        "二极管": "二极管",
        "晶体管": "晶体管",
        "集成电路": "集成电路",
        "处理器": "处理器",
        "晶振": "晶振",
        "连接器": "连接器",
        "电路保护": "电路保护",
        "传感器": "传感器",
        "光电子产品": "光电子产品",
        "开关继电器": "开关/继电器",
        "开发板套件": "开发板/套件",
        "电源": "电源",
        "无线射频": "无线射频",
        "模块": "模块",
        "机电产品": "机电产品",
        "工具仪器仪器": "工具/仪器/仪器/..",
        "免费样品申请": "免费样品申请",
        "最新活动": "最新活动",
        "高校计划": "高校计划",
        "新人大礼包": "新人大礼包",
        "积分商城": "积分商城",
        "品牌资源": "品牌资源",
        "领券中心": "领券中心",
        "代理品牌商品精选推荐": "代理品牌商品精选推荐",
        "热门代理新品咨询汇总":"热门代理新品咨询汇总",
        "产品名称": "产品名称",
        "查看更多": "查看更多",
        "服务时间": "服务时间",
        "媒体合作": "媒体合作",
        "关注贞光公众号": "关注贞光公众号",
        "字母索引": "字母索引",
        "新手上路": "新手上路",
        "免责声明": "免责声明",
        "销售条款": "销售条款",
        "联系我们": "联系我们",
        "贞光科技有限公司": "贞光科技有限公司",
        "公网安备": "公网安备",
        "号": "号",
        "备": "备",
        "原厂授权": "原厂授权 正品保障",
        "自营现货": "自营现货 极速发货",
        "品类齐全": "品类齐全 一站采购",
        "满99包邮": "满99包邮 省心省事",
        "关于贞光": "关于贞光",
        "关于我们": "关于我们",
        "新手入门": "新手入门",
        "注册登录": "注册登录",
        "找回密码": "找回密码",
        "收藏功能": "收藏功能",
        "购物指南": "购物指南",
        "购物流程": "购物流程",
        "搜索商品": "搜索商品",
        "订单管理": "订单管理",
        "合同下载": "合同下载",
        "配送支付": "配送支付",
        "支付方式": "支付方式",
        "账户信息": "账户信息",
        "支付安全": "支付安全",
        "运费说明": "运费说明",
        "售后服务": "售后服务",
        "发票须知": "发票须知",
        "重新交付": "重新交付",
        "查看详情": "查看详情",
        "相关商品": "相关商品",
        "简单易用的在线换算工具专区": "简单易用的在线换算工具专区",
        "电容换算器": "电容换算器",
        "电感换算器": "电感换算器",
        "长度换算器": "长度换算器",
        "压力换算器": "压力换算器",
        "温度换算器": "温度换算器",
        "体积换算器": "体积换算器",
        "重量换算器": "重量换算器",
        "特色产品行业独特解决方案一览": "特色产品行业独特解决方案一览",
        "IOT物联网": "IOT物联网",
        "车载": "车载",
        "人工智能": "人工智能",
        "工业应用": "工业应用",
        "消费电子": "消费电子",
        "医疗产品": "医疗产品",
        "丰富多元新闻咨询等你来": "丰富多元新闻咨询等你来",
        "新闻动态": "新闻动态",
        "原厂动态": "原厂动态",
        "型号": "型号",
        "自定义料号": "自定义料号",
        "单价": "单价(含税)",
        "数量": "数量",
        "合计": "合计",
        "进入我的收藏": "进入我的收藏",
        "我的足迹": "我的足迹",
        "清空浏览历史": "清空浏览历史",
        "产品型号": "产品型号",
        "品牌": "品牌",
        "分类": "分类",
        "描述": "描述",
        "最大申请数量": "最大申请数量",
        "操作": "操作",
        "荣誉风采": "荣誉风采",
        "加入样品篮": "加入样品篮",
        "样品筛选": "样品筛选",
        "我的样品篮": "我的样品篮",
        "选择样品": "选择样品",
        "提交申请信息": "提交申请信息",
        "平台审核": "平台审核",
        "等待收货": "等待收货",
        "收货成功": "收货成功",
        "样品申请规则": "样品申请规则",
        "每位会员每月最多可申请1次": "每位会员每月最多可申请1次",
        "每次最多限5种型号且总金额有限制": "每次最多限5种型号且总金额有限制",
        "邀请好友注册后": "邀请好友注册后,每月最多可增加1次",
        "样品订单通过审核后": "样品订单通过审核后，7个工作日内寄出",
        "若有刷单": "若有刷单，有权取消样品申请订单",
        "我的钱包": "我的钱包",
        "优惠券规则": "优惠券规则",
        "协议条款": "协议条款",
        "隐私协议": "隐私协议",
        "注册协议": "注册协议",
        "订单提交协议": "订单提交协议",
        "网站功能": "网站功能",
        "订单问题": "订单问题",
        "物流问题": "物流问题",
        "其他": "其他",
        "移除": "移除",
        "提交申请": "提交申请",
        "请问需要什么帮助": "请问需要什么帮助?",
        "帮助中心": "帮助中心",
        "请输入问题关键词": "请输入问题关键词，如”查询订单”",
        "样品申请信息填写": "样品申请信息填写",
        "提示": "提示:为了便于审核通过，请填写真实信息",
        "公司名称": "公司名称：",
        "职务": "职务：",
        "行业类型": "行业类型：",
        "邮箱": "邮箱：",
        "项目描述": "项目描述：",
        "姓名": "姓名：",
        "联系电话": "联系电话：",
        "收货地区": "收货地区：",
        "收货地址": "收货地址：",
        "取消": "取消",
        "会员首页": "会员首页",
        "账号设置": "账号设置",
        "我的优惠券": "我的优惠券",
    },
    "最新活动": {
        "正在进行": "正在进行",
        "往期活动": "往期活动",
        "正在进行中的热门活动": "正在进行中的热门活动",
        "活动时间": "活动时间",
        "正在进行中所有活动": "正在进行中所有活动",
    },
    "意见反馈": {
        "我们在意您的每一句反馈": "我们在意您的每一句反馈！",
        "请告诉我们您对贞光商城的意见和建议": "请告诉我们您对贞光商城的意见和建议，我们会参考您的反馈不断优化我们的产品和服务。",
        "我的建议": "我的建议（条）",
        "查看": "查看",
        "您想对哪些方面提出意见和建议": "1、您想对哪些方面提出意见和建议？",
        "我们存在哪些不足": "2、我们存在哪些不足？",
        "如果用图片来说明问题": "3、如果用图片来说明问题，请上传(最多3张)",
        "请输请输入入": "请输入",
        "提交": "提交",
        "意见被采用": "意见被采用。我们将给予一定的奖励（奖品有：电阻或电容样品本、开发工具、电子模块商城优惠券或积分等）。",

    },
    "关于我们": {
        "关于贞光": "关于贞光",
        "荣誉风采": "荣誉风采",
        "授权代理": "授权代理",
        "加入我们": "加入我们",
        "联系我们": "联系我们",
        "贞光大事件": "贞光大事件",
        "优势模块": "优势模块",
        "企业介绍": "企业介绍",
    },
    "资质": {
        "资质证书": "资质证书",
        "企业介绍": "企业介绍",
        "我们的优势": "我们的优势",
        "万条": "万条",
        "余年": "余年",
        "行业经验": "行业经验",
        "服务客户数": "服务客户数",
        "品牌代理": "品牌代理",
        "智能仓储": "智能仓储",
        "现货SKU": "现货SKU"
    },
    "授权代理": {
        "代理品牌": "代理品牌",
        "合作伙伴": "合作伙伴",
        "请输入类别关键字": "请输入类别关键字",
        "搜索": "搜索",
        "授权代理": "授权代理",
        "应用领域": "应用领域:",
    },
    "联系我们": {
        "办公地址": "办公地址",
        "联系电话": "联系电话",
        "邮箱": "邮箱",
    },
    "签到": {
        "积分": "积分",
        "赚积分": "赚积分",
        "今日签到": "今日签到",
        "您已连签到": "您已连签到",
        "复制成功": "复制成功",
        "天": "天",
        "我的礼券": "我的礼券",
        "活动说明": "活动说明",
        "公告": "公告",
        "完成任务可获得丰厚积分": "完成任务可获得丰厚积分",
        "恭喜": "恭喜",
        "签到领": "签到领",
        "积分!": "积分！",
        "奖励": "奖励",
        "每日签到": "每日签到",
        "关注公众号": "关注公众号",
        "邀请注册": "邀请注册",
        "上传数据手册": "上传数据手册",
        "完善个人信息": "完善个人信息",
        "支付订单": "支付订单",
        "已签到": "已签到",
        "未关注": "未关注",
        "未邀请": "未邀请",
        "未上传": "未上传",
        "未完善": "未完善",
        "无上限": "无上限",
    },
    "高校": {
        "薪酬福利": "薪酬福利",
        "锻炼提升职称能力": "锻炼提升职称能力",
        "扩展积累人脉": "扩展积累人脉",
        "专业FAE技术指导": "专业FAE技术指导",
        "组织文化": "组织文化",
        "高效的业务方法": "高效的业务方法",
        "业务主导": "业务主导/职务满足/能量",
        "业务集中": "业务集中/业务环境/业务改善",
        "促进创意性的环境": "促进创意性的环境",
        "创意力量": "创意力量/Team协作效应/创出成果",
        "创意领导力": "创意领导力/创意氛围/创意环境",
        "员工间的信赖": "员工间的信赖",
        "凝聚力": "凝聚力/Team自豪感/相互信赖",
        "组织参与": "组织参与/组织关注度/公司信赖",
        "校企合作案例": "校企合作案例",
        "线上申请": "线上申请",
        "提示": "提示：为了便于审核通过，请填写真实信息",
        "联系人姓名": "联系人姓名",
        "手机": "手机",
        "验证码": "验证码",
        "获取验证码": "获取验证码",
        "学校": "学校",
        "年级专业": "年级专业",
        "社团": "社团",
        "微信": "微信/QQ",
        "对于在校宣传有什么想法": "对于在校宣传有什么想法",
        "取消": "取消",
        "提交": "提交",
        "请输入您的真实姓名": "请输入您的真实姓名",
        "请输入您的手机号": "请输入您的手机号",
        "请输入验证码": "请输入验证码",
        "请输入您的学校": "请输入您的学校",
        "请输入您的年级专业": "请输入您的年级专业",
        "请填写您的社团": "请填写您的社团",
        "请填写您的微信": "请填写您的微信/QQ号",
        "简单描述一下您的想法": "简单描述一下您的想法",
    },
    "积分商城": {
        "我的积分": "我的积分",
        "兑换记录": "兑换记录",
        "热门产品": "热门产品",
        "赚取积分": "赚取积分",
        "积分": "积分",
        "商品标签": "商品标签",
        "类型": "类型",
        "每月最多可兑换3次当前还剩下": "每月最多可兑换3次当前还剩下",
        "次": "次",
        "全部": "全部",
        "只显示积分够兑换的物品": "只显示积分够兑换的物品",
        "立即兑换": "立即兑换",
    },
    "品牌资源": {
        "精选品牌授权代理": "精选品牌授权代理",
        "品牌": "品牌",
        "全部": "全部",
        "热门文档": "热门文档",
        "所有文档": "所有文档",
        "时间": "时间",
        "立即下载": "立即下载"
    },
    "领券中心": {
        "贞光领券规则": "贞光领券规则",
        "贞光商城的券": "贞光商城的券分为商品券，优惠券和运费券三种，所有的券不可兑现和转让，过期自动失效；",
        "同一种类型的券": "同一种类型的券不可叠加使用，使用券时，需注意券的限制条件，只有符合条件的商品下单时才能使用;",
        "法律允许范围内": "法律允许范围内，未尽事宜和最终解释权归贞光商城所有;",
        "商品券": "商品券",
        "优惠券": "优惠券",
        "运费券": "运费券",
        "新用户专享优惠券": "新用户专享优惠券",
        "注册完成后": "注册完成后，券即可送达会员中心",
        "元无门槛": "元无门槛",
        "满": "满",
        "减":"减",
        "已经领取过了～":"已经领取过了～",
        "已领取":"已领取",
        "折扣券":"折扣券",
        "可用": "可用",
        "失效日期":"失效日期",
        "元使用": "元使用",
        "有效期": "有效期",
        "日": "日",
        "领券立减": "领券立减",
        "品牌优惠券": "品牌优惠券",
        "已领": "已领",
        "立即领取": "立即领取",
    },
    "商品目录": {
        "全部分类": "全部分类",
        "元器件共计": "元器件共计：",
        "请输入关键字": "请输入关键字",
        "搜索": "搜索",
        "当前": "当前”",
        "共": "“共",
        "共2": "共",
        "件相关商品": "件相关商品",
        "从结果中搜索": "从结果中搜索",
        "输入搜索": "输入搜索",
        "仅显示授权代理品牌": "仅显示授权代理品牌",
        "综合排序": "综合排序",
        "库存": "库存",
        "价格": "价格",
        "型号": "型号",
        "条": "条",
        "商品信息": "商品信息",
        "单价(含税)": "单价(含税)",
        "按需求数量显示价格": "按需求数量显示价格",
        "交期(工作日)": "交期(工作日)",
        "起订/递增": "起订/递增",
        "数量": "数量",
        "操作": "操作",
        "供应商": "供应商：",
        "品牌": "品牌：",
        "封装": "封装：",
        "分类": "分类：",
        "描述": "描述：",
        "库存：": "库存：",
        "起订量": "起订量：",
        "递增量": "递增量：",
        "合计": "合计：",
        "立即购买": "立即购买",
        "加入购物车": "加入购物车",
        "有库存": "有库存",
        "有价格": "有价格",
        "符合RoHS": "符合RoHS",
        "有数据手册": "有数据手册",
        "隐藏不可选": "隐藏不可选",
        "大陆7-9": "大陆7-9",
        "海外3-6": "海外3-6",
        "精选品牌":"精选品牌"
    },
    "BOM": {
        "询价说明": "询价说明：",
        "如采购数量接近整包装数量": "如采购数量接近整包装数量，建议按整包装询价订购，方便运输存储",
        "为保证服务质量和效率": "为保证服务质量和效率，询价型号需满足以下条件，方能得到报价回复",
        "多型号拷贝": "多型号拷贝",
        "Excel导入": "Excel导入",
        "品牌": "例:贞光",
        "参数描述": "例:AC0201FR-0710KL2500300010uF+10% 16V18000603 +20% 10V",
        "需求数量": "例:100",
        "清除": "清除",
        "只匹配自营商品": "只匹配自营商品",
        "开始匹配BOM": "开始匹配BOM",
        "选择文件": "选择文件",
        "备注": "备注",
        "上传BOM需求文件": "上传BOM需求文件",
        "请输入询价的备注内容": "请输入询价的备注内容",
        "智能配单": "智能配单",
        "将文件拖到此处": "将文件拖到此处，或",
        "点击上传": "点击上传",
    },
    "供应商合作": {
        "合作申请": "合作申请",
        "公司名称": "公司名称",
        "商品品牌": "商品品牌：",
        "类别": "类别：",
        "原厂": "原厂",
        "代理商": "代理商",
        "供应商合作": "供应商合作",
        "联系人": "联系人：",
        "手机号": "手机号：",
        "邮箱": "邮箱：",
        "提交": "提交",
        "入住优势": "入住优势",
    },
    "资讯": {
        "热门标签": "热门标签",
        "热门文章": "热门文章",
        "热门物料": "热门物料",
        "型号": "型号",
        "价格": "价格",
        "全部资讯": "全部资讯",
        "请输入关键字": "请输入关键字",
        "搜索": "搜索",
        "全部": "全部",
        "方案": "方案",
        "技术": "技术",
        "问答": "问答",
        "动态": "动态",
        "新闻": "新闻",
        "活动": "活动",
        "发现": "发现",
        "猜你喜欢": "猜你喜欢",
        "上一篇": "上一篇:",
        "下一篇": "下一篇:",
    },
    "品牌专区": {
        "订阅": "订阅",
        "已订阅": "已订阅",
        "贞光已成为": "贞光已成为",
        "官方授权代理商": "官方授权代理商",
        "订阅可获得最新品牌资讯品牌资源和促销活动等":"订阅可获得最新品牌资讯品牌资源和促销活动等",
        "该品牌共有": "该品牌共有",
        "种商品": "种商品",
        "人订阅": "人订阅",
        "授权证明": "授权证明",
        "品牌介绍": "品牌介绍",
        "关于品牌": "关于品牌",
        "授权代理": "授权代理",
        "产品线": "产品线",
        "市场新闻": "市场新闻",
        "授权信息": "授权信息",
        "请输入关键字": "请输入关键字",
        "搜索": "搜索",
        "授权代理证明": "授权代理证明",
        "+订阅": "+订阅",
        "图片": "图片",
        "制造商零件编号": "制造商零件编号",
        "描述": "描述",
        "可供货数量": "可供货数量",
        "操作": "操作",
        "查看详情": "查看详情",
        "请输入类别关键字": "请输入类别关键字",
        "人订阅": "人订阅",
        "订阅可获得最新品牌资讯": "订阅可获得最新品牌资讯，品牌资源和促销活动等，已有",
    },
    "产品方案": {
        "车载": "车载",
        "IoT物联网": "IoT物联网",
        "人工智能": "人工智能",
        "工业应用": "工业应用",
        "消费电子": "消费电子",
        "医疗产品": "医疗产品",
        "产品方案导航": "产品方案导航",
        "推荐产品列表": "推荐产品列表",
        "产品类别": "产品类别",
        "应用位置": "应用位置",
        "品牌": "品牌",
    },
    "换算工具": {
        "贞光商城在线工具": "贞光商城在线工具为电子行业计算提供一站式资源服务",
        "推荐工具": "推荐工具",
        "推荐商品": "推荐商品",
        "数值": "数值",
        "单位": "单位",
        "科学计数法显示": "科学计数法显示",
        "关于": "关于",
        "提示": "提示：换算结果仅供参考！",
    },
    "购物车": {
        "全部": "全部",
        "国内": "国内",
        "海外": "海外",
        "购物车": "购物车",
        "全选": "全选",
        "按时间排序": "按时间排序",
        "请选择要更改的商品": "请选择要更改的商品",
        "继续购物": "继续购物",
        "订单已满": "订单已满",
        "已免运费！": "，已免运费！",
        "商品编号": "商品编号",
        "商品描述": "商品描述",
        "单价（含税）": "单价（含税）",
        "订单数量": "订单数量",
        "小计": "小计",
        "操作": "操作",
        "商品编号：": "商品编号：",
        "自定义料号": "自定义料号：",
        "品牌": "品牌：",
        "商品描述：": "商品描述：",
        "商品单价": "商品单价：",
        "需求数量": "需求数量：",
        "库存": "库存：",
        "批量删除": "批量删除",
        "批量更改国内外": "批量更改国内外",
        "清除失效商品": "清除失效商品",
        "导出商品": "导出商品",
        "批量添加料号": "批量添加料号",
        "已选择": "已选择",
        "个型号": "个型号",
        "总金额(不含运费):": "总金额(不含运费):",
        "积分!": "积分!",
        "您现在下单发货后还可获得": "您现在下单发货后还可获得",
        "去结算": "去结算",
        "热销商品": "热销商品",
        "我的收藏夹": "我的收藏夹",
        "我买过的": "我买过的",
        "产品名称": "产品名称：",
        "加入购物车": "加入购物车",
        "请选择要删除的商品":"请选择要删除的商品",
        "请选择要下单的商品":"请选择要下单的商品"
    },
    "商品详情": {
        "首页": "首页",
        "图片仅供参考": "图片仅供参考，商品以实物为准",
        "商品信息": "商品信息",
        "供应商编号": "商品供应商编号：",
        "品牌": "品牌：",
        "制造商": "制造商：",
        "厂家型号": "厂家型号：",
        "贞光编号": "贞光编号：",
        "产品类别": "产品类别：",
        "供货地": "供货地：",
        "库存": "库存：",
        "操作":"操作",
        "无铅情况/RoHs": "无铅情况/RoHs：",
        "供应商生产/周期": "供应商生产/周期：",
        "描述": "描述：",
        "批次": "批次：",
        "最小包": "最小包：",
        "交期": "交期：",
        "起订量": "起订量：",
        "倍数": "倍数：",
        "商品单价": "商品单价",
        "参数值":"参数值",
        "参数":"参数",
        "加入购物车": "加入购物车",
        "立即购买": "立即购买",
        "新品资讯": "新品资讯",
        "查看更多": "查看更多",
        "品牌资源": "品牌资源",
        "参数问题": "参数问题",
        "数据手册": "数据手册",
        "常见问题": "常见问题",
        "找到类似商品": "找到类似商品：",
        "查看相似商品": "查看相似商品",
        "技巧": "技巧：勾选主要参数，留空一些可替代的参数，点击查看相似商品，即可快速找到替代品了!",
    },
    "订单信息": {
        "订单信息": "订单信息",
        "微信支付": "微信支付",
        "支付宝支付": "支付宝支付",
        "余额支付": "余额支付",
        "对公转账": "对公转账",
        "默认地址": "默认地址",
        "修改": "修改",
        "删除": "删除",
        "添加收货地址": "添加收货地址",
        "配送方式": "配送方式",
        "您可以选择": "您可以选择",
        "请选择": "请选择",
        "商品名称": "商品名称",
        "当前订单已": "当前订单已 免费包邮",
        "下单联系人": "下单联系人",
        "保存": "保存",
        "联系人": "联系人：",
        "QQ": "QQ：",
        "手机": "手机：",
        "微信": "微信：",
        "若订单执行中有需确认的项目": "若订单执行中有需确认的项目，请务必接听0592来电",
        "发票信息": "发票信息",
        "数电增值税(专用)发票(可抵扣)": "数电增值税(专用)发票(可抵扣)",
        "数电增值税(普通)发票(不可抵扣) ": "数电增值税(普通)发票(不可抵扣) ",
        "不开发票(没有发票)": "不开发票(没有发票)",
        "开票公司名称": "开票公司名称：",
        "统一社会信用代码": "统一社会信用代码：",
        "注册地址": "注册地址：",
        "公用电话": "公用电话：",
        "邮箱": "邮箱：",
        "开户银行": "开户银行：",
        "银行账号": "银行账号：",
        "敝司已纳入全面数字化的电子发票试点": "敝司已纳入全面数字化的电子发票试点，电子发票后续将",
        "发送至您预留的邮箱": "发送至您预留的邮箱，如需纸质发票，可自行打印，其法律效力相同。",
        "确认订单信息": "确认订单信息",
        "商品编号": "商品编号",
        "自定义料号": "自定义料号：",
        "品牌": "品牌：",
        "商品描述": "商品描述",
        "单价": "单价",
        "订单数量": "订单数量",
        "合计": "合计",
        "订单备注": "订单备注：",
        "合同号": "合同号：",
        "支付方式": "支付方式",
        "优惠券": "优惠券",
        "商品券": "商品券",
        "运费券": "运费券",
        "暂无优惠券！": "暂无优惠券！",
        "全部优惠": "全部优惠：",
        "商品总额": "商品总额：",
        "运费": "运费：",
        "应付总额": "应付总额：",
        "我已阅读并同意": "我已阅读并同意",
        "订单提交协议": "《订单提交协议》",
        "和": "和",
        "销售条款": "《销售条款》",
        "提交订单": "提交订单",
        "贞光商城": "贞光商城《订单提交协议》和《销售条款》及意愿确认",
        "尊敬的用户": "尊敬的用户，您好!请您务必仔细阅读并确保您已经充分理解协议所写明的条款内容，让您可以按照指引做出您认为适当的选择。以下内容为《订单提交协议》和《销售条款》。",
        "不同意": "不同意",
        "已阅读并同意继续": "订单信息订单信息",
        "请确认发票信息是否正确": "请确认发票信息是否正确？一经开出无法退换。",
        "当前发票信息": "当前发票信息，请核对：",
        "我已确认无误": "我已确认无误！",
        "返回修改": "返回修改",
        "设置开票单位": "设置开票单位",
        "开票单位指发票上的数量单位": "开票单位指发票上的数量单位，请根据贵司需求选择。",
        "选择开票单位": "选择开票单位：",
        "确定": "确定",
        "确认提交": "确认提交",
        "注意1": "注意：1.设置后，此账号都将按该单位进行开票",
        "注意2": "2.设置后不可修改，若要修改，请联系在线销售",
        "您购买的商品中有特惠库存的商品": "您购买的商品中有特惠库存的商品，请注意生产周期， 一经售出概不退换。",
        "取消": "取消",
        "支付信息": "支付信息",
        "订单详情": "订单详情",
        "请使用微信扫一扫": "请使用微信扫一扫",
        "扫描二维码支付": "扫描二维码支付",
        "距离二维码过期还剩": "距离二维码过期还剩",
        "秒": "秒，过期后请刷新页面重新获取二维码",
        "请您在24小时内完成支付": "请您在24小时内完成支付，否则订单会被自动取消 (库存紧俏商品支付时限以订单详情页为准)",
        "订单号": "订单号:",
        "其他支付方式": "其他支付方式",
        "订单状态": "订单状态",
        "物流信息": "物流信息",
        "查看物流信息": "查看物流信息",
        "收货地址": "收货地址",
        "付款信息": "付款信息",
        "修改发票": "修改发票",
        "配送信息": "配送信息",
        "合同信息": "合同信息",
        "热销商品": "热销商品",
        "开票信息": "开票信息",
        "开票详情": "开票详情",
        "订单提交成功": "订单提交成功，请尽快付款!",
        "待审核": "待审核",
        "待支付": "待支付",
        "已支付/待发货": "已支付/待发货",
        "待发货": "待发货",
        "待收货": "待收货",
        "已收货": "已收货",
        "已发货": "已发货",
        "退货待审核": "退货待审核",
        "退货中": "退货中",
        "已退货": "已退货",
        "已取消": "已取消",
        "已完成": "已完成",
        "打印收据": "打印收据",
        "打印合同": "打印合同",
        "打印订单": "打印订单",
        "打印收货单": "打印收货单",
        "订单线路": "订单线路",
        "审核订单": "审核订单",
        "付款成功": "付款成功",
        "仓库备货": "仓库备货",
        "发货完成": "发货完成",
        "物流公司": "物流公司：",
        "物流单号": "物流单号：",
        "发货清单": "发货清单",
        "收货人": "收货人：",
        "地址": "地址：",
        "付款时间": "付款时间：",
        "余额支付：": "余额支付：",
        "发票类型": "发票类型：",
        "发票抬头": "发票抬头：",
        "公司注册地址": "公司注册地址：",
        "公司电话": "公司电话：",
        "统一信用代码": "统一信用代码：",
        "配送方式：": "配送方式：",
        "运费付款方式": "运费付款方式：",
        "运费优惠券抵扣金额": "运费优惠券抵扣金额：",
        "合同下载": "合同下载：",
        "状态": "状态：",
        "点击复制": "点击复制",
        "国内单号": "国内单号",
        "编辑发票": "编辑发票",
        "只能修改一次": "本订单发票信息只能修改一次，财务审核后将通过您的预留邮箱发送电子发票。",
        "开户银行账号": "开户银行账号",
        "将本次发票信息同步到开票资料中": "将本次发票信息同步到开票资料中",
        "邮件通知财务开票": "邮件通知财务开票",
        "重新交付": "重新交付",
        "邮政快递": "邮政快递：",
        "快递件数": "快递件数：",
        "商品型号": "商品型号：",
        "商品数量": "商品数量：",
        "快递快递单号": "快递 快递单号：",
        "数电增值税(专用)发票": "数电增值税(专用)发票",
        "点击复制清单": "点击复制清单",
        "数电增值税(普通)发票": "数电增值税(普通)发票",
        "发票号": "发票号：",
        "请阅读并同意订单提交协议":"请阅读并同意订单提交协议",
        "请添加收获地址":"请添加收获地址",
        "请选择配送方式":"请选择配送方式",
        "请填写下单联系人":"请填写下单联系人",
        "请选择支付方式":"请选择支付方式",
    },
    "会员中心": {
        "个人信息": "个人信息",
        "基本信息": "基本信息",
        "收货地址": "收货地址",
        "开票资料": "开票资料",
        "修改密码": "修改密码",
        "账户安全": "账户安全",
        "订单管理": "订单管理",
        "我的订单": "我的订单",
        "售后服务": "售后服务",
        "资产中心": "资产中心",
        "我的钱包": "我的钱包",
        "我的积分": "我的积分",
        "我的礼券": "我的礼券",
        "我的优惠券": "我的优惠券",
        "服务中心": "服务中心",
        "我的样品": "我的样品",
        "我的收藏": "我的收藏",
        "我的料号": "我的料号",
        "专属链接": "专属链接",
        "我的BOM": "我的BOM",
        "我的消息": "我的消息",
        "我的建议": "我的建议",
        "品牌订阅": "品牌订阅",
        "提醒设置": "提醒设置",
        "我买过的": "我买过的",
        "发票查询": "发票查询",
        "电子对账单": "电子对账单",
    },
    "我的样品": {
        "样品申请单": "样品申请单",
        "免费样品申请单": "免费样品申请单",
        "搜索样品": "搜索样品：",
        "类别": "类别：",
        "审核状态": "审核状态：",
        "状态": "状态：",
        "请选择": "请选择",
        "查询": "查询",
        "重置": "重置",
        "申请单号": "申请单号",
        "申请类别": "申请类别",
        "申请样品": "申请样品",
        "数量": "数量",
        "样品申请单提交后": "样品申请单提交后，我们会在5个工作日内完成审核。",
    },
    "我的收藏": {
        "我的收藏": "我的收藏",
        "查询": "查询",
        "请选择": "请选择",
        "收藏夹": "收藏夹：",
        "搜索商品": "搜索商品：",
        "文章收藏": "文章收藏",
        "搜索文章": "搜索文章：",
        "浏览量": "浏览量",
        "点赞量": "点赞量",
        "收藏量": "收藏量",
        "移除": "移除",
        "商品收藏": "商品收藏",
        "生成据价单": "生成据价单",
        "商品信息": "商品信息",
        "单价（含税）": "单价（含税）",
        "需求数量": "需求数量",
        "合计": "合计",
        "操作": "操作",
        "库存": "库存：",
        "取消收藏": "取消收藏",
        "加入购物车": "加入购物车",
        "全选": "全选",
        "批量移除": "批量移除",
        "清空失效": "清空失效",
        "转移收藏夹": "转移收藏夹",
        "已选": "已选",
        "件商品": "件商品，总计(不含运费)",
    },
    "我的料号": {
        "我的料号": "我的料号",
        "全选": "全选",
        "添加自定义料号": "添加自定义料号",
        "搜索料号": "搜索料号：",
        "输入单号": "输入单号/样品名称",
        "删除所选": "删除所选",
        "导出": "导出",
        "批量导入": "批量导入",
        "料号": "料号",
        "制造商编号": "制造商编号",
        "自定义料号": "自定义料号",
        "更新时间": "更新时间",
        "操作": "操作",
        "编辑": "编辑",
        "删除": "删除",
        "自定义料号只支持数字": "自定义料号只支持数字、字母、\"\"和\"\"字符哟!",
        "编号": "编号：",
        "自定义料号2": "自定义料号：",
        "保存": "保存",
        "取消": "取消",
    },
    "专属链接": {
        "专属二维码": "专属二维码",
        "我的专属二维码": "我的专属二维码",
        "分享给好友": "分享给好友",
        "好友扫一扫识别二维码": "好友扫一扫识别二维码",
        "积分到账": "积分到账",
        "专属链接": "专属链接",
        "向好友发送专属邀请链接": "向好友发送专属邀请链接，好友注册成功后，您即可获得积分奖励!",
        "点击分享链接": "点击分享链接",
        "商城即日起": "商城即日起，老会员推荐新会员，即可获得500积分，最高可获2500积分，超过将不再赠送邀请积分",
        "受邀账号": "受邀账号",
        "邀请奖励": "邀请奖励",
        "首单下单时间": "首单下单时间",
        "首单金额": "首单金额",
        "注册时间": "注册时间",
        "首单发货状态": "首单发货状态",
        "我的专属链接": "我的专属链接",
    },
    "我的BOM": {
        "我的BOM": "我的BOM",
        "新建BOM配单": "新建BOM配单",
        "BOM名称": "BOM名称：",
        "商品型号": "商品型号/自定义料号",
        "时间": "时间：",
        "至": "至",
        "开始日期": "开始日期",
        "结束日期": "结束日期",
        "查询": "查询",
        "重置": "重置",
        "BOM编号": "BOM编号",
        "BOM名称2": "BOM名称",
        "创建时间": "创建时间",
        "操作": "操作",
        "查看详情": "查看详情",
        "移除": "移除",
    },
    "我的消息": {
        "我的消息": "我的消息",
        "查看详情": "查看详情",
        "已提交": "已提交",
        "移除": "移除",
        "操作": "操作",
        "发送时间": "发送时间",
        "状态": "状态",
        "信息标题": "信息标题",
        "全部": "全部",
        "未读": "未读",
        "已读": "已读",
        "(": "(",
        ")": ")",
        "全部标记为已读": "全部标记为已读",
        "删除已读消息": "删除已读消息",
        "消息详情": "消息详情",
        "返回消息列表": "返回消息列表",
        "删除消息": "删除消息",
        "我的建议": "我的建议",
        "去提建议": "去提建议",
        "已采纳": "已采纳",
        "待处理": "待处理:",
        "反馈时间": "反馈时间:",
        "反馈类型": "反馈类型:",
        "品牌订阅": "品牌订阅",
        "订阅更多品牌": "订阅更多品牌",
        "搜索品牌": "搜索品牌：",
        "查询": "查询",
        "图片": "图片",
        "品牌": "品牌",
        "订阅时间": "订阅时间",
        "共有": "共有",
        "种样品": "种样品",
        "人订阅": "人订阅",
        "取消": "取消",
        "提醒设置": "提醒设置",
        "关闭全部提醒": "关闭全部提醒",
        "提醒项目": "提醒项目",
        "描述": "描述",
        "短信提醒": "短信提醒",
        "微信提醒": "微信提醒",
        "微信": "微信",
        "短信": "短信",
    },
    "我的钱包": {
        "钱包余额": "钱包余额",
        "备注": "备注",
        "账户余额": "账户余额",
        "创建时间": "创建时间",
        "金额": "金额",
        "交易类型": "交易类型",
        "交易流水号": "交易流水号",
        "重置": "重置",
        "查询": "查询",
        "时间": "时间",
        "单据类型": "单据类型：",
        "状态": "状态：",
        "提现记录": "提现记录",
        "充值记录": "充值记录",
        "支付明细": "支付明细",
        "所有明细": "所有明细",
        "交易记录明细": "交易记录明细",
        "绑定": "绑定",
        "安全手机": "安全手机：",
        "未绑定": "未绑定",
        "提现账号": "提现账号：",
        "修改": "修改",
        "支付密码": "支付密码：",
        "冻结金额": "冻结金额：",
        "钱包账户信息": "钱包账户信息",
        "提现": "提现",
        "线下对公充值": "线下对公充值",
        "充值金额": "充值金额",
        "充值方式": "充值方式",
        "打款银行": "打款银行",
        "打款账户": "打款账户",
        "支付订单号": "支付订单号",
        "支付时间": "支付时间",
        "状态2": "状态",
        "类别": "类别",
        "提交时间": "提交时间",
        "银行账号": "银行账号",
        "首款开户名": "首款开户名",
        "提现时间": "提现时间",
        "银行单号": "银行单号",
        "绑定银行账号": "绑定银行账号",
        "短信验证码": "短信验证码",
        "验证码": "验证码：",
        "获取验证码": "获取验证码",
        "请输入新的支付密码": "请输入新的支付密码",
        "下一步": "下一步",
        "开户名": "开户名：",
        "请输入开户名": "请输入开户名",
        "开户银行": "开户银行：",
        "请输入开户银行": "请输入开户银行",
        "银行账号2": "银行账号：",
        "请输入银行账号": "请输入银行账号",
        "保存": "保存",
        "修改安全手机号码": "修改安全手机号码",
        "新安全手机": "新安全手机：",
        "请输入新安全手机": "请输入新安全手机",
        "请输入支付密码": "请输入支付密码",
        "设置支付密码": "设置支付密码",
        "设置支付密码2": "设置支付密码：",
        "确认支付": "确认支付：",
        "请确认支付密码": "请确认支付密码",
    },
    "我的积分": {
        "订单号": "订单号",
        "积分类型": "积分类型",
        "积分": "积分",
        "时间": "时间",
        "备注": "备注",
        "查询": "查询",
        "重置": "重置",
        "时间2": "时间：",
        "名称": "名称",
        "使用积分": "使用积分",
        "状态": "状态",
        "兑换类型": "兑换类型",
        "我的积分": "我的积分",
        "将于": "将于",
        "过期": "过期",
        "进入积分商城": "进入积分商城",
        "赚取积分": "赚取积分",
        "积分分类信息": "积分分类信息",
        "消费赠送": "消费赠送",
        "注册获得积分": "注册获得积分",
        "完善信息积分": "完善信息积分",
        "绑定邮箱": "绑定邮箱",
        "关注公众号": "关注公众号",
        "快过期积分": "快过期积分",
        "签到获得积分": "签到获得积分",
        "积分记录明细": "积分记录明细",
        "所有明细": "所有明细",
        "积分记录": "积分记录",
        "兑换记录": "兑换记录",
        "积分类型2": "积分类型：",
    },
    "我的礼券": {
        "我的礼券": "我的礼券",
        "所有礼券": "所有礼券",
        "待兑换": "待兑换",
        "发放中": "发放中",
        "已完成": "已完成",
        "已过期": "已过期",
        "抱歉": "抱歉，您还没有任何相关的礼券！",
    },
    "我的优惠券": {
        "我的优惠券": "我的优惠券",
        "立即领取": "立即领取",
        "已领": "已领",
        "品牌优惠券": "品牌优惠券",
        "满": "满",
        "可用": "可用",
        "已过期": "已过期",
        "已使用": "已使用",
        "未使用": "未使用",
        "所有优惠券": "所有优惠券",
        "+ 领券中心": "+ 领券中心",
        "优惠券使用规则": "优惠券使用规则",
        "邮箱绑定": "邮箱绑定",
        "绑定邮箱": "绑定邮箱，每月可获得最新优惠券消息!",
        "邮箱": "邮箱：",
        "请输入邮箱": "请输入邮箱",
        "验证码": "验证码：",
        "短信验证码": "短信验证码",
        "获取验证码": "获取验证码",
        "提交": "提交",
        "跳过": "跳过，7天后提醒",
    },
    "基本信息": {
        "保存": "保存",
        "获取验证码": "获取验证码",
        "短信验证码": "短信验证码",
        "账号基础信息": "账号基础信息",
        "姓名": "姓名",
        "手机号码": "手机号码",
        "会员登记": "会员登记",
        "修改手机": "修改手机",
        "积分": "积分",
        "首次验证邮箱送": "首次验证邮箱送",
        "绑定": "绑定",
        "邮箱": "邮箱",
        "账号类型": "账号类型",
        "平台客服": "平台客服",
        "账号信息": "账号信息",
        "昵称": "昵称",
        "性别": "性别",
        "职务": "职务",
        "职业类型": "职业类型",
        "行业类型": "行业类型",
        "公司名称": "公司名称",
        "所在区域": "所在区域",
        "详情地址": "详情地址",
        "确认修改": "确认修改",
        "完善每一项资料": "完善每一项资料，可领取",
        "最近已有": "积分，最近已有",
        "人领取共": "人领取共",
        "积分!": "积分!",
        "修改邮箱": "修改邮箱",
        "当前账号密码": "当前账号密码",
        "请输入当前账号的密码": "请输入当前账号的密码",
        "原注册邮箱": "原注册邮箱",
        "请输入原注册邮箱": "请输入原注册邮箱",
        "原验证码": "原验证码",
        "修改邮箱号": "修改邮箱号",
        "请输入要修改的邮箱号": "请输入要修改的邮箱号",
        "修改邮箱验证码": "修改邮箱验证码",
        "请输入邮箱验证码": "请输入邮箱验证码",
        "修改手机号": "修改手机号",
        "注册手机": "注册手机",
        "验证码": "验证码",
        "需要修改手机号": "需要修改手机号",
        "请输入需要的修改手机号": "请输入需要的修改手机号",
        "请输入验证码": "请输入验证码",
    },
    "收货地址": {
        "收货地址": "收货地址",
        "取消": "取消",
        "保存": "保存",
        "设为默认地址": "设为默认地址",
        "收货人座机": "收货人座机",
        "收货人手机": "收货人手机",
        "详细地址": "详细地址",
        "所在区域": "所在区域",
        "收货人": "收货人",
        "应快递公司要求": "应快递公司要求，收货人需实名，才能发货，感谢配合。",
        "添加新的收货地址": "添加新的收货地址",
        "编辑": "编辑",
        "设为默认": "设为默认",
        "默认地址": "默认地址",
        "操作": "操作",
        "手机电话": "手机电话",
        "详情地址": "详情地址",
        "所在地区": "所在地区",
        "添加地址": "添加地址",
        "序号": "序号",
        "删除地址": "删除地址",
    },
    "开票资料": {
        "开票资料": "开票资料",
        "删除资料": "删除资料",
        "添加资料": "添加资料",
        "数电增值税(专用)开票资料": "数电增值税(专用)开票资料",
        "数电增值税(普通)开票资料": "数电增值税(普通)开票资料",
        "操作": "操作",
        "邮箱": "邮箱",
        "开户银行账号": "开户银行账号",
        "公司电话": "公司电话",
        "公司地址": "公司地址",
        "发票抬头": "发票抬头",
        "统一社会信用代码": "统一社会信用代码",
        "企业名称": "企业名称",
        "修改": "修改",
        "编辑发票": "编辑发票",
        "鄙司已纳入全面数字化的电子发票试点": "鄙司已纳入全面数字化的电子发票试点，电子发票后续将由“oneyac invoice@oneyac.com”发送至您预留的邮箱，如需纸质发票，可自 行打印，其法律效力相同。",
        "公司注册地址": "公司注册地址",
        "开户银行": "开户银行",
        "保存": "保存",
        "取消": "取消",
        "修改发票抬头": "修改发票抬头",
        "修改抬头后": "修改抬头后，请务必修改普票和专票的相关资料!",
        "确认修改": "确认修改",
        "添加发票": "添加发票",
        "发票抬头类型": "发票抬头类型",
        "企业": "企业",
        "个人": "个人"
    },
    "修改密码": {
        "手机号验证": "手机号验证",
        "邮箱验证码": "邮箱验证码",
        "修改密码": "修改密码",
        "身份认证": "身份认证",
        "设置操作": "设置操作",
        "完成": "完成",
        "注册手机号": "注册手机号",
        "输入验证码": "输入验证码",
        "获取验证码": "获取验证码",
        "下一步": "下一步",
        "上一步": "上一步",
        "当前密码": "当前密码",
        "新密码": "新密码",
        "确认新密码": "确认新密码",
        "确认修改": "确认修改",
    },
    "账户安全": {
        "登录方式": "登录方式",
        "登录地点": "登录地点(通过您的ip地址查询)",
        "登录IP": "登录IP",
        "登录时间": "登录时间(仅显示最近3个月)",
        "样品申请单提交后": "样品申请单提交后",
        "绑定微信": "绑定微信",
        "账户安全登录信息": "账户安全登录信息",
        "账号": "账号",
        "邮箱": "邮箱",
        "最近登录": "最近登录",
        "普通会员": "普通会员",
        "详情": "详情",
        "安全等级": "安全等级",
        "密码强度": "密码强度",
        "密码强度为": "密码强度为",
        "高强度密码且定期更换密码可以使账号更安全": "高强度密码且定期更换密码可以使账号更安全",
        "低": "低",
        "修改": "修改",
        "绑定邮箱": "绑定邮箱",
        "已绑定邮箱": "已绑定邮箱",
        "自由": "自由",
        "微信账号": "微信账号",
        "若微信丢失或停用": "若微信丢失或停用，请及时更换",
        "绑定手机": "绑定手机",
        "已经绑定手机": "已经绑定手机",
        "登录情况": "登录情况",
        "修改登录密码": "修改登录密码",
        "登录记录若存在非本人或异地登录记录，帐号可能存在风险，建议": "登录记录若存在非本人或异地登录记录",
        "微信扫码绑定": "微信扫码绑定 实时了解商城动态",
        "提交": "提交",
    },
    "我的订单": {
        "我的订单": "我的订单",
        "订单回收站": "订单回收站",
        "所有订单": "所有订单",
        "待审核": "待审核",
        "待付款": "待付款",
        "待发货": "待发货",
        "待收货": "待收货",
        "订单搜索": "订单搜索",
        "更多筛选条件": "更多筛选条件",
        "批量导出订单": "批量导出订单",
        "全部订单": "全部订单",
        "订单详情": "订单详情",
        "收货人": "收货人",
        "金额": "金额",
        "订单状态": "订单状态",
        "操作": "操作",
        "当前时段暂时没有订单哦": "当前时段暂时没有订单哦～",
        "查看全部订单": "查看全部订单",
        "去选购": "去选购",
        "订单号": "订单号",
        "订单型号": "订单型号",
        "品牌": "品牌",
        "单价": "单价",
        "订单数量": "订单数量",
        "已完成": "已完成",
        "再次购买": "再次购买",
        "去支付": "去支付",
    },
    "售后服务": {
        "售后服务": "售后服务",
        "订单搜索": "订单搜索",
        "更多筛选条件": "更多筛选条件",
        "批量导出订单": "批量导出订单",
        "抱歉暂时无相关售后服务记录": "抱歉暂时无相关售后服务记录～",
        "详情": "详情",
        "订单号": "订单号",
        "退货单号": "退货单号",
        "申请时间": "申请时间",
        "退款金额": "退款金额",
        "退款状态": "退款状态",
        "操作": "操作",
        "已完成": "已完成",
        "提交信息": "提交信息",
        "审核通过": "审核通过",
        "退款完成": "退款完成",
        "订单详情": "订单详情",
        "商品总额": "商品总额",
        "运费": "运费",
        "订单总额": "订单总额",
        "支付信息": "支付信息",
        "退款完成相关金额已经退回": "退款完成，相关金额已经退回！",
        "余额支付": "余额支付",
        "商品信息": "商品信息",
        "商品名称": "商品名称",
        "商品数量": "商品数量",
        "退款信息": "退款信息",
        "退款方式": "退款方式",
        "退款说明": "退款说明",
        "退款商品": "退款商品",
    },
    "我买过的": {
        "我买过的": "我买过的",
        "商品型号": "商品型号",
        "商城购买": "商城购买",
        "商品信息": "商品信息",
        "其他分类": "其他分类",
        "商品列表": "商品列表",
        "参数": "参数",
        "品牌": "品牌",
        "单价": "单价",
        "购买次数": "购买次数",
        "最近购买": "最近购买",
        "操作": "操作",
        "点击询价": "点击询价",
    },
    "发票查询": {
        "发票查询": "发票查询",
        "全部": "全部",
        "待开票": "待开票",
        "已开票": "已开票",
        "不开票": "不开票",
        "订单号": "订单号",
        "时间": "时间",
        "查询": "查询",
        "重置": "重置",
        "订单金额": "订单金额",
        "订单状态": "订单状态",
        "发票类型": "发票类型",
        "下单时间": "下单时间",
        "开票状态": "开票状态",
    },
    "电子对账单": {
        "电子对账单": "电子对账单",
        "全部": "全部",
        "待开票": "待开票",
        "已开票": "已开票",
        "不开票": "不开票",
        "订单号": "订单号",
        "时间": "时间",
        "批量导出订单": "批量导出订单",
        "重置": "重置",
        "商品型号": "商品型号",
        "自定义料号": "自定义料号",
        "品牌": "品牌",
        "商品分类": "商品分类",
        "购买数量": "购买数量",
        "下单时间": "下单时间",
        "合计金额": "合计金额",
    },
    "会员": {
        "手机": "手机",
        "邮箱": "邮箱",
        "修改": "修改",
        "提升": "提升",
        "低": "低",
        "安全": "安全",
        "完善资料赚积分": "完善资料赚积分",
        "普通会员": "普通会员",
        "会员等级权益": "会员等级权益",
        "赚取积分": "赚取积分",
        "成长值": "成长值",
        "普通会员权益": "普通会员权益",
        "积分值按": "积分值按 1.00倍订单金额累计",
        "去绑定": "去绑定",
        "绑定邮箱可加强账户安全": "绑定邮箱 绑定邮箱可加强账户安全，首次验证邮箱还能获得200积分。",
        "会员权益": "会员权益",
        "升等级享更多权益": "升等级享更多权益",
        "积分任务": "积分任务",
        "做任务赢翻倍积分": "做任务赢翻倍积分",
        "签到送礼": "签到送礼",
        "签到送10元京东卡": "签到送10元京东卡",
        "每日抽奖": "每日抽奖",
        "抽奖赢巨多好礼": "抽奖赢巨多好礼",
        "商城公告": "商城公告",
        "查看更多": "查看更多",
        "余额": "余额",
        "积分": "积分",
        "收藏": "收藏",
        "未读消息": "未读消息",
        "待审核": "待审核",
        "待付款": "待付款",
        "优惠券": "优惠券",
        "购物车": "购物车",
        "待发货": "待发货",
        "待收货": "待收货",
        "投诉建议": "投诉建议",
        "删除订单": "删除订单",
        "规则说明": "规则说明",
        "会员等级": "会员等级",
        "升级条件": "升级条件",
        "成长值为0的客户": "成长值为0的客户",
        "两次输入密码不一致": "两次输入密码不一致",
        "用户协议未勾选": "用户协议未勾选",
        "密码修改成功": "密码修改成功"
    }
}
