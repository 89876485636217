<template>
  <div id="app">
	  <RouterView/>
  </div>
</template>

<script>
import { RouterView } from 'vue-router';


export default {
  name: 'app',
  components: {
    RouterView
}
}
</script>

<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
  a{
    text-decoration: none;
  }
  input{ outline: none; }
  body{
    padding: 0;
    margin: 0;
  }
a {
  text-decoration: none;
  color: #000;
}
p{
  margin:0;
}
body {
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}
</style>
